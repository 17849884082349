<template>
    <a-card :bordered="false">
        <div>

        </div>
        <div id="components-form-demo-advanced-search">
            <a-form class="ant-advanced-search-form" :form="searchForm" >
                <a-row :gutter="24">

                    <a-col :span="4" v-if="qrImg!=''">
                        <img :src="qrImg" style="width: 80px;" @click="showImg(qrImg)">
<!--                        <img :src="qrImgDeclaration" style="width: 80px; margin-left: 30px;" @click="showImg(qrImgDeclaration)">-->
                        <a-modal :visible="previewVisible" :footer="null" @cancel="closeImgShow">
                            <img alt="example" style="width: 100%;" :src="previewImage" />
                        </a-modal>
                    </a-col>
                    <a-col :span="8" >
                        <a-form-item :label="`上报时间`">
                            <a-range-picker @change="onChange" :default-value="[moment(searchForm.startTime, dateFormat), moment(searchForm.endTime, dateFormat)]" />
                        </a-form-item>
                    </a-col>

                    <a-col :span="4">
                        <a-form-item :label="`数据范围`">
                            <a-select
                                    v-model = "searchForm.dataRange"
                                    placeholder="请选择数据范围"

                            >
                                <a-select-option value="0">
                                    所有
                                </a-select-option>
                                <a-select-option value="1">
                                    去重
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>

                    <a-col :span="6" :style="{ textAlign: 'right', marginTop: '3px'}">
                        <a-button type="primary" icon="search" :loading="loading" @click="search">
                            查询
                        </a-button>
                        <a-button :style="{ marginLeft: '8px' }" icon="download" :loading="exportLoading" type="primary" @click="exportExcelByVue">
                            导出
                        </a-button>
                    </a-col>
                </a-row>


            </a-form>
        </div>
        <a-table
                :columns="columns"
                :data-source="data"
                :pagination="pagination"
                @change="onPageChange"
                :loading="tableLoading"
                :scroll="{ x: 3000}"
        >
            <template slot="dayTemp" slot-scope="text, record">
                <span :style="{color: (record.dayTemp<='37.3' ? '' : 'red')}">{{ text }}</span>
            </template>
            <template slot="userName" slot-scope="text, record">
                <span :style="{color: (record.dayTemp<='37.3' && record.color==1 && record.checkLastStatus==1 && record.highRisk==0
                && record.familyStatus==1  ? '' : 'red')}">{{ text }}</span>
            </template>

            <template slot="color" slot-scope="text, record">
                <span :style="{color: (record.color==1 ? '' : 'red')}">{{ text===null||text==undefined? "" : text === 1? "绿码" : (text === 2? "黄码" : "红码") }}</span>
            </template>
            <template slot="checkLastStatus" slot-scope="text, record">
                <span :style="{color: (record.checkLastStatus==1 ? '' : 'red')}">{{text===null||text==undefined? "" : text === 1? "阴性" : "阳性" }}</span>
            </template>
            <template slot="highRisk" slot-scope="text, record">
                <span :style="{color: (record.highRisk==0 ? '' : 'red')}">{{text===null||text==undefined? "" : text === 0? "否" : "是," }} {{record.remark}}</span>
            </template>
            <template slot="familyStatus" slot-scope="text, record">
                <span :style="{color: (record.familyStatus==1 ? '' : 'red')}">{{ text===null||text==undefined? "" : text === 1? "无异常" : "其他," }}{{record.familyRemark}}</span>
            </template>
            <template slot="image" slot-scope="text">
                <img :src="text" width="80px;">
            </template>
            <template slot="travelCode" slot-scope="text">
                <img :src="text" width="80px;">
            </template>

            <template slot="travelCodeColor" slot-scope="text, record">
                <span :style="{color: (record.travelCodeColor===0 || record.travelCodeColor===1 ? '' : 'red')}">{{text===null||text==undefined? "" : text===0? "" : text === 1? "绿码" : (text === 2? "黄码" : "红码") }}</span>
            </template>
            <template slot="vaccinationImg" slot-scope="text">
                <img :src="text" width="80px;">
            </template>
            <template slot="nucleicAcidImg" slot-scope="text">
                <img :src="text" width="80px;">
            </template>
            <template slot="isolate" slot-scope="text, record">
                <span :style="{color: (record.isolate===0 ? '' : 'red')}">{{ text===null||text===undefined? "" : text === 1? "已隔离" : "未隔离" }}</span>
            </template>
        </a-table>


    </a-card>


</template>

<script>
    import {getEpidemicListByProject, dailyReportStatistics, exportEpidemicListByProject, getEpidemicQrCode} from '@/services/epidemic'
    import {UPLOAD_EPIDEMIC} from '@/services/api'
    import {request} from '@/utils/request'
    import moment from 'moment';
    import table2excel from 'js-table2excel'
    import { storage } from '@/utils/storage'
    import { TOKEN_KEY } from '@/utils/request.js'
    import { useRender } from '@/hooks/useRender.js'

    const columns = [
        {
            title: '姓名',
            dataIndex: 'userName',
            key: 'userName',
            slots: { title: 'userName' },
            scopedSlots: { customRender: 'userName' },
        },
        {
            title: '公司',
            dataIndex: 'officeName',
            key: 'officeName',
            ellipsis: true,
        },
        // {
        //     title: '项目',
        //     dataIndex: 'projectName',
        //     key: 'projectName',
        // },
        {
            title: '身份证号',
            dataIndex: 'idCard',
            key: 'idCard',
        },
        {
            title: '手机号',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: '班组',
            dataIndex: 'teamName',
            key: 'teamName',
        },
        {
            title: '体温|℃',
            dataIndex: 'dayTemp',
            key: 'dayTemp',
            slots: { title: 'dayTemp' },
            scopedSlots: { customRender: 'dayTemp' },
        },
        {
            title: '所属区域/社区',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: '健康码颜色',
            dataIndex: 'color',
            key: 'color',
            slots: { title: 'color' },
            scopedSlots: { customRender: 'color' },
        },
        {
            title: '是否接种疫苗',
            dataIndex: 'isVaccines',
            key: 'isVaccines',
            customRender: (text) => {
                return text===null||text===undefined? "" : text === 0? "否" : "是"
            }
        },
        // {
        //     title: '第一针接种时间',
        //     dataIndex: 'oneTimeVaccines',
        //     key: 'oneTimeVaccines',
        // },
        // {
        //     title: '第二针接种时间',
        //     dataIndex: 'twoTimeVaccines',
        //     key: 'twoTimeVaccines',
        // },
        // {
        //     title: '第三针接种时间',
        //     dataIndex: 'threeTimeVaccines',
        //     key: 'threeTimeVaccines',
        // },
        // {
        //     title: '最近核酸检测时间',
        //     dataIndex: 'checkLastDate',
        //     key: 'checkLastDate',
        // },
        {
            title: '最近核酸检测结果',
            dataIndex: 'checkLastStatus',
            key: 'checkLastStatus',
            slots: { title: 'checkLastStatus' },
            scopedSlots: { customRender: 'checkLastStatus' },
        },
        {
            title: '有无前往高风险地(情况说明)',
            dataIndex: 'highRisk',
            key: 'highRisk',
            slots: { title: 'highRisk' },
            scopedSlots: { customRender: 'highRisk' },
        },
        // {
        //     title: '情况说明',
        //     dataIndex: 'remark',
        //     key: 'remark',
        // },
        {
            title: '家人或周围人群健康状况(情况说明)',
            dataIndex: 'familyStatus',
            key: 'familyStatus',
            slots: { title: 'familyStatus' },
            scopedSlots: { customRender: 'familyStatus' },
        },
        // {
        //     title: '家庭情况说明',
        //     dataIndex: 'familyRemark',
        //     key: 'familyRemark',
        // },
        // {
        //     title: '是否途径确诊病例轨迹',
        //     dataIndex: 'isChannel',
        //     key: 'isChannel',
        //     customRender: (text) => {
        //         return text === 0? "否" : "是"
        //     }
        // },
        // {
        //     title: '是否接触过中高风险地区旅居人员',
        //     dataIndex: 'isHighPlace',
        //     key: 'isHighPlace',
        //     customRender: (text) => {
        //         return text === 0? "否" : "是"
        //     }
        // },
        // {
        //     title: '月内是否与确诊病例直接接触',
        //     dataIndex: 'isTouch',
        //     key: 'isTouch',
        //     customRender: (text) => {
        //         return text === 0? "否" : "是"
        //     }
        // },
        {
            title: '上报日期',
            dataIndex: 'reportDate',
            key: 'reportDate',
            customRender: (text) => useRender.renderDate(text)
        },
        {
            title: '健康码',
            dataIndex: 'image',
            key: 'image',
            slots: { title: 'image' },
            scopedSlots: { customRender: 'image' },
        },
        {
            title: '行程码',
            dataIndex: 'travelCode',
            key: 'travelCode',
            slots: { title: 'travelCode' },
            scopedSlots: { customRender: 'travelCode' },
        },
        {
            title: '行程卡颜色',
            dataIndex: 'travelCodeColor',
            key: 'travelCodeColor',
            slots: { title: 'travelCodeColor' },
            scopedSlots: { customRender: 'travelCodeColor' },
        },
        {
            title: '疫苗接种记录截图',
            dataIndex: 'vaccinationImg',
            key: 'vaccinationImg',
            slots: { title: 'vaccinationImg' },
            scopedSlots: { customRender: 'vaccinationImg' },
        },
        {
            title: '核酸检测记录截图',
            dataIndex: 'nucleicAcidImg',
            key: 'nucleicAcidImg',
            slots: { title: 'nucleicAcidImg' },
            scopedSlots: { customRender: 'nucleicAcidImg' },
        },
        {
            title: '返岗前居住地',
            dataIndex: 'residenceBeforeReturn',
            key: 'residenceBeforeReturn',
            slots: { title: 'residenceBeforeReturn' },
            scopedSlots: { customRender: 'residenceBeforeReturn' },
        },
        {
            title: '返程详细行程',
            dataIndex: 'travelBeforeReturn',
            key: 'travelBeforeReturn',
            slots: { title: 'travelBeforeReturn' },
            scopedSlots: { customRender: 'travelBeforeReturn' },
        },
        {
            title: '血型',
            dataIndex: 'bloodType',
            key: 'bloodType',
            slots: { title: 'bloodType' },
            scopedSlots: { customRender: 'bloodType' },
        },
        {
            title: '是否隔离',
            dataIndex: 'isolate',
            key: 'isolate',
            slots: { title: 'isolate' },
            scopedSlots: { customRender: 'isolate' },
        },
        {
            title: '隔离体温',
            dataIndex: 'isolateTemperature',
            key: 'isolateTemperature',
            slots: { title: 'isolateTemperature' },
            scopedSlots: { customRender: 'isolateTemperature' },
        },
    ];

    export default {
        data() {
            return {
                headers: {
                    [TOKEN_KEY]: storage.get(TOKEN_KEY),
                    'Tenant-Id': storage.get('Tenant-Id') ? storage.get('Tenant-Id') : ''
                },

                // 筛选
                searchForm: {
                    dataRange: "0"
                },
                loading: false,

                previewVisible: false,

                qrImg: "",
                qrImgDeclaration: "",
                previewImage: "",

                // 数据表格
                columns,
                data: [],
                pagination:{
                    current:1,
                    pageSize:10,
                    total:0,
                },
                tableLoading: false,
                exportLoading: false,
                dateFormat: 'YYYY-MM-DD',
                uploadEpidemic: UPLOAD_EPIDEMIC

            };
        },
        created() {
            this.initData()
        },
        methods: {
            moment,
            initData() {
                this.searchForm.endTime = this.getDay(0) + " 23:59:59"
                this.searchForm.startTime = this.getDay(-7) + " 00:00:00"
                this.searchForm.pageNumber = 1
                this.pagination.pageNumber = 1
                this.getEpidemicListByProject()
                getEpidemicQrCode().then(res=>{
                    if (res.data.code === 0){
                        this.qrImg = res.data.data.qrBase64
                        this.qrImgDeclaration = res.data.data.qrCodeBase64_declaration
                    }
                })
            },
            onChange(date, dateString) {
                this.searchForm.startTime = dateString[0] + " 00:00:00"
                this.searchForm.endTime = dateString[1] + " 23:59:59"
            },
            search() {
                this.searchForm.pageNumber = 1
                this.pagination.pageNumber = 1
                this.getEpidemicListByProject()
            },
            onPageChange(e){
                this.searchForm.pageNumber = e.current;
                this.pagination.current=e.current;
                this.getEpidemicListByProject();
            },
            getEpidemicListByProject(){
                this.data = []
                this.detailsData = []
                this.loading = true
                this.tableLoading = true
                getEpidemicListByProject(this.searchForm).then(res=>{
                    this.data = res.data.data.list
                    this.pagination.total = res.data.data.total
                }).finally(()=>{
                    this.loading = false
                    this.tableLoading = false
                })
            },
            exportExcel(){
                this.exportLoading = true;
                exportEpidemicListByProject(this.searchForm).then((res) => {
                    if(res.data.code == -1){
                        this.$message.warning(res.data.msg)
                    } else  {
                        console.log(res)
                        let fileName = '健康上报记录.xls'


                        // let tableData = res.data
                        // let blob = new Blob([tableData], {type: 'application/vnd.ms-excel'})//创建Blob对象,Blob(blobParts[, options])blobParts： 数组类型， 数组中的每一项连接起来构成Blob对象的数据

                        let downloadElement = document.createElement('a')//创建dom

                        // let href = window.URL.createObjectURL(blob) //创建下载链接
                        let href = window.URL.createObjectURL(res.data) //创建下载链接

                        downloadElement.href = href
                        downloadElement.download = fileName //下载后文件名
                        document.body.appendChild(downloadElement)//添加创建的节点
                        downloadElement.click() //点击下载
                        document.body.removeChild(downloadElement) //下载完成移除元素
                        window.URL.revokeObjectURL(href) //释放掉blob对象
                        this.isLoading = false
                    }
                }).catch((err) => {
                    setTimeout(() => {
                        this.exportLoading = false
                    }, 500);
                }).finally(()=>{
                    this.loading = false
                    this.tableLoading = false
                    this.exportLoading = false
                })
            },

            closeImgShow() {
                this.previewVisible = false;
            },
            showImg(imgPath){
                this.previewVisible = true;
                this.previewImage = imgPath;
            },
            getDay(day){
                var today = new Date();
                var targetday_milliseconds=today.getTime() + 1000*60*60*24*day;
                today.setTime(targetday_milliseconds); //注意，这行是关键代码
                var tYear = today.getFullYear();

                var tMonth = today.getMonth();

                var tDate = today.getDate();

                tMonth = this.doHandleMonth(tMonth + 1);

                tDate = this.doHandleMonth(tDate);

                return tYear+"-"+tMonth+"-"+tDate;

            },
            doHandleMonth(month){
                var m = month;
                if(month.toString().length == 1){
                    m = "0" + month;
                }
                return m;

            },

            exportExcelByVue(){
                const column = [
                    {
                        title: '姓名',
                        key: 'userName',
                        type: 'text'
                    },
                    {
                        title: '公司',
                        key: 'officeName',
                        type: 'text'
                    },
                    {
                        title: '身份证号',
                        key: 'idCard',
                        type: 'text',
                    },
                    {
                        title: '手机号',
                        key: 'phone',
                        type: 'text'
                    },
                    {
                        title: '班组',
                        key: 'teamName',
                        type: 'text'
                    },
                    {
                        title: '体温|℃',
                        key: 'dayTemp',
                        type: 'text'
                    },
                    {
                        title: '所属区域/社区',
                        key: 'address',
                        type: 'text'
                    },
                    {
                        title: '健康码颜色',
                        key: 'color',
                        type: 'text'
                    },
                    {
                        title: '是否接种疫苗',
                        key: 'isVaccines',
                        type: 'text'
                    },
                    {
                        title: '第一针接种时间',
                        key: 'oneTimeVaccines',
                        type: 'text',
                        customRender: (text) => useRender.renderDate(text)
                    },
                    {
                        title: '第二针接种时间',
                        key: 'twoTimeVaccines',
                        type: 'text',
                        customRender: (text) => useRender.renderDate(text)
                    },
                    {
                        title: '第三针接种时间',
                        key: 'threeTimeVaccines',
                        type: 'text',
                        customRender: (text) => useRender.renderDate(text)
                    },
                    {
                        title: '最近核酸检测时间',
                        key: 'checkLastDate',
                        type: 'text',
                        customRender: (text) => useRender.renderDate(text)
                    },
                    {
                        title: '最近核酸检测结果',
                        key: 'checkLastStatus',
                        type: 'text'
                    },
                    {
                        title: '有无前往高风险地',
                        key: 'highRisk',
                        type: 'text'
                    },
                    {
                        title: '情况说明',
                        key: 'remark',
                        type: 'text'
                    },
                    {
                        title: '家人或周围人群健康状况',
                        key: 'familyStatus',
                        type: 'text'
                    },
                    {
                        title: '家庭情况说明',
                        key: 'familyRemark',
                        type: 'text'
                    },
                    {
                        title: '上报日期',
                        key: 'reportDate',
                        type: 'text',
                        customRender: (text) => useRender.renderDate(text)
                    },
                    {
                        title: '健康码',
                        key: 'image',
                        type: 'image',
                        width: 110,
                        height: 260
                    },
                    {
                        title: '行程码',
                        key: 'travelCode',
                        type: 'image',
                        width: 110,
                        height: 260
                    },
                    {
                        title: '行程卡颜色',
                        key: 'travelCodeColor',
                        type: 'text'
                    },
                    {
                        title: '疫苗接种记录截图',
                        key: 'vaccinationImg',
                        type: 'image',
                        width: 110,
                        height: 260
                    },
                    {
                        title: '核酸检测记录截图',
                        key: 'nucleicAcidImg',
                        type: 'image',
                        width: 110,
                        height: 260
                    },
                    {
                        title: '返岗前居住地',
                        key: 'residenceBeforeReturn',
                        type: 'text'
                    },
                    {
                        title: '返程详细行程',
                        key: 'travelBeforeReturn',
                        type: 'text'
                    },
                    {
                        title: '血型',
                        key: 'bloodType',
                        type: 'text'
                    },
                    {
                        title: '是否隔离',
                        key: 'isolate',
                        type: 'text'
                    },
                    {
                        title: '隔离体温',
                        key: 'isolateTemperature',
                        type: 'text'
                    },
                ]
                this.exportLoading = true;
                exportEpidemicListByProject(this.searchForm).then((res) => {
                    table2excel(column, res.data.data, "健康上报.xls")
                }).finally(()=>{
                    this.exportLoading = false;
                })
            }



        },
    };


</script>

<style scoped>
    .ant-advanced-search-form {
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        margin-bottom: 10px;
    }

    .ant-advanced-search-form .ant-form-item {
        display: flex;
    }

    .ant-advanced-search-form .ant-form-item-control-wrapper {
        flex: 1;
    }

    #components-form-demo-advanced-search .ant-form {
        max-width: none;
    }

    tr{
        background-color: #ffffff;
    }

</style>
