import axios from 'axios'
import { getSystemType } from '@/utils/system.js'
import { TOKEN_KEY } from '@/utils/request.js'
import { storage } from '@/utils/storage'
 
const exportProgress = {
  methods: {
    exportFun(url,data,fileName,fileList){
      const token = storage.get(TOKEN_KEY)
      axios({
        method:"POST",
        url,
        data,
        timeout:3000000,
        responseType: 'blob', 
        headers:{ 
          'Content-Type': 'application/json; application/octet-stream',
          'Tenant-Id': storage.get('Tenant-Id') ? storage.get('Tenant-Id') : '',
          'System-Type': getSystemType(),
          ...(token ? { [TOKEN_KEY]: token } : {})
        },
        onDownloadProgress: (progressEvent)=> {
          //设置后台的代码  response.setContentLengthLong(文件长度); 
          if(fileList&&fileList.length==1){
            this.loadingText = "正在下载"+(fileList[0].userName?fileList[0].userName+"的":"")+"资料，请稍候……";
          }
          if(fileList&&fileList.length>1){
            let index = Math.ceil((progressEvent.loaded/progressEvent.total)*fileList.length);
            index = index-1;
            this.loadingText = "正在下载"+(fileList[index].userName?fileList[index].userName+"的":"")+"资料，请稍候……";
          }
          this.cPercent = parseInt((progressEvent.loaded / progressEvent.total) * 100);

          if( this.cPercent==100){
            setTimeout(()=>{ this.isExporting=false},500)
          }
        },
      }).then((res) => {
        this.isExporting=false
        if(res.type == 'application/json'){
          this.isExporting=false
          this.$message.error("导出失败")
        }else{
          const link = document.createElement('a');
          var blob = new Blob([res.data], {type: "application/zip"})
          link.style.display = 'none';
          link.href = URL.createObjectURL(blob);
          link.download =fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }).catch(error => {
        this.isExporting=false
        this.$message.error("导出异常……")
        console.log(error);
      });
    },
    exportFunNomal(url,data,fileName){
      const token = storage.get(TOKEN_KEY)
      axios({
        method:"POST",
        url,
        data,
        timeout:3000000,
        responseType: 'blob', 
        headers:{ 
          'Content-Type': 'application/json; application/octet-stream',
          'Tenant-Id': storage.get('Tenant-Id') ? storage.get('Tenant-Id') : '',
          'System-Type': getSystemType(),
          ...(token ? { [TOKEN_KEY]: token } : {})
        },
        onDownloadProgress: (progressEvent)=> {
          this.cPercent = parseInt((progressEvent.loaded / progressEvent.total) * 100);
          if( this.cPercent==100){
            setTimeout(()=>{ this.isExporting=false},500)
          }
        },
      }).then((res) => {
        this.isExporting=false
        if(res.type == 'application/json'){
          this.isExporting=false
          this.$message.error("导出失败")
        }else{
          const link = document.createElement('a');
          var blob = new Blob([res.data], {type: "application/zip"})
          link.style.display = 'none';
          link.href = URL.createObjectURL(blob);
          link.download =fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }).catch(error => {
        this.isExporting=false
        this.$message.error("导出异常……")
        console.log(error);
      });
    },

    exportFunNomal2(url,data,fileName,fileType){
      const token = storage.get(TOKEN_KEY)
      axios({
        method:"POST",
        url,
        data,
        timeout:3000000,
        responseType: 'blob', 
        headers:{ 
          'Content-Type': 'application/json; application/octet-stream',
          'Tenant-Id': storage.get('Tenant-Id') ? storage.get('Tenant-Id') : '',
          'System-Type': getSystemType(),
          ...(token ? { [TOKEN_KEY]: token } : {})
        },
        onDownloadProgress: (progressEvent)=> {
          this.cPercent = parseInt((progressEvent.loaded / progressEvent.total) * 100);

          if( this.cPercent==100){
            setTimeout(()=>{ this.isExporting=false},500)
          }
        },
      }).then((res) => {
        this.isExporting=false
        if(res.type == 'application/json'){
          this.isExporting=false
          this.$message.error("导出失败")
        }else{
          const link = document.createElement('a');
          var blob = new Blob([res.data], {type:fileType})
          link.style.display = 'none';
          link.href = URL.createObjectURL(blob);
          link.download =fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }).catch(error => {
        this.isExporting=false
        this.$message.error("导出异常……")
        console.log(error);
      });
    },

    exportFunNomal3(url, data) {
      const token = storage.get(TOKEN_KEY);
      axios({
        method: "POST",
        url,
        data,
        timeout: 3000000,
        responseType: 'blob', // 确保响应类型为 Blob
        headers: { 
          'Content-Type': 'application/json; application/octet-stream',
          'Tenant-Id': storage.get('Tenant-Id') ? storage.get('Tenant-Id') : '',
          'System-Type': getSystemType(),
          ...(token ? { [TOKEN_KEY]: token } : {})
        },
        onDownloadProgress: (progressEvent) => {
          if (progressEvent.total) {
            this.cPercent = parseInt((progressEvent.loaded / progressEvent.total) * 100);
            if (this.cPercent === 100) {
              setTimeout(() => { this.isExporting = false }, 500);
            }
          }
        },
      }).then((res) => {
        this.isExporting = false;
        const disposition = res.headers['content-disposition'];
        const fileNameMatch = disposition ? disposition.match(/filename[^;=\n]*[=\n]*([^;\n]*)/) : null;
        const fileName = fileNameMatch ? decodeURIComponent(fileNameMatch[1].replace(/"/g, '')) : 'defaultFileName';
    
        if (res.data.type === 'application/json') {
          this.$message.error("导出失败");
        } else {
          // Create a link element
          const link = document.createElement('a');
          link.href = URL.createObjectURL(res.data);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }).catch(error => {
        this.isExporting = false;
        this.$message.error("导出异常……");
        console.log(error);
      });
    },

    exportFunAlone(url,data,fileName,items,fileList){
      const token = storage.get(TOKEN_KEY)
      axios({
        method:"POST",
        url,
        data,
        timeout:3000000,
        responseType: 'blob', 
        headers:{ 
          'Content-Type': 'application/json; application/octet-stream',
          'Tenant-Id': storage.get('Tenant-Id') ? storage.get('Tenant-Id') : '',
          'System-Type': getSystemType(),
          ...(token ? { [TOKEN_KEY]: token } : {})
        },
        onDownloadProgress: (progressEvent)=> {
          let ind = 0; 
          fileList.forEach((o,i) =>{
            if(o.key==items[0].key){
              ind = i;
            }
          })
          this.cPercent = parseInt(((progressEvent.loaded / progressEvent.total)*(1/fileList.length)+(ind/fileList.length)) * 100);
          // console.log("当前进度个人下载进度:"+progressEvent.loaded / progressEvent.total)
          // console.log("当前个人占比:"+(1/fileList.length))
          // console.log("当前进度:"+this.cPercent)
          // console.log("已下载进度:"+(ind/fileList.length))
          if( this.cPercent==100){
            setTimeout(()=>{ this.isExporting=false},500)
          }
        },
      }).then((res) => {
        let ind = 0; 
        fileList.forEach((o,i) =>{
          if(o.key==items[0].key){
            ind = i;
          }
        })
        this.overDownIndex = this.overDownIndex+1;
        this.cPercent = (ind+1)/fileList.length*100;
        if((ind+1) >= fileList.length){
          this.loadingText = '已下载完成...';
          if( this.cPercent==100){
            setTimeout(()=>{ this.isExporting=false},500)
          }
        }else{
          this.dowmName = fileList[ind+1].userName;
          this.loadingText = '正在下载'+this.dowmName+"资料...";
        }
        if(res.type == 'application/json'){
          this.$message.error("导出失败")
        }else{
          const link = document.createElement('a');
          var blob = new Blob([res.data], {type: "application/zip"})
          link.style.display = 'none';
          link.href = URL.createObjectURL(blob);
          link.download =fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }).catch(error => {
        this.$message.error("导出异常……")
      });
    }
  }
}
export { exportProgress }