<template>
    <div class="a_Approval">
        <a-form-model :model="params" class="clearfix" @submit="handleSubmit">
            <a-col :span="4" v-if="!isProject && !isBranchOffice">
                <a-form-model-item label="公司" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">

                    <a-select v-model="params.selected" allowClear placeholder="">
                        <!-- <a-select-option value="">全部</a-select-option> -->
                        <a-select-option v-for="item in onlyGcglbList" :key="item.value" :value="item.value"
                            :title="item.label">
                            {{ item.label }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
            </a-col>
            <a-col :span="5" v-if="!isProject">
                <a-form-model-item label="项目" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
                    <a-input v-model="params.projectName" allow-clear />
                </a-form-model-item>
            </a-col>
            <a-col :span="5">
                <a-form-model-item label="方案名称" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
                    <a-input v-model="params.sgfaName" allow-clear />
                </a-form-model-item>
            </a-col>
            <a-col :span="4">
              <a-form-model-item label="是否超规模" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 0 }">
                <a-select v-model="params.overRange" allowClear placeholder="" style="width: 120px">
                  
                  <a-select-option v-for="item in options" :key="item.value" :value="item.value" :title="item.label">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="4">
                <a-form-model-item label="编制时间" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 0 }">

                    <a-date-picker v-model="params.makeTime" valueFormat="YYYY-MM-DD" style="width: 140px" placeholder="" />
                </a-form-model-item>
            </a-col>
            <a-col :span="4">
                <a-form-model-item label="最终审批时间" :labelCol="{ span: 9 }" :wrapperCol="{ span: 14, offset: 0 }">

                    <a-date-picker v-model="params.aproveTime" valueFormat="YYYY-MM-DD" style="width: 140px" placeholder="" />
                </a-form-model-item>
            </a-col>


            <a-col :span="4">
                <a-space class="btnBox">
                    <a-button type="primary" html-type="submit"> 查询 </a-button>
                    <a-button @click="resetQuery"> 重置 </a-button>
                </a-space>
            </a-col>
        </a-form-model>
        <standard-table :columns="columns" :dataSource="tableData" rowKey="id" :pagination="pagination"
            :loading="tableLoading" @change="handleChangePage" :isAlert="false" :bordered="true" :btnArr="tableBtnArr"
             :scroll="{ x: 1400 }">
            <!-- @selectedRowChange="handleSelectedRowsChange" :totalBoolean="true" :rowColor="false" -->
            <template slot="index" slot-scope="{ index }">
                <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
            </template>
            <template slot="expertProve" slot-scope="{ record }">
                <a-tag color="#108ee9" v-if="record.expertProve == 1">是</a-tag>
                <a-tag color="#f50" v-if="record.expertProve == 0">否</a-tag>
            </template>
            <template slot="overRange" slot-scope="{ record }">
                <a-tag color="#108ee9" v-if="record.overRange == 1">是</a-tag>
                <a-tag color="#f50" v-if="record.overRange == 0">否</a-tag>
            </template>

            <template slot="action" slot-scope="{ record }">
                <div class="sTables">
                    <div v-if="isProject" class="y-edit" @click="handleEdit(record)">编辑</div>
                    <div v-if="isProject" class="y-del" @click="handleDel(record)">删除</div>
                    <div v-if="!isProject" class="y-other" @click="handleView(record)">详情</div>
                </div>
            </template>
        </standard-table>
        <detail :visible.sync="visible" :isEdit="isEdit" :projectInfo="projectInfo" :id="id" @closeDialog="closeDialog" @refresh="handleQuery" />

    </div>
</template>

<script>

import {
    constructplanList, constructplanDelete, constructplanExport
} from "@/services/dangerousProject";

import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
import moment from "moment";
import detail from "./components/csDetail.vue";
import { getProjectDetail } from "@/services/equipmentLedger";

import mixin from './components/mixin'
import { QUERY_OFFICE_BY_USER } from "@/services/api";
import { request } from "@/utils/request";

export default {
    name: "emerTeam",
    components: {
        StandardTable,
        detail
    },
    mixins: [mixin],
    data() {
        return {
            params: {
            "companyId": "",  // 公司ID 
            "branchOfficeId": "",  // 分公司ID 
            "projectId": "",  // 项目ID 
            selected: '',
            projectName: '',
            "sgfaName": "",  // 施工方案名称
            "overRange": "",  // 是否超过一定规模 0 否 1 是  
            "proveTime": "",  // 论证时间 yyyy-MM-dd
            "aproveTime": "",  // 最终审批时间 yyyy-MM-dd
            "makeTime": "" , // 编制时间 yyyy-MM-dd
            "pageNo":1,//页码
            "page":10//每页条数  
            },
            pagination: {
                //分页数据
                current: 1,
                pageSize: 10,
                total: 0,
                showTotal: (total, range) => `共 ${total} 条`,
            },
            total: 0,
            tableData: [],
            tableLoading: false, //table加载中
            selectedRows: [],
            tmpSelectedRows: [],

            visible: false,
            isEdit: 0,
            id: "",
            projectInfo: {
                parentOffice: '',
                parentOfficeId: '',
                gcglbId: '',
                gcglbName: '',
                projectId: '',
                projectName: ''
            }
        };
    },
    created() {
        if(this.isProject) {
            let d = this.user.loginLaoWuUser.projectId
            getProjectDetail(d).then(res => {

                if (res.data.code === 0) {
                    
                    this.projectInfo.parentOfficeId = res.data.data.parentOfficeId;
                    this.projectInfo.parentOffice = res.data.data.parentOffice;
                    this.projectInfo.gcglbId = res.data.data.gcglbId;
                    this.projectInfo.gcglbName = res.data.data.gcglbName;
                    this.projectInfo.projectId = res.data.data.projectId;
                    this.projectInfo.projectName = res.data.data.projectName;
                    
                    this.params.projectId = this.projectInfo.projectId;
                    this.params.companyId = this.projectInfo.parentOfficeId;
                    this.params.branchOfficeId = this.projectInfo.gcglbId;
                } 

                this.handleQuery();
            })
        } else {
            // this.columns = this.columns.filter(el => el.title != '操作')
            if (this.isBranchOffice) {
                this.params.branchOfficeId = this.user.officeId
                this.handleQuery();
            } else {
                // 公司  集团
                request(QUERY_OFFICE_BY_USER, "get").then((res) => {
                    let data = res.data.data;
                    const arr1 = []
                    data.forEach((item) => {
                    
                        arr1.push({
                            label: item.master,
                            value: item.id,
                            grade: item.grade
                        });
                    });
                    // grade = 99 是分公司  其他是公司
                    let g1 = arr1.filter(el => el.grade == 99)
                    let g2 = arr1.filter(el => el.grade != 99)
                    let p1 = g1.map(item => item.value).join(',')
                    let p2 = g2.map(item => item.value).join(',')
                    
                    if(this.isSafe) {
                        
                        // this.params.branchOfficeId = p1
                        this.params.companyId = p2
                    }
    
                    this.handleQuery();
                });
            }
        }

    },
    computed: {
        tableBtnArr() {
            return [
                {
                    name: '新增',
                    attrs: {
                        type: 'primary'
                    },
                    hidden: !this.isProject,
                    click: this.addNewComplaint
                },
                {
                    name: '导出',
                    attrs: {
                        type: 'primary'
                    },
                    // hidden: !this.isJSOffice,
                    click: this.bathDownload
                }
            ]
        }
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            this.params.pageNo = 1
            this.pagination.current = 1
            this.handleQuery();
        },
        handleQuery(e) {
            this.tableData = []
            this.tableLoading = true
            let d = this.params;
            d.makeTime = d.makeTime ? moment(d.makeTime).format('YYYY-MM-DD') : ''
            d.aproveTime = d.aproveTime? moment(d.aproveTime).format('YYYY-MM-DD') : ''
            if(d.selected) {
                let dd = this.onlyGcglbList.find(el => el.value == d.selected)
                if(dd.grade == 99) {
                    d.branchOfficeId = d.selected
                    d.companyId = ''
                } else {
                    d.companyId = d.selected
                    d.branchOfficeId = ''
                }
            }
            constructplanList(d).then((res) => {
                this.tableLoading = false
                if (res.data.code == 0) {
                    let list = res.data.data.list;

                    this.tableData = list.map((item, index) => {
                        
                        return {
                            ...item,
                        };
                    })
                    this.pagination.total = res.data.data.total;
                } else {
                    this.$message.error(res.data.msg)
                }
            });
        },
        handleChangePage(e) {
            this.params.pageNo = e.current;
            this.params.page = e.pageSize;
            this.pagination.current = e.current;
            this.handleQuery();
        },
        addNewComplaint() {
            this.isEdit = 0
            this.id = ''
            this.visible = true
        },
        handleEdit(e) {

            this.isEdit = 1
            this.id = e.id
            this.visible = true
        },
        handleView(e) {

            this.isEdit = 2
            this.id = e.id
            this.visible = true
        },
        handleDel(record) {
            const _that = this
            this.$confirm({
                title: '是否确定删除？',
                cancelText: '否',
                okText: '是',
                onOk: () => {
                    constructplanDelete({ id: record.id }).then(res => {
                        if (res.data.code == 0) {
                            _that.$message.success('删除成功')
                            this.handleQuery(this.params);
                        } else {
                            _that.$message.error(res.data.msg)
                        }
                    })
                },
            })
        },
        resetQuery() {
            this.pagination.current = 1
            this.params = {
                "companyId": "",  // 公司ID 
                "branchOfficeId": "",  // 分公司ID 
                selected: '',
                "projectId": "",  // 项目ID 
                "sgfaName": "",  // 施工方案名称
                "overRange": "",  // 是否超过一定规模 0 否 1 是  
                "proveTime": "",  // 论证时间 yyyy-MM-dd
                "aproveTime": "",  // 最终审批时间 yyyy-MM-dd
                "makeTime": "" , // 编制时间 yyyy-MM-dd
                "pageNo":1,//页码
                "page":10//每页条数  
            };
            if(this.isProject) {
                this.params.projectId = this.projectInfo.projectId;
                this.params.companyId = this.projectInfo.parentOfficeId;
                this.params.branchOfficeId = this.projectInfo.gcglbId;
            } else {
                // 公司  分公司
                if (this.isBranchOffice) {
                    this.params.branchOfficeId = this.user.officeId
                } else {
                    // 公司  集团
                    let g1 = this.onlyGcglbList.filter(el => el.grade != 99)
                    let p1 = g1.map(item => item.value).join(',')
                    if(this.isSafe) {
                        this.params.companyId = p1
                    } else {
                        this.params.branchOfficeId = ''
                        this.params.companyId = ''

                    }
                }
            }
            this.handleQuery();
        },
        bathDownload() {
            // 取查询参数导出 companyId=&branchOfficeId=&projectId=&sgfaName=&overRange=&aproveTime=&makeTime=
            let url = constructplanExport + '?'+ `companyId=${this.params.companyId}&branchOfficeId=${this.params.branchOfficeId || ''}&projectName=${this.params.projectName}&projectId=${this.params.projectId}&sgfaName=${this.params.sgfaName}&overRange=${this.params.overRange || ''}&aproveTime=${this.params.aproveTime}&makeTime=${this.params.makeTime}`
            window.open(url)
        },
        closeDialog() {
            this.visible = false
        },

        

    },
};
</script>

<style lang="less" scoped>
.query-form {
    display: flex;
    // justify-content: space-between;
}

.post-border {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 5px;
    padding-top: 5px;
    // padding-top: 10px;
    // height: 40px;
}

.post-border:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.sTables {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.y-edit {
    color: #307dfa;
    cursor: pointer;
}
.y-del {
    color: #ff5c77;
    cursor: pointer;
}
.y-other {
    color: #615e83;
    cursor: pointer;
}
</style>