var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c(
        "a-form-model",
        {
          staticClass: "clearfix",
          attrs: { model: _vm.params },
          on: { submit: _vm.handleSubmit },
        },
        [
          _c(
            "a-col",
            { attrs: { span: 4 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "年份",
                    labelCol: { span: 6 },
                    wrapperCol: { span: 14, offset: 1 },
                  },
                },
                [
                  _c("a-date-picker", {
                    staticStyle: { width: "140px" },
                    attrs: {
                      mode: "year",
                      placeholder: "请输入年份",
                      format: "YYYY",
                      open: _vm.isOpen,
                    },
                    on: {
                      openChange: _vm.monthOpenChangeOne,
                      panelChange: _vm.monthPanelChangeOne,
                    },
                    model: {
                      value: _vm.params.reportTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "reportTime", $$v)
                      },
                      expression: "params.reportTime",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 4 } },
            [
              _c(
                "a-space",
                { staticClass: "btnBox" },
                [
                  _c(
                    "a-button",
                    { attrs: { type: "primary", "html-type": "submit" } },
                    [_vm._v(" 查询 ")]
                  ),
                  _vm.isProject
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.addNewComplaint },
                        },
                        [_vm._v(" 新增每月风险图 ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("standard-table", {
        attrs: {
          columns: _vm.columns,
          dataSource: _vm.tableData,
          rowKey: "id",
          pagination: _vm.pagination,
          loading: _vm.tableLoading,
          isAlert: false,
          bordered: true,
          btnArr: _vm.tableBtnArr,
          selectedRows: _vm.selectedRows,
        },
        on: {
          change: _vm.handleChangePage,
          "update:selectedRows": function ($event) {
            _vm.selectedRows = $event
          },
          "update:selected-rows": function ($event) {
            _vm.selectedRows = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var index = ref.index
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      (_vm.pagination.current - 1) * _vm.pagination.pageSize +
                        parseInt(index) +
                        1
                    )
                  ),
                ]),
              ]
            },
          },
          {
            key: "attach1",
            fn: function (ref) {
              var record = ref.record
              return [
                _c(
                  "div",
                  {
                    staticClass: "flex j-center",
                    staticStyle: { "flex-wrap": "wrap" },
                  },
                  _vm._l(record.attachList1, function (item, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        item.type == "img"
                          ? _c("img", {
                              staticStyle: { width: "32px" },
                              attrs: { alt: "", src: item.url },
                              on: {
                                click: function ($event) {
                                  return _vm.handlePreview(item)
                                },
                              },
                            })
                          : item.type == "pdf"
                          ? _c("a-icon", {
                              style: { fontSize: "32px" },
                              attrs: { type: "file-pdf" },
                              on: {
                                click: function ($event) {
                                  return _vm.handlePreview(item)
                                },
                              },
                            })
                          : _c("a-icon", {
                              style: { fontSize: "32px" },
                              attrs: { type: "file-text" },
                              on: {
                                click: function ($event) {
                                  return _vm.handlePreview(item)
                                },
                              },
                            }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]
            },
          },
          {
            key: "action",
            fn: function (ref) {
              var record = ref.record
              return [
                _c("div", { staticClass: "sTables" }, [
                  _c(
                    "div",
                    {
                      staticClass: "y-edit",
                      on: {
                        click: function ($event) {
                          return _vm.handlePreview(record.attachList1[0])
                        },
                      },
                    },
                    [_vm._v("平面图预览")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "y-other",
                      on: {
                        click: function ($event) {
                          return _vm.handleDownload(record)
                        },
                      },
                    },
                    [_vm._v("下载")]
                  ),
                  _vm.isProject
                    ? _c(
                        "div",
                        {
                          staticClass: "y-del",
                          on: {
                            click: function ($event) {
                              return _vm.handleDel(record)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      )
                    : _vm._e(),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("detail", {
        attrs: {
          visible: _vm.visible,
          prInfo: _vm.prInfo,
          isEdit: _vm.isEdit,
          id: _vm.id,
        },
        on: {
          "update:visible": function ($event) {
            _vm.visible = $event
          },
          closeDialog: _vm.closeDialog,
          refresh: _vm.handleQuery,
        },
      }),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.previewVisible, footer: false, title: "预览" },
          on: {
            cancel: function ($event) {
              _vm.previewVisible = false
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.previewImage },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }