import { render, staticRenderFns } from "./addNewInspectionPlan.vue?vue&type=template&id=f0bca076&scoped=true"
import script from "./addNewInspectionPlan.vue?vue&type=script&lang=js"
export * from "./addNewInspectionPlan.vue?vue&type=script&lang=js"
import style0 from "./addNewInspectionPlan.vue?vue&type=style&index=0&id=f0bca076&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0bca076",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\web-project\\jiananyi\\suijian_client\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f0bca076')) {
      api.createRecord('f0bca076', component.options)
    } else {
      api.reload('f0bca076', component.options)
    }
    module.hot.accept("./addNewInspectionPlan.vue?vue&type=template&id=f0bca076&scoped=true", function () {
      api.rerender('f0bca076', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/safeview/addNewInspectionPlan.vue"
export default component.exports