<template>
  <a-card class="projectConfig" :title="$route.query.officeId ? false : '项目参数设置'" :bordered="false">
    <a-form-model
      :model="projectConfig"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item
      :wrapper-col="{span: 20}"
        >
        <div class="flex j-between">
        <div class="font-16 black">
          建委平台参数配置 (广州地区适用)
        </div>
        <a-button icon="save" type="primary" @click="saveProjectConfig">
          保存
        </a-button>
      </div>
        </a-form-model-item
      >
      <a-form-model-item :wrapper-col="{ span: 24 }" style="height: 40px">
        <a-form-model layout="inline">
          <a-row>
            <a-col :span="8">
              <a-form-model-item label="跳过银行卡上传">
                <a-radio-group class="ml-1" v-model="projectConfig.isBankCard">
                  <a-radio :value="0"> 是 </a-radio>
                  <a-radio :value="1"> 否 </a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="是否上报建委">
                <!-- <a-switch
              v-model="projectConfig.pushJw"
              checked-children="是"
              un-checked-children="否"
            /> -->
                <a-radio-group class="ml-1" v-model="projectConfig.pushJw">
                  <a-radio :value="1"> 是 </a-radio>
                  <a-radio :value="0"> 否 </a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="是否设备直连" required>
                <!-- <a-switch
              v-model="projectConfig.isDirect"
              checked-children="是"
              un-checked-children="否"
            /> -->
                <a-radio-group class="ml-1" v-model="projectConfig.isDirect">
                  <a-radio :value="1"> 是 </a-radio>
                  <a-radio :value="0"> 否 </a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>

          </a-row>
        </a-form-model>
      </a-form-model-item>
      <!-- 设备直连卡片 -->
      <template v-if="projectConfig.isDirect">
        <a-row
          class="w-83"
          v-for="(fjwItem, index) in jwZlSecritVoList"
          :key="index"
        >
          <a-col class="flex j-between a-center card-jw">
            <div class="ml-15 flex-1">
              <div class="black-for mb-1 font-16">设备直连</div>
              <div class="flex">
                <div class="black-three flex a-center">
                  公司名称:
                  <a-select
                    class="card-select"
                    placeholder="请输入或选择公司"
                    option-filter-prop="children"
                    style="width: 220px"
                    :filter-option="filterOption"
                    v-model="fjwItem.officeId"
                    :showArrow="false"
                    :open="false"
                  >
                    <a-select-option
                      :key="item.id"
                      v-for="item in dataSource"
                      :value="item.officeId"
                      :title="item.officeName"
                    >
                      {{ item.officeName }}
                    </a-select-option>
                  </a-select>
                </div>
                <div class="black-three flex a-center">
                  参建类型:
                  <a-select
                    class="card-select"
                    placeholder="请输入或选择公司"
                    option-filter-prop="children"
                    style="width: 220px"
                    :filter-option="filterOption"
                    v-model="fjwItem.officeId"
                    :showArrow="false"
                    :open="false"
                  >
                    <a-select-option
                      :key="item.id"
                      v-for="item in dataSource"
                      :value="item.officeId"
                      :title="item.officeName"
                    >
                      <a-tag color="#55acee">
                        {{ item.label}}
                      </a-tag>
                    </a-select-option>
                  </a-select>
                </div>
                <div class="flex a-center ml-1" style="color: #F59B24" >
                    <template v-if="validateData(fjwItem)">
                      <a-icon type="info-circle" theme="twoTone" two-tone-color="#F59B24" class="mr-1"/>
                      {{ validateData(fjwItem) }}
                    </template>
                </div>
              </div>
            </div>
            <a-space class="card-action">
              <a-button
                type="primary"
                @click="viewEditing(fjwItem, projectConfig.isDirect, index)"
              >
                编辑
              </a-button>
              <!-- <a-button
                type="danger"
                style="background: #ff5d78"
                @click="removeUserSet(index)"
              >
                取消绑定
              </a-button> -->
            </a-space>
          </a-col>
        </a-row>
      </template>
        <!-- 新设备直连-卡片样式 -->
        <template v-else>
        <a-row
          class="w-83"
          v-for="(fjwItem, index) in jwFzlKeySecritList"
          :key="index"
        >
          <a-col class="flex j-between a-center card-jw">
            <div class="ml-15 flex-1">
              <div class="black-for mb-1 font-16">非设备直连</div>
              <div class="flex">
                <div class="black-three flex a-center">
                  公司名称:
                  <a-select
                    class="card-select"
                    placeholder="请输入或选择公司"
                    option-filter-prop="children"
                    style="width: 220px"
                    :filter-option="filterOption"
                    v-model="fjwItem.officeId"
                    :showArrow="false"
                    :open="false"
                  >
                    <a-select-option
                      :key="item.id"
                      v-for="item in dataSource"
                      :value="item.officeId"
                      :title="item.officeName"
                    >
                      {{ item.officeName }}
                    </a-select-option>
                  </a-select>
                </div>
                <div class="black-three flex a-center">
                  参建类型:
                  <a-select
                    class="card-select"
                    placeholder="请输入或选择公司"
                    option-filter-prop="children"
                    style="width: 220px"
                    :filter-option="filterOption"
                    v-model="fjwItem.officeId"
                    :showArrow="false"
                    :open="false"
                  >
                    <a-select-option
                      :key="item.id"
                      v-for="item in dataSource"
                      :value="item.officeId"
                      :title="item.officeName"
                    >
                      <a-tag color="#55acee">
                        {{ item.label}}
                      </a-tag>
                    </a-select-option>
                  </a-select>
                </div>
                <div class="black-three flex a-center " style="width: 220px">
                  <a-tooltip placement='topLeft'>
                    <template slot='title'>
                      {{ fjwItem.accessno }}
                    </template>
                    <div class='overtext-1'>接入编码: {{ fjwItem.accessno }}</div>
                  </a-tooltip>
                </div>
                <div class="flex a-center ml-1" style="color: #F59B24" >
                  <template v-if="validateData(fjwItem, true)">
                    <a-icon type="info-circle" theme="twoTone" two-tone-color="#F59B24" class="mr-1"/>
                    {{ validateData(fjwItem, true) }}
                  </template>
                </div>
              </div>
            </div>
            <a-space class="card-action">
              <a-button
                type="primary"
                @click="viewEditing(fjwItem, projectConfig.isDirect, index)"
              >
                编辑
              </a-button>
              <!-- <a-button
                type="danger"
                style="background: #ff5d78"
                @click="removeUserSet(index, 0)"
              >
                取消绑定
              </a-button> -->
            </a-space>
          </a-col>
        </a-row>
      </template>
      <a-form-model-item :wrapper-col="{ span: 24 }" >
            <!-- <a-switch
          v-model="projectConfig.jwbykq"
          checked-children="是"
          un-checked-children="否"
        /> -->
          <div class="flex a-center">
              <div class="font-16 black">
                是否启用备用考勤：
              </div>
            <a-radio-group class="ml-2" v-model="projectConfig.jwbykq" label="是否启用备用考勤">
              <a-radio :value="1"> 是 </a-radio>
              <a-radio :value="0"> 否 </a-radio>
            </a-radio-group>
          </div>
      </a-form-model-item>
      <a-form-model-item
        :wrapper-col="{ span: 20 }"
        v-if="projectConfig.jwbykq"
      >
        <div class="card-jw" style="height: 100%;">
        <a-form-model
          class="ml-2 pt-2"
          v-if="projectConfig.jwbykq"
          :model="jwbykqContent"
          layout="inline"
        >
          <a-form-model-item label="工程编码">
            <a-input v-model="jwbykqContent.projectCode" />
          </a-form-model-item>
          <a-form-model-item label="工程密钥">
            <a-input v-model="jwbykqContent.gcms" />
          </a-form-model-item>
          <a-form-model-item label="接入编号">
            <a-input v-model="jwbykqContent.accessNo" />
          </a-form-model-item>
          <!-- <a-form-model-item label="设备序列号">
            <a-input v-model="jwbykqContent.deviceNo" />
          </a-form-model-item> -->
          <!-- <a-form-model-item label="建委账户">
            <a-input v-model="jwbykqContent.jwAccount" />
          </a-form-model-item> -->
          <a-row>
            <a-col>
              <a-form-model-item label="进场设备">
            <a-input v-model="jwbykqContent.enterClientId" />
          </a-form-model-item>
          <a-form-model-item label="出场设备">
            <a-input v-model="jwbykqContent.leaveClientId" />
          </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
      </a-form-model-item>
      <a-form-model-item
        ><div class="font-bold font-16 black">第三方平台</div>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 20 }">
        <div class="font-16 black">
          <a-row :gutter="16">
            <a-col :span="12">
              <a-card class="ant-card-rable"  :bordered="false">
                <div slot="title">
                    <div class="flex a-center">
                      <div class="rable-check mr-1" :class="projectConfig.pushZdb ? 'rable-check-true' : '' ">
                        {{projectConfig.pushZdb ? '启用中' : '停用'}}
                      </div>
                      省重点办
                  </div>
                </div>

                <a-button
                slot="extra"
                type="primary"
                @click="handleThird(1)"
              >
                编辑
              </a-button>

              </a-card>
            </a-col>
            <a-col :span="12">
              <a-card class="ant-card-rable" :bordered="false">
                <div slot="title">
                    <div class="flex a-center">
                      <div class="rable-check mr-1" :class="projectConfig.pushDjj ? 'rable-check-true' : '' ">
                        {{projectConfig.pushDjj ? '启用中' : '停用'}}
                      </div>
                      代建局
                  </div>
                </div>

                <a-button
                slot="extra"
                type="primary"
                @click="handleThird(2)"
              >
                编辑
              </a-button>

              </a-card>
            </a-col>
            <a-col :span="12">
              <a-card
                class="mt-2 ant-card-rable"
                :bordered="false"
              >
              <div slot="title">
                    <div class="flex a-center">
                      <div class="rable-check mr-1" :class="projectConfig.pushZhgd ? 'rable-check-true' : '' ">
                        {{projectConfig.pushZhgd ? '启用中' : '停用'}}
                      </div>
                      市重点办
                  </div>
                </div>

                <a-button
                slot="extra"
                type="primary"
                @click="handleThird(3)"
              >
              编辑
              </a-button>
              </a-card>
            </a-col>
          </a-row>
        </div>
      </a-form-model-item>
      <!-- <a-form-model-item :wrapper-col="{ span: 24, offset: 20 }">
        <a-button icon="save" type="primary" @click="saveProjectConfig">
          保存
        </a-button>
      </a-form-model-item> -->
    </a-form-model>
    <a-modal
      centered
      :visible="showEvaluate"
      :confirm-loading="confirmLoading"
      @ok="handleEvaluate"
      @cancel="cancelEvaluate"
      :width="700"
    >
      <template #title>
        <div class="text-center">编辑内容</div>
      </template>
      <a-form-model
        :model="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
        ref="ruleForm"
        :rules="rules"
      >
        <a-form-model-item label="公司名称">
          <a-select
            disabled
            show-search
            placeholder="请输入或选择公司"
            option-filter-prop="children"
            :filter-option="filterOption"
            @change="handleChange"
            v-model="form.officeId"
            allowClear
          >
            <a-select-option
              :key="item.id"
              v-for="item in dataSource"
              :value="item.officeId"
              :title="item.officeName"
            >
              {{ item.officeName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="参建类型">
          <a-select
            disabled
            show-search
            placeholder="请输入或选择公司"
            option-filter-prop="children"
            :filter-option="filterOption"
            @change="handleChange"
            v-model="form.officeId"
            allowClear
          >
            <a-select-option
              :key="item.id"
              v-for="item in dataSource"
              :value="item.officeId"
              :title="item.officeName"
            >
              {{ item.label}}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item prop="accessno" label="接入编码" v-if="projectConfig.isDirect == 0">
          <a-input v-model.trim="form.accessno" placeholder="请输入接入编码"/>
        </a-form-model-item>
        <a-form-model-item prop="accesskey" label="接入密匙" v-if="projectConfig.isDirect == 0">
          <a-input v-model.trim="form.accesskey" placeholder="请输入接入密匙" />
        </a-form-model-item>
        <a-form-model-item label="建委账号">
        <a-list
      :data-source="form.jwAccountsVoList"
      bordered
    >
      <a-list-item slot="renderItem" :key="`a-${item.roleKey}`" slot-scope="item">
        <!-- <a slot="actions" >编辑</a>  -->
        <!-- @click="showDrawer" -->
        <a-list-item-meta>
          <a slot="title">{{ item.roleKey == 1 ? '项目经理' : item.roleKey == 2 ? '总包劳务员' : '分包劳务员' }}</a>
          <a-avatar
            slot="avatar"
            src="https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png"
          />
          <div slot="description">
              <a-form-model-item class="desc-form" >
                <span slot="label">建委账号</span>
                <a-input v-model.trim="item.account" placeholder="请输入建委账号" style="width: 200px;"/>
              </a-form-model-item>
              <a-form-model-item class="desc-form">
                <span slot="label">建委密码</span>
                <a-input v-model.trim="item.password" placeholder="请输入建委密码" style="width: 200px;"/>
              </a-form-model-item>
              <a-form-model-item class="desc-form">
                <span slot="label">手机号</span>
                <a-input v-model.trim="item.phone" placeholder="请输入手机号" style="width: 200px;"/>
              </a-form-model-item>
              <!-- <a-form-model-item class="desc-form"  v-if="projectConfig.isDirect == 0" >
                <span slot="label">建委密码</span>
                <a-input v-model="item.secret" placeholder="请输入建委密码" style="width: 200px;"/>
              </a-form-model-item> -->
          </div>
        </a-list-item-meta>
      </a-list-item>
        </a-list>
      </a-form-model-item>
        <!-- <a-form-model-item label="建委账号">
          <a-input v-model="form.account" placeholder="请输入建委账号"/>
        </a-form-model-item>
        <a-form-model-item label="建委密码" v-if="projectConfig.isDirect == 1">
          <a-input v-model="form.password" placeholder="请输入建委密码"/>
        </a-form-model-item>
        <a-form-model-item label="建委密码" v-if="projectConfig.isDirect == 0">
          <a-input v-model="form.secret" placeholder="请输入建委密码"/>
        </a-form-model-item> -->
      </a-form-model>
      <template slot="footer">
        <div class="flex j-center" style="margin: -20px 0 20px 0;"><a-alert message="请确保输入的手机号与建委平台账号中绑定的手机号码相同" type="warning" show-icon /></div>
        <a-space size="large">
          <a-button  @click="cancelEvaluate">
            关闭
          </a-button>
          <a-button type="primary" @click="handleEvaluate">
            确定
        </a-button>
        </a-space>
      </template>
    </a-modal>

    <a-modal centered
      :visible="showThirdMotal"
      @cancel="cancelThird"
      :forceRender="true"
      :width="700">
        <template #title>
          <div class="text-center">对接第三方平台</div>
        </template>
          <a-form-model layout="inline" class="mb-1">
            <a-row>
            <a-col :span="12" :style="{ textAlign: 'center' }">
              <a-form-model-item label="第三方平台">
                <span class="ml-2">{{ showThird == 1 ? '省重点办' : (showThird == 2 ? '代建局' : '市重点办')}}</span>

              </a-form-model-item>
            </a-col>
            <a-col :span="12" :style="{ textAlign: 'center' }">
              <a-form-model-item label="是否启用">
                <template v-if="showThird == 1">
                  <a-switch
                  v-model="projectConfig.pushZdb"
                  checked-children="启用"
                  un-checked-children="停用"
                  :class="projectConfig.pushZdb ? 'rable-check-true' : '' "
                />
                </template>
                <template v-if="showThird == 2">
                  <a-switch
                  v-model="projectConfig.pushDjj"
                  checked-children="启用"
                  un-checked-children="停用"
                  :class="projectConfig.pushDjj ? 'rable-check-true' : '' "
                />
                </template>
                <template v-if="showThird == 3">
                <a-switch
                  v-model="projectConfig.pushZhgd"
                  checked-children="启用"
                  un-checked-children="停用"
                  :class="projectConfig.pushZhgd ? 'rable-check-true' : '' "
                />
                </template>

              </a-form-model-item>
            </a-col>
          </a-row>
          </a-form-model>
          <form-index v-show="showThird == 1" ref="zdbContent" :formData="zdbContentData"></form-index>
          <form-index v-show="showThird == 2" ref="djjContent" :formData="djjContentData" ></form-index>
          <form-index v-show="showThird == 3" ref="zhgdContent" :formData="zhgdContentData" ></form-index>
          <template slot="footer">
        <a-space size="large">
          <a-button  @click="cancelThird">
            关闭
          </a-button>
          <a-button type="primary" @click="cancelThird">
            确定
        </a-button>
        </a-space>
      </template>
    </a-modal>
  </a-card>
</template>

<script>
import {
  getProjectIdConfig,
  saveProjectIdConfig,
} from "@/services/projectConfig";
import { getProAllOfficeList } from "@/services/participationUnit";
import formIndex from "@/pages/components/form/index";

const columns = [
  {
    title: "平台名称",
    dataIndex: "key",
    align: "center",
    width: 110,
    scopedSlots: { customRender: "key" },
    // fixed: "left",
  },
  {
    title: "接入编码",
    dataIndex: "accessKey",
    align: "center",
    width: 110,
    scopedSlots: { customRender: "accessKey" },
    // fixed: "left",
  },
  {
    title: "接入密钥",
    dataIndex: "accessNo",
    align: "center",
    width: 110,
    scopedSlots: { customRender: "accessNo" },
    // fixed: "left",
  },
  {
    title: "操作",
    align: "center",
    width: 65,
    // fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];
const djjContentData = [
  {
    label: "接入编码",
    placeholder: "请填写代建局接入编码",
    decorator: [
      "accessNo",
      { rules: [{ required: false, message: "请填写" }] },
    ],
    type: "input",
    key: "accessNo",
    col: 24,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "接入密钥",
    placeholder: "请填写代建局接入密钥",
    decorator: [
      "accessKey",
      { rules: [{ required: false, message: "请填写" }] },
    ],
    type: "input",
    key: "accessKey",
    col: 24,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
];
const zdbContentData = [
  {
    label: "接入appid",
    placeholder: "请填写重点办appid",
    decorator: ["appid", { rules: [{ required: false, message: "请填写" }] }],
    type: "input",
    key: "appid",
    col: 24,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "接入密钥",
    placeholder: "请填写重点办接入密钥",
    decorator: ["secret", { rules: [{ required: false, message: "请填写" }] }],
    type: "input",
    key: "secret",
    col: 24,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
];
// zhgdContentData
const zhgdContentData = [
  {
    label: "重点办项目Id",
    placeholder: "请填写重点办项目Id",
    decorator: ["projectId", { rules: [{ required: false, message: "请填写" }] }],
    type: "input",
    key: "projectId",
    col: 24,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "接入appid",
    placeholder: "请填写市重点办接入appid",
    decorator: ["appid", { rules: [{ required: false, message: "请填写" }] }],
    type: "input",
    key: "appid",
    col: 24,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "接入代码",
    placeholder: "请填写市重点办接入代码",
    decorator: [
      "sectioncode",
      { rules: [{ required: false, message: "请填写" }] },
    ],
    type: "input",
    key: "sectioncode",
    col: 24,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "接入密匙",
    placeholder: "请填写市重点办接入密匙",
    decorator: ["secret", { rules: [{ required: false, message: "请填写" }] }],
    type: "input",
    key: "secret",
    col: 24,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
];
import { mapGetters } from "vuex";
import { omit,debounce } from "lodash";
import { getOfficeTypeList } from "@/services/sys";
export default {
  props: {
    projectId: {
      type: [String, Number],
      require: true,
    },
  },
  components: {
    formIndex,
  },
  data() {
    return {
      zhgdContentData,
      zdbContentData,
      djjContentData,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      pushConfig: {},
      projectConfig: {
        rcMode: 2,
        isDirect: 0,
        pushJw: 0,
        jwbykq: 0,
        isBankCard: 0,
      },
      zdbContent: {
        projectId: "",
        // appid: "",
        // secret: "",
        accessKey: "",
        accessNo: "",
      },
      djjContent: {
        projectId: "",
        accessKey: "",
        accessNo: "",
      },
      zhgdContent: {
        // appid: "",
        // projectId: "",
        // secret: "",
        // sectioncode: "",
        projectId: "",
        accessKey: "",
        accessNo: "",
      },
      jwbykqContent: {
        gcms: "",
        projectCode: "",
        accessNo: "",
        deviceNo: "",
        enterClientId: "",
        leaveClientId: ""
      },

      jwZlSecritVoList: [], // 建委账号数组
      jwFzlKeySecritList: [], // 建委非设备直连参数
      roleType: [
        {
          value: "1",
          name: "项目经理",

        },
        {
          value: "2",
          name: "总包劳务员",
        },
        {
          value: "3",
          name: "分包劳务员",
        },
      ],
      roleTypeData: [
        {
          roleKey: "1",
          name: "项目经理",
          account: '', // 账号
          password: '' // 密码
        },
        {
          roleKey: "2",
          name: "总包劳务员",
          account: '', // 账号
          password: '' // 密码
        },
        {
          roleKey: "3",
          name: "分包劳务员",
          account: '', // 账号
          password: '' // 密码
        },

      ],
      dataSource: [], // 参建单位数组
      pushThird: [], // 第三方平台数组
      columns,
      arr: [],
      rules: {
        accessno: [{ required: true, message: "请填写接入编码", trigger: "change" }],
        accesskey : [
          { required: true, message: "请填写接入密匙", trigger: "change" },
        ],
      },
      form: {
        jwAccountsVoList: [
          {officeId: '', roleKey: '1', account: '', password: '',phone:''},
          {officeId: '', roleKey: '2', account: '', password: '',phone:''},
          {officeId: '', roleKey: '3', account: '', password: '',phone:''}
        ]
      },

      showEvaluate: false,
      confirmLoading: false,
      showThird:1,
      showThirdMotal: false,
      roleTypeList:[]
    };
  },
  filters: {
    ptypeState(status) {
      const obj = {
        zdbContent: "重点办",
        djjContent: "代建局",
        zhgdContent: "市重点办",
      };
      let str = null;
      Object.keys(obj).forEach((key) => {
        if (key == status) {
          str = obj[key];
        }
      });
      return str;
    }
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  mounted() {
    this.getProjectConfig();
    this.getOfficeTypeList()
    this.aqxjlist();
  },
  activated() {
    if (this.projectId) {
      this.aqxjlist();
      this.getProjectConfig();
    }
  },

  methods: {
    validateData(data,isCode) {
      // isCode 是否校验接入编码
      const isEmptyOrNull = (value) => value === null || value === undefined || value === '';

      // Validate jwAccountsVoList
      const jwAccountsVoListValid = data.jwAccountsVoList.length > 0
        ? data.jwAccountsVoList.every(item =>
            Object.values(item).every(value => !isEmptyOrNull(value))
        )
        : false;

      // Validate accessno and accesskey only if isCode is true
      let messages = [];
      if (isCode) {
          if (!data.accessno || !data.accesskey) {
              messages.push("未填写接入编码");
          }
      }

      // Check if any validations failed
      if (!jwAccountsVoListValid) {
          messages.push("人员信息待补充");
      }

      // Return result
      if (messages.length > 0) {
          return messages.join("、");
      } else {
          return false;
      }
    },
    roleTypeState(roleType) {
      let str = null;
      this.roleTypeList.forEach((el) => {
        if (el.value == roleType) {
          str = el.label
        }
      });
      return str;
    },
    getOfficeTypeList() {
      getOfficeTypeList().then((res) => {
        const data = res.data.data.companyTypeList;
        const arr = [];
        data.forEach((item) => {
          arr.push({
            name: item.label,
            value: item.value,
          });
        });
        this.roleTypeList = arr;
      });
    },
    getProjectConfig() {
      getProjectIdConfig({
        projectId: this.projectId ? this.projectId : this.user.projectId,
      }).then((res) => {
        if (res.data.code == -1) {
          // this.$message.success("设置成功");
          // this.projectConfig.rcMode = this.projectConfig.rcMode + "";
          this.setData(true);
        } else {
          this.projectConfig = res.data.data;
          this.setData(true);
          // this.projectConfig.rcMode = this.projectConfig.rcMode + "";
          this.zdbContent = res.data.data.zdbContent;
          this.djjContent = res.data.data.djjContent;
          this.zhgdContent = res.data.data.zhgdContent;
          this.jwbykqContent = res.data.data.jwbykqContent;

          if (res.data.data.jwZlSecritVoList) {
            this.jwZlSecritVoList = res.data.data.jwZlSecritVoList;
          }

          // 非设备直连数组
          if (
            res.data.data.jwFzlKeySecritList &&
            res.data.data.jwFzlKeySecritList.length > 0
          ) {
            this.jwFzlKeySecritList = res.data.data.jwFzlKeySecritList;
          }
          // this.zhgdqContent = res.data.data.zhgdqContent;
          // 第三方平台卡片表单数据
          const _ = this
          let arr = ["zdbContent", "djjContent", "zhgdContent"];
          arr.map((item) => {
            if (res.data.data[item] && this.$refs[item].queryObj) {
              this.$nextTick(() => {
                this.$refs[item].queryObj(res.data.data[item]);
                });
            }
          });
        }
      });
    },
    setData(type) {
      // 处理 a-swict 初始值是Number类型报错警告问题
      // let arr = [
      //   "pushJw",
      //   "isDirect",
      //   "jwbykq",
      //   "pushDjj",
      //   "pushZhgd",
      //   "pushZdb",
      // ];
      let arr = ["pushDjj", "pushZhgd", "pushZdb"];
      arr.forEach((item) => {
        Object.keys(this.projectConfig).forEach((key) => {
          if (item === key) {
            if (type == true) {
              this.projectConfig[key] = Boolean(this.projectConfig[key]);
            } else {
              this.pushConfig[key] = Number(this.projectConfig[key]);
            }
          }
        });
      });
    },
    saveProjectConfig:debounce(function(){
      this.handleSubmit().then((res) => {
        if (res) {
          this.projectConfig.zdbContent = this.zdbContent;
          this.projectConfig.djjContent = this.djjContent;
          this.projectConfig.zhgdContent = this.zhgdContent;
          this.projectConfig.jwbykqContent = this.jwbykqContent;
          this.projectConfig.jwZlSecritVoList = this.jwZlSecritVoList;
          this.projectConfig.jwFzlKeySecritList = this.jwFzlKeySecritList;
          this.pushConfig = {
            ...this.projectConfig,
          };
          // console.log(this.pushConfig, "this.pushConfig");

          // a-switch 传给后端参数为1或者0
          if (this.pushConfig.isDirect == null ) return this.$message.error("请选择是否是设备直连");
          this.setData();
          let pamras = {
            rcMode: 2, // 入场方式
            isBankCard: this.pushConfig.isBankCard, // 入场方式
            id: this.pushConfig.id, // id存在即修改，否则是新增
            pushJw: this.pushConfig.pushJw, // 是否上报建委
            isDirect: this.pushConfig.isDirect, // 1是设备直连 0是非设备直连
            jwbykq: this.pushConfig.jwbykq, // 是否启用备用考勤
            jwbykqContent: this.pushConfig.jwbykqContent, // 建委备用考勤参数
            jwZlSecritVoList: this.pushConfig.jwZlSecritVoList, // 设备直连
            jwFzlKeySecritList: this.pushConfig.jwFzlKeySecritList, // 非设备直连
            projectId: this.pushConfig.projectId
              ? this.pushConfig.projectId
              : (this.projectId ? this.projectId : this.user.projectId),
            pushDjj: this.pushConfig.pushDjj, // 推送代建局
            djjContent: this.pushConfig.djjContent, // 代建局
            pushZhgd: this.pushConfig.pushZhgd, // 推送市重点办
            zhgdContent: this.pushConfig.zhgdContent, // 市重点办
            pushZdb: this.pushConfig.pushZdb, // 推送重点办
            zdbContent: this.pushConfig.zdbContent, // 重点办
          };
          console.log(pamras, "pamras");
          if (pamras.isDirect == 1) {
            delete pamras.jwFzlKeySecritList;
          } else if (pamras.isDirect == 0){
            delete pamras.jwZlSecritVoList;
          }
          saveProjectIdConfig(pamras).then((res) => {
            if (res.data.code === 0) {
              this.$message.success("设置成功");
            }
          });
        }
      });
    },500),
    removeUserSet(index, e) {
      const _that = this;
      this.$confirm({
        title: "确认取消绑定？",
        onOk() {
          if (e == 0) {
            // 非设备直连
            if (_that.jwFzlKeySecritList.length == 1) {
              _that.$message.error("已经是最后一个了，不能再删除了");
              return false;
            }
            _that.jwFzlKeySecritList.splice(index, 1);
            _that.$message.success("取消绑定，需保存设置");
          } else {
            if (_that.jwZlSecritVoList.length == 1) {
              _that.$message.error("已经是最后一个了，不能再删除了");
              return false;
            }
            _that.jwZlSecritVoList.splice(index, 1);
            _that.$message.success("取消绑定，需保存设置");
          }
        },
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 参建单位数组
    aqxjlist(prams) {
      getProAllOfficeList({
        projectId: this.projectId ? this.projectId : this.user.projectId,
      }).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data;
          this.dataSource.forEach(el => {
            this.roleTypeList.forEach((role) => {
              if (el.roleType == role.value) {
                el.label = role.name
              }
            });
          })
        }
      });
    },
    handleChange(e) {},

    onSwitch(e) {
      console.log(e, "e");
    },
    // 弹窗
    viewEditing(record, isDirect, index) {
      this.showEvaluate = true;
      if (record.jwAccountsVoList) {
        this.form.jwAccountsVoList.forEach(el => {
          record.jwAccountsVoList.forEach(i => {
            if (el.roleKey == i.roleKey) {
              el.account = i.account
              el.password = i.password
              el.officeId = record.officeId
              el.phone = i.phone
            }
          })
        })
      } else {
        record.jwAccountsVoList = this.form.jwAccountsVoList
      }
      this.form.isDirect = isDirect
      this.form.index = index
      this.form = Object.assign(record,this.form)
      // 先注释
      // this.form = {
      //   ...record,
      //   officeId: (record.officeId != '' && record.officeId) ? record.officeId : undefined,
      //   roleKey: (record.roleKey != '' && record.roleKey) ? record.roleKey : undefined,
      //   isDirect,
      //   index
      //   }

    },
    // 保存第三方平台数据
    handleSubmit() {
      this.arr = [];
      let arr = ["zdbContent", "djjContent", "zhgdContent"];
      arr.forEach((item) => {
        this.arr.push(this.$refs[item].combinationSubmit());
      });
      return new Promise((resolve, reject) => {
        Promise.all(this.arr).then((res) => {
          res.forEach((item) => {
            if (!item.projectId) {
              item.projectId = this.projectId
                ? this.projectId
                : this.user.projectId;
            }
          });
          this.pushThird = res;
          this.zdbContent = this.pushThird[0]; // 重点办平台
          this.djjContent = this.pushThird[1]; // 代建局平台
          this.zhgdContent = this.pushThird[2]; // 智慧工地平台
          // console.log(res, "合并后的数据");
          resolve(true);
        });
      });
    },
    // 关闭评价motal
    cancelEvaluate(e) {
      this.showEvaluate = false;
      // this.$refs.ruleForm.resetFields();
      // this.form = {}
      this.form = {
        jwAccountsVoList: [
          {officeId: '', roleKey: '1', account: '', password: '', phone:''},
          {officeId: '', roleKey: '2', account: '', password: '', phone:''},
          {officeId: '', roleKey: '3', account: '', password: '', phone:''}
        ]
      }
    },
    // 添加评价
    handleEvaluate(e) {
      this.$refs.ruleForm.validate((valid) => {
        this.confirmLoading = true;
        if (valid) {
          const index = this.form.index;
          const isDirect = this.form.isDirect;
          // omit 代替delete
          const res = omit(this.form,['isDirect','index'])
          if (isDirect) {
            // 设备直连数组
            this.jwZlSecritVoList[index] = res;
          } else {
            // 非设备直连数组
            this.jwFzlKeySecritList[index] = res;
          }
        } else {
          return false;
        }
      });
      this.confirmLoading = false;
      this.cancelEvaluate();
    },
    // 第三方平台弹窗
    handleThird(type) {
      this.showThirdMotal = true
      this.showThird = type
    },
    cancelThird() {
      this.showThirdMotal = false
    }
  },
};
</script>

<style lang="less" scoped>
.imdexStyle {
  font-size: 20px;
  margin-left: 15px;
}

.loginList {
  // margin-bottom: 28px;
}
.loginList:last-child {
  margin-bottom: 0;
}

.projectConfig {
  // ant-card 样式设置
  /deep/ .ant-card-head-title {
    font-weight: bold;
  }
  /deep/ .ant-card-head {
    border-bottom: 1px solid #ebebeb;
  }
  /deep/ .ant-form-item {
    margin-bottom: 18px;
  }


  // 对接卡片样式
  .card-jw {
    height: 80px;
    box-shadow: 0px 0px 8px 0px rgba(48, 125, 250, 0.2);
    border-radius: 4px;
    margin-bottom: 16px;
  }
  .card-action {
    margin-right: 24px;
  }

  /deep/.card-select .ant-select-selection {
    border: none;
  }

  .w-83 {
    width: 83%;
  }
  .ant-switch-checked {
    background-color: #33D89E;
  }
  .rable-check {
    width: 55px;
height: 24px;
background: #CCCCCC;

border-radius:
12px;
color: #fff;
font-size: 14px;
text-align: center;
line-height: 24px;
  }
  .rable-check-true {
    background: #33D89E;
  }


}
/deep/ .desc-form > .ant-form-item-label > label{
      color: rgba(0, 0, 0, 0.65);
    }
    /deep/ .desc-form.ant-form-item {
      margin-bottom: 0 !important;
    }
/deep/ .ant-modal-footer {
    text-align: center;
    border-top: none;
    padding-bottom: 24px;
  }
  /deep/ .ant-modal-body {
    padding-bottom: unset;
  }
.black-for {
  color: #212121;
}

.ant-card-rable {
  box-shadow: 0px 0px 8px 0px rgba(48, 125, 250, 0.2);
  border-radius:4px;
  // padding-top: 6px;
}
.rable-check-true {
    background: #33D89E;
  }
</style>
