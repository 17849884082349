<template>
  <a-modal width="1600px" v-model="show" :title="title" :footer="null" @cancel="close" :dialog-style="{ top: '0px' }">
    <a-row :gutter="20">
      <a-col :span="12" :offset="0">
        <a-row :gutter="16">

          <a-col :span="6">
            <a-form-model-item label="月份" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
              <a-month-picker v-model="params.reportTime" placeholder="选择月份" valueFormat="YYYY-MM" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-button class="ml-4" type="primary" @click="handleQuery"> 查询 </a-button>
            <a-button class="ml-4" type="primary" @click="handleAdd"> 添加 </a-button>

          </a-col>
        </a-row>
        <standard-table :columns="columns" :dataSource="tableData" rowKey="id" :pagination="pagination"
          :loading="tableLoading" @change="handleChangePage" :isAlert="false" :bordered="true" :btnArr="tableBtnArr"
          :selectedRows.sync="selectedRows" :scroll="{ x: 500 }">
          <!-- @selectedRowChange="handleSelectedRowsChange" :totalBoolean="true" :rowColor="false" -->
          <template slot="index" slot-scope="{ index }">
            <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
          </template>


        </standard-table>
      </a-col>
      <a-col :span="12" :offset="0">
        <a-row :gutter="16" type="flex" justify="space-between" style="margin-bottom: 32px;">

          <a-col :span="6">

          </a-col>
          <a-col :span="8">
            <a-button class="mr-2" type="primary" :loading="sbloading" @click="handleSubmit">保存</a-button>
            <a-button @click="close">返回</a-button>

          </a-col>
        </a-row>
        <standard-table :columns="columns1" :dataSource="tableData1" rowKey="id" :pagination="false" :isAlert="false"
          :bordered="true" :btnArr="tableBtnArr" :scroll="{ x: 500 }">
          <!-- @selectedRowChange="handleSelectedRowsChange" :totalBoolean="true" :rowColor="false" -->
          <template slot="index" slot-scope="{ index }">
            <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
          </template>

          <template slot="reportTime" slot-scope="{ record }">
            <!-- 日期可编辑 -->
            <a-date-picker v-model="record.reportTime" placeholder="选择日期" valueFormat="YYYY-MM-DD" />
          </template>

          <template slot="action" slot-scope="{ record }">
            <a-button type="danger" @click="handleDelete(record)">删除</a-button>
          </template>

        </standard-table>

      </a-col>
    </a-row>

  </a-modal>
</template>

<script>
import StandardTable from "@/components/table/StandardTable";
import { request } from "@/utils/request";
import { mapGetters } from 'vuex'
import moment from "moment";
import { riskcontroldetailAddBatch } from "@/services/riskControll";
import { ATTACHMENT_UPLOAD } from "@/services/api";
import {
  riskcontroldetailList
} from "@/services/riskControll";

const columns = [
  {
    title: "序号",
    align: "center",
    scopedSlots: { customRender: "index" },
    width: 65,
  },

  {
    title: "填报时间",
    align: "center",
    dataIndex: "reportTime",
    width: 120,
  },
  {
    title: "作业活动/项目名称",
    align: "center",
    dataIndex: "workName",
    ellipsis: true,
    tooltip: true,
    width: 200,
  },
  {
    title: "危险源所在部位",
    dataIndex: "riskResourse",
    width: 150,
    align: "center",
    ellipsis: true,
    tooltip: true,
  },
  {
    title: "风险特征描述",
    dataIndex: "riskAttr",
    width: 150,
    align: "center",
    ellipsis: true,
    tooltip: true,
  },
  {
    title: "可能导致的事故类型",
    dataIndex: "accident",
    width: 180,
    align: "center",
    ellipsis: true,
    tooltip: true,
  },

  {
    title: "L",
    dataIndex: "riskL",
    width: 120,
    align: "center",
  },
  {
    title: "E",
    dataIndex: "riskE",
    width: 120,
    align: "center",
  },
  {
    title: "C",
    dataIndex: "riskC",
    width: 120,
    align: "center",
  },
  {
    title: "D",
    dataIndex: "riskD",
    width: 120,
    align: "center",
  },

  {
    title: "风险等级",
    dataIndex: "riskRank",
    width: 120,
    align: "center",
    ellipsis: true,
    tooltip: true,
  },
  {
    title: "工程技术",
    dataIndex: "engineeringTech",
    width: 150,
    align: "center",
    ellipsis: true,
    tooltip: true,
  },
  {
    title: "安全管理",
    dataIndex: "safetyManage",
    width: 150,
    align: "center",
    ellipsis: true,
    tooltip: true,
  },
  {
    title: "培训内容",
    dataIndex: "cultivateContent",
    width: 150,
    align: "center",
    ellipsis: true,
    tooltip: true,
  },
  {
    title: "个体防护",
    dataIndex: "personProtect",
    width: 150,
    align: "center",
    ellipsis: true,
    tooltip: true,
  },
  {
    title: "应急处置",
    dataIndex: "emergencyResponse",
    width: 150,
    align: "center",
    ellipsis: true,
    tooltip: true,
  },
  {
    title: "风险控制措施落实情况",
    dataIndex: "riskControlState",
    align: "center",
    width: 150,
    ellipsis: true,
    tooltip: true,
  },

];
const columns1 = [
  {
    title: "序号",
    align: "center",
    scopedSlots: { customRender: "index" },
    width: 65,
  },

  {
    title: "填报时间",
    align: "center",
    dataIndex: "reportTime",
    width: 160,
    scopedSlots: { customRender: "reportTime" },
  },
  {
    title: "作业活动/项目名称",
    align: "center",
    dataIndex: "workName",
    ellipsis: true,
    tooltip: true,
    width: 200,
  },
  {
    title: "危险源所在部位",
    dataIndex: "riskResourse",
    width: 150,
    align: "center",
    ellipsis: true,
    tooltip: true,
  },
  {
    title: "风险特征描述",
    dataIndex: "riskAttr",
    width: 150,
    align: "center",
    ellipsis: true,
    tooltip: true,
  },
  {
    title: "可能导致的事故类型",
    dataIndex: "accident",
    width: 180,
    align: "center",
    ellipsis: true,
    tooltip: true,
  },

  {
    title: "L",
    dataIndex: "riskL",
    width: 120,
    align: "center",
  },
  {
    title: "E",
    dataIndex: "riskE",
    width: 120,
    align: "center",
  },
  {
    title: "C",
    dataIndex: "riskC",
    width: 120,
    align: "center",
  },
  {
    title: "D",
    dataIndex: "riskD",
    width: 120,
    align: "center",
  },

  {
    title: "风险等级",
    dataIndex: "riskRank",
    width: 120,
    align: "center",
    ellipsis: true,
    tooltip: true,
  },
  {
    title: "工程技术",
    dataIndex: "engineeringTech",
    width: 150,
    align: "center",
    ellipsis: true,
    tooltip: true,
  },
  {
    title: "安全管理",
    dataIndex: "safetyManage",
    width: 150,
    align: "center",
    ellipsis: true,
    tooltip: true,
  },
  {
    title: "培训内容",
    dataIndex: "cultivateContent",
    width: 150,
    align: "center",
    ellipsis: true,
    tooltip: true,
  },
  {
    title: "个体防护",
    dataIndex: "personProtect",
    width: 150,
    align: "center",
    ellipsis: true,
    tooltip: true,
  },
  {
    title: "应急处置",
    dataIndex: "emergencyResponse",
    width: 150,
    align: "center",
    ellipsis: true,
    tooltip: true,
  },
  {
    title: "风险控制措施落实情况",
    dataIndex: "riskControlState",
    align: "center",
    width: 150,
    ellipsis: true,
    tooltip: true,
  },
  {
    title: "操作",
    align: "center",
    fixed: "right",
    width: 120,
    scopedSlots: { customRender: "action" },
  },

];
export default {
  name: 'batchAdd',
  components: {
    StandardTable,

  },
  computed: {
    ...mapGetters('account', ['user']),
    tableBtnArr() {
      return [
      ]
    },
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Number,
      default: 0
    },
    id: {
      default: ''
    },
    projectInfo: {
      default: () => {
        return {}
      }
    }


  },
  mixins: [],
  data() {
    return {
      rules: {},
      sbloading: false,
      title: '新增',
      show: false,
      params: {
        "companyName": "", //公司名称
        "projectName": "", //项目名称
        "reportTime": "", //填报时间
        "riskRankType": "", //风险等级ID
        "riskRank": "", //风险等级
        "pageNo": "1", //页码
        "pageSize": "10", //每页条数
        "parentId": "", //风险管控ID
        "companyId": "", //公司ID
        "branchOfficeId": "", //分公司ID
        "projectId": "", //项目ID
        reserved1: '1', //哪个角色查询 （1 安全员  2 项目经理  3 分公司  4公司 5集团)
      },
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `共 ${total} 条`,
      },
      total: 0,
      columns: columns,
      tableData: [],
      columns1: columns1,
      tableData1: [],
      tableLoading: false, //table加载中
      selectedRows: [],
      tmpSelectedRows: [],

      parentId: '',
      parentDate: '',
    };
  },
  watch: {
    visible(val) {
      console.log(val)

      if (this.isEdit == 0) {
        // dom 渲染后
        this.params.companyName = this.projectInfo.parentOffice;
        this.params.projectName = this.projectInfo.projectName;
        this.params.branchOfficeName = this.projectInfo.branchOfficeName;
        this.params.companyId = this.projectInfo.parentOfficeId;
        this.params.branchOfficeId = this.projectInfo.gcglbId;
        this.params.projectId = this.projectInfo.projectId;

        this.tableData1 = []

        this.selectedRows = []
        this.parentId = this.$route.query.id
        this.parentDate = this.$route.query.date
        this.handleQuery()
        this.title = '批量新增 安全生产风险排查管控清单'
      }
      this.show = val;
    }
  },
  methods: {
    handleQuery(e) {
      this.tableData = []
      this.tableLoading = true
      let d = this.params;
      d.reportTime = d.reportTime ? moment(d.reportTime).format('YYYY-MM') : ''
      riskcontroldetailList(d).then((res) => {
        this.tableLoading = false
        if (res.data.code == 0) {
          let list = res.data.data.list;

          this.tableData = list.map((item, index) => {
            return {
              ...item,
            };
          })
          this.pagination.total = res.data.data.total;
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    handleChangePage(e) {
      this.params.pageNo = e.current;
      this.params.pageSize = e.pageSize;
      this.pagination.current = e.current;
      this.handleQuery();
    },
    close() {
      this.resetForm()
      this.$emit('closeDialog')
      this.$emit('refresh')
    },
    resetForm() {

    },
    handleDelete(record) {
      this.tableData1 = this.tableData1.filter(item => item.id !== record.id)
    },
    handleAdd() {
      this.selectedRows.forEach(item => {
        let {id, reportTime, workName, riskResourse,riskResourseType, riskAttr,riskAttrType, accident, accidentType,riskL, riskLType,
          riskE,riskEType, riskC,riskCType, riskD, riskRank,riskRankType, engineeringTech, safetyManage, 
          cultivateContent, personProtect, emergencyResponse, riskControlState } = item
        let dd = {
          id,reportTime, workName, riskResourse,riskResourseType, riskAttr,riskAttrType, accident, accidentType,riskL, riskLType,
          riskE,riskEType, riskC,riskCType, riskD, riskRank,riskRankType, engineeringTech, safetyManage, 
          cultivateContent, personProtect, emergencyResponse, riskControlState
        }
        dd.parentId = this.parentId
        dd.reportTime = moment(this.parentDate).format('YYYY-MM-DD')

        // 去重
        let flag = this.tableData1.some(item => item.id === dd.id)
        if (!flag) this.tableData1.push(dd)
      })
    },
    handleSubmit() {
      // 确认是否保存当前修改
      this.$confirm({
        title: '提示',
        content: '是否保存当前修改？',
        onOk: () => {
          this.sbloading = true
          let data = this.tableData1.map(item => {
            delete item.id
            return {
              ...item,
            }
          })
          riskcontroldetailAddBatch(data).then(res => {
            this.sbloading = false
            if (res.data.code == 0) {
              this.$message.success('保存成功')
              this.close()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      })
    },


  },
};
</script>

<style lang="less" scoped>
.a_Approval {
  background: #fff;
  border-radius: 10px;
}

.btn_group {
  padding-right: 190px;
}
</style>