var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c("form-index", {
        ref: "formindex",
        attrs: {
          formData: _vm.formData,
          Overview: "视频监控",
          flexSubmit: "",
          flexAction: true,
          baiduMapBoolen: false,
        },
        on: { handleSubmit: _vm.handleSubmit, handlSelect: _vm.handlSelect },
        scopedSlots: _vm._u([
          {
            key: "flexAction",
            fn: function () {
              return [
                _c(
                  "a-space",
                  { style: { marginTop: "4px" } },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: {
                          type: "primary",
                          "html-type": "submit",
                          loading: _vm.loading,
                        },
                      },
                      [_vm._v(" 查询 ")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.videoList.length > 0
        ? _c("div", { staticClass: "flex a-center j-center flex-column" }, [
            _c("h2", [
              _vm._v(_vm._s(_vm.gcglbName) + "——" + _vm._s(_vm.projectName)),
            ]),
            _c(
              "div",
              { staticClass: "mt-2", staticStyle: { width: "70%" } },
              [
                _c(
                  "a-row",
                  _vm._l(_vm.videoList, function (item, index) {
                    return _c("a-col", { key: index, attrs: { span: 8 } }, [
                      _c(
                        "div",
                        { staticClass: "flex a-center j-center p relative" },
                        [
                          _c("video", {
                            ref: "video" + index,
                            refInFor: true,
                            staticStyle: { width: "100%" },
                            attrs: {
                              id: "video",
                              crossorigin: "anonymous",
                              autoplay: "",
                            },
                          }),
                          _c("a-icon", {
                            staticClass: "fullscreen-icon",
                            attrs: { type: "fullscreen" },
                            on: {
                              click: function ($event) {
                                return _vm.onClickVideo(item, index)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  }),
                  1
                ),
              ],
              1
            ),
          ])
        : _c("div", [_c("a-empty")], 1),
      _c(
        "a-modal",
        {
          attrs: {
            destroyOnClose: true,
            footer: null,
            width: 1100,
            maskClosable: false,
          },
          on: { cancel: _vm.closeModal },
          model: {
            value: _vm.modalVisible,
            callback: function ($$v) {
              _vm.modalVisible = $$v
            },
            expression: "modalVisible",
          },
        },
        [
          _c("video", {
            ref: "videoModal",
            staticStyle: { width: "100%" },
            attrs: {
              id: "video",
              crossorigin: "anonymous",
              autoplay: "",
              controls: "",
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }