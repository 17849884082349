<template>
  <div class="a_Approval">
    <formIndex
      ref="formindex"
      :formData="formData"
      @handleSubmit="getData"
      flexAction
    >
      <template #flexAction>
        <a-space style="margin-top: 5px">
          <a-button type="primary" html-type="submit">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-space>
      </template>
    </formIndex>

    <!-- 表头按钮 -->
    <div
      class="a_a_footer"
      style="width: 100%; display: flex; justify-content: space-between"
    >
      <a-space class="operator">
        <a-button @click="handleAdd" v-if="isProjectAccount"><a-icon type="file-add"/>新增</a-button>
        <a-button @click="handleExportBatch"
          ><a-icon type="download" />批量导出</a-button
        >
      </a-space>

      <a-space class="operator">
        <a-button
          @click="() => ((modalNoRecord = true), this.getNotEducatePreWeek())"
          >上周无教育记录班组</a-button
        >
      </a-space>
    </div>

    <standard-table
      class="mt-2"
      :columns="columns"
      :dataSource="dataSource"
      rowKey="id"
      :pagination="pagination"
      :loading="tableLoading"
      @change="onTableChange"
      :selectedRows.sync="selectedRows"
      bordered
      :isAlert="false"
      @update:selectedRows="updateSelectRows($event)"
    >
      <!-- 现场照片 -->
      <template slot="sitePhoto" slot-scope="{ text, record }">
        <div class="flex a-center j-center" style="height: 40px">
          <div v-if="text != '' && text != null">
            <img
              :src="text"
              style="width: 40px; height: 40px; cursor: pointer"
              @click="showPreview(text, record)"
            />
          </div>
          <div v-if="!text">暂无图片</div>
        </div>
      </template>
      <template slot="type" slot-scope="{ text }">
        <a-tag :color="text == 1 ? 'blue' : 'orange'">{{
          getTypeName(text)
        }}</a-tag>
      </template>
      <!-- 操作 -->
      <template slot="action" slot-scope="{ text, record }">
        <a type="link" @click="handleView(record)">查看</a>
        <a-divider type="vertical" v-if="isProjectAccount"/>
        <a type="link" @click="handleEdit(record)" v-if="isProjectAccount">修改</a>
        <a-divider type="vertical" />
        <a
          type="link"
          @click="
            downloadImage(
              record.educateDocument,
              record.teamName,
              record.type,
              record.educateDate
            )
          "
          >导出资料</a
        >
        <a-divider type="vertical" />
        <a
          v-if="record.type === 0"
          type="link"
          @click="handleDelete(record)"
          style="color: #ff7875"
          >删除</a
        >
      </template>
    </standard-table>

    <!-- 图片预览modal -->
    <a-modal
      :destroyOnClose="true"
      :visible="modalVisible"
      @cancel="cancelPreview"
      @ok="downQrCode"
      ok-text="下载"
      cancel-text="关闭"
    >
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
    <!-- 上周无教育记录班组modal -->
    <a-modal
      :destroyOnClose="true"
      title="上周无记录班组"
      v-model="modalNoRecord"
      :footer="null"
      :width="modalWidth"
    >
      <div class="flex j-between">
        <span class="font-bold">{{ user.loginLaoWuUser.projectName }}</span>
        <span
          >{{ modalParams.startDate }} (周一) ~
          {{ modalParams.endDate }} (周日)</span
        >
      </div>
      <standard-table
        class="mt-1"
        :columns="modalColumns"
        :dataSource="modalDataSource"
        :scroll="{ x: modalWidth - 100, y: 500 }"
        :pagination="modalPagination"
        :loading="modalTableLoading"
        @change="onModalPageChange"
        :isAlert="false"
        :bordered="true"
      >
      </standard-table>
    </a-modal>
    <Export-loading
      :cPercent="cPercent"
      :isExporting="isExporting"
      :loadingText="loadingText"
    ></Export-loading>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import { TYPE_ENUM, TYPE_NAME_MAP } from "./enum";
import {
  geteducationInfoPage,
  deleteEducationInfo,
  getNotEducatePreWeek,
} from "@/services/aqjyzl";
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import { useRender } from "@/hooks/useRender.js";
import { BATCHEXPORTEDUCATION } from "@/services/api";
import { DetailPageModeEnum } from "@/enums/pageEnum";
import { mapGetters } from "vuex";
import moment from "moment";
import { storage } from "@/utils/storage";
export default {
  name: "registePreClass",
  mixins: [exportProgress], //导出方法注入
  components: {
    formIndex,
    StandardTable,
    ExportLoading,
  },
  data() {
    return {
      formData: [
        {
          label: "班前教育日期",
          decorator: ["educateDate"],
          type: "rangePicker",
          key: "educateDate",
          col: 5,
          labelCol: 8,
          wrapperCol: 16,
          display: true,
          format: "yyyy-MM-DD",
          styles: { width: "100%" },
        },
        {
          label: "班组名称",
          placeholder: "请输入选择班组",
          decorator: ["teamName"],
          type: "inputGroup",
          key: "teamName",
          dataSource: [],
          col: 5,
          labelCol: 8,
          wrapperCol: 14,
          display: true,
        },
        {
          label: "记录来源",
          placeholder: "请输入记录来源",
          decorator: ["type"],
          type: "select",
          key: "type",
          selectlist: [
            { value: 0, name: "手工登记" },
            { value: 1, name: "AI识别" },
          ],
          col: 5,
          labelCol: 8,
          wrapperCol: 14,
          display: true,
        },
        {
          label: "最近编辑时间",
          placeholder: "",
          decorator: [
            "updateTime",
            { rules: [{ required: false, message: "" }] },
          ],
          type: "rangePicker",
          key: "updateTime",
          dataSource: [],
          col: 6,
          labelCol: 8,
          wrapperCol: 14,
          display: true,
          format: "YYYY-MM-DD",
        },
        {
          type: "action",
          col: 2,
          display: true,
        },
      ],
      columns: [
        {
          title: "项目名称",
          dataIndex: "projectName",
          align: "center",
        },
        {
          title: "班前教育日期",
          width: 160,
          dataIndex: "educateDate",
          align: "center",
          customRender: (text) => useRender.renderDate(text, "YYYY-MM-DD"),
        },
        {
          title: "记录来源",
          width: 120,
          dataIndex: "type",
          align: "center",
          scopedSlots: { customRender: "type" },
        },
        {
          title: "班组",
          dataIndex: "teamName",
          width: 200,
          tooltip: true,
          align: "center",
        },
        {
          title: "参与人数",
          dataIndex: "participatePersonNum",
          width: 120,
          align: "center",
          customRender(text, record) {
            return text === record.teamPersonNum ? `${text}（全员）` : text;
          },
        },
        {
          title: "教育日期班组在场人数",
          dataIndex: "teamPersonNum",
          align: "center",
          width: 160,
        },
        {
          title: "最近编辑时间",
          dataIndex: "updateTime",
          width: 120,
          align: "center",
          customRender: (text) => useRender.renderDate(text),
        },
        {
          title: "现场照片",
          dataIndex: "sitePhoto",
          align: "center",
          width: 100,
          scopedSlots: { customRender: "sitePhoto" },
        },
        {
          title: "操作",
          align: "center",
          width: 200,
          scopedSlots: { customRender: "action" },
        },
      ],
      modalColumns: [
        {
          title: "班组名称",
          dataIndex: "teamName",
          tooltip: true,
          align: "center",
        },
      ],
      dataSource: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showLessItems: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`,
      },
      tableLoading: false,
      modalVisible: false,
      previewImage: "",
      selectedRows: [], //选中的数据
      loadingText: "请稍后.",
      isExporting: false,
      cPercent: 0,
      modalWidth: 1000,
      modalNoRecord: false,
      modalPagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      modalTableLoading: false,
      modalDataSource: [],
      modalParams: {
        pageNo: 1,
        pageSize: 10,
        startDate: moment()
          .clone()
          .startOf("isoWeek")
          .subtract(1, "week")
          .format("YYYY-MM-DD"),
        endDate: moment()
          .clone()
          .endOf("isoWeek")
          .subtract(1, "week")
          .format("YYYY-MM-DD"),
      },
    };
  },
  computed: {
    ...mapGetters("account", ["user", "isProjectAccount"]),
  },
  methods: {
    getTypeName(text) {
      return TYPE_NAME_MAP[text];
    },
    onModalPageChange(e) {
      // let obj = this.$refs["formindex"].getFieldValue();
      this.modalParams.pageNo = e.current;
      this.modalPagination.current = e.current;
    },
    getNotEducatePreWeek() {
      getNotEducatePreWeek(this.modalParams).then((res) => {
        if (res.data.code === 0) {
          const data = res.data.data;
          if (data.length > 0) {
            this.modalDataSource = res.data.data.map((item) => ({
              teamName: item,
            }));
          }
        }
      });
    },
    getData(e) {
      storage.set('presenceList-submit', false)
      let params = {
        ...e,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
      };
      if (params?.educateDate && params?.educateDate.length > 1) {
        params.educateDate[0] = moment(
          params.educateDate[0] + " 00:00:00",
          "YYYY-MM-DD HH:mm:ss"
        ).valueOf();
        params.educateDate[1] = moment(
          params.educateDate[1] + " 23:59:59",
          "YYYY-MM-DD HH:mm:ss"
        ).valueOf();
      } else {
        delete params.educateDate;
      }
      if (params?.updateTime && params?.updateTime.length > 1) {
        params.updateTime[0] = moment(
          params.updateTime[0] + " 00:00:00",
          "YYYY-MM-DD HH:mm:ss"
        ).valueOf();
        params.updateTime[1] = moment(
          params.updateTime[1] + " 23:59:59",
          "YYYY-MM-DD HH:mm:ss"
        ).valueOf();
      } else {
        delete params.updateTime;
      }
      this.tableLoading = true;
      geteducationInfoPage(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          this.dataSource.forEach((item) => {
            if (item.sitePhoto && item.sitePhoto != "") {
              if (this.isOSSUrl(item.sitePhoto)) {
                // console.log('oss格式')
              } else {
                item.sitePhoto = `data:image/png;base64,${item.sitePhoto}`;
              }
            } else {
              item.sitePhoto = null;
            }
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    isOSSUrl(url) {
      // 正则表达式检查是否是 OSS URL
      const ossUrlPattern = /^https:\/\/[a-z0-9.-]+\.aliyuncs\.com\/.+/i;
      return ossUrlPattern.test(url);
    },
    updateSelectRows(e) {
      console.log(e);
    },

    formDataFn(key, data, itemName) {
      const arrSelect = [];
      const arrSource = [];
      data.forEach((item) => {
        arrSource.push({ title: item[itemName], id: item.id });
        arrSelect.push({ name: item[itemName], value: item.id });
      });
      this.formData.forEach((item) => {
        if (item.key === key) {
          item.dataSource = arrSource;
          item.selectlist = arrSelect;
        }
      });
    },

    handleReset() {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.$refs["formindex"].resetFields();
      this.getData({});
    },

    onTableChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      this.pagination.current = e.current;
      this.pagination.pageSize = e.pageSize;
      this.getData(obj);
    },

    showPreview(imgPath, record) {
      this.modalVisible = true;
      this.previewImage = imgPath;
      this.teamName = record.teamName + "现场照片";
    },

    cancelPreview() {
      this.modalVisible = false;
      this.previewImage = "";
      this.teamName = "";
    },
    downloadImage(url,filename,type,educateDate) {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = window.URL.createObjectURL(blob);
          let fileName = filename
          if (type || type === 0) {
            fileName = `班前教育记录(${type === 1 ? 'AI' : '登记'})-` + filename;
          }
          if (educateDate) {
            fileName = fileName + moment(educateDate).format("YYYY-MM-DD");
          }
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        });
    },
    downQrCode() {
      if (!this.previewImage) {
        return this.$message.warning("暂无图片！");
      }
      if (this.isOSSUrl(this.previewImage)) {
        this.downloadImage(this.previewImage, `${this.teamName}.png`);
        this.$message.success("下载成功！");
      } else {
        const [_, base64String] = this.previewImage.split(",");
        const byteCharacters = atob(base64String);
        const byteNumbers = Array.from(byteCharacters).map((c) =>
          c.charCodeAt(0)
        );
        const blob = new Blob([new Uint8Array(byteNumbers)], {
          type: "image/png",
        });
        const url = window.URL.createObjectURL(blob);
        this.downloadImage(url, `${this.teamName}.png`);
        window.URL.revokeObjectURL(url);
        this.$message.success("下载成功！");
      }
    },
    // 新增手工登记单
    handleAdd() {
      this.$router.push({
        path: "/preClassEducation/detail",
        query: { type: TYPE_ENUM.MANUAL, mode: DetailPageModeEnum.ADD },
      });
    },
    handleView({ id, type }) {
      this.$router.push({
        path: "/preClassEducation/detail",
        query: { id, type, mode: DetailPageModeEnum.VIEW },
      });
    },
    handleEdit({ id, type }) {
      this.$router.push({
        path: "/preClassEducation/detail",
        query: { id, type, mode: DetailPageModeEnum.EDIT },
      });
    },
    handleExportBatch() {
      let formObj = this.$refs["formindex"].getFieldValue();
      if (this.selectedRows.length === 0) {
        return this.$message.error("请选中需要导出的班前教育记录进行下载");
      }
      this.loadingText = "正在导出全部信息，请稍候 ····";
      let ids = [];

      if (this.selectedRows.length > 0) {
        this.selectedRows.forEach((el) => {
          ids.push(el.id);
        });
      }
      let obj = {
        ids: ids.length > 0 ? ids : undefined,
      };

      this.isExporting = true;
      this.cPercent = 0;
      this.exportFunNomal3(BATCHEXPORTEDUCATION, obj);
      this.selectedRows = [];
    },
    handleExport(record) {
      this.loadingText = "正在导出，请稍候 ····";
      const obj = {
        ids: record.id,
      };
      this.isExporting = true;
      this.cPercent = 0;
      this.exportFunNomal2(BATCHEXPORTEDUCATION, obj);
    },

    handleDelete(record) {
      this.deletePerson({ id: record.id }, "提示", "请问是否确认删除此条记录?");
    },
    //通过、驳回请求
    deletePerson(params, title, content, color) {
      this.$confirm({
        title: title,
        content: (h) => {
          return h("div", [
            h("div", { style: { color: color ? color : "unset" } }, content),
          ]);
        },
        onOk: () => {
          deleteEducationInfo(params).then((res) => {
            if (res.data.code === 0) {
              this.getData();
              this.$message.success("操作成功");
            } else {
              this.$message.error(res.data.msg);
            }
          });
        },
      });
    },
  },
  mounted() {
    this.getData();
    this.getNotEducatePreWeek();
    this.modalParams.projectId = this.user.projectId;
  },
  activated() {
    let obj = this.$refs["formindex"].getFieldValue();
    const submited = storage.get("presenceList-submit");
    submited && this.getData(obj);
  },
};
</script>

<style lang="less" scoped>
.a_a_footer {
  .operator {
    margin-right: 8px;

    .ant-btn {
      color: #307dfa;
    }
  }
}

.a_Approval {
  /deep/ .ant-modal-header .ant-modal-title {
    text-align: center;
    font-weight: bold;
  }
}
</style>
