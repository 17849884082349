var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "plug-form" },
    [
      _c(
        "a-form",
        { attrs: { form: _vm.form }, on: { submit: _vm.handleSubmit } },
        [
          _c("div", { staticClass: "flex j-between a-center" }, [
            _vm.Overview
              ? _c("div", { staticClass: "plug-Overview" }, [
                  _vm._v(_vm._s(_vm.Overview)),
                ])
              : _vm._e(),
            _vm.topSubmit
              ? _c(
                  "div",
                  { staticClass: "topSubmit" },
                  [_vm._t("topSubmit")],
                  2
                )
              : _vm._e(),
          ]),
          _vm.Overview ? _c("a-divider") : _vm._e(),
          _c(
            "div",
            { staticClass: "formdiv" },
            [
              _vm._l(_vm.formData, function (item) {
                return _c(
                  "div",
                  { key: item.key, class: ["formindex", item.class] },
                  [
                    item.display || item.disType == _vm.query.type
                      ? _c(
                          "a-col",
                          { attrs: { span: item.col } },
                          [
                            item.type == "place" ? void 0 : _vm._e(),
                            item.type == "input"
                              ? [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: item.label,
                                        labelCol: { span: item.labelCol },
                                        wrapperCol: {
                                          span: item.wrapperCol,
                                          offset: 1,
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "aIput",
                                          class: {
                                            "custom-input-number":
                                              item.inputType === "number",
                                          },
                                        },
                                        [
                                          _c("a-input", {
                                            directives: [
                                              {
                                                name: "decorator",
                                                rawName: "v-decorator",
                                                value: item.unsetRules
                                                  ? item.decorator
                                                  : _vm.setRules(
                                                      item.decorator
                                                    ),
                                                expression:
                                                  "item.unsetRules ? item.decorator : setRules(item.decorator)",
                                              },
                                            ],
                                            style: item.style,
                                            attrs: {
                                              type: item.inputType || "text",
                                              disabled: item.disabled,
                                              maxLength: item.maxLength,
                                              placeholder: item.placeholder,
                                              allowClear: "",
                                              "addon-after": item.addonAfter,
                                              suffix: item.suffix,
                                            },
                                            on: { change: item.inputChange },
                                          }),
                                          item.key == "lanAndLat"
                                            ? _c(
                                                "div",
                                                { staticClass: "aMap" },
                                                [_vm._v(" 地图上选择 ")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                            item.type == "inputNumber"
                              ? [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: item.label,
                                        labelCol: { span: item.labelCol },
                                        wrapperCol: {
                                          span: item.wrapperCol,
                                          offset: 1,
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "aIput",
                                          class: {
                                            "custom-input-number":
                                              item.inputType === "number",
                                          },
                                        },
                                        [
                                          _c("a-input-number", {
                                            directives: [
                                              {
                                                name: "decorator",
                                                rawName: "v-decorator",
                                                value: item.unsetRules
                                                  ? item.decorator
                                                  : _vm.setRules(
                                                      item.decorator
                                                    ),
                                                expression:
                                                  "item.unsetRules ? item.decorator : setRules(item.decorator)",
                                              },
                                            ],
                                            style: item.style,
                                            attrs: {
                                              disabled: item.disabled,
                                              maxLength: item.maxLength,
                                              placeholder: item.placeholder,
                                              allowClear: "",
                                              "addon-after": item.addonAfter,
                                            },
                                            on: { change: item.inputChange },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                            item.type == "inputPassWord"
                              ? [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: item.label,
                                        labelCol: { span: item.labelCol },
                                        wrapperCol: {
                                          span: item.wrapperCol,
                                          offset: 1,
                                        },
                                      },
                                    },
                                    [
                                      _c("a-input-password", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: item.unsetRules
                                              ? item.decorator
                                              : _vm.setRules(item.decorator),
                                            expression:
                                              "item.unsetRules ? item.decorator : setRules(item.decorator)",
                                          },
                                        ],
                                        style: item.style,
                                        attrs: {
                                          disabled: item.disabled,
                                          maxLength: item.maxLength,
                                          placeholder: item.placeholder,
                                          allowClear: "",
                                        },
                                        on: { change: item.inputChange },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            item.type == "inputList"
                              ? [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: item.label,
                                        labelCol: { span: item.labelCol },
                                        wrapperCol: {
                                          span: item.wrapperCol,
                                          offset: 1,
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "aIput custom-input-number",
                                          staticStyle: {
                                            display: "flex",
                                            "justify-content": "space-between",
                                          },
                                        },
                                        _vm._l(item.inputList, function (i) {
                                          return _c(
                                            "a-form-item",
                                            {
                                              key: i.key,
                                              attrs: { label: i.label },
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value: i.unsetRules
                                                      ? i.decorator
                                                      : _vm.setRules(
                                                          i.decorator
                                                        ),
                                                    expression:
                                                      "i.unsetRules ? i.decorator : setRules(i.decorator)",
                                                  },
                                                ],
                                                style: i.style,
                                                attrs: {
                                                  type: i.inputType || "text",
                                                  disabled: i.disabled,
                                                  maxLength: i.maxLength,
                                                  placeholder: i.placeholder,
                                                  allowClear: "",
                                                  "addon-after": i.addonAfter,
                                                },
                                                on: { change: i.inputChange },
                                              }),
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                            item.type == "inputGroup"
                              ? [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: item.label,
                                        labelCol: { span: item.labelCol },
                                        wrapperCol: {
                                          span: item.wrapperCol,
                                          offset: 1,
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "a-auto-complete",
                                        {
                                          directives: [
                                            {
                                              name: "decorator",
                                              rawName: "v-decorator",
                                              value: item.decorator,
                                              expression: "item.decorator",
                                            },
                                          ],
                                          style: item.style,
                                          attrs: {
                                            "data-source": item.dataSource,
                                            placeholder: item.placeholder,
                                            allowClear: "",
                                            disabled: item.disabled,
                                          },
                                          on: {
                                            search: function ($event) {
                                              return _vm.handleSearch(
                                                $event,
                                                item
                                              )
                                            },
                                            select: function ($event) {
                                              return _vm.handlSelect(
                                                $event,
                                                item.key
                                              )
                                            },
                                            change: function (val, opt) {
                                              return _vm.handleChange(
                                                val,
                                                item.key,
                                                opt
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "dataSource" },
                                            _vm._l(
                                              item.dataSource,
                                              function (items) {
                                                return _c(
                                                  "a-select-option",
                                                  {
                                                    key: items.id + "",
                                                    attrs: {
                                                      title: items.title,
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(items.title))]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            item.type == "select"
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "select" },
                                    [
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: item.label,
                                            labelCol: { span: item.labelCol },
                                            wrapperCol: {
                                              span: item.wrapperCol,
                                              offset: 1,
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "a-select",
                                            {
                                              directives: [
                                                {
                                                  name: "decorator",
                                                  rawName: "v-decorator",
                                                  value: item.decorator,
                                                  expression: "item.decorator",
                                                },
                                              ],
                                              attrs: {
                                                mode: item.mode
                                                  ? item.mode
                                                  : "default",
                                                disabled: item.disabled,
                                                "show-search": item.showSearch,
                                                placeholder: item.placeholder,
                                                allowClear: !item.allowClear,
                                                "filter-option":
                                                  _vm.filterOption,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.handlSelect(
                                                    $event,
                                                    item.key
                                                  )
                                                },
                                                search: function ($event) {
                                                  return _vm.handleSearch(
                                                    $event,
                                                    item.key
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              item.qb
                                                ? _c(
                                                    "a-select-option",
                                                    { attrs: { value: "" } },
                                                    [_vm._v("全部")]
                                                  )
                                                : _vm._e(),
                                              _vm._l(
                                                item.selectlist,
                                                function (items) {
                                                  return _c(
                                                    "a-select-option",
                                                    {
                                                      key: items.name,
                                                      attrs: {
                                                        value: items.value,
                                                        title: items.name,
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(items.name))]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            item.type == "Upload"
                              ? [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: item.label,
                                        labelCol: { span: item.labelCol },
                                        wrapperCol: {
                                          span: item.wrapperCol,
                                          offset: 1,
                                        },
                                      },
                                    },
                                    [
                                      [
                                        _c(
                                          "a-upload",
                                          {
                                            directives: [
                                              {
                                                name: "decorator",
                                                rawName: "v-decorator",
                                                value: item.decorator,
                                                expression: "item.decorator",
                                              },
                                            ],
                                            attrs: {
                                              "file-list": _vm.defaultFileList,
                                              remove: _vm.upRemove,
                                              data: item,
                                              accept: _vm.accept,
                                              disabled: item.disabled,
                                              customRequest: _vm.resourceUpload,
                                              "list-type": "picture-card",
                                              multiple: true,
                                              beforeUpload: _vm.beforeUpload,
                                            },
                                            on: { preview: _vm.handlePreview },
                                          },
                                          [
                                            _vm.defaultFileList.length <
                                            (item.uploadLength || 1)
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c("a-icon", {
                                                      attrs: { type: "plus" },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      _c(
                                        "a-modal",
                                        {
                                          attrs: {
                                            visible: _vm.previewVisible,
                                            footer: null,
                                          },
                                          on: { cancel: _vm.handleCancel },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              alt: "example",
                                              src: _vm.previewImage,
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              : _vm._e(),
                            item.type == "tsUpload"
                              ? [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: item.label,
                                        labelCol: { span: item.labelCol },
                                        wrapperCol: {
                                          span: item.wrapperCol,
                                          offset: 1,
                                        },
                                      },
                                    },
                                    [
                                      item.defaultFileList.length > 0 &&
                                      _vm.checkFileType(
                                        item.defaultFileList[0].url,
                                        item.defaultFileList[0].name
                                      ) != "image" &&
                                      item.listType &&
                                      item.listType === "picture-card"
                                        ? _vm._l(
                                            item.defaultFileList,
                                            function (items) {
                                              return _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value: item.decorator,
                                                      expression:
                                                        "item.decorator",
                                                    },
                                                  ],
                                                  key: items.uid,
                                                  staticClass:
                                                    "ant-upload-list-item-info",
                                                },
                                                [
                                                  _vm.checkFileType(
                                                    item.defaultFileList[0].url,
                                                    item.defaultFileList[0].name
                                                  ) == "pdf"
                                                    ? _c("a-icon", {
                                                        style: {
                                                          fontSize: "60px",
                                                        },
                                                        attrs: {
                                                          type: "file-pdf",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm.checkFileType(
                                                    item.defaultFileList[0].url,
                                                    item.defaultFileList[0].name
                                                  ) == "doc"
                                                    ? _c("a-icon", {
                                                        style: {
                                                          fontSize: "60px",
                                                        },
                                                        attrs: { type: "file" },
                                                      })
                                                    : _vm._e(),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "ant-upload-list-item-actions",
                                                    },
                                                    [
                                                      _c("a-icon", {
                                                        staticClass:
                                                          "anticon-eye-o",
                                                        attrs: { type: "eye" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleTsPreview(
                                                              items
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c("a-icon", {
                                                        staticClass:
                                                          "anticon-delete",
                                                        attrs: {
                                                          type: "delete",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.upRemove(
                                                              items
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : [
                                            _c(
                                              "a-upload",
                                              {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value: item.decorator,
                                                    expression:
                                                      "item.decorator",
                                                  },
                                                ],
                                                attrs: {
                                                  "file-list":
                                                    item.defaultFileList,
                                                  remove: _vm.upRemove,
                                                  data: item,
                                                  accept: item.accept
                                                    ? item.accept
                                                    : _vm.accept,
                                                  customRequest:
                                                    _vm.resourceTsUpload,
                                                  "list-type": item.listType
                                                    ? item.listType
                                                    : "text",
                                                  multiple: false,
                                                  beforeUpload:
                                                    _vm.beforeUpload,
                                                  showUploadList:
                                                    item.showUploadList
                                                      ? item.showUploadList
                                                      : false,
                                                },
                                                on: {
                                                  preview: _vm.handleTsPreview,
                                                },
                                              },
                                              [
                                                item.listType &&
                                                item.listType === "picture-card"
                                                  ? [
                                                      item.defaultFileList
                                                        .length < 1
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c("a-icon", {
                                                                attrs: {
                                                                  type: "plus",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  : [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "ts-upload-box",
                                                        },
                                                        [
                                                          item.defaultFileList
                                                            .length > 0
                                                            ? _c("div", [
                                                                _c("img", {
                                                                  staticClass:
                                                                    "ts-img",
                                                                  style:
                                                                    item.defaultStyleImg,
                                                                  attrs: {
                                                                    src: item
                                                                      .defaultFileList[0]
                                                                      .url,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                      },
                                                                  },
                                                                }),
                                                              ])
                                                            : _vm._e(),
                                                          item.defaultFileList
                                                            .length < 1 &&
                                                          item.defaultImg
                                                            ? _c("div", [
                                                                _c("img", {
                                                                  staticClass:
                                                                    "ts-img",
                                                                  style:
                                                                    item.defaultStyleImg,
                                                                  attrs: {
                                                                    src: item.defaultImg,
                                                                  },
                                                                }),
                                                              ])
                                                            : _vm._e(),
                                                          _c(
                                                            "a-button",
                                                            {
                                                              class:
                                                                item.defaultImg
                                                                  ? "ts-upload-button"
                                                                  : "",
                                                              attrs: {
                                                                type: "primary",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.title
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                              ],
                                              2
                                            ),
                                          ],
                                      _c(
                                        "a-modal",
                                        {
                                          attrs: {
                                            visible: _vm.previewTsVisible,
                                            footer: null,
                                          },
                                          on: { cancel: _vm.handleCancel },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              alt: "example",
                                              src: _vm.previewImage,
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              : _vm._e(),
                            item.type == "tsCropperUpload"
                              ? [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: item.label,
                                        labelCol: { span: item.labelCol },
                                        wrapperCol: {
                                          span: item.wrapperCol,
                                          offset: 1,
                                        },
                                      },
                                    },
                                    [
                                      item.defaultFileList.length > 0 &&
                                      _vm.checkFileType(
                                        item.defaultFileList[0].url,
                                        item.defaultFileList[0].name
                                      ) != "image" &&
                                      item.listType &&
                                      item.listType === "picture-card"
                                        ? _vm._l(
                                            item.defaultFileList,
                                            function (items) {
                                              return _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value: item.decorator,
                                                      expression:
                                                        "item.decorator",
                                                    },
                                                  ],
                                                  key: items.uid,
                                                  staticClass:
                                                    "ant-upload-list-item-info",
                                                },
                                                [
                                                  _vm.checkFileType(
                                                    item.defaultFileList[0].url,
                                                    item.defaultFileList[0].name
                                                  ) == "pdf"
                                                    ? _c("a-icon", {
                                                        style: {
                                                          fontSize: "60px",
                                                        },
                                                        attrs: {
                                                          type: "file-pdf",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm.checkFileType(
                                                    item.defaultFileList[0].url,
                                                    item.defaultFileList[0].name
                                                  ) == "doc"
                                                    ? _c("a-icon", {
                                                        style: {
                                                          fontSize: "60px",
                                                        },
                                                        attrs: { type: "file" },
                                                      })
                                                    : _vm._e(),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "ant-upload-list-item-actions",
                                                    },
                                                    [
                                                      _c("a-icon", {
                                                        staticClass:
                                                          "anticon-eye-o",
                                                        attrs: { type: "eye" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleTsPreview(
                                                              items
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c("a-icon", {
                                                        staticClass:
                                                          "anticon-delete",
                                                        attrs: {
                                                          type: "delete",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.upRemove(
                                                              items
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : [
                                            _c(
                                              "a-upload",
                                              {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value: item.decorator,
                                                    expression:
                                                      "item.decorator",
                                                  },
                                                ],
                                                attrs: {
                                                  "file-list":
                                                    item.defaultFileList,
                                                  remove: _vm.upRemove,
                                                  data: item,
                                                  accept: item.accept
                                                    ? item.accept
                                                    : _vm.accept,
                                                  customRequest:
                                                    _vm.resourceCropperTsUpload,
                                                  "list-type": item.listType
                                                    ? item.listType
                                                    : "text",
                                                  multiple: false,
                                                  beforeUpload:
                                                    _vm.beforeUpload,
                                                  showUploadList:
                                                    item.showUploadList
                                                      ? item.showUploadList
                                                      : false,
                                                },
                                                on: {
                                                  preview: _vm.handleTsPreview,
                                                },
                                              },
                                              [
                                                item.listType &&
                                                item.listType === "picture-card"
                                                  ? [
                                                      item.defaultFileList
                                                        .length < 1
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c("a-icon", {
                                                                attrs: {
                                                                  type: "plus",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  : [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "ts-upload-box",
                                                        },
                                                        [
                                                          item.defaultFileList
                                                            .length > 0
                                                            ? _c("div", [
                                                                _c("img", {
                                                                  staticClass:
                                                                    "ts-img",
                                                                  attrs: {
                                                                    src: item
                                                                      .defaultFileList[0]
                                                                      .url,
                                                                  },
                                                                }),
                                                              ])
                                                            : _vm._e(),
                                                          item.defaultFileList
                                                            .length < 1 &&
                                                          item.defaultImg
                                                            ? _c("div", [
                                                                _c("img", {
                                                                  staticClass:
                                                                    "ts-img",
                                                                  attrs: {
                                                                    src: item.defaultImg,
                                                                  },
                                                                }),
                                                              ])
                                                            : _vm._e(),
                                                          _c(
                                                            "a-button",
                                                            {
                                                              class:
                                                                item.defaultImg
                                                                  ? "ts-upload-button"
                                                                  : "",
                                                              attrs: {
                                                                type: "primary",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.title
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                              ],
                                              2
                                            ),
                                          ],
                                      _c(
                                        "a-modal",
                                        {
                                          attrs: {
                                            title: item.cropperTitle,
                                            width: item.showCroImg ? 1400 : 700,
                                            visible: _vm.cropperVisible,
                                          },
                                          on: {
                                            cancel: function ($event) {
                                              _vm.cropperVisible = false
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "avatar",
                                              staticStyle: { display: "flex" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "avatar-left" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.options1.img,
                                                          expression:
                                                            "options1.img",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "avatar-left-crop",
                                                    },
                                                    [
                                                      _c("vueCropper", {
                                                        ref: "cropper",
                                                        refInFor: true,
                                                        staticClass: "crop-box",
                                                        attrs: {
                                                          img: _vm.options1.img,
                                                          autoCrop:
                                                            _vm.options1
                                                              .autoCrop,
                                                          centerBox:
                                                            _vm.options1
                                                              .centerBox,
                                                          full: _vm.options1
                                                            .full,
                                                          canScale: true,
                                                        },
                                                        on: {
                                                          realTime:
                                                            _vm.realTime,
                                                        },
                                                      }),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "avatar-left-p",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " 鼠标滚轮缩放控制图片显示大小，鼠标拖拽调整显示位置"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "w-100 flex" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "avatar-right mr-2",
                                                      style: {
                                                        width: item.showCroImg
                                                          ? 150 + "px"
                                                          : 250 + "px",
                                                      },
                                                    },
                                                    [
                                                      _vm._l(
                                                        _vm.previewsDiv,
                                                        function (item, index) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: index,
                                                              staticClass:
                                                                "avatar-right-div",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "show",
                                                                      rawName:
                                                                        "v-show",
                                                                      value:
                                                                        _vm
                                                                          .options1
                                                                          .img,
                                                                      expression:
                                                                        "options1.img",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "avatar-right-previews",
                                                                  style: [
                                                                    _vm.previews
                                                                      .div,
                                                                    item.zoomStyle,
                                                                    item.style,
                                                                  ],
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    style:
                                                                      _vm
                                                                        .previews
                                                                        .img,
                                                                    attrs: {
                                                                      src: _vm
                                                                        .previews
                                                                        .url,
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "avatar-right-text",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "mr-2 pointer",
                                                              staticStyle: {
                                                                color:
                                                                  "#62A6F8",
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.rotateLeft,
                                                              },
                                                            },
                                                            [_vm._v("左转")]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "pointer",
                                                              staticStyle: {
                                                                color:
                                                                  "#62A6F8",
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.rotateRight,
                                                              },
                                                            },
                                                            [_vm._v("右转")]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                  item.showCroImg
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "croImg flex-1",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              alt: "croImg",
                                                              src: _vm.croImg,
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "dialog-footer",
                                              attrs: { slot: "footer" },
                                              slot: "footer",
                                            },
                                            [
                                              _c(
                                                "a-button",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.cropperVisible = false
                                                    },
                                                  },
                                                },
                                                [_vm._v("取 消")]
                                              ),
                                              _c(
                                                "a-button",
                                                {
                                                  attrs: { type: "primary" },
                                                  on: { click: _vm.getCrop },
                                                },
                                                [_vm._v("确 定")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              : _vm._e(),
                            item.type == "textarea"
                              ? [
                                  _c(
                                    "a-form-item",
                                    {
                                      style: item.style,
                                      attrs: {
                                        label: item.label,
                                        labelCol: { span: item.labelCol },
                                        wrapperCol: {
                                          span: item.wrapperCol,
                                          offset: 1,
                                        },
                                      },
                                    },
                                    [
                                      _c("a-textarea", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: item.decorator,
                                            expression: "item.decorator",
                                          },
                                        ],
                                        style: item.styles,
                                        attrs: {
                                          disabled: item.disabled,
                                          "auto-size": {
                                            minRows: 4,
                                            maxRows: 5,
                                          },
                                          placeholder: item.placeholder,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            item.type == "datePicker"
                              ? [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: item.label,
                                        labelCol: { span: item.labelCol },
                                        wrapperCol: {
                                          span: item.wrapperCol,
                                          offset: 1,
                                        },
                                      },
                                    },
                                    [
                                      _c("a-date-picker", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: item.decorator,
                                            expression: "item.decorator",
                                          },
                                        ],
                                        style: item.styles,
                                        attrs: {
                                          defaultPickerValue:
                                            item.defaultPickerValue,
                                          disabledDate: item.disabledDate,
                                          disabled: item.disabled,
                                          allowClear: "",
                                          format: item.format,
                                          valueFormat: item.format,
                                          "show-time": item.showTime,
                                        },
                                        on: { change: _vm.onDateChange },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            item.type == "yearPicker"
                              ? [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: item.label,
                                        labelCol: { span: item.labelCol },
                                        wrapperCol: {
                                          span: item.wrapperCol,
                                          offset: 1,
                                        },
                                      },
                                    },
                                    [
                                      _c("a-date-picker", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: item.decorator,
                                            expression: "item.decorator",
                                          },
                                        ],
                                        style: item.styles,
                                        attrs: {
                                          disabled: item.disabled,
                                          allowClear: "",
                                          mode: "year",
                                          format: "YYYY",
                                        },
                                        on: { panelChange: _vm.changeYear },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            item.type == "monthPicker"
                              ? [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: item.label,
                                        labelCol: { span: item.labelCol },
                                        wrapperCol: {
                                          span: item.wrapperCol,
                                          offset: 1,
                                        },
                                      },
                                    },
                                    [
                                      _c("a-month-picker", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: item.decorator,
                                            expression: "item.decorator",
                                          },
                                        ],
                                        style: item.styles,
                                        attrs: {
                                          disabled: item.disabled,
                                          allowClear: "",
                                          valueFormat: item.format,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            item.type == "rangePicker"
                              ? [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: item.label,
                                        labelCol: { span: item.labelCol },
                                        wrapperCol: {
                                          span: item.wrapperCol,
                                          offset: 1,
                                        },
                                      },
                                    },
                                    [
                                      _c("a-range-picker", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: item.decorator,
                                            expression: "item.decorator",
                                          },
                                        ],
                                        style: item.styles,
                                        attrs: {
                                          "show-time": item.showTime,
                                          valueFormat: item.format,
                                          allowClear: "",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            item.type == "rangeMonthPicker"
                              ? [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: item.label,
                                        labelCol: { span: item.labelCol },
                                        wrapperCol: {
                                          span: item.wrapperCol,
                                          offset: 1,
                                        },
                                      },
                                    },
                                    [
                                      _c("a-range-picker", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: item.decorator,
                                            expression: "item.decorator",
                                          },
                                        ],
                                        attrs: {
                                          open: _vm.isRangeOpen,
                                          mode: item.mode,
                                          format: item.format,
                                          allowClear: "",
                                        },
                                        on: {
                                          openChange: _vm.openRangeChange,
                                          panelChange: function ($event) {
                                            return _vm.handlePanelChange(
                                              $event,
                                              item.key
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            item.type == "cascader"
                              ? [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: item.label,
                                        labelCol: { span: item.labelCol },
                                        wrapperCol: {
                                          span: item.wrapperCol,
                                          offset: 1,
                                        },
                                      },
                                    },
                                    [
                                      _c("a-cascader", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: item.decorator,
                                            expression: "item.decorator",
                                          },
                                        ],
                                        attrs: {
                                          "show-search": { filter: _vm.filter },
                                          options: _vm.options,
                                          placeholder: item.placeholder,
                                          changeOnSelect: item.changeOnSelect
                                            ? item.changeOnSelect
                                            : false,
                                        },
                                        on: { change: _vm.onChange },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            item.type == "radio"
                              ? [
                                  _c(
                                    "a-form-item",
                                    {
                                      class: item.class,
                                      attrs: {
                                        label: item.label,
                                        labelCol: { span: item.labelCol },
                                        wrapperCol: {
                                          span: item.wrapperCol,
                                          offset: 1,
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "a-radio-group",
                                        {
                                          directives: [
                                            {
                                              name: "decorator",
                                              rawName: "v-decorator",
                                              value: item.decorator,
                                              expression: "item.decorator",
                                            },
                                          ],
                                          attrs: { disabled: item.disabled },
                                          on: {
                                            change: function ($event) {
                                              return _vm.handlSelect(
                                                $event,
                                                item.key
                                              )
                                            },
                                          },
                                        },
                                        _vm._l(
                                          item.radiolist,
                                          function (items) {
                                            return _c(
                                              "a-radio",
                                              {
                                                key: items.name,
                                                attrs: { value: items.value },
                                              },
                                              [_vm._v(_vm._s(items.name))]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            item.type == "checkbox"
                              ? [
                                  _c(
                                    "a-form-item",
                                    {
                                      class: item.noClass ? "" : "checkbox",
                                      attrs: {
                                        label: item.label,
                                        labelCol: { span: item.labelCol },
                                        wrapperCol: {
                                          span: item.wrapperCol,
                                          offset: 1,
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "a-checkbox-group",
                                        {
                                          directives: [
                                            {
                                              name: "decorator",
                                              rawName: "v-decorator",
                                              value: item.decorator,
                                              expression: "item.decorator",
                                            },
                                          ],
                                        },
                                        _vm._l(
                                          item.checkboxlist,
                                          function (items) {
                                            return _c(
                                              "a-checkbox",
                                              {
                                                key: items.name,
                                                style: item.styles,
                                                attrs: {
                                                  disabled: item.disabled,
                                                  value: items.value,
                                                },
                                              },
                                              [_vm._v(_vm._s(items.name))]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            item.type == "checkboxList"
                              ? [
                                  _c(
                                    "a-form-item",
                                    {
                                      class: item.noClass ? "" : "checkbox",
                                      attrs: {
                                        label: item.label,
                                        labelCol: { span: item.labelCol },
                                        wrapperCol: {
                                          span: item.wrapperCol,
                                          offset: 1,
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "flex" },
                                        _vm._l(item.checkboxList, function (i) {
                                          return _c(
                                            "a-form-item",
                                            { key: i.key },
                                            [
                                              _c(
                                                "a-checkbox-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value: i.decorator,
                                                      expression: "i.decorator",
                                                    },
                                                  ],
                                                },
                                                _vm._l(
                                                  i.checkboxItemList,
                                                  function (items) {
                                                    return _c(
                                                      "a-checkbox",
                                                      {
                                                        key: items.name,
                                                        style: items.styles,
                                                        attrs: {
                                                          disabled:
                                                            items.disabled,
                                                          value: items.value,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(items.name)
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                            item.type == "addonBefore"
                              ? [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: item.label,
                                        labelCol: { span: item.labelCol },
                                        wrapperCol: {
                                          span: item.wrapperCol,
                                          offset: 1,
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "a-input",
                                        {
                                          directives: [
                                            {
                                              name: "decorator",
                                              rawName: "v-decorator",
                                              value: item.decorator,
                                              expression: "item.decorator",
                                            },
                                          ],
                                        },
                                        [
                                          _c(
                                            "a-select",
                                            {
                                              directives: [
                                                {
                                                  name: "decorator",
                                                  rawName: "v-decorator",
                                                  value: item.decorators,
                                                  expression: "item.decorators",
                                                },
                                              ],
                                              staticStyle: { width: "100px" },
                                              attrs: { slot: "addonBefore" },
                                              slot: "addonBefore",
                                            },
                                            _vm._l(
                                              item.selectList,
                                              function (items) {
                                                return _c(
                                                  "a-select-option",
                                                  {
                                                    key: items.name,
                                                    attrs: {
                                                      value: items.value,
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(items.name))]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            item.type == "treeSelect"
                              ? [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: item.label,
                                        labelCol: { span: item.labelCol },
                                        wrapperCol: {
                                          span: item.wrapperCol,
                                          offset: 1,
                                        },
                                      },
                                    },
                                    [
                                      _c("a-tree-select", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: item.decorator,
                                            expression: "item.decorator",
                                          },
                                        ],
                                        attrs: {
                                          disabled: item.disabled,
                                          "dropdown-style": {
                                            maxHeight: "400px",
                                            overflow: "auto",
                                          },
                                          "tree-data": item.selectlist,
                                          placeholder: "请选择",
                                          "tree-default-expand-all": "",
                                          replaceFields: {
                                            children: "children",
                                            title: "name",
                                            key: "id",
                                            value: "id",
                                          },
                                          treeDefaultExpandAll: false,
                                          allowClear: true,
                                          "show-search": item.showSearch,
                                          "tree-node-filter-prop": "title",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.handlSelect(
                                              $event,
                                              item.key
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            item.type == "action"
                              ? [
                                  _vm.flexAction
                                    ? _c("div", [_vm._t("flexAction")], 2)
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                            item.type == "customAction"
                              ? [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: item.label,
                                        labelCol: { span: item.labelCol },
                                        wrapperCol: {
                                          span: item.wrapperCol,
                                          offset: 1,
                                        },
                                      },
                                    },
                                    [_vm._t("customAction"), _vm._t(item.slot)],
                                    2
                                  ),
                                ]
                              : _vm._e(),
                            item.type == "customAction2"
                              ? [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: item.label,
                                        labelCol: { span: item.labelCol },
                                        wrapperCol: {
                                          span: item.wrapperCol,
                                          offset: 1,
                                        },
                                      },
                                    },
                                    [_vm._t(item.slot)],
                                    2
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              _vm.flexSubmit ? _c("div", [_vm._t("flexSubmit")], 2) : _vm._e(),
            ],
            2
          ),
          _vm.bottomSubmit
            ? _c(
                "div",
                { staticClass: "bottomSubmit" },
                [_vm._t("bottomSubmit"), _vm._t("bottomSubmitLeft")],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.baiduMapBoolen
        ? _c(
            "div",
            [
              _c(
                "baidu-map",
                {
                  staticClass: "bm-view",
                  attrs: { "scroll-wheel-zoom": true },
                  on: { click: _vm.getPoint, ready: _vm.mapReady },
                },
                [
                  _c("bm-view", {
                    staticStyle: { width: "100%", flex: "1", height: "454px" },
                  }),
                  _c("bm-local-search", {
                    staticStyle: { display: "none" },
                    attrs: {
                      keyword: _vm.addressKeyword,
                      "auto-viewport": true,
                    },
                  }),
                  _c("bm-navigation", {
                    attrs: { anchor: "BMAP_ANCHOR_TOP_RIGHT" },
                  }),
                  _c(
                    "div",
                    { staticClass: "bm-complate" },
                    [
                      _c(
                        "bm-auto-complete",
                        {
                          attrs: { sugStyle: { zIndex: 999999 } },
                          model: {
                            value: _vm.searchJingwei,
                            callback: function ($$v) {
                              _vm.searchJingwei = $$v
                            },
                            expression: "searchJingwei",
                          },
                        },
                        [
                          _c("a-input", {
                            staticStyle: {
                              width: "300px",
                              "margin-right": "15px",
                            },
                            attrs: { placeholder: "输入地址" },
                            model: {
                              value: _vm.searchJingwei,
                              callback: function ($$v) {
                                _vm.searchJingwei = $$v
                              },
                              expression: "searchJingwei",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.getBaiduMapPoint },
                        },
                        [_vm._v("搜索")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "bm-marker",
                    {
                      attrs: {
                        show: _vm.show,
                        position: {
                          lng: _vm.queryParams.longitude,
                          lat: _vm.queryParams.latitude,
                        },
                        dragging: true,
                      },
                      on: { dragend: _vm.getPoint },
                    },
                    [
                      _c(
                        "bm-info-window",
                        {
                          attrs: { show: _vm.show },
                          on: {
                            close: _vm.infoWindowClose,
                            open: _vm.infoWindowOpen,
                          },
                        },
                        [
                          _c("p", [
                            _vm._v("纬度:" + _vm._s(_vm.queryParams.latitude)),
                          ]),
                          _c("p", [
                            _vm._v("经度:" + _vm._s(_vm.queryParams.longitude)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }