<template>
    <div class="a_Approval y-rc-main">
        <a-form-model :model="params" class="clearfix" @submit="handleSubmit">
        <a-row type="flex" justify="space-between">
            <a-col :span="16">
                
                 <a-row :span="16" v-if="isJiTuan">
                    <a-col :span="4" v-if="!isProject && !isBranchOffice">
                        <a-form-model-item label="分公司" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">

                            <a-select v-model="params.branchOfficeId" allowClear placeholder="">
                                <!-- <a-select-option value="">全部</a-select-option> -->
                                <a-select-option v-for="item in onlyGcglbList" :key="item.value" :value="item.value"
                                    :title="item.label">
                                    {{ item.label }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="5" v-if="!isProject">
                        <a-form-model-item label="项目" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
                            <a-input v-model="params.projectName" allow-clear />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="4">
                        <a-form-model-item label="月份" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
                            <a-month-picker v-model="params.reportTime"  placeholder="选择月份" valueFormat="YYYY-MM" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="6">
                        <a-button class="ml-4" type="primary" html-type="submit"> 查询 </a-button>

                    </a-col>    
                 </a-row>
            </a-col>

            
            <a-col :span="6">
                <a-space class="btnBox">
                    
                    <!-- <a-button type="primary" @click="resetQuery"> 新建清单 </a-button> -->
                    <a-button class="mr-10" type="primary" @click="handleExport"> 导出 </a-button>

                    <template v-if="!isJiTuan">

                        <a-tag v-if="currentStatus == 2" color="red">已驳回</a-tag>
                        <a-tag v-if="currentStatus == 1" color="green">已提交</a-tag>
                        <a-button v-if="plan.reserved3 == role" type="primary" @click="handleOpenReject"> 提交 </a-button>
                    </template>
                   
                    
                </a-space>
            </a-col>
        </a-row>
        </a-form-model>
        <a-row type="flex" justify="center" v-if="!isJiTuan">
            <a-col span="12">

                <a-steps :current="currentStep" size="small">
                    <a-step v-for="(item,index) in stepList" :title="item.name" :key="index" >
                        <span slot="description">
                            <!-- <a-tag v-if="item.status == 0" color="blue">未提交</a-tag> -->
                            <a-tag v-if="item.status == 1" color="green">已提交</a-tag>
                            <a-tag v-if="item.status == 2" color="red">已驳回</a-tag>
                        </span>
                    </a-step>
                </a-steps>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="3" >
                <div class="rc-st-title">
                    
                </div>
            </a-col>
            <a-col :span="12" :offset="3">
                <div class="rc-title">

                    {{params.reportTime}}月安全生产风险排查管控清单
                </div>
            </a-col>
            <a-col :span="3" :offset="3">
                <a-button type="primary" @click="addNewComplaint" v-if="isProject"> 新增风险 </a-button>

            </a-col>
        </a-row>
        <standard-table :columns="columns" :dataSource="tableData" rowKey="id" :pagination="pagination"
            :loading="tableLoading" @change="handleChangePage" :isAlert="false" :bordered="true" :btnArr="tableBtnArr"
            :selectedRows.sync="selectedRows" :scroll="{ x: 1400 }">
            <!-- @selectedRowChange="handleSelectedRowsChange" :totalBoolean="true" :rowColor="false" -->
            <template slot="index" slot-scope="{ index }">
                <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
            </template>
            <template slot="expertProve" slot-scope="{ record }">
                <a-tag color="#108ee9" v-if="record.expertProve == 1">是</a-tag>
                <a-tag color="#f50" v-if="record.expertProve == 0">否</a-tag>
            </template>
            <template slot="overRange" slot-scope="{ record }">
                <a-tag color="#108ee9" v-if="record.overRange == 1">是</a-tag>
                <a-tag color="#f50" v-if="record.overRange == 0">否</a-tag>
            </template>
            <template slot="riskCoverState" slot-scope="{ record }">
                <a-tag color="#f50" v-if="record.riskCoverState == 1">未覆盖</a-tag>
                <a-tag color="#108ee9" v-if="record.riskCoverState == 2">已覆盖</a-tag>
            </template>
            <template slot="projectAppointHead" slot-scope="{ record }">
                <div>{{ record.projectAppointHead }}</div>
                <div>{{ record.projectAppointHeadPhone }}</div>
            </template>
            <template slot="projectConfirmHead" slot-scope="{ record }">
                <div>{{ record.projectConfirmHead }}</div>
                <div>{{ record.projectConfirmHeadPhone }}</div>
            </template>
            <template slot="companyAppointHead" slot-scope="{ record }">
                <div>{{ record.companyAppointHead }}</div>
                <div>{{ record.companyAppointHeadPhone }}</div>
            </template>
            <template slot="companyConfirmHead" slot-scope="{ record }">
                <div>{{ record.companyConfirmHead }}</div>
                <div>{{ record.companyConfirmHeadPhone }}</div>
            </template>

            <template slot="action" slot-scope="{ record }">
                <div class="sTables">
                    <!-- <div class="y-edit" @click="handleEdit(record)">编辑</div> -->
                    <div v-if="isProjectLeader" class="y-edit" @click="handleOpenPhone(record,1)">填写项目指定责任人</div>
                    <div v-if="isSafe" class="y-edit" @click="handleOpenPhone(record,2)">填写公司指定责任人</div>
                    <div v-if="isProject" class="y-del" @click="handleDel(record)">删除</div>
                </div>
            </template>
        </standard-table>
        <detail :visible.sync="visible" :isEdit="isEdit" :projectInfo="projectInfo" :id="id" @closeDialog="closeDialog" @refresh="handleQuery" />

        <!-- 弹框提示是否驳回及意件
         点击确认会有两个选项：
1.将双表递交给分公司做确认。
2.选择驳回，驳回后将给退给安全员填写。 -->
        <a-modal v-model="visibleReject" title="提交" @ok="handleReject" @cancel="handleCancel">
            <a-form-model :model="rejectForm" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
                <!-- 两个 radio -->
                <a-form-model-item label="选择" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
                    <a-radio-group v-model="rejectForm.rejectType">
                        <a-radio value="1">提交</a-radio>
                        <a-radio value="2" v-if="!isProjectSafer">驳回</a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item v-if="!isProjectSafer" label="驳回意见" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
                    <a-textarea v-model="rejectForm.opinions" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>

        <a-modal v-model="visiblePhone" title="填写责任人" @ok="handlePhoneOk" @cancel="handlePhoneCancel">
            <a-form-model ref="phoneForm" :model="phoneForm" :rules="rulePhone" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
                <!-- 两个 radio -->
                <a-form-model-item :label="phoneType==1 ?'项目指定责任人': '公司指定责任人'"  prop="name">
                    <a-input v-model="phoneForm.name" />
                </a-form-model-item>
                <a-form-model-item v-if="!isProjectSafer" label="电话"  prop="phone">
                    <a-input v-model="phoneForm.phone" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>

import {
    riskcontroldetailList, riskcontroldetailDelete, riskcontroldetailExport,riskcontrolSubmit,riskcontrolByID,riskcontroldetailUpdate
} from "@/services/riskControll";

import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
import moment from "moment";
import detail from "./components/clDetail.vue";
import { getProjectDetail } from "@/services/equipmentLedger";

import mixin from './components/mixinCl'
import mixinRole from './components/mixinRole'

export default {
    name: "emerTeam",
    components: {
        StandardTable,
        detail
    },
    mixins: [mixin,mixinRole],
    data() {
        return {
            params: {
                "companyName": "", //公司名称
                "projectName": "", //项目名称
                "reportTime": "", //填报时间
                "riskRankType": "", //风险等级ID
                "riskRank": "", //风险等级
                "pageNo": "1", //页码
                "pageSize": "10", //每页条数
                "parentId": "", //风险管控ID
                "companyId": "", //公司ID
                "branchOfficeId": "", //分公司ID
                "projectId": "", //项目ID
                reserved1: '', //哪个角色查询 （1 安全员  2 项目经理  3 分公司  4公司 5集团)
            },
            pagination: {
                //分页数据
                current: 1,
                pageSize: 10,
                total: 0,
                showTotal: (total, range) => `共 ${total} 条`,
            },
            total: 0,
            tableData: [],
            tableLoading: false, //table加载中
            selectedRows: [],
            tmpSelectedRows: [],

            visible: false,
            isEdit: 0,
            id: "",
            projectInfo: {
                parentOffice: '',
                parentOfficeId: '',
                gcglbId: '',
                gcglbName: '',
                projectId: '',
                projectName: '',
                reportTime: ''
            },
            visibleReject: false,
            rejectForm: {
                rejectType: "1",
                opinions: ""
            },
            plan: {
                "companyStatus": 0, //公司状态(0 初始状态 1提交 2驳回)
                "branchOfficeStatus": 0, //分公司状态(0 初始状态 1提交 2驳回)
                "projectManagerStatus": 0, //项目经理状态(0 初始状态 1提交 2驳回)
                "safetyOfficerStatus": 0, //安全员状态(0 初始状态 1提交)
                reserved3: '',//当前步骤人
            },
            role: '',
            currentStatus: 0,
            currentStep: 0,
            stepList: [
                {name: '安全员', roleID:"1",key: 'safetyOfficerStatus',status: 0, des: 'test'},
                {name: '项目经理', roleID:"2",key: 'projectManagerStatus',status: 0, des: ''},
                {name: '分公司', roleID:"3",key: 'branchOfficeStatus',status: 0, des: ''},
                {name: '公司', roleID:"4",key: 'companyStatus',status: 0, des: ''},
            ],

            phoneType: null,
            phoneRecord: null,
            visiblePhone: false,
            phoneForm: {
                phone: '',
                name: ''
            },
            rulePhone: {
                phone: [
                    { required: true, message: '请输入电话', trigger: 'blur' },
                    { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的电话', trigger: 'blur' }
                ],
                name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                ]
            }
        };
    },
    activated() {
        this.params.parentId = this.$route.query.id || ''
        this.params.reportTime = this.$route.query.date
        this.projectInfo.reportTime = this.$route.query.date
        this.role = this.calcRole()
        this.params.reserved1 = this.role
        if(this.isProject) {
            let d = this.user.loginLaoWuUser.projectId
            getProjectDetail(d).then(res => {

                if (res.data.code === 0) {
                    
                    this.projectInfo.parentOfficeId = res.data.data.parentOfficeId;
                    this.projectInfo.parentOffice = res.data.data.parentOffice;
                    this.projectInfo.gcglbId = res.data.data.gcglbId;
                    this.projectInfo.gcglbName = res.data.data.gcglbName;
                    this.projectInfo.projectId = res.data.data.projectId;
                    this.projectInfo.projectName = res.data.data.projectName;
                    
                    this.params.companyName = this.projectInfo.parentOffice;
                    this.params.projectName = this.projectInfo.projectName;
                    this.params.branchOfficeName = this.projectInfo.branchOfficeName;
                    this.params.companyId = this.projectInfo.parentOfficeId;
                    this.params.branchOfficeId = this.projectInfo.gcglbId;
                    this.params.projectId = this.projectInfo.projectId;
                } 

                this.handleQuery();
            })
        } else {
            // this.columns = this.columns.filter(el => el.title != '操作')
            this.handleQuery();
        }

    //    this.getPlanInfo()
    },
    created() {
        if (!this.isSafe) {
            // this.columns = this.columns.filter(el => el.title != '操作')
        }
        // this.params.parentId = this.$route.query.id
        // this.handleQuery();
    },
    computed: {
        tableBtnArr() {
            return [
            ]
        }
    },
    methods: {
        getPlanInfo() {
            riskcontrolByID({ id: this.$route.query.id }).then(res => {
                if (res.data.code == 0) {
                    this.plan = res.data.data
                    let status = null
                    switch (this.role) {
                        case '1':
                            status = this.plan.safetyOfficerStatus
                            break;
                        case '2':
                            status = this.plan.projectManagerStatus
                            break;
                        case '3':
                            status = this.plan.branchOfficeStatus
                            break;
                        case '4':
                            status = this.plan.companyStatus
                            break;
                    
                        default:
                            break;
                    }
                    this.currentStatus = status
                    let s = parseInt(this.plan.reserved3)
                    this.currentStep = s - 1

                    this.stepList.forEach((el, index) => {
                        el.status = this.plan[el.key]
                    })

                }
            })
        },
        handleSubmit(e) {
            e.preventDefault();
            this.params.pageNo = 1
            this.pagination.current = 1
            this.handleQuery();
        },
        handleQuery(e) {
            this.tableData = []
            this.tableLoading = true
            let d = this.params;
            d.reportTime = d.reportTime ? moment(d.reportTime).format('YYYY-MM') : ''
            riskcontroldetailList(d).then((res) => {
                this.tableLoading = false
                if (res.data.code == 0) {
                    let list = res.data.data.list;

                    this.tableData = list.map((item, index) => {
                        
                        return {
                            ...item,
                        };
                    })
                    this.pagination.total = res.data.data.total;
                } else {
                    this.$message.error(res.data.msg)
                }
            });
        },
        handleChangePage(e) {
            this.params.pageNo = e.current;
            this.params.pageSize = e.pageSize;
            this.pagination.current = e.current;
            this.handleQuery();
        },
        addNewComplaint() {
            this.isEdit = 0
            this.id = ''
            this.visible = true
        },
        handleEdit(e) {

            this.isEdit = 1
            this.id = e.id
            this.visible = true
        },
        handleDel(record) {
            const _that = this
            this.$confirm({
                title: '是否确定删除？',
                cancelText: '否',
                okText: '是',
                onOk: () => {
                    riskcontroldetailDelete({ id: record.id }).then(res => {
                        if (res.data.code == 0) {
                            _that.$message.success('删除成功')
                            this.handleQuery(this.params);
                        } else {
                            _that.$message.error(res.data.msg)
                        }
                    })
                },
            })
        },
        resetQuery() {
            this.pagination.current = 1
            this.params = {
                "companyName": "", //公司名称
                "projectName": "", //项目名称
                "reportTime": "", //填报时间
                "riskRankType": "", //风险等级ID
                "riskRank": "", //风险等级
                "pageNo": "1", //页码
                "pageSize": "10", //每页条数
                "parentId": "", //风险管控ID
                "companyId": "", //公司ID
                "branchOfficeId": "", //分公司ID
                "projectId": "", //项目ID
                reserved1: '', //哪个角色查询 （1 安全员  2 项目经理  3 分公司  4公司 5集团)
            };
            this.params.parentId = this.$route.query.id
            this.params.reportTime = this.$route.query.date
            this.params.reserved1 = this.role
            if(this.isProject) {
                this.params.companyName = this.projectInfo.parentOffice;
                this.params.projectName = this.projectInfo.projectName;
                this.params.branchOfficeName = this.projectInfo.branchOfficeName;
                this.params.companyId = this.projectInfo.parentOfficeId;
                this.params.branchOfficeId = this.projectInfo.gcglbId;
                this.params.projectId = this.projectInfo.projectId;
            } else {
                // 公司  分公司
            }
            this.handleQuery();
        },
        bathDownload() {
            // 取查询参数导出 companyId=&branchOfficeId=&projectId=&sgfaName=&overRange=&proveTime=&aproveTime=&makeTime=
            let url = riskcontroldetailExport + '?'+ `companyId=${this.params.companyId}&branchOfficeId=${this.params.branchOfficeId}&projectId=${this.params.projectId}&sgfaName=${this.params.sgfaName}&overRange=${this.params.overRange}&proveTime=${this.params.proveTime}&aproveTime=${this.params.aproveTime}&makeTime=${this.params.makeTime}`
            window.open(url)
        },
        closeDialog() {
            this.visible = false
        },
        handleExport(){
            // /riskcontroldetail/export-excel?companyName=&projectName=&reportTime=&riskRankType=&riskRank=&parentId=
            let url = riskcontroldetailExport + '?'+ `companyName=${this.params.companyName}&projectName=${this.params.projectName}&reportTime=${this.params.reportTime}&riskRankType=${this.params.riskRankType}&riskRank=${this.params.riskRank}&parentId=${this.params.parentId}`
            window.open(url)
        },
        handleOpenReject() {
            this.visibleReject = true
        },
        handleReject() {
            // /riskcontroldetail/reject
            let d = {
                id: this.params.parentId,
                status: this.rejectForm.rejectType,
                opinions: this.rejectForm.opinions,
                role: this.calcRole()
            }
            
            riskcontrolSubmit(d).then(res => {
                if (res.data.code == 0) {
                    this.$message.success('操作成功')
                    this.visibleReject = false
                    this.getPlanInfo()
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        handleCancel() {
            this.visibleReject = false
        },
        handleOpenPhone(record, type) {
            this.phoneForm = {
                phone: '',
                name: ''
            }
            this.phoneType = type
            this.phoneRecord = record
            this.phoneForm.name = type == 1 ? record.projectAppointHead : record.companyAppointHead
            this.phoneForm.phone = type == 1 ? record.projectAppointHeadPhone : record.companyAppointHeadPhone
            this.visiblePhone = true
        },
        handlePhoneOk() {
            this.$refs.phoneForm.validate((valid) => {
                if (valid) {
                    let d = JSON.parse(JSON.stringify(this.phoneRecord))
                    if (this.phoneType == 1) {
                        d.projectAppointHead = this.phoneForm.name
                        d.projectAppointHeadPhone = this.phoneForm.phone
                    } else {
                        d.companyAppointHead = this.phoneForm.name
                        d.companyAppointHeadPhone = this.phoneForm.phone
                    }
                    riskcontroldetailUpdate(d).then(res => {
                        if (res.data.code == 0) {
                            this.$message.success('操作成功')
                            this.visiblePhone = false
                            this.handleQuery()
                        } else {
                            this.$message.error(res.data.msg)
                        }
                    })
                }
            })
        },
        handlePhoneCancel() {
            this.visiblePhone = false
        }

        

    },
};
</script>

<style lang="less" scoped>
.query-form {
    display: flex;
    // justify-content: space-between;
}

.post-border {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 5px;
    padding-top: 5px;
    // padding-top: 10px;
    // height: 40px;
}

.post-border:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.sTables {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.y-edit {
    color: #307dfa;
    cursor: pointer;
}
.y-del {
    color: #ff5c77;
    cursor: pointer;
}
.y-other {
    color: #615e83;
    cursor: pointer;
}

.y-rc-main {
    .rc-title {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        
    }
    .rc-st-title {
        font-size: 18px;
        text-align: center;
        color: #27db45;
    }
}
</style>