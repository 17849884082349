var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c("div", { staticClass: "plug-Overview" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$route.path.includes("un_project_list")
                ? "未通过创优台账"
                : "在建项目安全创优情况表"
            ) +
            " "
        ),
      ]),
      _c("a-divider"),
      _c(
        "a-form-model",
        { staticClass: "clearfix", attrs: { model: _vm.params } },
        [
          _vm.user.nowRoleKey.includes("OFFICE_AQMGLY")
            ? _c(
                "a-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "分公司",
                        labelCol: { span: 8 },
                        wrapperCol: { span: 14, offset: 1 },
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: { allowClear: "", placeholder: "" },
                          model: {
                            value: _vm.params.gcglbId,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "gcglbId", $$v)
                            },
                            expression: "params.gcglbId",
                          },
                        },
                        _vm._l(_vm.gcglbList, function (item) {
                          return _c(
                            "a-select-option",
                            {
                              key: item.value,
                              attrs: { value: item.value, title: item.name },
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.user.nowRoleKey.includes("OFFICE_AQMGLY")
            ? _c(
                "a-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "月份检评",
                        labelCol: { span: 8 },
                        wrapperCol: { span: 14, offset: 1 },
                      },
                    },
                    [
                      _c("a-month-picker", {
                        attrs: { valueFormat: "YYYY-MM", placeholder: "" },
                        model: {
                          value: _vm.params.checkTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "checkTime", $$v)
                          },
                          expression: "params.checkTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-col",
            { attrs: { span: 5 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "创优要求",
                    labelCol: { span: 8 },
                    wrapperCol: { span: 14, offset: 1 },
                  },
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { allowClear: "", placeholder: "" },
                      model: {
                        value: _vm.params.cyyq,
                        callback: function ($$v) {
                          _vm.$set(_vm.params, "cyyq", $$v)
                        },
                        expression: "params.cyyq",
                      },
                    },
                    [
                      _c("a-select-option", { attrs: { value: "1" } }, [
                        _vm._v(" 合同要求 "),
                      ]),
                      _c("a-select-option", { attrs: { value: "2" } }, [
                        _vm._v(" 滚动计划 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 5 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "检评状态",
                    labelCol: { span: 8 },
                    wrapperCol: { span: 14, offset: 1 },
                  },
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { allowClear: "", placeholder: "" },
                      model: {
                        value: _vm.params.checkFinishType,
                        callback: function ($$v) {
                          _vm.$set(_vm.params, "checkFinishType", $$v)
                        },
                        expression: "params.checkFinishType",
                      },
                    },
                    [
                      _c("a-select-option", { attrs: { value: "" } }, [
                        _vm._v(" 全部 "),
                      ]),
                      _c("a-select-option", { attrs: { value: "1" } }, [
                        _vm._v(" 已完成 "),
                      ]),
                      _c("a-select-option", { attrs: { value: "2" } }, [
                        _vm._v(" 未完成 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 5 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "工程类别",
                    labelCol: { span: 8 },
                    wrapperCol: { span: 14, offset: 1 },
                  },
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { allowClear: "", placeholder: "" },
                      model: {
                        value: _vm.params.ptype,
                        callback: function ($$v) {
                          _vm.$set(_vm.params, "ptype", $$v)
                        },
                        expression: "params.ptype",
                      },
                    },
                    [
                      _c("a-select-option", { attrs: { value: "" } }, [
                        _vm._v(" 全部 "),
                      ]),
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v(" 房建 "),
                      ]),
                      _c("a-select-option", { attrs: { value: 2 } }, [
                        _vm._v(" 市政 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 5 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "是否发给协会",
                    labelCol: { span: 9 },
                    wrapperCol: { span: 14, offset: 1 },
                  },
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { allowClear: "", placeholder: "" },
                      model: {
                        value: _vm.params.isSend,
                        callback: function ($$v) {
                          _vm.$set(_vm.params, "isSend", $$v)
                        },
                        expression: "params.isSend",
                      },
                    },
                    [
                      _c("a-select-option", { attrs: { value: "" } }, [
                        _vm._v(" 全部 "),
                      ]),
                      _c("a-select-option", { attrs: { value: "1" } }, [
                        _vm._v(" 是 "),
                      ]),
                      _c("a-select-option", { attrs: { value: "0" } }, [
                        _vm._v(" 否 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          this.params.cylevel == 2 || this.params.cylevel == 3
            ? _c(
                "a-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "立项情况",
                        labelCol: { span: 8 },
                        wrapperCol: { span: 14, offset: 1 },
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: { allowClear: "", placeholder: "" },
                          model: {
                            value: _vm.params.projectStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "projectStatus", $$v)
                            },
                            expression: "params.projectStatus",
                          },
                        },
                        [
                          _c("a-select-option", { attrs: { value: "" } }, [
                            _vm._v(" 全部 "),
                          ]),
                          _c(
                            "a-select-option",
                            { attrs: { value: "已立项" } },
                            [_vm._v(" 已立项 ")]
                          ),
                          _c(
                            "a-select-option",
                            { attrs: { value: "未立项" } },
                            [_vm._v(" 未立项 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-col",
            { attrs: { span: 4 } },
            [
              _c(
                "a-space",
                { staticClass: "btnBox" },
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleQuery },
                    },
                    [_vm._v(" 查询 ")]
                  ),
                  _c("a-button", { on: { click: _vm.resetQuery } }, [
                    _vm._v(" 重置 "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "space-between",
            "align-items": "center",
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "a-space",
                { attrs: { size: "middle" } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.doDowload },
                    },
                    [_vm._v(" 导出 ")]
                  ),
                  _c("div", [
                    _c(
                      "div",
                      [
                        _c("a-space", { attrs: { size: "middle" } }, [
                          _c("span", [
                            _c("span", { staticClass: "font-bold" }, [
                              _vm._v("省市政协会："),
                            ]),
                            _vm._v("2172345172@qq.com"),
                          ]),
                          _c("span", [
                            _c("span", { staticClass: "font-bold" }, [
                              _vm._v("省安全协会："),
                            ]),
                            _vm._v("cisagd@163.com"),
                          ]),
                          _c("span", [
                            _c("span", { staticClass: "font-bold" }, [
                              _vm._v("市市政协会："),
                            ]),
                            _vm._v("gzsszglxh@163.com"),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("a-space", { attrs: { size: "middle" } }, [
                          _c("span", [
                            _c(
                              "a",
                              {
                                staticStyle: {
                                  "border-bottom": "1px solid #76abf5",
                                },
                                attrs: {
                                  target: "_blank",
                                  href: "http://sfgd.cisagd.cn/login.aspx",
                                },
                              },
                              [_vm._v("房建项目省双优报名网址")]
                            ),
                          ]),
                          _c("span", [
                            _c(
                              "a",
                              {
                                staticStyle: {
                                  "border-bottom": "1px solid #76abf5",
                                },
                                attrs: {
                                  target: "_blank",
                                  href: "http://www.gcia.org.cn:8080/User/index.aspx",
                                },
                              },
                              [_vm._v("房建项目市双优报名网址")]
                            ),
                          ]),
                          _c("span", [
                            _c(
                              "a",
                              {
                                staticStyle: {
                                  "border-bottom": "1px solid #76abf5",
                                },
                                attrs: {
                                  target: "_blank",
                                  href: "http://119.29.187.237:12111/account/login",
                                },
                              },
                              [_vm._v("市政项目市双优报名网址")]
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-radio-group",
            {
              on: { change: _vm.handleChangeType },
              model: {
                value: _vm.params.cylevel,
                callback: function ($$v) {
                  _vm.$set(_vm.params, "cylevel", $$v)
                },
                expression: "params.cylevel",
              },
            },
            [
              _c("a-radio-button", { attrs: { value: "1" } }, [
                _vm._v(" 市双优 "),
              ]),
              _c("a-radio-button", { attrs: { value: "2" } }, [
                _vm._v(" 省双优 "),
              ]),
              _c("a-radio-button", { attrs: { value: "3" } }, [
                _vm._v(" 国家级创优 "),
              ]),
              _c("a-radio-button", { attrs: { value: "4" } }, [
                _vm._v(" 其它 "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("a-table", {
        key: _vm.params.cylevel,
        staticClass: "table",
        attrs: {
          columns: _vm.columns,
          "data-source": _vm.tableData,
          bordered: "",
          size: "default",
          pagination: {
            defaultCurrent: _vm.params.pageNumber,
            total: _vm.total,
            pageSize: _vm.params.pageSize,
            showTotal: function (total, range) {
              return "共 " + total + " 条"
            },
          },
        },
        on: { change: _vm.handleChangePage },
        scopedSlots: _vm._u([
          {
            key: "projectName",
            fn: function (text) {
              return [
                _c(
                  "a-tooltip",
                  { attrs: { placement: "topLeft" } },
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v(" " + _vm._s(text) + " "),
                    ]),
                    _c("div", { staticClass: "overtext-1" }, [
                      _vm._v(_vm._s(text)),
                    ]),
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "projectFile",
            fn: function (text, row) {
              return [
                _c(
                  "div",
                  { staticClass: "flex j-center" },
                  [
                    row.projectFile
                      ? _c("a-icon", {
                          style: { fontSize: "32px" },
                          attrs: { type: "file-pdf" },
                          on: {
                            click: function ($event) {
                              return _vm.handleLXPreview(row.projectFile)
                            },
                          },
                        })
                      : row.projectStatus == "/"
                      ? _c("span", [_vm._v("/")])
                      : _c("span"),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "isSend",
            fn: function (text) {
              return [
                text == "1"
                  ? _c(
                      "label",
                      [
                        _c("a-icon", {
                          staticStyle: { "font-size": "15px", color: "green" },
                          attrs: { type: "check" },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "pjsPath",
            fn: function (text, row) {
              return [
                _c(
                  "div",
                  { staticClass: "flex j-center" },
                  [
                    row.pjsPath && row.pjsPath.length > 0
                      ? _vm._l(row.pjsPath.split(","), function (path, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              path && path.indexOf(".pdf") > -1
                                ? _c("a-icon", {
                                    style: { fontSize: "32px" },
                                    attrs: { type: "file-pdf" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlePreview(path)
                                      },
                                    },
                                  })
                                : _c("img", {
                                    staticStyle: { width: "32px" },
                                    attrs: { src: path },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlePreview(path)
                                      },
                                    },
                                  }),
                            ],
                            1
                          )
                        })
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "action",
            fn: function (text, record) {
              return [
                _c(
                  "a",
                  {
                    on: {
                      click: function () {
                        return _vm.handleEdit(record)
                      },
                    },
                  },
                  [
                    _vm.isOrg
                      ? _c("span", [_vm._v("查看")])
                      : _c("span", [_vm._v("编辑")]),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.previewVisible, footer: null },
          on: { cancel: _vm.handleCancel },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { alt: "", src: _vm.previewImage },
          }),
        ]
      ),
      _c("Export-loading", {
        attrs: {
          cPercent: _vm.cPercent,
          isExporting: _vm.isExporting,
          loadingText: _vm.loadingText,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }