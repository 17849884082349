<template>
	<div>
		<div class="a_Approval" v-show="$route.meta.showFather">
			<form-index
				ref="formindex"
				:formData="formData"
				:flexSubmit="true"
				@handleSubmit="handleSubmit"
				@handlSelect="handlSelect"
				@handleChange="handleChange"
				Overview="省双优检评时间安排"
			>
				<!-- 按钮插槽 -->
				<template #flexSubmit>
					<a-space class="btnBox pl-4 j-start">
						<a-button type="primary" html-type="submit">查询</a-button>
						<a-button @click="close">重置</a-button>
					</a-space>
				</template>
			</form-index>
			<div class="a_a_footer">
				<div class="mb-1">
						<a-space class="operator">
								<a-button @click="addNewRecord" type="primary" v-if="user.nowRoleKey.includes('OFFICE_AQMGLY')">新增</a-button>
						</a-space>
				</div>
				<!-- 表格封装 -->
				<standard-table
					class=""
					:columns="columns"
					:dataSource="dataSource"
					rowKey="id"
					:pagination="pagination"
					:loading="tableLoading"
					@change="onPageChange"
					:isAlert="false"
					:bordered="true"
				>
					<template slot="index" slot-scope="{ index }">
						<span>
							{{
								(pagination.current - 1) * pagination.pageSize +
								parseInt(index) +
								1
							}}
						</span>
					</template>
					<template slot="inspectionDate" slot-scope="{ record }">
						<div>{{ moment(record.checkDate).format('YYYY-MM-DD') }}</div>
						<div>{{ `${record.week}` }}</div>
					</template>
					<template slot="checkDateTime" slot-scope="{ text }">
						<span>{{ getCheckDateTime(text) }}</span>
					</template>
					<template slot="checkType" slot-scope="{ text }">
						<span>{{ text == 0 ? '初评' : '复评' }}</span>
					</template>
					<template slot="expertInfo" slot-scope="{ record }" v-if="record.captainVo || record.teamVos">
						<div>
							<div style="font-weight: bold;">组长：</div>
							<div>
								<span v-if="record.captainVo.name">{{ record.captainVo.name }}</span>
								<span v-if="record.captainVo.phone">{{ `/${record.captainVo.phone}` }}</span>
								<span v-if="record.captainVo.workUnit">{{ `/${record.captainVo.workUnit}` }}</span>
								<span v-if="record.captainVo.post">{{ `/${record.captainVo.post}` }}</span>
							</div>
						</div>
						<div style="white-space: pre-wrap;">
							<div style="font-weight: bold;">组员：</div>
							<div v-for="(item, index) in record.teamVos" :key="index">
								<span v-if="item.name">{{ item.name }}</span>
								<span v-if="item.phone">{{ `/${item.phone}` }}</span>
								<span v-if="item.workUnit">{{ `/${item.workUnit}` }}</span>
								<span v-if="item.post">{{ `/${item.post}` }}</span>
							</div>
						</div>
					</template>
					<template slot="action" slot-scope="{ record }" v-if="user.nowRoleKey.includes('OFFICE_AQMGLY')">
						<div class="sTables">
							<div @click="visit(record)">编辑</div>
							<div @click="del(record)">删除</div>
						</div>
					</template>
				</standard-table>
				<Export-loading
					:cPercent="cPercent"
					:isExporting="isExporting"
					:loadingText="loadingText"
				></Export-loading>
			</div>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import { request } from "@/utils/request";
import { mapGetters } from 'vuex'
import { getCivilizedExcellentList, delCivilizedExcellent } from "@/services/civilizedExcellent";
import { getProjectByOffice } from "@/services/statistics";
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import { QUERY_OFFICE_BY_USER } from "@/services/api";
import moment from "moment";
// import { SUB_POINT_EXPORT } from "@/services/api";
const formData = [
  {
    label: '分公司',
    placeholder: '请选择分公司',
    decorator: ['gcglbId', { rules: [{ required: false, message: '请选择分公司' }] }],
    type: 'select',
    key: 'gcglbId',
    selectlist: [],
    col: 5,
		labelCol: 8,
    wrapperCol: 14
  },
	{
    label: "项目名称",
    placeholder: "请输入项目名称",
    decorator: [
      "projectId",
      { rules: [{ required: false, message: "请输入项目名称" }] },
    ],
    type: "inputGroup",
    key: "projectId",
    dataSource: [],
    col: 5,
		labelCol: 8,
    wrapperCol: 14
  },
	{
		label: "选择日期",
		placeholder: "请选择日期",
		decorator: [
			"checkDate",
			{
				initialValue: [moment().startOf('year').format('YYYY-MM-DD'), moment().endOf('year').format('YYYY-MM-DD')],
				rules: [{ required: false, message: "请选择" }],
			},
		],
		type: "rangePicker",
		key: "checkDate",
    col: 5,
		labelCol: 8,
    wrapperCol: 16,
		display: true,
		format: "yyyy-MM-DD",
		styles: {width: '100%'}
	},
	{
		label: "检评性质",
		placeholder: "请选择检评性质",
		decorator: [
			"checkType",
			{ rules: [{ required: false, message: "请选择检评性质" }] },
		],
		type: "select",
		key: "checkType",
		selectlist: [{
			value: 0,
			name: '初评'
		},{
			value: 1,
			name: '复评'
		}],
    col: 5,
		labelCol: 8,
    wrapperCol: 14,
	},
];
const columns = [
	{
		title: "序号",
		width: 65,
		scopedSlots: { customRender: "index" },
		// customRender: (text, record, index) => `${index + 1}`,
		align: "center",
	},
	{
    title: "公司",
    align: "center",
    dataIndex: "parentOffice",
    width: 120,
  },
  {
    title: "分公司",
    align: "center",
    dataIndex: "gcglbName",
    width: 120,
  },
	{
		title: "项目名称",
		dataIndex: "projectName",
		width: 245,
		ellipsis: true,
		tooltip: true
	},
	{
		title: "检查日期",
		dataIndex: "inspectionDate",
		align: "center",
		width: 120,
		scopedSlots: { customRender: "inspectionDate" },
	},
	{
		title: "检查时间",
		dataIndex: "checkDateTime",
		width: 110,
		align: "center",
		// ellipsis: true,
		scopedSlots: { customRender: "checkDateTime" },
	},
	{
		title: "检评性质",
		dataIndex: "checkType",
		align: "center",
		width: 65,
		scopedSlots: { customRender: "checkType" },
	},
	{
		title: "参评序号",
		dataIndex: "checkNum",
		width: 110,
		align: "center",
		key: "checkNum",
		scopedSlots: { customRender: "checkNum" },
	},
	{
		title: "带队人员",
		dataIndex: "leaders",
		width: 150,
		align: "center",
		key: "leaders",
		scopedSlots: { customRender: "leaders" },
	},
	{
		title: "专家信息",
		dataIndex: "expertInfo",
		scopedSlots: { customRender: "expertInfo" }
	},
	{
		title: "注意事项",
		dataIndex: "remarks",
		align: "center",
		width: 120,
		tooltip: true,
		ellipsis: true
	},
	{
		title: "操作",
		align: "center",
		width: 130,
		scopedSlots: { customRender: "action" },
	},
];
export default {
	mixins: [exportProgress], //导出方法注入
	components: {
		formIndex,
		StandardTable,
		ExportLoading, //导出数据加载封装
	},
	data() {
		return {
			formData: formData,
			columns: columns,
			dataSource: [], //table数组
			pagination: {
				//分页数据
				current: 1,
				pageSize: 10,
				total: 0,
				showTotal: (total, range) => `共 ${total} 条`,
			},
			params: {
				pageNumber: 1,
				pageSize: 10,
				startTime: moment().startOf('year').format('YYYY-MM-DD'),
				endTime: moment().endOf('year').format('YYYY-MM-DD'),
				checkType: ''
				// year: moment(new Date()).format('YYYY')
			},
			tableLoading: false, //table加载中
			loadingText: "数据加载中...",
			cPercent: 0,
			isExporting: false,
		};
	},
	computed: {
		...mapGetters('account', ['user']),
	},
	activated() {
		this.init()
	},
	methods: {
		moment,
		init() {
			this.getgcglbList(QUERY_OFFICE_BY_USER);
			//获取项目名称
			this.getProjectStatissKqS('')
			this.aqxjlist(this.params);
		},
		getProjectStatissKqS(projectName) {
      getProjectByOffice({gcglbId: this.params.gcglbId, projectName}).then((res) => {
        if (res.data.code === 0) {
          this.formData.forEach((item) => {
            if ('projectId' === item.key) {
              let arr = []
              res.data.data.forEach(el => {
                arr.push({
                  title: el.projectName,
                  id: el.projectId,
                  gcglbId: el.gcglbId,
                  ptype: el.ptype
                })
              })
              item.dataSource = arr
              if (!projectName) {
                this.allProjectArr = arr
              }
            }
          });
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
		// 获取分公司数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(
          (item) => item.id != "3da0f5533f87425c9a9e8a11a7923300"
        );
        this.formDataFn("gcglbId", data);
      });
    },
		// 对下拉框做数据处理
    formDataFn(key, data) {
      let arr = [];
      data.forEach((item) => {
        arr.push({ name: item.master, value: item.id });
      });
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = arr;
        }
      });
    },
			/**
		 * 导出excel
		 */
		// 	doDowload() {
		// 	let obj = this.$refs["formindex"].getFieldValue();
		// 	this.loadingText = "正在导出EXCEL文件，请稍候 ····";
		// 	this.isExporting = true;
		// 	this.cPercent = 0;
		// 	let fileName = "动态扣分台账.xlsx";
		// 	this.exportFunNomal2(
		// 		SUB_POINT_EXPORT,
		// 		obj,
		// 		fileName,
		// 		"application/vnd.ms-excel"
		// 	);
		// },
		aqxjlist(params) {
			this.tableLoading = true;
			getCivilizedExcellentList(params).then((res) => {
				if (res.data.code === 0) {
					this.dataSource = res.data.data.list;
					this.pagination.total = res.data.data.total;
				} else {
					this.$message.error(res.data.msg)
				}
				this.tableLoading = false;
			});
		},
		addNewRecord() {
			this.$router.push({
				name: '新增省双优检评时间安排',
				query: {
					isEdit: 0,
				},
			});
		},
		handleSubmit(e) {
			let obj = {
				startTime: e.checkDate[0],
				endTime: e.checkDate[1],
				checkType: e.checkType,
				projectName: e.projectName,
				gcglbId: e.gcglbId
			}
			this.aqxjlist(Object.assign(this.params, obj));
		},
		handlSelect(obj) {
      const { val, keys } = obj
      console.log(val, keys)
      if (keys === 'gcglbId') {
        this.params.gcglbId = val
        this.getProjectStatissKqS('')
      } else if (keys === 'projectId') {
        this.params.projectId = val
      }
    },
		// 重置
    close() {
      this.params = {
        pageNumber: 1,
				pageSize: 10,
				startTime: moment().startOf('year').format('YYYY-MM-DD'),
				endTime: moment().endOf('year').format('YYYY-MM-DD'),
				checkType: ''
      };
      this.pagination.current = 1;
      this.$refs["formindex"].resetFields();
			this.aqxjlist(this.params);
    },
		// 输入选择框触发
    handleChange(e) {
			//获取项目名称
			this.getProjectStatissKqS(e.value)
    },
		//表格 - 分页查询
		onPageChange(e) {
			this.params.pageNumber = e.current;
			this.pagination.current = e.current;
			this.aqxjlist(Object.assign({}, this.params));
		},
		//查看
		visit(record) {
			this.$router.push({
				name: '省双优检评时间安排详情',
				query: {
					id: record.id,
					isEdit: 1
				},
			});
		},
		del(record) {
			const _that = this
			this.$confirm({
				title: '是否确定删除？',
				cancelText: '否',
				okText: '是',
				onOk: () => {
					delCivilizedExcellent(record.id).then(res => {
						if (res.data.code === 0) {
							_that.$message.success('删除成功')
							_that.aqxjlist(this.params);
						} else {
							_that.$message.error(res.data.msg)
						}
					})
				}
			})
		},
		getCheckDateTime(text) {
			switch(text) {
				case 0: 
					return '上午第一站'
				case 1: 
					return '上午第二站'
				case 2: 
					return '下午第一站'
				case 3: 
					return '下午第二站'
				case 4: 
					return '下午第三站'
			}
		},
	},
};
</script>

<style lang="less" scoped>
.a_a_footer {
	padding: 20px;
	background: #ffffff;

	.operator {
		margin-right: 8px;
	}

	.sTables {
		display: flex;
		justify-content: space-between;
		align-items: center;

		div {
			width: 100%;
			text-align: center;
			cursor: pointer;
		}

		div:nth-child(1) {
			color: #307dfa;
		}

		div:nth-child(2) {
			color: #ff5c77;
		}

		div:nth-child(3) {
			color: #615e83;
		}
	}
}
.content_div {
	padding: 5px 0;
	cursor: pointer;
}
.content_div:hover {
	color: #307dfa;
}
</style>
