<template>
    <div class="container">
        <div id="components-form-demo-advanced-search">
            <a-form-model
                    :model="queryData"
                    layout="inline"
                    class="ant-advanced-search-form"
            >
                <a-form-model-item ref="month" label="制表月份" prop="month">
                    <a-month-picker v-model="queryData.month" placeholder="请选择制表月份"
                                    @change="queryDataMonthChange"  style="width: 100%;" format="yyyyMM"
                    />
                </a-form-model-item>
<!--                    <a-form-model-item ref="projectName" label="项目名称" prop="projectName">-->
<!--                        <a-input v-model="queryData.projectName" />-->
<!--                    </a-form-model-item>-->
                <a-button type="primary" icon="search" style="margin-top:4px"  :loading="loading" @click="getData(true)">
                    查询
                </a-button>
            </a-form-model>

        </div>
        <a-table
            :row-selection="{ selectedRowKeys: selectedSummaryIdList, onChange: selectSummaryChange }"
            row-key="id"
            :columns="columns"
            :data-source="data"
            :pagination="pagination"
            @change="summaryTableChange"
            :bordered="true"
            :loading="loading">
            <template slot="action" slot-scope="text, record">
                <span>
                    <a @click="showDetailed(record)">查看项目详细</a>
                    <a-divider type="vertical" />
                    <a @click="summaryExport(record)">下载汇总工资单</a>
                    <a-divider type="vertical" />
                    <a @click="taxRecord(record)">分税记录</a>
                </span>
            </template>

        </a-table>

        <a-modal
            title="工资单记录"
            :visible="projectDetailedModelVisible"
            width="60%"
            :footer="null"
            @cancel="projectDetailedModelCancel"
            :keyboard="true"
            :maskClosable="true"
        >
            <a-form-model
                :model="projectDetailedQueryData"
                layout="inline"
                :label-col="{span: 6}"
                :wrapper-col="{span: 16}"
            >
                <a-form-model-item ref="projectName" label="项目名称" prop="projectName">
                    <!-- <a-input v-model="projectDetailedQueryData.projectName" /> -->
                    <a-select style="width: 200px" v-model="projectDetailedQueryData.projectName" allowClear>
                        <a-select-option :title="item" :value="item" v-for="(item, index) in projectList" :key="index">
                            {{item}}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item ref="month" label="工资月份" prop="month">
                    <!-- <a-month-picker v-model="projectDetailedQueryData.month" placeholder="请选择工资月份"
                        @change="detailedQueryDataMonthChange"  style="width: 100%;" format="yyyyMM"
                    /> -->
                    <a-select style="width: 200px" v-model="projectDetailedQueryData.month" allowClear>
                        <a-select-option :title="item" :value="item" v-for="(item, index) in dateList" :key="index">
                            {{moment(item).format('YYYY年MM月')}}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-button type="primary" icon="search" :loading="loading" @click="searchProjectDetailed">查询</a-button>
                <a-button type="primary" style="margin-left: 10px"   icon="download" :loading="loading" @click="summaryExport">下载汇总工资单</a-button>
                <a-upload
                    name="file"
                    :multiple="true"
                    :action="importStaffPayrollTax+ '?summaryId=' + showSummaryData.id"
                    :headers="headers"
                    @change="taxFileChange"
                    :showUploadList="false"
                >
                    <a-button style="margin-left: 10px"  :loading="loading"> <a-icon type="upload" />导入税务单</a-button>
                </a-upload>
            </a-form-model>
            <div class="ml-2 my-1">
                合计：            
                <span class="mr-3">应发金额：{{totalObj.payableMoney}}</span>
                <span class="mr-3">个税：{{totalObj.tax}}</span>
                <span class="mr-2">实发金额：{{totalObj.actualMoney}}</span>
            </div>
            <a-table :columns="projectDetailedColumns"
                :data-source="projectDetailedData"
                :loading="loading">
<!--                <template slot="action" slot-scope="text, record">-->
<!--                    <span>-->
<!--                      <a @click="delStaffPayroll(record)">删除</a>-->
<!--                    </span>-->
<!--                </template>-->
                    
            </a-table>
        </a-modal>

        <a-modal
            title="分税记录"
            :visible="taxRecordModelVisible"
            width="80%"
            :footer="null"
            @cancel="taxRecordModelCancel"
            :keyboard="true"
            :maskClosable="true"
        >
            <a-form-model
                :model="taxRecordQueryData"
                layout="inline"
                :label-col="{span: 6}"
                :wrapper-col="{span: 16}"
            >
                <a-form-model-item ref="name" label="姓名" prop="name">
                    <a-input v-model="taxRecordQueryData.name" />
                </a-form-model-item>
                <a-form-model-item ref="projectName" label="项目" prop="projectName">
                    <a-input v-model="taxRecordQueryData.projectName" />
                </a-form-model-item>
                <a-form-model-item ref="officeName" label="公司名" prop="officeName">
                    <a-input v-model="taxRecordQueryData.officeName" />
                </a-form-model-item>
                <a-form-model-item ref="teamName" label="班组名" prop="teamName">
                    <a-input v-model="taxRecordQueryData.teamName" />
                </a-form-model-item>
                <a-button type="primary" icon="search" :loading="loading" @click="searchTaxRecord">查询</a-button>
                <a-button type="primary" style="margin-left: 10px"   icon="download" :loading="loading" @click="taxRecordExport(showSummaryData)">导出</a-button>
            </a-form-model>

            <a-table :columns="taxRecordColumns"
                :data-source="taxRecordData"
                :pagination="taxPagination"
                @change="taxChange"
                :bordered="true"
                :loading="loading">
            </a-table>
        </a-modal>
    </div>
</template>
<script>
    import {getSummaryList, summaryExport, getProjectSummaryApplyBySummaryId, getTaxRecordList, taxRecordExport} from '@/services/payroll'
    import {IMPORT_STAFF_PAYROLL_TAX} from '@/services/api'
    import {mapGetters} from 'vuex'
    import moment from 'moment'
    import { storage } from '@/utils/storage'
    import { TOKEN_KEY } from '@/utils/request.js'
    import { useRender } from '@/hooks/useRender.js'

    const columns = [
        {
            title: '汇总id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '制表月份',
            dataIndex: 'month',
            key: 'month',
        },
        {
            title: '应发金额',
            dataIndex: 'payableMoney',
            key: 'payableMoney',
        },
        {
            title: '个税',
            dataIndex: 'tax',
            key: 'tax',
        },
        {
            title: '实发金额',
            dataIndex: 'actualMoney',
            key: 'actualMoney',
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            customRender: (text) => {
                return text  === 0 ? "已汇总" : text  === 1? "已导入税额" : text  === 2? "已结束" : "未知"
            }
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            customRender: (text) => useRender.renderDate(text)
        },
        {
            title: '导入税表时间',
            dataIndex: 'updateTaxtFileTime',
            key: 'updateTaxtFileTime',
            customRender: (text) => useRender.renderDate(text)
        },
        {
            title: '操作',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' },
        },

    ];

    const projectDetailedColumns = [
        {
            title: '项目名称',
            dataIndex: 'projectName',
            key: 'projectName',
        },
        {
            title: '工资月份',
            dataIndex: 'month',
            key: 'month',
        },
        {
            title: '班组名称',
            dataIndex: 'teamNames',
            key: 'teamNames',
        },
        {
            title: '应发金额',
            dataIndex: 'payableMoney',
            key: 'payableMoney',
        },
        {
            title: '个税',
            dataIndex: 'tax',
            key: 'tax',
        },
        {
            title: '实发金额',
            dataIndex: 'actualMoney',
            key: 'actualMoney',
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            customRender: (text) => {
                return text  === 0 ? "已汇总" : text  === 1? "已导入税额" : text  === 2? "已结束" : "未知"
            }
        },

    ];
    const taxRecordColumns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            width: 70
        },
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
            width: 80
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
            width: 70
        },
        {
            title: '项目',
            dataIndex: 'projectName',
            key: 'projectName',
            width: 380,
            ellipsis: true,
        },
        {
            title: '公司',
            dataIndex: 'officeName',
            key: 'officeName',
        },
        {
            title: '班组',
            dataIndex: 'teamName',
            key: 'teamName',
        },
        {
            title: '应发金额',
            dataIndex: 'payableMoney',
            key: 'payableMoney',
        },
        {
            title: '实发金额',
            dataIndex: 'actualMoney',
            key: 'actualMoney',
        },
        {
            title: '分税额',
            dataIndex: 'tax',
            key: 'tax',
        },
        {
            title: '总税额',
            dataIndex: 'totalTax',
            key: 'totalTax',
        },
        {
            title: '分税时间',
            dataIndex: 'createTime',
            key: 'createTime',
            customRender: (text) => useRender.renderDate(text)
        },
    ];

    export default {
        data() {
            return {
                headers: {
                    [TOKEN_KEY]: storage.get(TOKEN_KEY),
                    'Tenant-Id': storage.get('Tenant-Id') ? storage.get('Tenant-Id') : ''
                },

                queryData: {
                    officeName: '',
                    projectName: '',
                    month: '',
                    teamName: '',
                    applyType: '',

                },
                projectList: [],
                dateList: [],
                data: [],
                pagination: {
                    current: 1,
                    pageSize: 20,
                    total: 0,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
                    showTotal: (total, range) => `共 ${total} 条`
                },
                loading: false,
                columns,
                selectedSummaryIdList: [],

                importStaffPayrollTax: IMPORT_STAFF_PAYROLL_TAX,


                showSummaryData: {},

                projectDetailedModelVisible: false,
                projectDetailedQueryData:{

                },
                projectDetailedColumns,
                projectDetailedData: [],

                taxRecordColumns,
                taxRecordModelVisible: false,
                taxRecordQueryData: {
                    summaryId: '',
                    pageNumber: 1,
                    pageSize: 10,
                },
                taxRecordData: [],
                taxPagination: {
                    //分页数据
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                totalObj: {
                    actualMoney: 0, // 实发金额
                    payableMoney: 0, // 应发金额
                    tax: 0 // 个税
                }
            };
        },
        computed: {
            hasTeamSelected() {
                return this.selectedTeamIds.length > 0;
            },
            ...mapGetters('account', ['user']),
        },
        created() {
            // this.queryData.projectId = this.user.projectId
            this.getData(false);
        },
        methods: {
            moment,
            getData(isSearch){
                if (isSearch){
                    this.pagination.current = 1
                }
                this.queryData.pageNumber = this.pagination.current;
                this.queryData.pageSize = this.pagination.pageSize;
                this.loading = true
                getSummaryList(this.queryData).then(res => {
                    if (res.data.code === 0) {
                        this.data = res.data.data.list;
                        this.pagination.total = res.data.data.total;
                    } else {
                        this.$message.error(res.data.msg)
                    }
                }).finally(() => {
                    this.selectedSummaryIdList = []
                    this.loading = false
                })
            },
            queryDataMonthChange(dateString){
                if (dateString === '' || dateString=== null || dateString === undefined) {
                    this.queryData.month = ''
                    return
                }
                this.queryData.month = moment(dateString).format('YYYYMM')
            },
            selectSummaryChange(selectedRowKeys) {
                this.selectedSummaryIdList = selectedRowKeys;
                console.log( this.selectedSummaryIdList);
            },

            summaryExport(summary){
                let data = {
                    "summaryId": summary.id ? summary.id : this.showSummaryData.id,
                }
                this.loading = true
                summaryExport(data).then(res => {
                    let fileName = summary.id + "_" + summary.month + "工人工资申请汇总.xlsx";
                    let fileName2 = this.showSummaryData.id + "_" + this.showSummaryData.month + "工人工资申请汇总.xlsx";
                    let downloadElement = document.createElement('a')//创建dom
                    let href = window.URL.createObjectURL(res.data) //创建下载链接
                    downloadElement.href = href
                    downloadElement.download = summary.id ? fileName : fileName2 //下载后文件名
                    document.body.appendChild(downloadElement)//添加创建的节点
                    downloadElement.click() //点击下载
                    document.body.removeChild(downloadElement) //下载完成移除元素
                    window.URL.revokeObjectURL(href) //释放掉blob对象
                }).finally(() => {
                    this.loading = false
                })
            },
            taxFileChange(info){
                this.loading = true
                if (info.file.status === 'done') {
                    const stat = info.file.response.code
                    if (stat === 0){
                        this.searchProjectDetailed()
                        this.getData(false)
                    }else {
                        this.$message.error(info.file.response.msg)
                    }
                    this.loading = false
                }
            },
            summaryTableChange(pagination){
                this.pagination.current = pagination.current
                this.pagination.pageSize = pagination.pageSize
                this.getData(false)
            },
            detailedQueryDataMonthChange(dateString){
                if (dateString === '' || dateString=== null || dateString === undefined) {
                    this.projectDetailedQueryData.month = ''
                    return
                }
                this.projectDetailedQueryData.month = moment(dateString).format('YYYYMM')
            },
            showDetailed(record){
                this.projectDetailedQueryData = {}
                this.projectDetailedModelVisible = true
                this.showSummaryData = record
                this.projectDetailedQueryData.summaryId = record.id
                this.getProjectSummaryApplyBySummaryId(true)
            },
            /*
            *isFirst是否第一次请求获取筛选项
            */
            getProjectSummaryApplyBySummaryId(isFirst = false){
                this.loading = true
                getProjectSummaryApplyBySummaryId(this.projectDetailedQueryData).then(res => {
                    this.projectDetailedData = res.data.data.list
                    let payableMoney = 0
                    let actualMoney = 0
                    let tax = 0
                    if (isFirst) {
                        this.projectList = []
                        this.dateList = []
                    }
                    this.projectDetailedData.forEach((item)=>{
                        if (isFirst) {
                            this.projectList.push(item.projectName)
                            this.dateList.push(item.month)
                        }
                        let teamList = item.teamPayrollApplyVoList.map((team)=> {return team.teamName});
                        let teamNames = teamList.join(',');
                        item.teamNames = teamNames;
                        payableMoney += item.payableMoney
                        actualMoney += item.actualMoney
                        tax += item.tax
                    })
                    if (isFirst) {
                        this.projectList = [...new Set(this.projectList)];
                        this.dateList = [...new Set(this.dateList)]
                        this.dateList.sort((a, b) => {
                            return parseInt(a) - parseInt(b);
                        });
                    }
                    this.totalObj.payableMoney = payableMoney.toFixed(2);
                    this.totalObj.actualMoney = actualMoney.toFixed(2);
                    this.totalObj.tax = tax.toFixed(2);
                }).finally(() => {
                    this.loading = false
                })
            },
            getTaxRecordList() {
                this.loading = true
                getTaxRecordList(this.taxRecordQueryData).then(res => {
                    this.taxPagination.total = res.data.data.total;
                    this.taxRecordData = res.data.data.list
                }).finally(() => {
                    this.loading = false
                })
            },
            searchProjectDetailed(){
                this.getProjectSummaryApplyBySummaryId()
            },
            projectDetailedModelCancel(){
                this.projectDetailedModelVisible = false
                this.showSummaryData = {}
            },
            taxRecord(item) {
                this.taxPagination.current = 1
                this.taxRecordQueryData.summaryId = item.id
                this.taxRecordModelVisible = true
                this.getTaxRecordList()
            },
            taxRecordModelCancel(){
                this.taxRecordModelVisible = false
            },
            searchTaxRecord() {
                this.taxRecordQueryData.pageNumber = 1
                this.taxPagination.current = 1
                this.getTaxRecordList()
            },
            taxRecordExport(){
                let data = {
                    "summaryId": this.taxRecordQueryData.summaryId,
                }
                this.loading = true
                taxRecordExport(data).then(res => {
                    console.log(res)
                    let fileName = "分税记录.xlsx";
                    let downloadElement = document.createElement('a')//创建dom
                    let href = window.URL.createObjectURL(res.data) //创建下载链接
                    downloadElement.href = href
                    downloadElement.download = fileName //下载后文件名
                    document.body.appendChild(downloadElement)//添加创建的节点
                    downloadElement.click() //点击下载
                    document.body.removeChild(downloadElement) //下载完成移除元素
                    window.URL.revokeObjectURL(href) //释放掉blob对象
                }).finally(() => {
                    this.loading = false
                })
            },
            taxChange(pagination){
                this.taxRecordQueryData.pageNumber = pagination.current
                this.taxPagination.current = pagination.current
                this.taxPagination.pageSize = pagination.pageSize
                this.getTaxRecordList()
            },
        },


    };

</script>
<style scoped>

    .ant-advanced-search-form {
        background: #fff;
        border-radius: 6px;
    }

    tr{
        background-color: #ffffff;
    }

    .steps-content {
        margin-top: 16px;
        border: 1px dashed #e9e9e9;
        border-radius: 6px;
        background-color: #fafafa;
        padding-top: 10px;
    }

    .steps-action {
        margin-top: 24px;
    }
</style>
