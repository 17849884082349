<template>
  <a-row :gutter="16">
    <!-- 地图 -->
    <a-col :span="13">
      <mapOverview fatherType="project"></mapOverview>

      <!-- 项目自检检查次数统计 -->
      <a-card class="card" style="margin-top: 10px">
        <div
          slot="title"
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <span>项目自检检查次数统计</span>
          <a-date-picker
            mode="year"
            v-model="queryParams.year"
            placeholder="请输入年份"
            :default-value="moment(year, 'YYYY')"
            format="YYYY"
            style="width: 100px"
            @panelChange="checkCountChange"
          />
        </div>

        <div id="checkCountChart" style="width: 95%; height: 350px;"></div>
      </a-card>
    </a-col>

    <!-- 项目安全检查情况 -->
    <a-col :span="11">
      <a-card class="card" style="width: 100%">
        <div
          slot="title"
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <span>项目安全检查情况</span
          >
          <div>
            <div style="max-width: 60%; float: right">
              <a-range-picker
                v-if="monthQueryParams.queryType == 3"
                v-model="monthQueryParams.plan_date"
                :default-value="[
                  moment(startDate, 'YYYY-MM-DD'),
                  moment(endDate, 'YYYY-MM-DD'),
                ]"
                format="YYYY-MM-DD"
                @change="mtChangeDate"
              />
              <a-month-picker
                style="width: 100px"
                v-if="monthQueryParams.queryType == 2"
                @change="mtChangeDate"
                placeholder="请选择月份"
                :default-value="moment(month, 'YYYY-MM')"
                format="YYYY-MM"
                v-model="monthQueryParams.plan_date"
              >
                <a-icon slot="suffixIcon" type="smile" />
              </a-month-picker>
              <a-date-picker
                v-if="monthQueryParams.queryType == 1"
                mode="year"
                v-model="monthQueryParams.plan_date"
                placeholder="请输入年份"
                :default-value="moment(year, 'YYYY')"
                format="YYYY"
                style="width: 100px"
                :open="monthQueryParams.yearShowOne"
                @openChange="monthOpenChangeOne"
                @panelChange="monthPanelChangeOne"
              />
            </div>
            <a-select
              v-model="monthQueryParams.queryType"
              style="width: 80px; float: right"
              @change="mtChangeTypeDate"
            >
              <a-select-option :key="1"> 年 </a-select-option>
              <a-select-option :key="2"> 月 </a-select-option>
              <a-select-option :key="3">其他</a-select-option>
            </a-select>
          </div>
        </div>

        <a-row :gutter="16" style="padding-left: 5%">
          <a-col :span="6">
            <a-statistic
              title="隐患总数"
              :value="totalNums"
              suffix="个"
              :value-style="{ fontSize: '28px' }"
              style="margin-bottom: 35px"
            />
          </a-col>
          <a-col :span="6">
            <a-statistic
              title="一般隐患数"
              :value="(totalNums - gkObj.zdyhs) || 0"
              suffix="个"
              :value-style="{ color: '#cf1322', fontSize: '28px' }"
            />
          </a-col>
          <a-col :span="6">
            <a-statistic
              title="重大隐患数"
              suffix="个"
              :value="gkObj.zdyhs"
              :value-style="{ color: '#cf1322', fontSize: '28px' }"
            />
          </a-col>
          <a-col :span="6">
            <a-statistic
              title="隐患整改率"
              :value="gkObj.zgl"
              :value-style="{ fontSize: '28px' }"
            />
          </a-col>
        </a-row>

        <a-row :gutter="16" style="padding-left: 5%">
          <a-col :span="6">
            <a-statistic
              title="检查总数"
              :value="totalChecks"
              suffix="次"
              :value-style="{ fontSize: '28px' }"
              style="margin-bottom: 35px"
            />
          </a-col>
          <a-col :span="6">
            <a-statistic
              title="日常检查总数"
              :value="`${(gkObj.checks - gkObj.zxjc - gkObj.tgdcnt) | 0}`"
              suffix="次"
              :value-style="{ fontSize: '28px' }"
            />
          </a-col>
          <a-col :span="6">
            <a-statistic
              title="专项检查总数"
              :value="`${gkObj.zxjc | 0}`"
              suffix="次"
              :value-style="{ fontSize: '28px' }"
            />
          </a-col>
          <a-col :span="6">
            <a-statistic
              title="安全验收总数"
              :value="`${gkObj.aqyscnt | 0}`"
              suffix="次"
              :value-style="{ fontSize: '28px' }"
            />
          </a-col>
        </a-row>
      </a-card>
    </a-col>

    <a-col :span="11" style="margin-top: 10px">
      <!-- 检查隐患类型统计 -->
      <checkDangerOverview fatherType="project" @updateYhzsTotal="(e) => yhzs = e"></checkDangerOverview>
    </a-col>
  </a-row>
</template>

<script>
import mapOverview from "@/components/safeHome/mapOverview/index.vue";
import checkDangerOverview from "@/components/safeHome/checkDangerOverview/index.vue";
import moment from "moment";
import { getIsGcglb } from '@/services/user'
import { request } from "@/utils/request";
import { QUERY_PROJECT_GK, PROBLEMS_BY_TOTAL_QUERY, CURRENT_CHECKTIMES_CHART } from "@/services/api";
import { debounce } from 'lodash';
import { mapGetters, mapMutations } from 'vuex';
export default {
  components: { mapOverview, checkDangerOverview },
  data() {
    return {
      moment,
      // 隐患总数
      yhzs: 0,
      gkObj: {},
      startDate: moment(new Date()).format("YYYY-MM") + "-01",
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      year: moment(new Date()).format("YYYY-MM-DD"),
      month: moment(new Date()).format("YYYY-MM"),
      monthQueryParams: {
        queryType: 2,
        plan_date: null,
        startDate: this.startDate,
        endDate: this.endDate,
        year: this.year,
        month: this.month,
        yearShowOne: false,
        projectId: ''
      },
      queryParams: {
        projectId: '',
        planTypes: [2],
        queryType: 1,
        year: moment(new Date()).format('YYYY')
      },
      checkCountChart: null
    };
  },
  computed: {
    totalChecks() {
      let total = 0;
      if (this.gkObj.checks && this.gkObj.checks != null) {
        total = total + this.gkObj.checks;
      }
      if (this.gkObj.dbCnt && this.gkObj.dbCnt != null) {
        total = total + this.gkObj.dbCnt;
      }
      return total;
    },
    totalNums() {
      let total = 0
      if (this.gkObj.yhs) {
        total = total + this.gkObj.yhs + this.gkObj.leaderNums
      }
      return total;
    },
    ...mapGetters('account', ['user']),
  },
  created() {
    getIsGcglb().then(res => {
      if (res.data.code == 0) { 
        let user = this.user
        user.isGcglb = res.data.data
        this.setUser(user)
      } else {
        this.$message.error(res.data.msg)
      }
    })
  },
  mounted() {
    this.queryXjGk();
    this.checkCountChange()
    // window.addEventListener('resize', this.resizeChart);
  },
  beforeDestroy() {
    // window.removeEventListener('resize', this.resizeChart);
  },
  methods: {
    ...mapMutations("account", ["setUser"]),
    async checkCountChange(e) {
      if (e) {
        this.queryParams.year = e
      }
      this.queryParams.projectId = this.user.projectId
      const res = await request(CURRENT_CHECKTIMES_CHART, "post", { ...this.queryParams, year: moment(this.queryParams.year).year() })
      if (res.data.code == 0) {
        const chartData = []
        res.data.data.forEach((item, i) => {
          chartData.push({
            name: item.timeDate[0] === '0' ? item.timeDate[1] + '月' : item.timeDate + '月',
            value: item.cnt
          })
        })
        this.initCheckCountChart(chartData);
      }
    },
    initCheckCountChart(data) {
      // 基于准备好的dom，初始化echarts实例
      this.checkCountChart = this.$echarts.init(
        document.getElementById("checkCountChart")
      );
      // 指定图表的配置项和数据
      let option = {
        grid: {
          top: 30,
          left: 30,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function(params) { // 自定义 tooltip 的显示内容
            let colorBlock = '<span style="display:inline-block;margin-right:5px;width:10px;height:10px;background-color:#2D5AFF;border-radius:50%;"></span>';
            let name = params[0].name;
            let value = params[0].value;
            return colorBlock + name + ': ' + value + '次'; // 返回自定义的 HTML 字符串
          }
        },
        xAxis: [
          {
            type: "category",
            data: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
            axisTick: {
              alignWithLabel: true,
              show: false, // 隐藏刻度线
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisTick: {
              show: false, // 隐藏刻度线
            },
            axisLine: {
              show: false, // 隐藏轴线
            }
          },
        ],
        series: [
          {
            name: "Direct",
            barGap: '10px',
            type: "bar",
            barWidth: "40%",
            // barCategoryGap: '10%',
            data: data,
            itemStyle: {
              color: "#2D5AFF",
            },
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      this.checkCountChart.setOption(option);
    },
    // 调整图表大小方法
    resizeChart: debounce(function() {
      // 调用 echarts 的 resize 方法
      this.checkCountChart.resize();
    }, 100),
    mtChangeDate(value) {
      this.monthQueryParams.plan_date = value;
      this.queryXjGk();
    },
    monthOpenChangeOne(status) {
      if (status) {
        this.monthQueryParams.yearShowOne = true;
      }
    },
    monthPanelChangeOne(value) {
      this.monthQueryParams.yearShowOne = false;
      this.monthQueryParams.plan_date = value;
      this.$nextTick(this.queryXjGk);
    },
    mtChangeTypeDate() {
      if (
        this.monthQueryParams.queryType == 1 ||
        this.monthQueryParams.queryType == 2
      ) {
        this.monthQueryParams.plan_date = moment(new Date());
      } else {
        this.monthQueryParams.plan_date = null;
      }
      if (this.monthQueryParams.queryType == 1) {
        this.monthQueryParams.month = moment(new Date()).format("YYYY");
      }
      if (this.monthQueryParams.queryType == 2) {
        this.monthQueryParams.month = moment(new Date()).format("YYYY-MM");
      }
      this.queryXjGk();
    },
    queryXjGk() {
      if (
        this.monthQueryParams.queryType == 1 &&
        this.monthQueryParams.plan_date != null
      ) {
        this.monthQueryParams.month =
          this.monthQueryParams.plan_date.format("YYYY");
      }
      if (
        this.monthQueryParams.queryType == 2 &&
        this.monthQueryParams.plan_date != null
      ) {
        this.monthQueryParams.month =
          this.monthQueryParams.plan_date.format("YYYY-MM");
      }
      if (
        this.monthQueryParams.queryType == 3 &&
        this.monthQueryParams.plan_date &&
        this.monthQueryParams.plan_date.length > 0
      ) {
        this.monthQueryParams.startDate =
          this.monthQueryParams.plan_date[0].format("YYYY-MM-DD");
        this.monthQueryParams.endDate =
          this.monthQueryParams.plan_date[1].format("YYYY-MM-DD");
      }

      this.monthQueryParams.projectId = this.user.projectId
      request(QUERY_PROJECT_GK, "post", this.monthQueryParams).then((res) => {
        if (res.data.code == 0) {
          this.gkObj = res.data.data;
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>
.card {
  border-radius: 10px;
  /deep/ .ant-card-body {
    padding-bottom: 0px;
  }
}
</style>
