import axios from 'axios'
import { storage } from '@/utils/storage'
import { getSystemType } from '@/utils/system.js'

// token 字段名
export const TOKEN_KEY = 'Authorization'

axios.defaults.timeout = 24 * 60 * 60 * 1000
axios.defaults.headers['System-Type'] = getSystemType()

// http method
const METHOD = {
  GET: 'get',
  POST: 'post',
  DELETE: 'delete',
  PUT: 'put'
}

/**
 * axios请求
 * @param url 请求地址
 * @param method {METHOD} http method
 * @param params 请求参数
 * @returns {Promise<AxiosResponse<T>>}
 */
async function request(url, method, params, config) {
  const token = storage.get(TOKEN_KEY)
  const headers = {
    ...(token ? { [TOKEN_KEY]: token } : {}),
    'Tenant-Id': storage.get('Tenant-Id') ? storage.get('Tenant-Id') : ''
  }
  switch (method) {
    case METHOD.GET:
      return axios.get(url, {params, ...config, headers})
    case METHOD.POST:
      return axios.post(url, params, {...config, headers})
    case METHOD.DELETE:
      return axios.delete(url, {params, ...config, headers})
    case METHOD.PUT:
      return axios.put(url, params, {...config, headers})
    default:
      return axios.get(url, {params, ...config, headers})
  }
}

/**
 * 设置认证信息
 * @param token
 */
function setAuthorization(token) {
  token && storage.set(TOKEN_KEY, `Bearer ${token}`)
}

/**
 * 检查认证信息
 * @returns {boolean}
 */
function checkAuthorization() {
  if (storage.get(TOKEN_KEY)) {
    return true
  }
  return false
}

/**
 * 加载 axios 拦截器
 * @param interceptors
 * @param options
 */
function loadInterceptors(interceptors, options) {
  const {request, response} = interceptors
  // 加载请求拦截器
  request.forEach(item => {
    console.log("请求拦截器",item)
    let {onFulfilled, onRejected} = item
    if (!onFulfilled || typeof onFulfilled !== 'function') {
      onFulfilled = config => config
    }
    if (!onRejected || typeof onRejected !== 'function') {
      onRejected = error => Promise.reject(error)
    }
    axios.interceptors.request.use(
      config => onFulfilled(config, options),
      error => onRejected(error, options)
    )
  })
  // 加载响应拦截器
  response.forEach(item => {
    console.log("响应拦截器",item)
    let {onFulfilled, onRejected} = item
    if (!onFulfilled || typeof onFulfilled !== 'function') {
      onFulfilled = response => response
    }
    if (!onRejected || typeof onRejected !== 'function') {
      onRejected = error => Promise.reject(error)
    }
    axios.interceptors.response.use(
      response => onFulfilled(response, options),
      error => onRejected(error, options)
    )
  })
}

/**
 * 解析 url 中的参数
 * @param url
 * @returns {Object}
 */
function parseUrlParams(url) {
  const params = {}
  if (!url || url === '' || typeof url !== 'string') {
    return params
  }
  const paramsStr = url.split('?')[1]
  if (!paramsStr) {
    return params
  }
  const paramsArr = paramsStr.replace(/&|=/g, ' ').split(' ')
  for (let i = 0; i < paramsArr.length / 2; i++) {
    const value = paramsArr[i * 2 + 1]
    params[paramsArr[i * 2]] = value === 'true' ? true : (value === 'false' ? false : value)
  }
  return params
}

export {
  METHOD,
  request,
  setAuthorization,
  checkAuthorization,
  loadInterceptors,
  parseUrlParams
}
