var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-card",
        [
          _c(
            "a-row",
            { staticStyle: { height: "100%" }, attrs: { gutter: 16 } },
            [
              _c(
                "a-col",
                {
                  staticClass: "flex flex-column",
                  staticStyle: { height: "100%" },
                  attrs: { span: 8 },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "left-box",
                      staticStyle: { height: "253px" },
                    },
                    [
                      _c("div", { staticClass: "flex a-center j-between" }, [
                        _c("div", { staticClass: "flex a-center" }, [
                          _c("img", {
                            staticStyle: { width: "21px", height: "21px" },
                            attrs: {
                              src: "https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241120/1732091350443.png?Expires=2047451350&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=59AEqlAw%2B%2BGyQ%2F%2B4QYdFWxJZ7rs%3D",
                              alt: "",
                            },
                          }),
                          _c("span", { staticClass: "font-bold ml-1" }, [
                            _vm._v("项目数总计"),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c(
                              "a-select",
                              {
                                staticStyle: { width: "150px" },
                                attrs: { "default-value": _vm.defaultUnit },
                                on: { change: _vm.onHandleUnitChange },
                                model: {
                                  value: _vm.defaultUnit,
                                  callback: function ($$v) {
                                    _vm.defaultUnit = $$v
                                  },
                                  expression: "defaultUnit",
                                },
                              },
                              _vm._l(_vm.officeList, function (items, id) {
                                return _c(
                                  "a-select-option",
                                  { key: id, attrs: { value: items.id } },
                                  [
                                    _c(
                                      "span",
                                      { attrs: { title: items.name } },
                                      [_vm._v(_vm._s(items.name))]
                                    ),
                                  ]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("a-divider"),
                      _c(
                        "div",
                        [
                          _c(
                            "a-row",
                            { attrs: { gutter: [8, 8] } },
                            _vm._l(_vm.tabList, function (item, index) {
                              return _c(
                                "a-col",
                                { key: index, attrs: { span: 12 } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex flex-column a-center j-center statistics-item-box",
                                      class:
                                        _vm.actived == index ? "actived" : "",
                                      style: { background: item.background },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onTabClick(index, item)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "font-20 font-bold" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.projectCatalogStatObj[
                                                item.key
                                              ] || 0
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "flex a-center" },
                                        [
                                          _c("div", {
                                            staticClass: "dot-icon mr-1",
                                            style: {
                                              background: item.iconBackground,
                                            },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-12 black-three",
                                            },
                                            [_vm._v(_vm._s(item.title))]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "left-box mt-1 flex-1" },
                    [
                      _c("div", { staticClass: "flex a-center" }, [
                        _c("img", {
                          staticStyle: { width: "21px", height: "21px" },
                          attrs: {
                            src: "https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241120/1732091350443.png?Expires=2047451350&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=59AEqlAw%2B%2BGyQ%2F%2B4QYdFWxJZ7rs%3D",
                            alt: "",
                          },
                        }),
                        _c("span", { staticClass: "font-bold ml-1" }, [
                          _vm._v("项目分布情况"),
                        ]),
                      ]),
                      _c("a-divider"),
                      _c("div", {
                        staticStyle: { width: "100%", height: "75%" },
                        attrs: { id: "yhzsPieChart" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "a-col",
                { staticStyle: { height: "100%" }, attrs: { span: 16 } },
                [
                  _c(
                    "div",
                    { staticStyle: { height: "100%" } },
                    [
                      _c(
                        "baidu-map",
                        {
                          staticClass: "bmView",
                          attrs: {
                            "scroll-wheel-zoom": true,
                            center: _vm.location,
                            zoom: _vm.zoom,
                          },
                        },
                        [
                          _c("bm-view", { staticClass: "bmMap" }),
                          _c("bm-local-search", {
                            staticStyle: { display: "none" },
                            attrs: {
                              keyword: _vm.addressKeyword,
                              "auto-viewport": true,
                            },
                          }),
                          _vm._l(_vm.persons, function (items, index) {
                            return _c(
                              "div",
                              { key: index },
                              [
                                _c(
                                  "bm-marker",
                                  {
                                    attrs: {
                                      position: {
                                        lng: items.lng,
                                        lat: items.lat,
                                      },
                                      icon: {
                                        url: items.show
                                          ? _vm.localActive
                                          : items.icon,
                                        size: { width: 28, height: 28 },
                                      },
                                      dragging: false,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.infoWindowOpen(index, items)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "bm-info-window",
                                      {
                                        attrs: { show: items.show },
                                        on: {
                                          close: function ($event) {
                                            return _vm.infoWindowClose(index)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "info-window" },
                                          [
                                            _c("a-popover", [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "content" },
                                                  slot: "content",
                                                },
                                                [_vm._v(_vm._s(items.name))]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "ellipsis-2" },
                                                [
                                                  _vm._v(
                                                    "项目名称： " +
                                                      _vm._s(items.name)
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "line-height": "30px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " 项目经理：" +
                                                    _vm._s(
                                                      _vm.staffObj.xmjl
                                                        ? _vm.staffObj.xmjl
                                                        : 0
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                " 联系电话：" +
                                                  _vm._s(
                                                    _vm.staffObj.xmjlPhone
                                                      ? _vm.staffObj.xmjlPhone
                                                      : 0
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }