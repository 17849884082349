<template>
    <div class="container">
        <a-card :body-style="{padding: '0px'}" :bordered="false">
            <div id="components-form-demo-advanced-search">
                <a-form class="ant-advanced-search-form">
                    <a-row :gutter="24">
                        <a-col :span="3" v-if="canOperate">
                            <a-button  type="primary" @click="handleUpload" > <a-icon type="upload" /> 导入工人考勤 </a-button>
                        </a-col>
                        <a-col :span="3" v-if="canOperate">
                            <a-button  type="primary" @click="managerAtt" > 填写管理人员考勤 </a-button>
                        </a-col>
                        <a-col :span="5" >
                            <a-form-item label="姓名">
                                <a-input v-model="query.name" placeholder="请输入查询姓名" />
                            </a-form-item>
                        </a-col>
                        <a-col :span="4">
                            <a-button type="primary" icon="search" :loading="loading" @click="handleSearch">
                                查询
                            </a-button>
                        </a-col>
                    </a-row>
                </a-form>
            </div>
            <standard-table
                    :columns="columns"
                    :data-source="data"
                    :pagination="pagination"
                    @change="onPageChange"
                    rowKey="id"
                    :loading="loading"
                    :isAlert="false"
                    :bordered="false"
                    >
                <template #titleWithTooltip> 
                    <a-tooltip>
                    <template slot="title">设备直联补报，遇到重名报错，可手动填写建委staffId</template>
                    建委staffId<a-icon style="margin-left:5px" type="question-circle" theme="twoTone" />
                    </a-tooltip>
                </template>
                <template slot="index" slot-scope="{ index }">
                    <span>
                        {{
                        (pagination.current - 1) * pagination.pageSize +
                            parseInt(index) +
                            1
                        }}
                    </span>
                </template>

                <template slot="action" slot-scope="{text, record}">
                    <div class="sTables">
                        <div @click="edit(record)">填写建委staffId</div>
                        <div @click="del(record)">删除</div>
                    </div>
                </template>
                <template slot="checkType" slot-scope="{text}">
                    {{text == 1 ? '进场' : '出场'}}
                </template>
                <template slot="personType" slot-scope="{text}">
                    {{text == 1 ? '工人' : '管理人员'}}
                </template>
                <template slot="atteImg" slot-scope="{text}">
                    <a-avatar shape="square" :size="64" :src="text" icon="user"/>
                </template>
                <template slot="pullStatus" slot-scope="{text}">
                    <span v-if="text == 1"><i class="iconfont duigouxiao" style="color:#00CE86"></i></span>
                    <span v-else><i class="iconfont path" style="color:#FF5C77"></i></span>
                </template>
            </standard-table>
        </a-card>
        <a-modal
            :visible="uploadWorkerModal"
            :footer="null"
            @cancel="cancelUploadWorkerModal"
            >
            <div class="flex flex-column a-center">
                <h4 class="mb-2">只能导入工人考勤数据，并且3天内的</h4>
                <a-upload
                    :action="jwBatchApplyAddUrl"
                    :headers="headers"
                    :file-list="fileList"
                    @change="handleChange"
                    accept=".xls,.xlsx"
                    :beforeUpload="beforeUpload"
                >
                    <div >
                        <a-button  type="primary"> 确认导入 </a-button>
                    </div>
                </a-upload>
            </div>
        </a-modal>
        <a-modal
            :visible="managerModal"
            @ok="managerOk"
            @cancel="managerCancel"
            :width="680"
            >
            <form-index
                ref="formindex"
                :formData="formData"
                @upRemove="upRemove" 
                @resourceUpload="resourceUpload"
            >
            </form-index>
        </a-modal>
        <a-modal
            :visible="editModal"
            @ok="editOk"
            @cancel="editCancel"
            :width="680"
            >
            <form-index
                ref="editForm"
                :formData="editFormData"
            >
            </form-index>
        </a-modal>
    </div>
</template>
<script>
    import { jwBatchApplyList, jwBatchApplyDel, batchApplyAddManager,delBackAtteRecord} from '@/services/dmgr'
    import { getBackAtteRecord,updateBackAtteRecord } from "@/services/participationUnit"
    import {mapGetters} from 'vuex'
    import moment from 'moment'
    import { storage } from '@/utils/storage'
    import { TOKEN_KEY } from '@/utils/request.js'
    import {JW_BATCH_APPLY_ADD} from '@/services/api'
    import {request} from '@/utils/request'
    import formIndex from "@/pages/components/form/index";
    import StandardTable from "@/components/table/StandardTable";
    import { useRender } from '@/hooks/useRender.js'

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    const editFormData = [
        {
            label: "建委staffId",
            placeholder: "请填写",
            decorator: [
                "jwStaffId",
                { rules: [{ required: true, message: "请填写" }] },
            ],
            type: "input",
            key: "jwStaffId",
            col: 24,
            labelCol: 6,
            wrapperCol: 14,
            display: true,
        },
    ]
    const formData = [
        {
            label: "姓名",
            placeholder: "请填写",
            decorator: [
                "name",
                { rules: [{ required: true, message: "请填写" }] },
            ],
            type: "input",
            key: "name",
            col: 12,
            labelCol: 6,
            wrapperCol: 14,
            display: true,
        },
        {
            label: "身份证号",
            placeholder: "请填写",
            decorator: [
                "idCard",
                { rules: [{ required: true, message: "请填写" }] },
            ],
            type: "input",
            key: "idCard",
            col: 12,
            labelCol: 6,
            wrapperCol: 14,
            display: true,
        },
        {
            label: "打卡形式",
            decorator: [
                "checkType",
                {
                initialValue: "1",
                rules: [{ required: true, message: "请选择打卡形式" }],
                },
            ],
            type: "radio",
            key: "checkType",
            radiolist: [
                {
                    name: "进场",
                    value: "1",
                },
                {
                    name: "出场",
                    value: "2",
                },
            ],
            col: 12,
            labelCol: 6,
            wrapperCol: 14,
            display: true,
        },
        {
            label: "打卡时间",
            placeholder: "",
            decorator: ["atteTime", { rules: [{ required: true, message: "" }] }],
            type: "datePicker",
            key: "atteTime",
            col: 12,
            labelCol: 6,
            wrapperCol: 14,
            display: true,
            format: "YYYY-MM-DD HH:mm:ss",
            showTime: true
        },
        {
            label: "照片",
            decorator: [
                "atteImg",
                { rules: [{ required: true, message: "请上传照片" }] },
            ],
            type: "tsUpload",
            defaultFileList: [],
            listType: 'picture-card',
            key: "atteImg",
            col: 12,
            labelCol: 6,
            wrapperCol: 14,
            display: true,
            class: "Upload",
            showUploadList: true
        },
    ];
    const columns = [
        {
            title: "序号",
            width: 65,
            scopedSlots: { customRender: "index" },
            // customRender: (text, record, index) => `${index + 1}`,
            align: "center",
        },
        {
            title: "姓名",
            dataIndex: "name",
            align: "center",
            width: 120,
            scopedSlots: { customRender: "name" },
        },
        {
            title: "头像",
            dataIndex: "atteImg",
            align: "center",
            width: 100,
            scopedSlots: { customRender: "atteImg" },
        },
        {
            title: "进出方向",
            dataIndex: "checkType",
            align: "center",
            key: 'checkType',
            scopedSlots: { customRender: "checkType" },
        },
        {
            title: "人员类型",
            dataIndex: "personType",
            align: "center",
            scopedSlots: { customRender: "personType" },
        },
        {
            title: "考勤时间",
            dataIndex: "atteTime",
            align: "center",
            customRender: (text) => useRender.renderDate(text)
        },
        {
            title: "上报状态",
            dataIndex: "pullStatus",
            align: "center",
            width: 100,
            scopedSlots: { customRender: "pullStatus" },
        },
        {
            title: "上报时间",
            dataIndex: "pullTime",
            align: "center",
            customRender: (text) => useRender.renderDate(text)
        },
        {
            title: "上传设备序列号",
            align: "center",
            dataIndex: "pullDeviceCode",
            scopedSlots: { customRender: "pullDeviceCode" },
        },
        {
            dataIndex: "jwStaffId",
            align: "center",
            width: 140,
            scopedSlots: { customRender: "editInput", title: 'titleWithTooltip' },
        },
        {
            title: '操作',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' },
        },

    ];
    export default {
        components: {
            formIndex,
            StandardTable
        },
        data() {
            return {
                formData: formData,
                headers: {
                    [TOKEN_KEY]: storage.get(TOKEN_KEY),
                    'Tenant-Id': storage.get('Tenant-Id') ? storage.get('Tenant-Id') : ''
                },
                query:{
                    name: undefined,
                    fileReport: "1",
                    pageSize: 20,
                    type: 2
                },
                data: [],
                pagination: {
                    current: 1,
                    pageSize: 20,
                    total: 0,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
                    showTotal: (total, range) => `共 ${total} 条`
                },
                loading: false,
                columns,
                personForm: {
                    personName: '',
                    personId: '',
                    url: '',
                },
                fileList: [],
                jwBatchApplyAddUrl: JW_BATCH_APPLY_ADD,
                uploadWorkerModal: false,
                managerModal: false,
                arrRoles:['project_leader','ATTENDANCE_CLERK'], // 按钮权限
                personEdit: {},
                editFormData:editFormData,
                editModal: false
            };
        },
        computed: {
            hasTeamSelected() {
                return this.selectedTeamIds.length > 0;
            },
            ...mapGetters('account', ['user']),
            canOperate() {
                return this.user.nowRoleKey.some(role => this.arrRoles.includes(role));
            },
        },
        activated() {
            this.getData();
        },
        methods: {
            // 图片上传限制处理
            beforeUpload(file){
                const fileType = file.type;
                const isExcel = fileType === 'application/vnd.ms-excel' || fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                if (!isExcel) {
                    this.$message.error('只能上传.xls和.xlsx文件');
                    return false
                } 
            },
            getData(){
                this.loading = true
                getBackAtteRecord(this.query).then(res => {
                    if (res.data.code === 0) {
                    this.data = res.data.data.list;
                    this.pagination.total = res.data.data.total;
                    }
                    this.loading = false
                })
            },
            handleChange({ fileList }) {
                if (fileList.length <= 0){
                    this.fileList = fileList;
                    this.uploadWorkerModal = false
                    return
                }
                let info = fileList[0]
                if (info.status === 'done') {
                    if (info.response.code == 0){
                        this.fileList = [];
                        this.uploadWorkerModal = false
                        this.$message.success(info.response.msg)
                    }else {
                        this.fileList = []
                        this.uploadWorkerModal = false
                        this.$message.error(info.response.msg)
                    }
                    this.getData();
                }else {
                    this.uploadWorkerModal = false
                    this.fileList = fileList;
                }
            },
            del(record){
                delBackAtteRecord({"id": record.id}).then(res => {
                    if (res.data.code === 0){
                        this.$message.success('删除成功')
                        this.getData();
                    }else {
                        this.$message.error(res.data.msg)
                    }
                })
                // jwBatchApplyDel({"id": record.id}).then(res => {
                //     if (res.data.code === 0){
                //         this.$message.success(res.data.msg)
                //         this.getData();
                //     }else {
                //         this.$message.error(res.data.msg)
                //     }
                // })
            },

            onPageChange(e){
                console.log(e)
                this.query.pageNumber = e.current;
                this.pagination.current=e.current;
                this.getData();
            },
            handleSearch(){
                this.query.pageNumber = 1;
                this.pagination.current=1;
                this.getData();


            },
            handleUpload() {
                this.uploadWorkerModal = true
            },
            cancelUploadWorkerModal() {
                this.uploadWorkerModal = false
            },
            managerAtt() {
                this.managerModal = true
            },
            managerOk() {
                this.$refs.formindex.combinationSubmit().then(res => {
                    batchApplyAddManager(res).then(el => {
                        if (el.data.code === 0){
                            this.$message.success('操作成功！')
                            this.managerCancel();
                            this.getData();
                        }else {
                            this.$message.error(el.data.msg)
                        }
                    })
                })
            },
            managerCancel() {
                this.$refs.formindex.resetFields()
                this.formData.forEach(item => {
                    if (item.key === 'atteImg') {
                        item.defaultFileList = []
                    }
                })
                this.managerModal = false
            },
            //删除上传附件
            upRemove(file) {
                this.formData.forEach(item => {
                    if (item.key === file.key) {
                        item.defaultFileList = []
                    }
                })
            },
            //上传文件回调
            resourceUpload(obj) {
                this.formData.forEach(item => {
                if (item.key === obj.key) {
                    item.defaultFileList = [obj]
                    if (item.key === 'facePath') {
                    this.getIdCardInfo(obj.formData, 'front')
                    } else if (item.key === 'backPath') {
                    this.getIdCardInfo(obj.formData, 'back')
                    } else if (item.key === 'salaryCardAttachment') {
                    // 上传工资卡-并回显银行卡号、银行名称
                    this.getBankInfo(obj.formData)
                    }
                }
                })
            },
            edit(record) {
                this.editModal = true
                this.personEdit = record
                this.$nextTick(() => {
                    this.$refs.editForm.queryObj({jwStaffId: record.jwStaffId})
                })
            },
            editOk() {
                this.$refs.editForm.combinationSubmit().then(res => {
                    const params = {
                        ...res,
                        id: this.personEdit?.id
                    }
                    updateBackAtteRecord(params).then(el => {
                        if (el.data.code === 0){
                            this.$message.success('修改成功！')
                            this.editCancel();
                            this.getData();
                        }else {
                            this.$message.error(el.data.msg)
                        }
                    })
                })
            },
            editCancel() {
                this.$refs.editForm.resetFields()
                this.editModal = false
            }
        },
    };
</script>
<style lang="less" scoped>
.ant-card {
    border-radius: 10px;
}
.ant-advanced-search-form {
    padding: 20px 24px;
}

.ant-advanced-search-form .ant-form-item {
    display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
    flex: 1;
}

#components-form-demo-advanced-search .ant-form {
    max-width: none;
}

tr{
    background-color: #ffffff;
}

.sTables {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      width: 100%;
      text-align: center;
      cursor: pointer;
    }

    div:nth-child(1) {
      color: #307dfa;
    }

    div:nth-child(2) {
      color: #ff5c77;
    }

    div:nth-child(3) {
      color: #615e83;
    }
  }

</style>
