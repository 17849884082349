<template>
  <div class="relative">
    <div class="flex a-center j-center relative mt-1">
      <a-icon class="absolute pointer" style="left: 0;font-size: 22px;" type="left" @click="back"/>
      <span class="title">忘记密码</span>
    </div>
    <div class="mt-2">
      <a-steps :current="current" labelPlacement="vertical">
        <a-step v-for="item in steps" :key="item.title" :title="item.title" />
      </a-steps>
    </div>
    <div class="mt-2">
      <template>
        <a-form @submit="submit" :form="form">
          <a-form-item v-if="current == 0">
            <a-input
              :maxLength="11"
              size="large"
              placeholder="请输入手机号"
              class=""
              name="mobile"
              v-decorator="[
                'mobile',
                {
                  rules: [
                    { required: true, message: '请输入手机号' },
                    { pattern: /^1[3-9]\d{9}$/, message: '请输入有效的手机号码' }
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="mobile" />
            </a-input>
          </a-form-item>
          <a-form-item v-if="current == 0">
            <a-input
              size="large"
              placeholder="请输入验证码"
              class=""
              name="smsCode"
              v-decorator="[
                'smsCode',
                {
                  rules: [{ required: true, message: '请输入验证码' }],
                },
              ]"
            >
              <a-icon slot="prefix" type="lock" />
              <a-button slot="addonAfter" type="link" @click="getCode" :disabled="countdown > 0"> {{ countdown > 0 ? `${countdown}秒后重新获取` : '获取验证码' }} </a-button>
            </a-input>
          </a-form-item>
          <a-form-item v-if="current == 1">
            <a-input-password
              size="large"
              placeholder="请输入新密码"
              class=""
              type="password"
              name="newPwd"
              v-decorator="[
                'newPwd',
                {
                  rules: [
                    { required: true, message: '请输入新密码', whitespace: true, },
                    { validator: validatePasswordRules },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="lock" />
            </a-input-password>
          </a-form-item>
          <a-form-item v-if="current == 1">
            <a-input-password
              size="large"
              placeholder="请确认新密码"
              class=""
              name="checkNewPwd"
              type="password"
              v-decorator="[
                'checkNewPwd',
                {
                  rules: [{ required: true, message: '请确认新密码', whitespace: true, }, { validator: validateToNextPassword },],
                },
              ]"
            >
              <a-icon slot="prefix" type="lock" />
            </a-input-password>
          </a-form-item>
          <a-form-item>
            <a-button
              :loading="btnLoading"
              style="width: 100%; margin-top: 10px"
              size="large"
              htmlType="submit"
              type="primary"
              >{{ BtnText }}</a-button
            >
          </a-form-item>
        </a-form>
      </template>
      <div class="text-center font-bold" v-if="loginType == 1">如您使用账号登录且未绑定手机号，请联系客服人员</div>
    </div>
      <!-- 图形验证码 -->
    <Verify ref="verify" :captcha-type="'blockPuzzle'" :img-size="{width:'330px',height:'180px'}"
            @success="handleSuccessVerify" />
  </div>
</template>

<script>
import Verify from '../../components/Verifition/Verify.vue';
import { sendSmsCode, checkSmsCode, updatePhonePwd, checkPhone, getTenantIdByPhone } from "@/services/user";
import { isLaowu } from '@/utils/system.js'
import { storage } from "../../utils/storage";
import {logout} from '@/services/user'
export default {
  components: { Verify },
  data() {
    return {
      form: this.$form.createForm(this),
      current: 0,
      btnLoading: false,
      BtnText: '下一步',
      steps: [
        {
          title: '身份验证',
        },
        {
          title: '设置新密码',
        },
      ],
      countdown: 0, // 倒计时时间
      intervalId: null, // 计时器ID
      mobile: '',
      isLaowu: isLaowu(),
    };
  },
  props: {
    loginType: {
      type: Number,
      default: 1
    },
  },
  mounted() {
  },

  methods: {
    back() {
      this.current = 0
      this.BtnText = '下一步'
      this.form.resetFields();
      this.$emit('back')
    },
    submit(e) {
      e.preventDefault();
      if (this.current == 0) {
        this.form.validateFields(["mobile", "smsCode"], (err) => {
          if (!err) {
            this.btnLoading = true
            const mobile = this.form.getFieldValue("mobile")
            const smsCode = this.form.getFieldValue("smsCode")
            checkSmsCode({phone: mobile, smsCode}).then(res => {
              this.btnLoading = false
              if (res.data.code == 0) {
                if (res.data.data) {
                  this.current++;
                  this.mobile = mobile
                  this.BtnText = '确定'
                }
              } else {
                this.$message.error(res.data.msg)
              }
            })
          }
        });
      } else {
        this.form.validateFields(["newPwd", "checkNewPwd"], (err) => {
          if (!err) {
            this.btnLoading = true
            const system = this.isLaowu ? 0 : 1
            checkPhone({system, phone: this.mobile}).then(res => {
              if (res.data.code == 0) {
                if (res.data.data) {
                  this.updatePhonePwd()
                } else {
                  this.$confirm({
                    title: '检测到您的手机号与多个账号关联，新的密码将会应用到所有绑定的账号上。请问是否确定修改？',
                    onOk() {
                      this.updatePhonePwd()
                    },
                    cancel() {
                      this.btnLoading = false
                    }
                  })
                }
              } else {
                this.$message.error(res.data.msg)
              }
            })
          }
        });
      }
    },
    updatePhonePwd() {
      const system = this.isLaowu ? 0 : 1
      const newPwd = this.form.getFieldValue("newPwd")
      const checkNewPwd = this.form.getFieldValue("checkNewPwd")
      updatePhonePwd({phone: this.mobile, newPwd, checkNewPwd, system}).then(res => {
        this.btnLoading = false
        if (res.data.code == 0) {
          this.$message.success(res.data.data)
          logout()
          this.back()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    validateToNextPassword(rule, value, callback) {
      if (value && value !== this.form.getFieldValue('newPwd')) {
        callback('两次输入的密码不一致！');
      } else {
        callback();
      }
    },
    validatePasswordRules(rule, value, callback) {
      const lengthPattern = /^.{8,}$/;
      const complexityPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/;
      const sequentialPattern = /0123|1234|2345|3456|4567|5678|6789|7890|8901|9012/;
      const repeatedPattern = /(\d)\1\1/;
      
      if (!lengthPattern.test(value)) {
        callback('密码长度至少8位');
      } else if (!complexityPattern.test(value)) {
        callback('密码必须包含大小写字母、数字和符号');
      } else if (sequentialPattern.test(value)) {
        callback('密码不能包含四位连续的数字');
      } else if (repeatedPattern.test(value)) {
        callback('密码不能包含三个连续相同的数字');
      } else {
        callback();
      }
    },
    async handleSuccessVerify(e) {
      await this.getTenantId()
      const mobile = this.form.getFieldValue("mobile")
      sendSmsCode({mobile, scene: 5}).then(res => {
        if (res.data.code == 0) {
          if (res.data.data) {
            this.$message.success('发送成功')
          }
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    async getTenantId() {
      const phone = this.form.getFieldValue("mobile");
      const params = {
        phone,
        sysType: 'jan1'
      }
      const res = await getTenantIdByPhone(params)
      if (res.data.code == 0) {
        if (res.data.data) {
          if (res.data.data.length === 1) {
            this.tenantId = res.data.data[0].id
            storage.set('Tenant-Id', this.tenantId)
            return true
          } else {
            this.tenantIdList = res.data.data
            this.tenantVisible = true
            return false
          }
        } else {
          this.logging = false;
          this.$message.error('请求的租户标识未传递，请进行排查')
        }
      } else {
        this.logging = false;
        this.$message.error(res.data.msg)
      }
    },
    getCode() {
      this.form.validateFields(["mobile"], (err) => {
        if (!err) {
          if (this.intervalId) {
            clearInterval(this.intervalId)
          }
          this.countdown = 60;
          // 设置定时器更新倒计时
          this.intervalId = setInterval(() => {
            if (this.countdown > 0) {
              this.countdown -= 1;
            } else {
              clearInterval(this.intervalId);
            }
          }, 1000);
          this.$refs.verify.show()
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 24px;
  color: @title-color;
  font-family: "Myriad Pro", "Helvetica Neue", Arial, Helvetica,
    sans-serif;
  font-weight: 600;
}
.verifybox {
  position: absolute !important;
  top: 45%;
  left: 50%;
  z-index: 1001;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #e4e7eb;
  border-radius: 5px;
  box-shadow: 0 0 10px rgb(0 0 0 / 30%);
  transform: translate(-50%, -25%);
  padding: 8px;
  margin-top: 5px;
}
</style>