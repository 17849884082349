<template>
  <common-layout
    :class="isLaowu ? 'laowu-login-container' : 'login-container'"
  >
    <img v-if="isSafe" alt="logo" class="logo2" src="@/assets/img/logo2.png" />
    <!-- 劳务登录页 -->
    <div v-if="isLaowu">
      <!-- 劳务管理登录页UI -->
      <template>
        <div class="login-new-box">
          <div class="laowu-box">
            <div class="top">
            <div class="header">
              <span class="title2">欢迎使用</span>
              <br />
              <span class="title2">建工一号 ● 劳务管家</span>
            </div>
          </div>
          <template v-if="!isBindPhone">
            <div v-if="!isForgetPwd" class="login">
              <a-form @submit="onSubmit" :form="form">
                <a-tabs
                  size="large"
                  :tabBarStyle="{ textAlign: 'center' }"
                  style="padding: 0 2px"
                  @change="changeLogin"
                  class="new-tabs"
                >
                  <a-tab-pane tab="手机号登录" key="2">
                    <a-alert
                      type="error"
                      :after-close="handleClose"
                      :closable="true"
                      v-if="error"
                      :message="error"
                      showIcon
                      style="margin-bottom: 24px"
                    />
                    <a-form-item>
                      <a-input
                        :maxLength="11"
                        size="large"
                        placeholder="请输入手机号"
                        class=""
                        name="mobile"
                        v-decorator="[
                          'mobile',
                          {
                            rules: [
                              { required: true, message: '请输入手机号' },
                              { pattern: /^1[3-9]\d{9}$/, message: '请输入有效的手机号码' }
                            ],
                          },
                        ]"
                      >
                        <a-icon slot="prefix" type="mobile" />
                      </a-input>
                    </a-form-item>
                    <a-form-item v-if="isPwdLogin">
                      <a-input-password
                        :maxLength="16"
                        size="large"
                        placeholder="请输入密码"
                        autocomplete="autocomplete"
                        type="password"
                        v-decorator="[
                          'pwd',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入密码',
                                whitespace: true,
                              },
                            ],
                          },
                        ]"
                      >
                        <a-icon slot="prefix" type="lock" />
                      </a-input-password>
                    </a-form-item>
                    <a-form-item v-else>
                      <a-input
                        size="large"
                        placeholder="请输入验证码"
                        class=""
                        name="smsCode"
                        v-decorator="[
                          'smsCode',
                          {
                            rules: [{ required: true, message: '请输入验证码' }],
                          },
                        ]"
                      >
                        <a-icon slot="prefix" type="lock" />
                        <a-button slot="addonAfter" type="link" @click="getCode" :disabled="countdown > 0"> {{ countdown > 0 ? `${countdown}秒后重新获取` : '获取验证码' }} </a-button>
                      </a-input>
                    </a-form-item>
                  </a-tab-pane>
                  <a-tab-pane tab="账户密码登录" key="1">
                    <a-alert
                      type="error"
                      :after-close="handleClose"
                      :closable="true"
                      v-if="error"
                      :message="error"
                      showIcon
                      style="margin-bottom: 24px"
                    />
                    <a-form-item>
                      <a-input
                        :maxLength="16"
                        autocomplete="autocomplete"
                        size="large"
                        placeholder="请输入登录账号"
                        v-decorator="[
                          'name',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入账户名',
                                whitespace: true,
                              },
                            ],
                          },
                        ]"
                      >
                        <a-icon slot="prefix" type="user" />
                      </a-input>
                    </a-form-item>
                    <a-form-item>
                      <a-input-password
                        :maxLength="16"
                        size="large"
                        placeholder="请输入密码"
                        autocomplete="autocomplete"
                        type="password"
                        v-decorator="[
                          'password',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入密码',
                                whitespace: true,
                              },
                            ],
                          },
                        ]"
                      >
                        <a-icon slot="prefix" type="lock" />
                      </a-input-password>
                    </a-form-item>
                  </a-tab-pane>
                </a-tabs>
                <div style="min-height: 28px;">
                  <span class="pointer sys-blue" v-if="loginType == 2" @click="isPwdLogin = !isPwdLogin">{{btnText}}</span>
                  <span class="pointer sys-blue" v-if="isPwdLogin || this.loginType == 1" style="float: right;" @click="isForgetPwd = !isForgetPwd">忘记密码</span>
                </div>
                <a-form-item>
                  <a-button
                    :loading="logging"
                    style="width: 100%; margin-top: 24px"
                    size="large"
                    htmlType="submit"
                    type="primary"
                    >登录</a-button
                  >
                </a-form-item>
              </a-form>
              <!-- 图形验证码 -->
              <Verify ref="verify" :captcha-type="'blockPuzzle'" :img-size="{width:'330px',height:'180px'}"
                      @success="handleSuccessVerify" />
            </div>
            <forget-password v-else class="login" @back="isForgetPwd = !isForgetPwd" :loginType="loginType"></forget-password>
          </template>
          <bind-phone class="login" :account="account" v-else></bind-phone>
          </div>

        </div>
      </template>
    </div>
    <!-- 机施安全/建安易登录页 -->
    <div v-else>
      <div class="login-box">
        <div class="top">
          <div class="header">
            <span class="title2">Hi,欢迎来到</span>
            <br />
            <span class="title2">{{ systemFullname }}</span>
          </div>
        </div>
        <template v-if="!isBindPhone">
          <div v-if="!isForgetPwd" class="login">
            <a-form @submit="onSubmit" :form="form">
              <a-alert
                  type="error"
                  :after-close="handleClose"
                  :closable="true"
                  v-if="error"
                  :message="error"
                  showIcon
                  style="margin-bottom: 24px"
                />
              <!-- <a-form-item v-if="isJianAnYi || isShiZheng">
                <a-input
                  size="large"
                  placeholder="租户"
                  class="test"
                  name="name"
                  v-decorator="[
                    'name',
                    { rules: [{ required: true, message: '请输入租户' }] },
                  ]"
                >
                  <a-icon slot="prefix" type="user" />
                </a-input>
              </a-form-item> -->
              <a-form-item>
                <a-input
                  :maxLength="11"
                  size="large"
                  placeholder="请输入手机号"
                  class="test"
                  name="mobile"
                  v-decorator="[
                    'mobile',
                    { rules: [{ required: true, message: '请输入手机号' }] },
                  ]"
                >
                  <a-icon slot="prefix" type="mobile" />
                </a-input>
              </a-form-item>
              <a-form-item v-if="isPwdLogin">
                <a-input-password
                  :maxLength="16"
                  size="large"
                  placeholder="请输入密码"
                  class="test"
                  autocomplete="autocomplete"
                  type="password"
                  v-decorator="[
                    'pwd',
                    {
                      rules: [
                        {
                          required: true,
                          message: '请输入密码',
                          whitespace: true,
                        },
                      ],
                    },
                  ]"
                >
                  <a-icon slot="prefix" type="lock" />
                </a-input-password>
              </a-form-item>
              <a-form-item v-else>
                <a-input
                  size="large"
                  placeholder="请输入验证码"
                  class=""
                  name="smsCode"
                  v-decorator="[
                    'smsCode',
                    {
                      rules: [{ required: true, message: '请输入验证码' }],
                    },
                  ]"
                >
                  <a-icon slot="prefix" type="lock" />
                  <a-button slot="addonAfter" type="link" @click="getCode" :disabled="countdown > 0"> {{ countdown > 0 ? `${countdown}秒后重新获取` : '获取验证码' }} </a-button>
                </a-input>
              </a-form-item>
              <div style="min-height: 28px;">
                <span class="pointer sys-blue" v-if="loginType == 2" @click="isPwdLogin = !isPwdLogin">{{btnText}}</span>
                <span class="pointer sys-blue" v-if="isPwdLogin || this.loginType == 1" style="float: right;" @click="isForgetPwd = !isForgetPwd">忘记密码</span>
              </div>
              <a-form-item>
                <a-button
                  :loading="logging"
                  style="width: 100%; margin-top: 24px"
                  size="large"
                  htmlType="submit"
                  type="primary"
                  >登录</a-button
                >
              </a-form-item>
              <!-- <div>
              其他登录方式
              <a-icon class="icon" type="alipay-circle" />
              <a-icon class="icon" type="taobao-circle" />
              <a-icon class="icon" type="weibo-circle" />
              <router-link style="float: right" to="/dashboard/workplace" >注册账户</router-link>
            </div> -->
            </a-form>
             <!-- 图形验证码 -->
            <Verify ref="verify" :captcha-type="'blockPuzzle'" :img-size="{width:'330px',height:'180px'}"
                      @success="handleSuccessVerify" />
          </div>
          <forget-password v-else class="login" @back="isForgetPwd = !isForgetPwd" :loginType="loginType"></forget-password>
        </template>
        <bind-phone class="login" :account="account" v-else></bind-phone>
      </div>
    </div>
    <choice-type
      :visible.sync="choice.visible"
      @doCloseChoice="doCloseChoice"
      @doSure="(e) => gotoHome(e.type)"
    >
    </choice-type>
    <a-modal v-model="tenantVisible" title="请选择租户" @ok="handleOk" @cancel="handleCancel">
      <a-radio-group v-model="tenantId">
        <div class="flex flex-column">
          <a-radio class="mb-1" v-for="(item, index) in tenantIdList" :key="index" :value="item.id">
            {{item.name}}
          </a-radio>
        </div>
      </a-radio-group>
    </a-modal>
  </common-layout>
</template>

<script>
import CommonLayout from "@/layouts/CommonLayout";
import { login, getRoutesConfig, loginPhone, sendSmsCode, getTenantIdByName, getTenantIdByPhone } from "@/services/user";
import { loadRoutes, afterLoginPhone } from "@/utils/routerUtil";
import { Decrypt, Encrypt } from "@/config/crypto-js";
import { mapMutations } from "vuex";
import ChoiceType from "@/pages/login/choicetype";
import { checkAuthorization } from '@/utils/request'
import { storage } from "../../utils/storage";
import { SYSTEM_TYPE_ENUM } from '@/enums/system.js'
import ForgetPassword from './forgetPassword.vue';
import Verify from '../../components/Verifition/Verify.vue';
import BindPhone from './bindPhone.vue';
import { isJianAnYi, isLaowu, isSafe, isShiZheng } from "../../utils/system";
const systemMap = {
  [SYSTEM_TYPE_ENUM.LAOWU]: 0,
  [SYSTEM_TYPE_ENUM.SAFE]: 1,
  [SYSTEM_TYPE_ENUM.JIANANYI]: null
}
export default {
  name: "Login",
  components: { CommonLayout, ChoiceType, ForgetPassword, Verify, BindPhone },
  data() {
    return {
      choice: {
        visible: false,
      },
      logging: false,
      error: "",
      form: this.$form.createForm(this),
      loginType: 2,
      visibleAlert: false,
      SYSTEM_TYPE_ENUM,
      btnText: '验证码登录',
      isPwdLogin: true,
      isForgetPwd: false,
      countdown: 0, // 倒计时时间
      intervalId: null, // 计时器ID
      isBindPhone: false, // 是否绑定手机号
      account: '',//登录的账号
      tenantId: 1,//租户id
      isJianAnYi: isJianAnYi(),
      isShiZheng: isShiZheng(),
      isLaowu: isLaowu(),
      isSafe: isSafe(),
      tenantVisible: false, // 租户选择弹框
      tenantIdList: [], // 租户id列表
    };
  },
  watch: {
    'choice.visible': {
      immediate: true,
      handler(newQuery,oldQuery) {
        if (newQuery == true) { // 修复项目版--手机号登录账户锁定提示未隐藏
          this.error = ""
        }
      }
    },
    isPwdLogin: {
      handler(val) {
        if (val) {
          this.btnText = '验证码登录'
        } else {
          this.btnText = '密码登录'
        }
      }
    }
  },
  computed: {
    systemFullname() {
      return this.$store.getters['setting/systemFullname'];
    },
    systemType() {
      return this.$store.getters['setting/systemType'];
    },
    realmType() {
      return this.$store.state.setting.realmType;
    },
  },
  mounted() {
    if (checkAuthorization()) {
      const user = storage.get(process.env.VUE_APP_USER_KEY)
      this.gotoHome(user.loginType)
    }
  },
  methods: {
    ...mapMutations("account", ["setUser", "setPermissions", "setRoles"]),
    getCode() {
      this.form.validateFields(["mobile"], async (err) => {
        if (!err) {
          // 建安易/市政集团，获取验证码前，先获取租户ID
          if (this.isJianAnYi || this.isShiZheng) {
            await this.getTenantId()
          }
          if (this.intervalId) {
            clearInterval(this.intervalId)
          }
          this.countdown = 60;
          // 设置定时器更新倒计时
          this.intervalId = setInterval(() => {
            if (this.countdown > 0) {
              this.countdown -= 1;
            } else {
              clearInterval(this.intervalId);
            }
          }, 1000);
          this.$refs.verify.show()
        }
      });
    },
    handleSuccessVerify(e) {
      const mobile = this.form.getFieldValue("mobile")
      sendSmsCode({mobile, scene: 5}).then(res => {
        if (res.data.code == 0) {
          if (res.data.data) {
            this.$message.success('发送成功')
          }
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    changeLogin(tabKey) {
      this.loginType = tabKey;
    },
    onSubmit(e) {
      e.preventDefault();
      let validateFields = [];
      const loginType = this.loginType;
      if (loginType == 1) {
        validateFields[0] = "name";
        validateFields[1] = "password";
      } else {
        validateFields[0] = "mobile";
        validateFields[1] = this.isPwdLogin ? "pwd" : 'smsCode'
      }
      this.form.validateFields(validateFields, async (err) => {
        if (!err) {
          this.logging = true;
          const system = systemMap[this.systemType]
          // 账号密码登录
          if (loginType == 1) {
            // 非建安易的系统，不需要获取租户id，直接设死
            storage.set('Tenant-Id', '1')
            const name = this.form.getFieldValue("name");
            const password = Encrypt(this.form.getFieldValue("password"));
            this.account = name // 暂时把账号保存起来，后面可能需要重新绑定手机号
            // TODO: 后面得改造
            login(name, password, system).then(this.loginCallback);
          }
          // 手机号密码登录
          else {
            // 非建安易/市政的系统，不需要获取租户id，直接设死
            if (!this.isJianAnYi && !this.isShiZheng) {
              storage.set('Tenant-Id', '1')
              this.questLogin()
            } else {
              let tenantRes = await this.getTenantId()
              if (tenantRes) {
                this.questLogin()
              }
            }
            
          }
        }
      });
    },

    questLogin() {
      const phone = this.form.getFieldValue("mobile");
      // TODO: 后面得改造
      let params = { phone: phone, system: systemMap[this.systemType] }
      if (this.isPwdLogin) {
        params.pwd = Encrypt(this.form.getFieldValue("pwd")); // 密码加密
        params.isSmsLogin = false
      } else {
        params.smsCode = this.form.getFieldValue("smsCode")
        params.isSmsLogin = true
      }
      loginPhone(params).then(this.loginCallback);
    },

    async getTenantId() {
      const phone = this.form.getFieldValue("mobile");
      const params = {
        phone,
        sysType: 'jan1'
      }
      const res = await getTenantIdByPhone(params)
      if (res.data.code == 0) {
        if (res.data.data) {
          if (res.data.data.length === 1) {
            this.tenantId = res.data.data[0].id
            storage.set('Tenant-Id', this.tenantId)
            return true
          } else {
            this.tenantIdList = res.data.data
            this.tenantVisible = true
            return false
          }
        } else {
          this.logging = false;
          this.$message.error('请求的租户标识未传递，请进行排查')
        }
      } else {
        this.logging = false;
        this.$message.error(res.data.msg)
      }
    },

    handleOk(e) {
      storage.set('Tenant-Id', this.tenantId)
      this.questLogin()
      this.tenantVisible = false;
    },

    handleCancel() {
      // 清空 storage 缓存
      localStorage.clear()
      this.logging = false;
      this.tenantVisible = false;
    },

    /**
    * @description 跳转首页
    * @param {Number} loginType 登录类型 0-项目 1-公司
    */
    gotoHome(loginType) {
      const projectHome = 'staff_lib_home/homePage1'
      const companyHome = 'staff_lib_home/homePage'
      const homeMap = {
        0: projectHome,
        1: companyHome
      }
      // TODO: 【市政】有域名之后，删掉 .then 代码
      this.$router.push(homeMap[loginType]).then(() => {
        window.location.reload();
      });
    },
    async loginCallback(res) {
      if (res.data.code === -1) {
        this.$message.error(res.data.msg || '登录失败')
        this.logging = false;
        return
      }
      // 判断租户id和登录接口返回的租户id是否一致或者租户id==1，或者租户id==0
      if (res.data.data.tenantId == this.tenantId || res.data.data.tenantId == 0) {
        let isManyProject =
        res.data.data.laoWuUserList &&
        res.data.data.laoWuUserList.length > 0
          ? res.data.data.laoWuUserList.length
          : 0;
        let officeRoles =
          res.data.data.officeLoginData &&
          res.data.data.officeLoginData.labourRoleKeyList &&
          res.data.data.officeLoginData.labourRoleKeyList.length > 0
            ? res.data.data.officeLoginData.labourRoleKeyList.length
            : 0;
        let isPty =
          officeRoles == 1 &&
          res.data.data.officeLoginData.labourRoleKeyList[0] ==
            "OFFICE_ORDINARY_STAFF"
            ? true
            : false;
        if (isManyProject > 1 || (isManyProject == 1 && !isPty)) {
          // 需选择项目/公司，暂不获取路由
          await afterLoginPhone({ res, pageThis: this, noMessage: true });
          //需要绑定手机号
          if (this.loginType == 1 && res.data.data.needBindPhone) {
            this.$confirm({
              title: '为了进一步保障您的账号安全，平台已对系统安全进行了升级。请您为该账号绑定手机号，成功绑定后可使用手机号作为登录方式，感谢您的理解与支持！',
              onOk() {}
            })
            this.isBindPhone = res.data.data.needBindPhone
            return
          }
          this.choice.visible = true;
        } else {
          // 只有公司，无需选择，直接获取路由
          await afterLoginPhone({ res, pageThis: this, noMessage: true, loginDone: true });
          //需要绑定手机号
          if (this.loginType == 1 && res.data.data.needBindPhone) {
            this.$confirm({
              title: '为了进一步保障您的账号安全，平台已对系统安全进行了升级。请您为该账号绑定手机号，成功绑定后可使用手机号作为登录方式，感谢您的理解与支持！',
              onOk() {}
            })
            this.isBindPhone = res.data.data.needBindPhone
            return
          }
          this.gotoHome(res.data.data.loginType)
        }
      } else {
        this.$message.error('该租户下找不到对应账号！')
      }
    },
    doCloseChoice() {
      localStorage.clear()
      this.logging = false;
      this.choice.visible = false;
    },
    handleClose() {
      this.error = false
    },
  },
};
</script>

<style lang="less" scoped>
.login-container {
  width: 100%;
  height: 100vh;
  background: url("../../assets/img/login.jpg");
  background-size: 100%;
  position: relative;
  .logo2 {
    position: absolute;
    right: 30px;
    top: 20px;
    height: 50px;
    margin-right: 16px;
  }
}
.laowu-login-container {
   width: 100%;
  height: 100vh;
  background: url("../../assets/img/new-laowu-login.png") ;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
/deep/.test {
  input {
    background: rgba(255, 255, 255, 0) !important;
    border-color: rgba(255, 255, 255, 0.2) !important;
  }
}
.common-layout {
  .login-box {
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0.6)
    );
    border-radius: 10%;
    padding: 60px 40px 20px 40px;
    box-sizing: border-box;
    // 定位居中
    position: absolute;
    transform: translate(-50%,-50%);
    left: 20%;
    top: 37%;
    z-index: 99;
    .top {
    text-align: left;
    .header {
      height: 44px;
      line-height: 44px;
      a {
        text-decoration: none;
      }
      
      .logo {
        height: 44px;
        vertical-align: top;
        margin-right: 16px;
      }
      .title2 {
        font-size: 30px;
        color: @title-color;
        font-family: "Myriad Pro", "Helvetica Neue", Arial, Helvetica,
          sans-serif;
        font-weight: 600;
        position: relative;
        top: -20px;
      }
      .title {
        font-size: 33px;
        color: @title-color;
        font-family: "Myriad Pro", "Helvetica Neue", Arial, Helvetica,
          sans-serif;
        font-weight: 600;
        position: relative;
        top: 2px;
      }
    }
    .desc {
      font-size: 14px;
      color: @text-color-second;
      margin-top: 12px;
      margin-bottom: 40px;
    }
  }
  .login {
    width: 368px;
    margin: 0 auto;
    padding-top: 60px;
    @media screen and (max-width: 576px) {
      width: 95%;
    }
    @media screen and (max-width: 320px) {
      .captcha-button {
        font-size: 14px;
      }
    }
    /deep/ .new-tabs .ant-tabs-bar {
      border-bottom: none
    }

    .icon {
      font-size: 24px;
      color: @text-color-second;
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: @primary-color;
      }
    }
    }
  }
  .login-new-box:before {
  content: "";
   position: absolute;
   top: 0;
   left: 0;
   z-index: 88;
   width: 100%;
   height: 80%;
   background: url("../../assets/img/login-card.png");
   background-size: 100% ;
   background-repeat: no-repeat;
  }
  .login-new-box {
    width: 25vw;
    height: 66vh;
    box-shadow: 0px 0px 29px 0px
		rgba(48, 125, 250, 0.2);
    border-radius: 50px;
    position: absolute;
    right: 9%;
    top: 15%;
    @media screen and (max-width: 1669px) {
      // 适配笔记本显示-125%
      zoom: 0.95 !important;
      width: 32vw;
      right: 6%;
      height: 72vh;
        .top {
        text-align: center !important;
        zoom: 0.95 !important;
        }
        .title2 {
        // font-size: 20px !important;
        zoom: 0.95 !important;
        }
      .login {
        // font-size: 15px !important;
        zoom: 0.95 !important;
      }
    }
    @media screen and (max-width: 1475px) {
      background-color: rgba(255, 255, 255, 1);
    }
    @media screen and (max-width: 1280px) {
      // 适配笔记本显示-150%
      zoom: 0.84!important;
      width: 32vw;
      right: 8%;
      height: 72vh;
        .top {
        text-align: center !important;
        zoom: 0.84 !important;
        }
        .title2 {
        // font-size: 20px !important;
        zoom: 0.84 !important;
        }
      .login {
        // font-size: 15px !important;
        zoom: 0.84 !important;
      }
    }

    .laowu-box {
    // 定位居中
    position: absolute;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
    z-index: 99;
    .top {
    text-align: left;
    .header {
      height: 44px;
      line-height: 44px;
      a {
        text-decoration: none;
      }
      .logo2 {
        position: absolute;
        right: 30px;
        top: 20px;
        height: 50px;
        margin-right: 16px;
      }
      .logo {
        height: 44px;
        vertical-align: top;
        margin-right: 16px;
      }
      .title2 {
        font-size: 30px;
        color: @title-color;
        font-family: "Myriad Pro", "Helvetica Neue", Arial, Helvetica,
          sans-serif;
        font-weight: 600;
        position: relative;
        top: -20px;
      }
      .title {
        font-size: 33px;
        color: @title-color;
        font-family: "Myriad Pro", "Helvetica Neue", Arial, Helvetica,
          sans-serif;
        font-weight: 600;
        position: relative;
        top: 2px;
      }
    }
    .desc {
      font-size: 14px;
      color: @text-color-second;
      margin-top: 12px;
      margin-bottom: 40px;
    }
  }
  .login {
    width: 368px;
    margin: 0 auto;
    padding-top: 40px;
    @media screen and (max-width: 576px) {
      width: 95%;
    }
    @media screen and (max-width: 320px) {
      .captcha-button {
        font-size: 14px;
      }
    }
    // 下划线
    /deep/ .new-tabs  .ant-tabs-nav .ant-tabs-tab::before {
      bottom: 10px;
      top: unset;
      left: 14%;
      width: 74%;
      height: 2px;
    }
    // 下划线
    /deep/ .new-tabs .ant-tabs-nav .ant-tabs-tab-active.ant-tabs-tab::before {
      background-color: #4a86e8;
      border-top: none;
      border-radius: unset;
    }
    // 默认组件下划线去除
    /deep/ .new-tabs .ant-tabs-ink-bar {
      background-color: unset;
    }
    /deep/ .new-tabs .ant-tabs-bar {
      border-bottom: none
    }
    .icon {
      font-size: 24px;
      color: @text-color-second;
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: @primary-color;
      }
    }
    }
  }
  }


}
.radioStyle {
  display: block;
  height: 30px;
  line-height: 30px;
}
</style>
