<template>

    <div >
        <div id="components-form-demo-advanced-search">
            <a-form class="ant-advanced-search-form" :form="form" >
                <a-row :gutter="24">
                    <a-col :span="5" >
                        <a-form-item :label="`月份`">
                            <a-input v-model="option.month" placeholder="请输入月份,格式：202105" />
                        </a-form-item>
                    </a-col>

                    <a-col :span="6">
                        <a-form-item :label="`所属单位`">
                            <a-select
                                    label-in-value
                                    @change="handleChangeOffice"
                                    placeholder="请选择所属单位"
                            >
                                <a-select-option v-for="(office, index) in officeList" :key="index" :value="office.id">
                                    {{office.name}}
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row>
                    <a-col :span="24" :style="{ textAlign: 'right' }">
                        <a-button type="primary" icon="search" :loading="loading" @click="handleSearch">
                            查询
                        </a-button>
                    </a-col>
                </a-row>

            </a-form>
        </div>
        <a-table
                :columns="columns"
                :data-source="data"
                :pagination="pagination"
                :loading="loading"
                rowKey="index"
        >
            <template style="background: #ffffff;" slot="name" slot-scope="text"> {{text}}

            </template>

            <template slot="attendSheetFile" slot-scope="attendSheetFile, record">
                <a v-if="attendSheetFile != ''" :href="attendSheetFile"> {{attendSheetFile == "" ? "" : "file_" + record.id + "_" + record.month + ".xls(点击下载)" }} </a>
                <a-upload
                        name="file"
                        :multiple="true"
                        :action="uploadAttendSheetFileUrl+ '?id=' + record.id"
                        :headers="headers"
                        @change="attendSheetFileChange"
                        :showUploadList="false"
                >
                    <a-button> <a-icon type="upload" /> {{attendSheetFile==''? '上传' : '重新上传'}} </a-button>
                </a-upload>

            </template>

            <template slot="index" slot-scope="text">
                {{text}}
            </template>

            <template slot="confirmImg" slot-scope="confirmImg, record">
                <img v-if="confirmImg!=''" :src="confirmImg" style="width: 60px;height: 60px" @click="showImg(confirmImg)">
                <a-modal :visible="previewVisible" :footer="null" @cancel="closeImgShow">
                    <img alt="example" style="width: 100%;" :src="previewImage" />
                </a-modal>
                <a-upload
                        name="file"
                        :multiple="true"
                        :action="uploadAttendSheetImg + '?id=' + record.id"
                        :headers="headers"
                        @change="confirmImgChange"
                        :showUploadList="false"
                >
                    <a-button> <a-icon type="upload" /> {{confirmImg==''? '上传' : '重新上传'}} </a-button>
                </a-upload>

            </template>

            <template slot="operation" slot-scope="text, record">
                <a-popconfirm
                        v-if = "record.edit"
                        title="确定提交?"
                        @confirm="() => submitData(record)"
                >
                    <a-button type="primary" :loading="loading">
                        提交
                    </a-button>
                </a-popconfirm>

                <a-button type="primary" style="margin-left: 8px" :loading="loading" @click="exportFile(record)">
                    导出
                </a-button>
            </template>
        </a-table>
    </div>

</template>
<script>
    import axios from 'axios';
    import {getPayrollList, submitPayrollData, exportFile, getOfficeListByProjectId} from '@/services/payroll'
    import {UPLOAD_ATTEND_SHEET_FILE, UPLOAD_ATTEND_SHEET_IMG} from '@/services/api'
    import { storage } from '@/utils/storage'
    import { TOKEN_KEY } from '@/utils/request.js'

    const queryData = params => {
        console.log(params)
        return getPayrollList(params);
    };
    const columns = [
        {
            title: '序号',
            dataIndex: 'index',
            scopedSlots: { customRender: 'index' },
        },
        {
            title: '班组',
            dataIndex: 'teamName',
        },
        {
            title: '出勤人数',
            dataIndex: 'attendNum',
        },
        {
            title: '应发金额',
            dataIndex: 'payableMoney',
        },
        {
            title: '实发金额',
            dataIndex: 'actualMoney',
        },
        {
            title: '工资计划单(Excel表)',
            dataIndex: 'attendSheetFile',
            scopedSlots: { customRender: 'attendSheetFile' },
        },
        {
            title: '工资单(拍照)',
            dataIndex: 'confirmImg',
            scopedSlots: { customRender: 'confirmImg' },
        },
        {
            title: '操作',
            dataIndex: 'operation',
            scopedSlots: { customRender: 'operation' },
        },
    ];

    export default {
        data() {
            return {
                data: [],
                pagination: {},
                loading: false,
                columns,
                option: {
                    officeId: "",
                    month: "",
                },

                uploadAttendSheetFileUrl: UPLOAD_ATTEND_SHEET_FILE,
                uploadAttendSheetImg: UPLOAD_ATTEND_SHEET_IMG,
                headers: {
                    [TOKEN_KEY]: storage.get(TOKEN_KEY),
                    'Tenant-Id': storage.get('Tenant-Id') ? storage.get('Tenant-Id') : ''
                },

                previewImage: '',
                previewVisible: false,
                officeList: [],

                expand: false,
                form: this.$form.createForm(this, { name: 'advanced_search' }),
            };
        },
        mounted() {
            // this.fetch(this.option);
            this.getOfficeListByProjectId();
        },
        methods: {
            handleTableChange(pagination, filters, sorter) {
                console.log(pagination);
                const pager = { ...this.pagination };
                pager.current = pagination.current;
                this.pagination = pager;
                this.fetch(this.option);
            },



            fetch(params = {}) {
                this.loading = true;
                queryData(params).then(({ data }) => {
                    console.log(data)
                    this.data = data.data;

                    this.loading = false;
                });
            },

            handleSearch(e) {
                this.fetch(this.option);
            },
            handleChangeOffice(val){
                this.option.officeId = val.key;
            },
            confirmImgChange(info){
                if (info.file.status === 'done') {
                    const stat = info.file.response.code
                    if (stat === 0){
                        const data = info.file.response.data;
                        const id = data.id;
                        const confirmImg = data.confirmImg;
                        this.data.forEach((item,index)=>{
                            if(item.id == id ){
                                this.data[index].confirmImg = confirmImg //修改
                                this.data[index].edit = true;
                            }
                        })
                    }
                }
            },
            attendSheetFileChange(info){
                if (info.file.status === 'done') {
                    const stat = info.file.response.code
                    if (stat === 0){
                        const data = info.file.response.data;
                        const id = data.id;
                        const attendSheetFile = data.attendSheetFile;
                        const actualMoney = data.actualMoney;
                        const payableMoney = data.payableMoney;
                        this.data.forEach((item,index)=>{
                            if(item.id == id ){
                                this.data[index].attendSheetFile = attendSheetFile //修改
                                this.data[index].actualMoney = actualMoney //修改
                                this.data[index].payableMoney = payableMoney //修改
                                this.data[index].edit = true;
                            }
                        })
                    }
                }
            },
            closeImgShow() {
                this.previewVisible = false;
            },
            showImg(imgPath){
                this.previewVisible = true;
                this.previewImage = imgPath;
            },
            submitData(data){
                this.loading = true;
                submitPayrollData(data).then((res) => {
                    console.log(res)
                    this.$message.info(res.data.msg);
                    this.data.forEach((item,index)=>{
                        if(item.id == data.id ){
                            this.data[index].edit = false;
                        }
                    })
                    this.loading = false;
                })
            },
            exportFile(record){
                exportFile({id: record.id}).then((res) => {
                    console.log(res)
                    if(res.data.code != 0){
                        this.$message.warning(res.data.msg)
                    } else  {
                        console.log(res)
                        let fileName = record.teamName.replace(/\s*/g,"") + '_工资计划.zip'


                        // let tableData = res.data
                        // let blob = new Blob([tableData], {type: 'application/vnd.ms-excel'})//创建Blob对象,Blob(blobParts[, options])blobParts： 数组类型， 数组中的每一项连接起来构成Blob对象的数据

                        let downloadElement = document.createElement('a')//创建dom

                        // let href = window.URL.createObjectURL(blob) //创建下载链接
                        let href = window.URL.createObjectURL(res.data) //创建下载链接

                        downloadElement.href = href
                        downloadElement.download = fileName //下载后文件名
                        document.body.appendChild(downloadElement)//添加创建的节点
                        downloadElement.click() //点击下载
                        document.body.removeChild(downloadElement) //下载完成移除元素
                        window.URL.revokeObjectURL(href) //释放掉blob对象
                        this.isLoading = false
                    }
                }).catch((err) => {
                    setTimeout(() => {
                        this.isLoading = false
                    }, 500);
                })
            },
            getOfficeListByProjectId(){
                getOfficeListByProjectId().then((res) => {
                    console.log(res)
                    this.officeList = res.data.data;
                })
            },
        },
    };
</script>


<style scoped>
    .ant-advanced-search-form {
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        margin-bottom: 10px;
    }

    tr{
        background-color: #ffffff;
    }

    /*.ant-upload-list-item-info{*/
    /*    display: none;*/
    /*}*/

</style>
