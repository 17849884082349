var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { staticStyle: { height: "100%" }, attrs: { gutter: 16 } },
        [
          _c(
            "a-col",
            {
              staticClass: "flex flex-column",
              staticStyle: { height: "100%" },
              attrs: { span: 15 },
            },
            [
              _c("warning-status", { staticStyle: { height: "188px" } }),
              _c("project-map", {
                staticClass: "flex-1",
                staticStyle: { "margin-top": "15px" },
                attrs: { officeList: _vm.officeList },
              }),
            ],
            1
          ),
          _c(
            "a-col",
            {
              staticClass: "flex flex-column",
              staticStyle: { height: "100%" },
              attrs: { span: 9 },
            },
            [
              _c("dangerous-project", {
                staticStyle: { height: "360px" },
                attrs: { officeList: _vm.officeList },
              }),
              _c("project-inspection", {
                staticClass: "flex-1",
                staticStyle: { "margin-top": "15px" },
                attrs: { officeList: _vm.officeList },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }