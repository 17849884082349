<template>
  <div>
    <a-card>
      <div class="flex a-center j-between">
        <div class="flex a-center">
          <img style="width: 21px; height: 21px" src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241120/1732091350443.png?Expires=2047451350&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=59AEqlAw%2B%2BGyQ%2F%2B4QYdFWxJZ7rs%3D" alt="">
          <span class="font-bold ml-1">危大工程情况</span>
        </div>
        <div class="flex">
          <a-select
            :default-value="defaultUnit"
            v-model="defaultUnit"
            style="width: 200px"
            @change="onHandleUnitChange"
          >
            <a-select-option
              v-for="(items, id) in officeList"
              :key="id"
              :value="items.id"
            >
              <span :title="items.name">{{ items.name }}</span>
            </a-select-option>
          </a-select>
          <a-month-picker class="ml-1" style="width: 120px"  @change="mtChangeDate" placeholder="请选择月份" :default-value="moment(nowMonth, monthFormat)" :format="monthFormat" v-model="statTime"/>
        </div>
      </div>
      <a-divider />
      <div class="mt-1">
        <a-row :gutter="[24,24]">
          <a-col :span="8">
            <div class="flex flex-column j-center a-center content-box content-box-bg1">
              <span class="font-20 font-bold">{{wdData.wdgcTotal || 0}}</span>
              <span class="black-three font-12" style="margin-top: 5px;">危大工程项目数</span>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="flex flex-column j-center a-center content-box content-box-bg1">
              <span class="font-20 font-bold">{{wdData.wdgcCount || 0}}</span>
              <span class="black-three font-12" style="margin-top: 5px;">危大工程分部分项数</span>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="flex flex-column j-center a-center content-box content-box-bg1">
              <span class="font-20 font-bold">{{wdData.overWdgcCount || 0}}</span>
              <span class="black-three font-12" style="margin-top: 5px;">超危大工程分部分项数</span>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="flex flex-column j-center a-center content-box content-box-bg2">
              <span class="font-20 font-bold">{{wdData.riskTotal || 0}}</span>
              <span class="black-three font-12" style="margin-top: 5px;">风险总数</span>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="flex flex-column j-center a-center content-box content-box-bg2">
              <span class="font-20 font-bold">{{wdData.riskRankType1Count || 0}}</span>
              <span class="black-three font-12" style="margin-top: 5px;">一级风险个数</span>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="flex flex-column j-center a-center content-box content-box-bg2">
              <span class="font-20 font-bold">{{wdData.riskRankType2Count || 0}}</span>
              <span class="black-three font-12" style="margin-top: 5px;">二级风险个数</span>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="flex flex-column j-center a-center content-box content-box-bg3">
              <span class="font-20 font-bold">{{wdData.curWdgcTotal || 0}}</span>
              <span class="black-three font-12" style="margin-top: 5px;">当前危大工程项目数</span>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="flex flex-column j-center a-center content-box content-box-bg3">
              <span class="font-20 font-bold">{{wdData.curWdgcCount || 0}}</span>
              <span class="black-three font-12" style="margin-top: 5px;">当前危大工程分部分项数</span>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="flex flex-column j-center a-center content-box content-box-bg3">
              <span class="font-20 font-bold">{{wdData.curOverWdgcCount || 0}}</span>
              <span class="black-three font-12" style="margin-top: 5px;">当前超危大工程分部分项数</span>
            </div>
          </a-col>
        </a-row>
      </div>
    </a-card>
  </div>
</template>

<script>
import { getwdStatusData } from "@/services/labourProjectExcellent";
import { mapGetters, mapMutations } from "vuex";
import moment from 'moment';
export default {
  props: {
    officeList: {
      type: Array,
      default: () =>  []
    }
  },
  data() {
    return {
      defaultUnit: "",
      deptId: '',
      statTime: '',
      nowMonth: null,
      monthFormat: 'YYYY-MM',
      wdData: {}
    };
  },

  computed: {
    ...mapGetters("account", ["user"]),
  },

  watch: {
    officeList: {
      handler(val) {
        this.deptId = this.user.officeId;
        // 数据过滤成当前账号登录的信息
        let items = val.filter((item) => {
          return item.id === this.deptId;
        });
        this.defaultUnit = items[0].name;
        this.getData()
      },
      deep: true
    }
  },

  created() {
    this.nowMonth = moment(new Date()).format('YYYY-MM');
    this.statTime = moment(new Date()).format('YYYY-MM');
  },

  methods: {
    moment,
    getData() {
      const year = this.statTime.split('-')[0]
      const month = this.statTime.split('-')[1]
      getwdStatusData({officeId: this.deptId, year, month}).then(res => {
        if (res.data.code === 0) {
          this.wdData = res.data.data
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    onHandleUnitChange(e) {
      this.deptId = e
      this.getData()
    },
    mtChangeDate(value) {
      this.statTime = moment(value).format('YYYY-MM');
      this.getData();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-card {
  border-radius: 10px;
  border: none;
  .ant-card-body {
    padding: 15px;
  }
}
.content-box {
  border-radius: 10px;
  padding: 10px 20px;
}
.content-box-bg1 {
  background: linear-gradient(0.00deg, rgb(245, 248, 254),rgba(245, 248, 254, 0) 100%);
}
.content-box-bg2 {
  background: linear-gradient(90.00deg, rgb(245, 248, 254),rgba(245, 248, 254, 0) 100%);
}
.content-box-bg3 {
  background: linear-gradient(180.00deg, rgb(245, 248, 254),rgba(245, 248, 254, 0) 100%);
}
</style>