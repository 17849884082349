var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    {
      staticClass: "projectConfig",
      attrs: {
        title: _vm.$route.query.officeId ? false : "项目参数设置",
        bordered: false,
      },
    },
    [
      _c(
        "a-form-model",
        {
          attrs: {
            model: _vm.projectConfig,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c("a-form-model-item", { attrs: { "wrapper-col": { span: 20 } } }, [
            _c(
              "div",
              { staticClass: "flex j-between" },
              [
                _c("div", { staticClass: "font-16 black" }, [
                  _vm._v(" 建委平台参数配置 (广州地区适用) "),
                ]),
                _c(
                  "a-button",
                  {
                    attrs: { icon: "save", type: "primary" },
                    on: { click: _vm.saveProjectConfig },
                  },
                  [_vm._v(" 保存 ")]
                ),
              ],
              1
            ),
          ]),
          _c(
            "a-form-model-item",
            {
              staticStyle: { height: "40px" },
              attrs: { "wrapper-col": { span: 24 } },
            },
            [
              _c(
                "a-form-model",
                { attrs: { layout: "inline" } },
                [
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "跳过银行卡上传" } },
                            [
                              _c(
                                "a-radio-group",
                                {
                                  staticClass: "ml-1",
                                  model: {
                                    value: _vm.projectConfig.isBankCard,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.projectConfig,
                                        "isBankCard",
                                        $$v
                                      )
                                    },
                                    expression: "projectConfig.isBankCard",
                                  },
                                },
                                [
                                  _c("a-radio", { attrs: { value: 0 } }, [
                                    _vm._v(" 是 "),
                                  ]),
                                  _c("a-radio", { attrs: { value: 1 } }, [
                                    _vm._v(" 否 "),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "是否上报建委" } },
                            [
                              _c(
                                "a-radio-group",
                                {
                                  staticClass: "ml-1",
                                  model: {
                                    value: _vm.projectConfig.pushJw,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.projectConfig, "pushJw", $$v)
                                    },
                                    expression: "projectConfig.pushJw",
                                  },
                                },
                                [
                                  _c("a-radio", { attrs: { value: 1 } }, [
                                    _vm._v(" 是 "),
                                  ]),
                                  _c("a-radio", { attrs: { value: 0 } }, [
                                    _vm._v(" 否 "),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "是否设备直连", required: "" } },
                            [
                              _c(
                                "a-radio-group",
                                {
                                  staticClass: "ml-1",
                                  model: {
                                    value: _vm.projectConfig.isDirect,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.projectConfig,
                                        "isDirect",
                                        $$v
                                      )
                                    },
                                    expression: "projectConfig.isDirect",
                                  },
                                },
                                [
                                  _c("a-radio", { attrs: { value: 1 } }, [
                                    _vm._v(" 是 "),
                                  ]),
                                  _c("a-radio", { attrs: { value: 0 } }, [
                                    _vm._v(" 否 "),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.projectConfig.isDirect
            ? _vm._l(_vm.jwZlSecritVoList, function (fjwItem, index) {
                return _c(
                  "a-row",
                  { key: index, staticClass: "w-83" },
                  [
                    _c(
                      "a-col",
                      { staticClass: "flex j-between a-center card-jw" },
                      [
                        _c("div", { staticClass: "ml-15 flex-1" }, [
                          _c("div", { staticClass: "black-for mb-1 font-16" }, [
                            _vm._v("设备直连"),
                          ]),
                          _c("div", { staticClass: "flex" }, [
                            _c(
                              "div",
                              { staticClass: "black-three flex a-center" },
                              [
                                _vm._v(" 公司名称: "),
                                _c(
                                  "a-select",
                                  {
                                    staticClass: "card-select",
                                    staticStyle: { width: "220px" },
                                    attrs: {
                                      placeholder: "请输入或选择公司",
                                      "option-filter-prop": "children",
                                      "filter-option": _vm.filterOption,
                                      showArrow: false,
                                      open: false,
                                    },
                                    model: {
                                      value: fjwItem.officeId,
                                      callback: function ($$v) {
                                        _vm.$set(fjwItem, "officeId", $$v)
                                      },
                                      expression: "fjwItem.officeId",
                                    },
                                  },
                                  _vm._l(_vm.dataSource, function (item) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: item.id,
                                        attrs: {
                                          value: item.officeId,
                                          title: item.officeName,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.officeName) + " "
                                        ),
                                      ]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "black-three flex a-center" },
                              [
                                _vm._v(" 参建类型: "),
                                _c(
                                  "a-select",
                                  {
                                    staticClass: "card-select",
                                    staticStyle: { width: "220px" },
                                    attrs: {
                                      placeholder: "请输入或选择公司",
                                      "option-filter-prop": "children",
                                      "filter-option": _vm.filterOption,
                                      showArrow: false,
                                      open: false,
                                    },
                                    model: {
                                      value: fjwItem.officeId,
                                      callback: function ($$v) {
                                        _vm.$set(fjwItem, "officeId", $$v)
                                      },
                                      expression: "fjwItem.officeId",
                                    },
                                  },
                                  _vm._l(_vm.dataSource, function (item) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: item.id,
                                        attrs: {
                                          value: item.officeId,
                                          title: item.officeName,
                                        },
                                      },
                                      [
                                        _c(
                                          "a-tag",
                                          { attrs: { color: "#55acee" } },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.label) + " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "flex a-center ml-1",
                                staticStyle: { color: "#F59B24" },
                              },
                              [
                                _vm.validateData(fjwItem)
                                  ? [
                                      _c("a-icon", {
                                        staticClass: "mr-1",
                                        attrs: {
                                          type: "info-circle",
                                          theme: "twoTone",
                                          "two-tone-color": "#F59B24",
                                        },
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.validateData(fjwItem)) +
                                          " "
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                        ]),
                        _c(
                          "a-space",
                          { staticClass: "card-action" },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.viewEditing(
                                      fjwItem,
                                      _vm.projectConfig.isDirect,
                                      index
                                    )
                                  },
                                },
                              },
                              [_vm._v(" 编辑 ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              })
            : _vm._l(_vm.jwFzlKeySecritList, function (fjwItem, index) {
                return _c(
                  "a-row",
                  { key: index, staticClass: "w-83" },
                  [
                    _c(
                      "a-col",
                      { staticClass: "flex j-between a-center card-jw" },
                      [
                        _c("div", { staticClass: "ml-15 flex-1" }, [
                          _c("div", { staticClass: "black-for mb-1 font-16" }, [
                            _vm._v("非设备直连"),
                          ]),
                          _c("div", { staticClass: "flex" }, [
                            _c(
                              "div",
                              { staticClass: "black-three flex a-center" },
                              [
                                _vm._v(" 公司名称: "),
                                _c(
                                  "a-select",
                                  {
                                    staticClass: "card-select",
                                    staticStyle: { width: "220px" },
                                    attrs: {
                                      placeholder: "请输入或选择公司",
                                      "option-filter-prop": "children",
                                      "filter-option": _vm.filterOption,
                                      showArrow: false,
                                      open: false,
                                    },
                                    model: {
                                      value: fjwItem.officeId,
                                      callback: function ($$v) {
                                        _vm.$set(fjwItem, "officeId", $$v)
                                      },
                                      expression: "fjwItem.officeId",
                                    },
                                  },
                                  _vm._l(_vm.dataSource, function (item) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: item.id,
                                        attrs: {
                                          value: item.officeId,
                                          title: item.officeName,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.officeName) + " "
                                        ),
                                      ]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "black-three flex a-center" },
                              [
                                _vm._v(" 参建类型: "),
                                _c(
                                  "a-select",
                                  {
                                    staticClass: "card-select",
                                    staticStyle: { width: "220px" },
                                    attrs: {
                                      placeholder: "请输入或选择公司",
                                      "option-filter-prop": "children",
                                      "filter-option": _vm.filterOption,
                                      showArrow: false,
                                      open: false,
                                    },
                                    model: {
                                      value: fjwItem.officeId,
                                      callback: function ($$v) {
                                        _vm.$set(fjwItem, "officeId", $$v)
                                      },
                                      expression: "fjwItem.officeId",
                                    },
                                  },
                                  _vm._l(_vm.dataSource, function (item) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: item.id,
                                        attrs: {
                                          value: item.officeId,
                                          title: item.officeName,
                                        },
                                      },
                                      [
                                        _c(
                                          "a-tag",
                                          { attrs: { color: "#55acee" } },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.label) + " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "black-three flex a-center ",
                                staticStyle: { width: "220px" },
                              },
                              [
                                _c(
                                  "a-tooltip",
                                  { attrs: { placement: "topLeft" } },
                                  [
                                    _c("template", { slot: "title" }, [
                                      _vm._v(
                                        " " + _vm._s(fjwItem.accessno) + " "
                                      ),
                                    ]),
                                    _c("div", { staticClass: "overtext-1" }, [
                                      _vm._v(
                                        "接入编码: " + _vm._s(fjwItem.accessno)
                                      ),
                                    ]),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "flex a-center ml-1",
                                staticStyle: { color: "#F59B24" },
                              },
                              [
                                _vm.validateData(fjwItem, true)
                                  ? [
                                      _c("a-icon", {
                                        staticClass: "mr-1",
                                        attrs: {
                                          type: "info-circle",
                                          theme: "twoTone",
                                          "two-tone-color": "#F59B24",
                                        },
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.validateData(fjwItem, true)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                        ]),
                        _c(
                          "a-space",
                          { staticClass: "card-action" },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.viewEditing(
                                      fjwItem,
                                      _vm.projectConfig.isDirect,
                                      index
                                    )
                                  },
                                },
                              },
                              [_vm._v(" 编辑 ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
          _c("a-form-model-item", { attrs: { "wrapper-col": { span: 24 } } }, [
            _c(
              "div",
              { staticClass: "flex a-center" },
              [
                _c("div", { staticClass: "font-16 black" }, [
                  _vm._v(" 是否启用备用考勤： "),
                ]),
                _c(
                  "a-radio-group",
                  {
                    staticClass: "ml-2",
                    attrs: { label: "是否启用备用考勤" },
                    model: {
                      value: _vm.projectConfig.jwbykq,
                      callback: function ($$v) {
                        _vm.$set(_vm.projectConfig, "jwbykq", $$v)
                      },
                      expression: "projectConfig.jwbykq",
                    },
                  },
                  [
                    _c("a-radio", { attrs: { value: 1 } }, [_vm._v(" 是 ")]),
                    _c("a-radio", { attrs: { value: 0 } }, [_vm._v(" 否 ")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm.projectConfig.jwbykq
            ? _c(
                "a-form-model-item",
                { attrs: { "wrapper-col": { span: 20 } } },
                [
                  _c(
                    "div",
                    { staticClass: "card-jw", staticStyle: { height: "100%" } },
                    [
                      _vm.projectConfig.jwbykq
                        ? _c(
                            "a-form-model",
                            {
                              staticClass: "ml-2 pt-2",
                              attrs: {
                                model: _vm.jwbykqContent,
                                layout: "inline",
                              },
                            },
                            [
                              _c(
                                "a-form-model-item",
                                { attrs: { label: "工程编码" } },
                                [
                                  _c("a-input", {
                                    model: {
                                      value: _vm.jwbykqContent.projectCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.jwbykqContent,
                                          "projectCode",
                                          $$v
                                        )
                                      },
                                      expression: "jwbykqContent.projectCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                { attrs: { label: "工程密钥" } },
                                [
                                  _c("a-input", {
                                    model: {
                                      value: _vm.jwbykqContent.gcms,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.jwbykqContent, "gcms", $$v)
                                      },
                                      expression: "jwbykqContent.gcms",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                { attrs: { label: "接入编号" } },
                                [
                                  _c("a-input", {
                                    model: {
                                      value: _vm.jwbykqContent.accessNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.jwbykqContent,
                                          "accessNo",
                                          $$v
                                        )
                                      },
                                      expression: "jwbykqContent.accessNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-row",
                                [
                                  _c(
                                    "a-col",
                                    [
                                      _c(
                                        "a-form-model-item",
                                        { attrs: { label: "进场设备" } },
                                        [
                                          _c("a-input", {
                                            model: {
                                              value:
                                                _vm.jwbykqContent.enterClientId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.jwbykqContent,
                                                  "enterClientId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "jwbykqContent.enterClientId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-model-item",
                                        { attrs: { label: "出场设备" } },
                                        [
                                          _c("a-input", {
                                            model: {
                                              value:
                                                _vm.jwbykqContent.leaveClientId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.jwbykqContent,
                                                  "leaveClientId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "jwbykqContent.leaveClientId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _c("a-form-model-item", [
            _c("div", { staticClass: "font-bold font-16 black" }, [
              _vm._v("第三方平台"),
            ]),
          ]),
          _c("a-form-model-item", { attrs: { "wrapper-col": { span: 20 } } }, [
            _c(
              "div",
              { staticClass: "font-16 black" },
              [
                _c(
                  "a-row",
                  { attrs: { gutter: 16 } },
                  [
                    _c(
                      "a-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "a-card",
                          {
                            staticClass: "ant-card-rable",
                            attrs: { bordered: false },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "title" }, slot: "title" },
                              [
                                _c("div", { staticClass: "flex a-center" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "rable-check mr-1",
                                      class: _vm.projectConfig.pushZdb
                                        ? "rable-check-true"
                                        : "",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.projectConfig.pushZdb
                                              ? "启用中"
                                              : "停用"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" 省重点办 "),
                                ]),
                              ]
                            ),
                            _c(
                              "a-button",
                              {
                                attrs: { slot: "extra", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleThird(1)
                                  },
                                },
                                slot: "extra",
                              },
                              [_vm._v(" 编辑 ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "a-card",
                          {
                            staticClass: "ant-card-rable",
                            attrs: { bordered: false },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "title" }, slot: "title" },
                              [
                                _c("div", { staticClass: "flex a-center" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "rable-check mr-1",
                                      class: _vm.projectConfig.pushDjj
                                        ? "rable-check-true"
                                        : "",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.projectConfig.pushDjj
                                              ? "启用中"
                                              : "停用"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" 代建局 "),
                                ]),
                              ]
                            ),
                            _c(
                              "a-button",
                              {
                                attrs: { slot: "extra", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleThird(2)
                                  },
                                },
                                slot: "extra",
                              },
                              [_vm._v(" 编辑 ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "a-card",
                          {
                            staticClass: "mt-2 ant-card-rable",
                            attrs: { bordered: false },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "title" }, slot: "title" },
                              [
                                _c("div", { staticClass: "flex a-center" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "rable-check mr-1",
                                      class: _vm.projectConfig.pushZhgd
                                        ? "rable-check-true"
                                        : "",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.projectConfig.pushZhgd
                                              ? "启用中"
                                              : "停用"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" 市重点办 "),
                                ]),
                              ]
                            ),
                            _c(
                              "a-button",
                              {
                                attrs: { slot: "extra", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleThird(3)
                                  },
                                },
                                slot: "extra",
                              },
                              [_vm._v(" 编辑 ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "a-modal",
        {
          attrs: {
            centered: "",
            visible: _vm.showEvaluate,
            "confirm-loading": _vm.confirmLoading,
            width: 700,
          },
          on: { ok: _vm.handleEvaluate, cancel: _vm.cancelEvaluate },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("div", { staticClass: "text-center" }, [
                    _vm._v("编辑内容"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "a-form-model",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                "label-col": { span: 6 },
                "wrapper-col": { span: 14 },
                rules: _vm.rules,
              },
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "公司名称" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        disabled: "",
                        "show-search": "",
                        placeholder: "请输入或选择公司",
                        "option-filter-prop": "children",
                        "filter-option": _vm.filterOption,
                        allowClear: "",
                      },
                      on: { change: _vm.handleChange },
                      model: {
                        value: _vm.form.officeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "officeId", $$v)
                        },
                        expression: "form.officeId",
                      },
                    },
                    _vm._l(_vm.dataSource, function (item) {
                      return _c(
                        "a-select-option",
                        {
                          key: item.id,
                          attrs: {
                            value: item.officeId,
                            title: item.officeName,
                          },
                        },
                        [_vm._v(" " + _vm._s(item.officeName) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "参建类型" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        disabled: "",
                        "show-search": "",
                        placeholder: "请输入或选择公司",
                        "option-filter-prop": "children",
                        "filter-option": _vm.filterOption,
                        allowClear: "",
                      },
                      on: { change: _vm.handleChange },
                      model: {
                        value: _vm.form.officeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "officeId", $$v)
                        },
                        expression: "form.officeId",
                      },
                    },
                    _vm._l(_vm.dataSource, function (item) {
                      return _c(
                        "a-select-option",
                        {
                          key: item.id,
                          attrs: {
                            value: item.officeId,
                            title: item.officeName,
                          },
                        },
                        [_vm._v(" " + _vm._s(item.label) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.projectConfig.isDirect == 0
                ? _c(
                    "a-form-model-item",
                    { attrs: { prop: "accessno", label: "接入编码" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入接入编码" },
                        model: {
                          value: _vm.form.accessno,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "accessno",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.accessno",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.projectConfig.isDirect == 0
                ? _c(
                    "a-form-model-item",
                    { attrs: { prop: "accesskey", label: "接入密匙" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入接入密匙" },
                        model: {
                          value: _vm.form.accesskey,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "accesskey",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.accesskey",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                { attrs: { label: "建委账号" } },
                [
                  _c("a-list", {
                    attrs: {
                      "data-source": _vm.form.jwAccountsVoList,
                      bordered: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "renderItem",
                        fn: function (item) {
                          return _c(
                            "a-list-item",
                            { key: "a-" + item.roleKey },
                            [
                              _c(
                                "a-list-item-meta",
                                [
                                  _c(
                                    "a",
                                    { attrs: { slot: "title" }, slot: "title" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          item.roleKey == 1
                                            ? "项目经理"
                                            : item.roleKey == 2
                                            ? "总包劳务员"
                                            : "分包劳务员"
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("a-avatar", {
                                    attrs: {
                                      slot: "avatar",
                                      src: "https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png",
                                    },
                                    slot: "avatar",
                                  }),
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "description" },
                                      slot: "description",
                                    },
                                    [
                                      _c(
                                        "a-form-model-item",
                                        { staticClass: "desc-form" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "label" },
                                              slot: "label",
                                            },
                                            [_vm._v("建委账号")]
                                          ),
                                          _c("a-input", {
                                            staticStyle: { width: "200px" },
                                            attrs: {
                                              placeholder: "请输入建委账号",
                                            },
                                            model: {
                                              value: item.account,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "account",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression: "item.account",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-model-item",
                                        { staticClass: "desc-form" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "label" },
                                              slot: "label",
                                            },
                                            [_vm._v("建委密码")]
                                          ),
                                          _c("a-input", {
                                            staticStyle: { width: "200px" },
                                            attrs: {
                                              placeholder: "请输入建委密码",
                                            },
                                            model: {
                                              value: item.password,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "password",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression: "item.password",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-model-item",
                                        { staticClass: "desc-form" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "label" },
                                              slot: "label",
                                            },
                                            [_vm._v("手机号")]
                                          ),
                                          _c("a-input", {
                                            staticStyle: { width: "200px" },
                                            attrs: {
                                              placeholder: "请输入手机号",
                                            },
                                            model: {
                                              value: item.phone,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "phone",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression: "item.phone",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "div",
                {
                  staticClass: "flex j-center",
                  staticStyle: { margin: "-20px 0 20px 0" },
                },
                [
                  _c("a-alert", {
                    attrs: {
                      message:
                        "请确保输入的手机号与建委平台账号中绑定的手机号码相同",
                      type: "warning",
                      "show-icon": "",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-space",
                { attrs: { size: "large" } },
                [
                  _c("a-button", { on: { click: _vm.cancelEvaluate } }, [
                    _vm._v(" 关闭 "),
                  ]),
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleEvaluate },
                    },
                    [_vm._v(" 确定 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "a-modal",
        {
          attrs: {
            centered: "",
            visible: _vm.showThirdMotal,
            forceRender: true,
            width: 700,
          },
          on: { cancel: _vm.cancelThird },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("div", { staticClass: "text-center" }, [
                    _vm._v("对接第三方平台"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "a-form-model",
            { staticClass: "mb-1", attrs: { layout: "inline" } },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { style: { textAlign: "center" }, attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "第三方平台" } },
                        [
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v(
                              _vm._s(
                                _vm.showThird == 1
                                  ? "省重点办"
                                  : _vm.showThird == 2
                                  ? "代建局"
                                  : "市重点办"
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { style: { textAlign: "center" }, attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "是否启用" } },
                        [
                          _vm.showThird == 1
                            ? [
                                _c("a-switch", {
                                  class: _vm.projectConfig.pushZdb
                                    ? "rable-check-true"
                                    : "",
                                  attrs: {
                                    "checked-children": "启用",
                                    "un-checked-children": "停用",
                                  },
                                  model: {
                                    value: _vm.projectConfig.pushZdb,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.projectConfig,
                                        "pushZdb",
                                        $$v
                                      )
                                    },
                                    expression: "projectConfig.pushZdb",
                                  },
                                }),
                              ]
                            : _vm._e(),
                          _vm.showThird == 2
                            ? [
                                _c("a-switch", {
                                  class: _vm.projectConfig.pushDjj
                                    ? "rable-check-true"
                                    : "",
                                  attrs: {
                                    "checked-children": "启用",
                                    "un-checked-children": "停用",
                                  },
                                  model: {
                                    value: _vm.projectConfig.pushDjj,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.projectConfig,
                                        "pushDjj",
                                        $$v
                                      )
                                    },
                                    expression: "projectConfig.pushDjj",
                                  },
                                }),
                              ]
                            : _vm._e(),
                          _vm.showThird == 3
                            ? [
                                _c("a-switch", {
                                  class: _vm.projectConfig.pushZhgd
                                    ? "rable-check-true"
                                    : "",
                                  attrs: {
                                    "checked-children": "启用",
                                    "un-checked-children": "停用",
                                  },
                                  model: {
                                    value: _vm.projectConfig.pushZhgd,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.projectConfig,
                                        "pushZhgd",
                                        $$v
                                      )
                                    },
                                    expression: "projectConfig.pushZhgd",
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("form-index", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showThird == 1,
                expression: "showThird == 1",
              },
            ],
            ref: "zdbContent",
            attrs: { formData: _vm.zdbContentData },
          }),
          _c("form-index", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showThird == 2,
                expression: "showThird == 2",
              },
            ],
            ref: "djjContent",
            attrs: { formData: _vm.djjContentData },
          }),
          _c("form-index", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showThird == 3,
                expression: "showThird == 3",
              },
            ],
            ref: "zhgdContent",
            attrs: { formData: _vm.zhgdContentData },
          }),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-space",
                { attrs: { size: "large" } },
                [
                  _c("a-button", { on: { click: _vm.cancelThird } }, [
                    _vm._v(" 关闭 "),
                  ]),
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.cancelThird },
                    },
                    [_vm._v(" 确定 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }