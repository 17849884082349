<template>
  <a-layout-header :class="[headerTheme, 'admin-header']">
    <div :class="['admin-header-wide', layout, pageWidth]">
      <router-link v-if="isMobile || layout === 'head'" to="/" :class="['logo', isMobile ? null : 'pc', headerTheme]">
        <img width="32" v-if="isLaowu" src="@/assets/img/logo.png" />
        <img width="70" v-if="isSafe" src="@/assets/img/logo2.png" />
        <h1 v-if="!isMobile">{{ systemFullName }}</h1>
      </router-link>
      <a-divider v-if="isMobile" type="vertical" />
      <a-icon v-if="layout !== 'head'" class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="toggleCollapse"/>
      <div v-if="layout !== 'side' && !isMobile" class="admin-header-menu" :style="`width: ${menuWidth};`">
        <i-menu class="head-menu" :theme="headerTheme" mode="horizontal" :options="menuData" @select="onSelect"/>
      </div>
      <div :class="['admin-header-right', headerTheme]">
        <header-search class="header-item" @active="val => searchActive = val" v-if = "false"/>
        <!-- 市政大屏 TODO: 临时用着建安易，后面改回来 -->
        <a v-if="showGoview" class="header-item" @click="onShowGoview">打开大屏</a>
        <a v-if="manualPath && checkAuthorization()" class="header-item" :href="manualPath" target="_blank">操作手册</a>
        <header-notice class="header-item"/>
        <header-project class="header-item"/>
        <header-avatar class="header-item"/>
      </div>
    </div>
  </a-layout-header>
</template>

<script>
import HeaderSearch from './HeaderSearch'
import HeaderNotice from './HeaderNotice'
import HeaderAvatar from './HeaderAvatar'
import HeaderProject from './HeaderProject'
import IMenu from '@/components/menu/menu'
import {mapState, mapMutations, mapGetters} from 'vuex'
import { checkAuthorization } from '@/utils/request.js'
import { getSystemType, isLaowu, isSafe } from '@/utils/system.js'
import { SYSTEM_TYPE_ENUM } from '@/enums/system'
import { ShiZhengOfficeEnum } from '@/enums/officeEnum.js'

export default {
  name: 'AdminHeader',
  components: {IMenu, HeaderAvatar, HeaderNotice, HeaderSearch, HeaderProject},
  props: ['collapsed', 'menuData'],
  data() {
    return {
      langList: [
        {key: 'CN', name: '简体中文', alias: '简体'},
        {key: 'HK', name: '繁體中文', alias: '繁體'},
        {key: 'US', name: 'English', alias: 'English'}
      ],
      searchActive: true,
      checkAuthorization,
      manualPath: '',
      isLaowu: isLaowu(),
      isSafe: isSafe()
    }
  },
  created() {
    this.setManualPath()
  },
  computed: {
    ...mapState('setting', ['theme', 'isMobile', 'layout', 'systemType', 'systemFullName', 'lang', 'pageWidth']),
    ...mapGetters('account', ['user']),
    headerTheme () {
      if (this.layout == 'side' && this.theme.mode == 'dark' && !this.isMobile) {
        return 'light'
      }
      return this.theme.mode
    },
    langAlias() {
      let lang = this.langList.find(item => item.key == this.lang)
      return lang.alias
    },
    menuWidth() {
      const {layout, searchActive} = this
      const headWidth = layout === 'head' ? '100% - 188px' : '100%'
      const extraWidth = searchActive ? '700px' : '500px'
      return `calc(${headWidth} - ${extraWidth})`
    },
    showGoview() {
      // 市政集团、公司账号开放大屏入口
      if (
       this.checkAuthorization() && [...ShiZhengOfficeEnum.GROUP, ...ShiZhengOfficeEnum.COMPANY].includes(this.user.officeId)
      ) {
        return true
      } else {
        return false
      }
    },
  },
  watch: {
    headerTheme: {
      handler(newVal) {
        console.log(this.isMobile)
      },
      deep:true,
      immediate: true
    }
  },
  methods: {
    onShowGoview() {
      // 市政大屏预览链接
      const pathMap = {
        test: 'http://datav.gz-suijian.com/chart/preview/cm5c7p1d100dyfck0v7qfvy3f',
        prodcution: 'http://datav.gz-suijian.com/chart/preview/cm3sffrud0009fck0e747d1va'
      }
      const isProd = process.env.NODE_ENV === 'production'
      const path = isProd ? pathMap.prodcution : pathMap.test
      window.location.href = `${path}?token=${this.user.token}`
    },
    toggleCollapse () {
      this.$emit('toggleCollapse')
    },
    onSelect (obj) {
      this.$emit('menuSelect', obj)
    },
    setManualPath() {
      const systemType = getSystemType()
      const manualPathMap = {
        [SYSTEM_TYPE_ENUM.LAOWU]: 'http://doc.gz-suijian.com/docs/mindoc/mindoc-1f7taos0ej6kg',
        [SYSTEM_TYPE_ENUM.SAFE]: '',
        [SYSTEM_TYPE_ENUM.JIANANYI]: 'http://doc.gz-suijian.com/docs/jay',
        [SYSTEM_TYPE_ENUM.SHIZHENG]: 'http://doc.gz-suijian.com/docs/jay'
      }
      this.manualPath = manualPathMap[systemType]
    },
    ...mapMutations('setting', ['setLang'])
  }
}
</script>

<style lang="less" scoped>
@import "index";
</style>
