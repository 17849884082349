<template>
  <div class="a_Approval">
    <div class="plug-Overview">标准化评分汇总表</div>
    <a-divider />
    <!-- 表单 -->
    <form-index
      ref="formindexRef"
      :formData="formConfig"
      flexSubmit
      @handleSubmit="onSearch"
      @changeYear="changeYear"
      @handleChange="handleChange"
      @handlSelect="handlSelect"
    >
      <template #flexSubmit>
        <a-space class="btnBox j-start ml-4">
          <a-button type="primary" html-type="submit"> 查询 </a-button>
          <a-button @click="onReset"> 重置 </a-button>
        </a-space>
      </template>
    </form-index>

    <!-- 按钮 -->
    <div class="flex j-between">
      <a-space v-if="isGroupAccount">
        <a-button type="primary" @click="openModal(DetailPageModeEnum.ADD)"> 新增 </a-button> <!-- 新增按钮 -->
        <a-upload
          name="file"
          :multiple="false"
          :before-upload="beforeUpload"
          :showUploadList="false"
        >
          <a-button type="primary" :loading="importLoading">
            导入
          </a-button>
        </a-upload>
        <a-button
          type="link"
          @click="downloadFile(
            'https://sj-file-bak.oss-cn-guangzhou.aliyuncs.com/aac473c2e575098373fbbdb0cd08eb0c1b2fc416c6a54fdd514c7ef3a669dabd.xlsx'
          )"
        >
          下载导入模板
        </a-button>
      </a-space>
      <!-- 懒得改样式，占个坑 -->
      <div v-else></div>

      <a-radio-group v-model="standQuarter" @change="() => onSearch()">
        <a-radio-button :value="1"> 第一季度 </a-radio-button>
        <a-radio-button :value="2"> 第二季度 </a-radio-button>
        <a-radio-button :value="3"> 第三季度 </a-radio-button>
        <a-radio-button :value="4"> 第四季度 </a-radio-button>
      </a-radio-group>
    </div>

    <div class="text-center font-20 font-bold mb-1">集团对项目标准化考评汇总表</div>

    <!-- 表格 -->
    <standard-table
      :columns="columns"
      :dataSource="tableData"
      rowKey="id"
      :pagination="pagination"
      :loading="tableLoading"
      @change="onPageChange"
      :isAlert="false"
      :bordered="true"
    >
      <template slot="action" slot-scope="{ record }">
        <a-space>
          <template v-if="isGroupAccount">
            <a-button type="link" @click="openModal(DetailPageModeEnum.EDIT, record.id)">编辑</a-button>
            <a-popconfirm
              title="是否确认删除"
              ok-text="删除"
              cancel-text="取消"
              okType="danger"
              @confirm="onDelete(record.id)"
            >
              <a-button type="link" style="color: red;">删除</a-button>
            </a-popconfirm>
          </template>
          <template v-else>
            <a-button type="link" @click="openModal(DetailPageModeEnum.VIEW, record.id)">查看</a-button>
          </template>
        </a-space>
      </template>
    </standard-table>

    <!-- Full Modal 用于新增页面 -->
    <full-modal :visible="modalVisible" @close="closeModal">
      <detailPage
        :pageMode="pageMode"
        :id="editId"
        @save="onSaveModal"
        @close="closeModal"
      />
    </full-modal>
  </div>
</template>

<script>
import { request } from "@/utils/request";
import { QUERY_OFFICE_BY_USER } from "@/services/api";
import { getProjectByOffice } from "@/services/statistics";
import { assessmentPage, assessmentImport, assessmentDelete } from "@/services/safeManage.js";

import { mapGetters } from "vuex";
import moment from "moment";
import { debounce } from 'lodash'

import { downloadFile } from "@/utils/fileUtil.js";

import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import detailPage from './detailPage.vue'
import fullModal from '@/components/fullModal/index.vue'

import { useFullModal } from "@/hooks/useFullModal";  // 引入 useFullModal hook
import { DetailPageModeEnum, DetailPageModeNameMap } from '@/enums/pageEnum'

const formConfig = [
  {
    label: "选择年份",
    placeholder: "请选择年份",
    decorator: [
      "standYear",
      {
        initialValue: moment(new Date()).format("YYYY"),
        rules: [{ required: false, message: "请选择" }],
      },
    ],
    type: "yearPicker",
    key: "standYear",
    col: 5,
    labelCol: 8,
    wrapperCol: 14,
    display: true,
    styles: { width: "100%" },
  },
  {
    label: "公司",
    placeholder: "请选择公司",
    decorator: ["officeId"],
    type: "select",
    key: "officeId",
    selectlist: [],
    col: 5,
    labelCol: 8,
    wrapperCol: 14,
  },
  {
    label: "分公司",
    placeholder: "请选择分公司",
    decorator: ['childOfficeId'],
    type: "select",
    key: "childOfficeId",
    selectlist: [],
    col: 5,
    labelCol: 8,
    wrapperCol: 14,
  },
  {
    label: "项目名称",
    placeholder: "请选择项目",
    decorator: ['projectId'],
    type: "inputGroup",
    dataSource: [],
    key: "projectId",
    col: 5,
    labelCol: 8,
    wrapperCol: 14,
  },
];
const columns = [
  {
    title: "序号",
    width: 65,
    customRender: (text, record, index) => index + 1,
    align: "center",
  },
  {
    title: "公司",
    dataIndex: "officeName",
    width: 205,
    align: "center",
    ellipsis: true,
  },

  {
    title: "分公司",
    dataIndex: "childOfficeName",
    align: "center",
    width: 110,
  },
  {
    title: "项目名称",
    dataIndex: "projectName",
    width: 80,
    align: "center",
  },
  {
    title: "名次",
    dataIndex: "standRank",
    align: "center",
    width: 130,
  },
  {
    title: "操作",
    align: "center",
    width: 130,
    scopedSlots: { customRender: "action" },
  },
];

export default {
  components: { formIndex, StandardTable, fullModal, detailPage },
  data() {
    return {
      DetailPageModeEnum,
      standQuarter: 1,
      formConfig,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      columns,
      tableData: [],
      tableLoading: false,
      importLoading: false,
      modalVisible: false,  // 用于控制 modal 的显示与隐藏
      pageMode: '',
      editId: null,
      fullModalActions: null, // 用于处理浏览器历史状态
    };
  },
  computed: {
    ...mapGetters("account", ["user", "isGroupAccount", "isProjectAccount"]),
  },
  methods: {
    async onSearch() {
      const formModel = this.$refs.formindexRef.getFieldsValue()
      const params = {
        ...formModel,
        standQuarter: this.standQuarter,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
      };
      if (formModel.projectId) {
        const projectIndex = this.findIndex(this.formConfig, 'projectId')
        const projectName = this.formConfig[projectIndex].dataSource.find(item => item.id == formModel.projectId).title
        params.projectName = projectName
      }
      this.tableLoading = true;
      try {
        const res = await assessmentPage(params);
        if (res.data.code === 0) {
          this.tableData = res.data.data.list;
          this.pagination.total = res.data.data.total;
        } else {
          this.tableData = [];
        }
      } catch (e) {
        this.tableData = [];
      }
      this.tableLoading = false;
    },
    getChildOfficeId() {
      return this.$refs.formindexRef.getFieldValueByKey('childOfficeId')
    },
    // 获取项目列表
    async getProjectList(projectName, childOfficeId) {
      const res = await getProjectByOffice({ gcglbId: childOfficeId || this.getChildOfficeId(), ...(projectName && { projectName }) })
      const projectIndex = this.findIndex(this.formConfig, 'projectId')
      this.formConfig[projectIndex].dataSource = res.data.data.map((item) => ({ title: item.projectName, id: item.projectId }))
    },
    // 输入项目名称，进行项目数据筛选(实际是表单组件的search事件触发的)
    handleChange: debounce(async function (e) {
      // 不是输入项目名称，直接返回
      if (e.item.key !== 'projectId') return

      this.getProjectList(e.value)
    }, 300),
    async handlSelect({ val, keys }) {
      // 不是选择分公司，直接返回
      if (keys !== 'childOfficeId') return

      const projectIndex = this.findIndex(this.formConfig, 'projectId')
      // 分公司值清空，也把项目清空
      if (!val) {
        this.formConfig[projectIndex].dataSource = []
        this.$refs.formindexRef.setFieldsValue('', { projectId: '' }, 'projectId')
      } else {
        // 根据分公司，获取项目列表
        this.getProjectList('', val)
      }
    },
    findIndex(arr, key) {
      return arr.findIndex(item => item.key === key)
    },
    onReset() {
      this.$refs.formindexRef.resetFields();
      this.onSearch();
    },
    async onDelete(id) {
      const res = await assessmentDelete(id);
      if (res.data.code === 0) {
        this.$message.success(res.data.msg);
        this.onSearch();
      }
    },
    onPageChange({ pageSize, current }) {
      this.pagination.pageSize = pageSize;
      this.pagination.current = current;
      this.onSearch();
    },

    // 打开 modal
    openModal(pageMode, editId = null) {
      this.pageMode = pageMode;
      this.editId = editId;
      this.modalVisible = true;
      this.fullModalActions.pushHistoryState();
    },

    // 关闭 modal
    closeModal() {
      this.modalVisible = false;
      this.fullModalActions.removeListener(); // 关闭 modal 时清理事件监听
    },

    // 处理新增表单提交
    onSaveModal() {
      this.$message.success(`${DetailPageModeNameMap[this.pageMode]}成功`);
      this.closeModal();
      this.onSearch();
    },
    
    changeYear(date) {
      this.$refs.formindexRef.queryObj({
        standYear: date.format('YYYY'),
      })
    },

    async beforeUpload(file) {
      this.importLoading =  true

      const formData = new FormData();
      formData.append('file', file);
      const res = await assessmentImport(formData)
      if (res.data.code === 0) {
        this.$message.success(res.data.msg)
        this.onSearch()
      } else {
        this.$message.error(res.data.msg)
      }

      this.importLoading =  false
    },
    downloadFile,
  },

  mounted() {
    // 不是公司账号，去掉【公司】筛选条件
    if (!this.isGroupAccount) {
      const companyIndex = this.formConfig.findIndex((item) => item.key === 'officeId');
      this.formConfig.splice(companyIndex, 1);
    }

    // 当前是项目账号，只保留【选择年份】筛选条件
    if (this.isProjectAccount) {
      this.formConfig = this.formConfig.filter((item) => item.key === 'standYear');
    }

    // 初始化请求数据和表单数据
    request(QUERY_OFFICE_BY_USER, "get").then((res) => {
      // 公司和分公司下拉数据等
      this.formConfig[1].selectlist = res.data.data.map((item) => ({
        name: item.name,
        value: item.id,
      }));
      this.formConfig[2].selectlist = res.data.data.map((item) => ({
        name: item.name,
        value: item.id,
      }));
    });

    // 初始化分页数据
    this.onSearch();
    
    // 调用 useFullModal hook
    this.fullModalActions = useFullModal(this.closeModal);  // 使用 hook 来处理浏览器历史
  },

  beforeDestroy() {
    // 清理 hook
    this.fullModalActions.removeListener();
  },
};
</script>
