<template>
    <div>
        <div class="a_Approval">
            <!-- 暂时注释 -->
            <!-- <div class="c_i_header">
                <div v-for="item of conTabList" :key="item.id" :class="[item.class, 'c_i_h_center']">
                    <div>
                        {{ item.title }}
                    </div>
                    <div>
                        {{ item.total }}
                    </div>
                </div>
            </div> -->
            <!-- 表单封装
                projectObj：下拉弹出框第一项对象
                Overview：项目名称
                baiduMapBoolen：控制地图显示
                topSubmit：控制上部分按钮显示
                bottomSubmit：控制下部分按钮显示
                handleSubmit：表单提交
                handleChange：输入出发
                handlSelect：输入下拉选择触发
            -->
            <form-index ref="formindex" v-if="tenantId == 1 || (isOrg || isOffice)" :projectList="projectList" :formData="formData" Overview="项目管理/在建工程一览表" flexSubmit
                :flexAction="true" :baiduMapBoolen="false" @handleSubmit="handleSubmit" @handleChange="handleChange" @handlSelect="handlSelect">
                <!-- 按钮插槽 -->
                <!-- <template #flexSubmit>
                    <a-space class="btnBox">
                        <a-button type="primary" html-type="submit">
                            查询
                        </a-button>
                        <a-button @click="close">
                            重置
                        </a-button>
                    </a-space>
                </template> -->
                <!-- 下部分按钮插槽 -->
                <template #flexAction>
                    <a-space :style="{ marginTop: '4px' }">
                        <a-button type="primary" html-type="submit"> 查询 </a-button>
                        <a-button @click="close"> 重置 </a-button>
                    </a-space>
                </template>
            </form-index>

            <!-- 表格封装 -->
            <standard-table class="" :columns="columns" :dataSource="dataSource" rowKey="id" :scroll="{ x: 1300 }"
                :pagination="pagination" :loading="tableLoading" @change="onPageChange" :isAlert="false" :bordered="true" :btnArr="tableBtnArr">
                <template slot="index" slot-scope="{index}">
                    <span>
                       {{  (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}
                    </span>
                </template>
                <template slot="projectName" slot-scope="{record}">
                    <!-- :style="record.pjsPath == null || record.pjsPath == ''? '' : 'color:red'" -->
                    <a-tooltip placement="topLeft">
                        <template slot="title">
                            {{ record.projectName }}
                        </template>
                        <span :style="textStyle(record)">
                            {{ record.projectName }}
                        </span>
                    </a-tooltip>
                </template>
                <template slot="isAttached" slot-scope="{ text }">
                    <span>{{ text == 1 ? '是' : '否'  }}</span>
                </template>
                
                <template slot="monitorDisable" slot-scope="{ text }">
                    <a-tag color="red"  v-if="text == 1">
                        是
                    </a-tag>
                    <a-tag color="blue"  v-else>
                        否
                    </a-tag>
                </template>
                <div slot="action" slot-scope="{ record }">
                    <div class="sTables">
                        <div v-if="!isOrg" @click="viewEditing(record)">编辑</div>
                        <span v-if="isOffice && !isOrg" class="ml-1 mr-1" style="color: #dfdfdf">|</span>
                        <div v-if="isOffice && !isOrg" @click="tobeFinished(record)">完工</div>
                        <!-- <a-icon @click="viewEditing(record)" type="edit" theme="twoTone" /> -->
                        <!-- <a-icon @click="tobeFinished(record)" type="check-circle" theme="twoTone" two-tone-color="#52c41a" />
                        <a-icon @click="recorDelete(record)" type="delete"  theme="twoTone" two-tone-color="#eb2f96"  /> -->
                    </div>
                </div>
            </standard-table>
            <Export-loading :cPercent="cPercent" :isExporting="isExporting" :loadingText="loadingText"></Export-loading>
        </div>
        <a-modal v-model="finishDialogVisible" title="是否确认完工" :width="1200" @ok="handlefinish" @cancel="handleCancel" :destroyOnClose="true">
            <div>
                <form-index
                    ref="finishFormindex"
                    :formData="finishFormData"
                    :defaultFileList="defaultFileList"
                    @resourceUpload="resourceUpload"
                >
                </form-index>
            </div>
        </a-modal>
    </div>
</template>

<script>
// AQXJPROJECTSGETLIST
import formIndex from '@/pages/components/form/index'
import StandardTable from "@/components/table/StandardTable";
import { aqxjprojectsgetlist,aqxjprojectsdetail,aqxjProjectsupdate,aqxjprojectsdel} from '@/services/projectManagement';
import { request } from '@/utils/request'
import { exportProgress } from '@/pages/aqjyzl/export' // 导出方法
import ExportLoading from '@/components/ExportLoading/ExportLoading' // progress组件
import {
    PROJECT_OFFICE_CHILD,
    QUERY_OFFICE_BY_USER,
    AQXJ_PROJECTS_EXPORT_ZAIJIAN
} from '@/services/api'
import { formatNumber } from '@/utils/whole'
import { projectMsgGetList } from '@/services/projectManagement';
import moment from 'moment';
import { mapGetters } from "vuex";
import { storage } from '@/utils/storage'
import { isJianAnYi, isShiZheng } from '@/utils/system.js'

import {
  getExcellentDetail,
} from "@/services/labourProjectExcellent";
const columns = [
    {
        title: "序号",
        width: 65,
        scopedSlots: { customRender: 'index' },
        // customRender: (text, record, index) => `${index + 1}`,
        align: "center",
        fixed: 'left',
    },
    // {
    //     title: "党支部",
    //     dataIndex: "ssdzb",
    //     align: "center",
    //     width: 80,
    //     ellipsis: true,
    //     scopedSlots: { customRender: "ssdzb" },
    // },
    {
        title: "公司",
        dataIndex: "parentOffice",
        align: "center",
        width: 110,
        fixed: 'left'
    },
    {
        title: "工程管理部",
        dataIndex: "gcglbName",
        align: "center",
        width: 110,
        fixed: 'left'
    },
    {
        title: "项目名称",
        dataIndex: "projectName",
        width: 245,
        ellipsis: true,
        scopedSlots: { customRender: "projectName" },
        fixed: 'left',
    },
    {
        title: "项目类型",
        dataIndex: "ptype",
        align: "center",
        width: 65,
        scopedSlots: { customRender: "ptype" },
    },
    {
        title: '工程造价(万元)',
        align: "center",
        width: 100,
        dataIndex: 'projBudget',
        scopedSlots: { customRender: "projBudget" },
    },
    // {
    //     title: '工程地址',
    //     dataIndex: 'projAddress',
    //     tooltip: true
    // },
    {
        title: '工期（日历天）',
        dataIndex: 'gqDays',
        align: "center",
        width: 90,
        scopedSlots: { customRender: "gqDays" },
    },
    // {
    //     title: '工程概况',
    //     dataIndex: 'projDesc',
    //     align: "center",
    //     // width: 245,
    //     tooltip: true,
    //     key: 'projDesc',
    // },
    {
        title: '投标项目经理',
        dataIndex: 'xmjl',
        align: "center",
        width: 80,
        key: 'xmjl',
    }, {
        title: '建设单位',
        dataIndex: 'buildOfficeName',
        tooltip: true,
        // width: 160,
        key: 'buildOfficeName',
    }, {
        title: '监理单位',
        dataIndex: 'effcOfficeName',
        tooltip: true,
        // width: 160,
        key: 'effcOfficeName',
    }, {
        title: '当前施工状态',
        dataIndex: 'status',
        align: "center",
        width: 80,
        key: 'status',
    },
//     {
//     title: "附属项目",
//     dataIndex: "status",
//     align: "center",
//     width: 100,
//     key: "isAttached",
//     scopedSlots: { customRender: "isAttached" },
//   },
    {
        title: '形象进度',
        dataIndex: 'xxjd',
        align: "center",
        width: 100,
        key: 'xxjd',
        tooltip: true
    },
    {
        title: '重点监控项目',
        align: "center",
        width: 100,
        dataIndex: 'monitorDisable',
        scopedSlots: { customRender: "monitorDisable" },
    },
    {
        title: "操作",
        align: "center",
        width: 120,
        fixed: 'right',
        scopedSlots: { customRender: "action" },
    },
];
const formData = [{
    label: '工程管理部',
    placeholder: '请选择工程管理部',
    decorator: ['gcglbId', { rules: [{ required: false, message: '请选择工程管理部' }] }],
    type: 'select',
    key: 'gcglbId',
    selectlist: [],
    qb:true,
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
}, {
    label: '项目名称',
    placeholder: '请输入项目名称',
    decorator: ['projectName', { rules: [{ required: false, message: '请输入项目名称' }] }],
    type: 'inputGroup',
    key: 'projectName',
    dataSource: [],
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
}, {
    label: '施工状态',
    placeholder: '请选择施工状态',
    decorator: ['status', { rules: [{ required: false, message: '请选择施工状态' }] }],
    type: 'select',
    key: 'status',
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    selectlist: [{
        value: 3,
        name: '未开工'
    },{
        value: 0,
        name: '在建'
    },{
        value: 2,
        name: '停缓建'
    },
    // {
    //     value: 4,
    //     name: '完工'
    // }
    ]

}, {
    label: '项目类型',
    placeholder: '请选择项目类型',
    decorator: ['ptype', { rules: [{ required: false, message: '请选择项目类型' }] }],
    type: 'select',
    key: 'ptype',
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    selectlist: [
      {
        value: 1,
        name: "房建",
      },
      {
        value: 2,
        name: "市政",
      },
      {
        value: 3,
        name: "装饰装修",
      },
      {
        value: 4,
        name: "水利",
      },
    ],
}, {
        label: '重点监控项目',
        placeholder: '请选择重点监控状态',
        decorator: ['monitorDisable', { rules: [{ required: false, message: '请选择重点监控状态' }] }],
        type: 'select',
        key: 'monitorDisable',
        col: 6,
        labelCol: 6,
        wrapperCol: 14,
            selectlist: [
            {
                value: 1,
                name: "是",
            },
            {
                value: 0,
                name: "否",
            },
        ]
    },
    {
    type: "action",
    col: 6,
    display: true,
  },
]
const finishFormData = [
{
    label: "完工日期",
    placeholder: "请选择完工日期",
    decorator: [
      "endDate",
      { rules: [{ required: false, message: "请选择完工日期" }] },
    ],
    type: "datePicker",
    key: "endDate",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: false,
    format: "yyyy-MM-DD",
  },
	{
    label: "安全评价书获取时间",
    placeholder: "请选择安全评价书获取时间",
    decorator: [
      "pjsDate",
      { rules: [{ required: false, message: "请选择安全评价书获取时间" }] },
    ],
    type: "datePicker",
    key: "pjsDate",
    col: 8,
    labelCol: 12,
    wrapperCol: 12,
    display: false,
    format: "yyyy-MM-DD",
  },
	{
    label: "上传安全评价书",
    placeholder: "上传安全评价书",
    decorator: [
      "pjsPath",
      { rules: [{ required: false, message: "上传安全评价书" }] },
    ],
    type: "Upload",
    uploadLength: 3,
    key: "pjsPath",
    col: 8,
    labelCol: 10,
    wrapperCol: 12,
    display: false,
    class: "Upload",
  },
];
export default {
    mixins: [exportProgress], //导出方法注入
    components: {
        formIndex, //form表单封装
        StandardTable,//表格封装
        ExportLoading,//导出数据加载封装
    },
    data() {
        return {
            columns: columns, //表格数据
						finishDialogVisible: false,
						finishFormData: finishFormData,
						defaultFileList: [], //图片数组
						selectRecord: {},
            dataSource: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0,
                showTotal: (total, range) => `共 ${total} 条`,
            },
            tableLoading: false,
            formData: formData,
            conTabList: [{
                id: 1,
                title: "项目总数",
                total: 80,
                class: 'bg1'
            }, {
                id: 2,
                title: "本月新增项目数",
                total: 3,
                class: 'bg2'
            }, {
                id: 3,
                title: "在建项目",
                total: 78,
                class: 'bg1'
            }, {
                id: 4,
                title: "停工项目",
                total: 2,
                class: 'bg1'
            }],
            prams: {
                pageNumber: 1,
                pageSize: 10,
                queryType:1,
            },
            projectList: [],//获取当前项目数据
            loadingText:"数据加载中...",
            cPercent:0,
            isExporting:false,
        }
    },
    computed:{
        ...mapGetters("account", ["user"]),
        textStyle(){
            return function(val){
                if(val.statusw == 4 && (val.pjsPath != null || val.pjsPath != '')){
                    return 'color:red'
                }
            }
        },
				tenantId() {
					return storage.get('Tenant-Id') || 1
				},
				isOffice() { //是否公司级
					return this.user.isGcglb == 0 && this.user.loginType == 1;
				},
				isOrg() { //是否集团级
					return this.user.nowRoleKey.some(item => item == 'CLIGUE_AQMGLY')
				},
        tableBtnArr() {
					let arr = []
					if (this.tenantId == 1) {
						return arr = [{
							name: '导出',
							attrs: {
									type: 'primary'
							},
							click: this.doDowload
						}]
					} else {
						if (this.isOrg) {
							return arr = [{
								name: '导出',
								attrs: {
										type: 'primary'
								},
								click: this.doDowload
							}]
						} else if(this.isOffice) {
							return arr = [{
								name: '导出',
								attrs: {
										type: 'primary'
								},
								click: this.doDowload
							}, {
									name: '新建项目',
									attrs: {
											type: 'primary'
									},
									// 建安易开放此按钮
									hidden: isJianAnYi() === false && isShiZheng() === false,
									click: this.createItem
							}]
						} else {
							return arr
						}
					}
        },
    },
    watch:{
        loadingText:{
            handler(newName, oldName) {
                this.loadingText=newName;
            },
            immediate: true,
        }
    },
    created() {
        if(this.$route.query.pageNumber){
            this.prams.pageNumber = this.$route.query.pageNumber
        }
        // this.getgcglbList(PROJECT_OFFICE_CHILD + "/OFFICE_CHILD")
        this.getgcglbList(QUERY_OFFICE_BY_USER) 
        this.aqxjlist(this.prams)
    },
    mounted() {
        console.log(isJianAnYi(), '是否是建安易开放')
        // 非机施安全
        if (!this.$store.state.setting.realmType) {
          // 找到 title 为 工程管理部 的对象
          const column = this.columns.find(column => column.title === '工程管理部')
          const formData = this.formData.find(column => column.label === '工程管理部')
          // 修改属性值
          if (column) this.$set(column, 'title', '管理部门')
          if (formData) this.$set(formData, 'label', '管理部门')
					if (formData) this.$set(formData, 'placeholder', '请选择管理部门')
        }
    },
    activated() {
        const submited = storage.get('presenceList-submit')
        if (submited)
        setTimeout(() => {
            this.aqxjlist(this.prams)
        })
    },
    methods: {
				resourceUpload(obj) {
					this.$refs.finishFormindex.setFieldsValue('pjsPath', {'pjsPath': obj.url}, 'pjsPath')
				},
        // 完工
        tobeFinished(val){
            console.log(val)
					this.selectRecord = val
					this.finishDialogVisible = true
					this.$nextTick(() => {
						if (val.pjsPath) {
							const paths = val.pjsPath.split(',')
                            const defaultFileList = []
                            paths.forEach((path, index) => {
                                defaultFileList.push({
                                    uid: index,
                                    name: "安全评价书",
                                    url: path,
                                    key: "pjsPath",
                                    type: 'Upload'
                                })
                            })
                            this.defaultFileList = defaultFileList
							this.$refs.finishFormindex.setFieldsValue('endDate', {'endDate': val.endDate}, 'endDate')
                            this.$refs.finishFormindex.setFieldsValue('pjsDate', {'pjsDate': val.pjsDate}, 'pjsDate')
                            this.$refs.finishFormindex.setFieldsValue('pjsPath', {'pjsPath': val.pjsPath}, 'pjsPath')
						}
					})
        },
				handlefinish() {
					let obj = this.$refs.finishFormindex.getFieldValue()
					obj = Object.assign(obj, {id: this.selectRecord.id, projectId: this.selectRecord.projectId, status:1})
					this.update(obj)
				},
				handleCancel() {
					this.defaultFileList = []
					this.$refs.finishFormindex.setFieldsValue('pjsPath', {'pjsPath': ''}, 'pjsPath')
				},
        // 封装弹窗确定按钮
        confirm(title,content){
            return new Promise((resolve,reject) =>{
                this.$confirm({
                    title: title,
                    content: content,
                    onOk() {
                        resolve()
                    }
                });
            })
        },
        // 删除接口
        recorDelete(val){
            this.confirm('删除','是否删除').then(()=>{
                 aqxjprojectsdel(val.id).then(res =>{
                    if (res.data.code === 0) {
                        this.$message.success(
                            '删除成功',
                            10,
                        )
                        this.aqxjlist(this.prams)
                    } else {
                        this.$message.error(
                            res.data.msg,
                            10,
                        );
                    }
                })
            })
        },
        // 更新接口
        update(e){
            e.updated = this.user.id
            aqxjProjectsupdate(e).then(res => {
                if (res.data.code === 0) {
                    this.$message.success(
                        '操作成功',
                        10,
                    )
										this.finishDialogVisible = false
										this.defaultFileList = []
                                        this.aqxjlist(this.prams)
                } else {
                    this.$message.error(
                        res.data.msg,
                        10,
                    );
										this.finishDialogVisible = false
										this.defaultFileList = []
                }
            })
        },
            /**
         * 导出excel
         */
        doDowload(){
            let obj = this.$refs['formindex'].getFieldValue()
            obj.queryType = this.prams.queryType
            this.loadingText = "正在导出EXCEL文件，请稍候 ····";
            this.isExporting=true;
            this.cPercent=0;
            let fileName="在建工程一览表.xlsx";
            this.exportFunNomal2(AQXJ_PROJECTS_EXPORT_ZAIJIAN,obj,fileName,'application/vnd.ms-excel')
        },
        // 查看编辑
        viewEditing(e){
            aqxjprojectsdetail(e.id).then(res =>{
              // 获取项目创优详情

                if(res.data.code === 0){

                    // this.$router.push({
                    //     path:'../project_management/details_index',
                    //     query:{
                    //         data:res.data.data,
                    //         type:'table',
                    //         pageNumber:this.prams.pageNumber
                    //     }
                    // })

                  getExcellentDetail(res.data.data.projectId).then(resEx =>{

                    if (resEx.data.code === 0) {
                        if (resEx.data.data) {

                            // 赋值  cylevel cyyq
                            let p = res.data.data
                            p.cylevel = resEx.data.data.excellentInfo.cylevel
                            p.cyyq = resEx.data.data.excellentInfo.cyyq

                            this.$router.push({
                                path:'/project_details_index',
                                query:{
                                    data:res.data.data,
                                    type:'table',
                                    pageNumber:this.prams.pageNumber,
                                    exData: resEx.data.data,
                                    random: Math.random()
                                    }
                                })
                                // 刷新详情页面，form组件回显数据
                            // this.$refreshPage('/project_management/details_index',false)
                        } else {
                            this.$router.push({
                                path:'/project_details_index',
                                query:{
                                    data:res.data.data,
                                    type:'table',
                                    pageNumber:this.prams.pageNumber,
                                    exData: 0,
                                    random: Math.random()
                                    }
                                })
                            // 刷新详情页面，form组件回显数据
                            // this.$refreshPage('/project_management/details_index',false)
                        }
                    }
                  })
                }
            })
        },
        handleChange(e) {
            if (e.value) {
                this.projectArr = []
                this.getZdata(projectMsgGetList, e, 'fullName')
                // 获取当前匹配key，赋值相对于数组
                this.formData.forEach(item => {
                    if (e.item.key === item.key) {
                        item.dataSource = this.projectArr
                    }
                })
            } else {
                this.$refs['formindex'].setFieldsValue('', {}, 'projectName')
            }
        },
        // 接口封装
        /**
         * msg 接收接口名称
         * e 数据
         * name 参数名
         * */
        getZdata(msg, e, name) {
            let data = {
                [name]: e.value
            }
            msg(data).then(res => {
                if (res.status == 200) {
                    const data = res.data.data.list ? res.data.data.list : res.data.data
                    data.forEach(item => {
                        this.projectArr.push({
                            title: item[name],
                            id: item.id
                        })
                    })
                    this.projectList = data
                }
            })
        },
        // 新建项目
        createItem() {
            this.$router.push('./create_index')
        },
        // 项目数据
        ptypeState(type, status) {
            if (type == 'ptype') {
                const obj = {
                    1:'房建',
                    2:'市政',
                    3:'装饰装修',
                    // 3:'装修',
                }
                let str = null;
                Object.keys(obj).forEach(key =>{
                    if(key == status){
                        str =  obj[key]
                    }
                })
                return str
            } else {
                const obj = {
                    0:'在建',
                    1:'完工',
                    2:'停缓建',
                    3:'未开工',
                    4:'完工'
                }
                let str = null;
                Object.keys(obj).forEach(key =>{
                    if(key == status){
                        str =  obj[key]
                    }
                })
                return str
            }

        },
        // 特殊数据处理
        handlSelect({ val, keys }) {
            this.projectList.forEach(item => {
               if (keys == 'projectName' && item.id == val) {
                    this.$refs['formindex'].setFieldsValue(item.fullName, {}, 'projectName')
                }
            })
        },
        aqxjlist(prams) {
            storage.set('presenceList-submit', false)
            this.tableLoading = true;
            aqxjprojectsgetlist(prams).then(res => {
                if (res.data.code === 0) {
                    this.dataSource = res.data.data.list;
                    this.pagination.total = res.data.data.total;
                    this.dataSource.forEach(item => {
                        item.ptype = this.ptypeState('ptype', item.ptype)
                        item.statusw =  item.status
                        item.status = this.ptypeState('status', item.status)
                        item.projBudget = formatNumber(item.projBudget)
                    })
                }
                this.tableLoading = false;
            })
        },
        // 对下拉框做数据处理
        formDataFn(key, data) {
            console.log(data)
            let arr = []
            data.forEach(item => {
                arr.push({ 'name': item.master, 'value': item.id })
            })
            this.formData.forEach(item => {
                if (item.key == key) {
                    item.selectlist = arr
                }
            })
        },
        // 获取工程管理部数据
        getgcglbList(path) {
            request(path, 'get').then(res => {
                let data = res.data.data.filter(item => item.id != "3da0f5533f87425c9a9e8a11a7923300")
                this.formDataFn('gcglbId', data)
            })
        },
        // 提交表单数据
        handleSubmit(e) {
            this.prams.pageNumber = 1 // 点击查询按钮 从第一页开始
            this.pagination.current = 1
            let obj = Object.assign({},e, this.prams)
            this.aqxjlist(obj)
        },
        // 重置
        close() {
            this.prams = {
                pageNumber: 1,
                pageSize: 10,
                queryType:1
            }
						this.pagination.current = 1
            this.aqxjlist(this.prams)
            this.$refs['formindex'].setFieldsValue('', {}, 'projectName')
            this.$refs['formindex'].resetFields()

        },
        //表格 - 分页查询
        onPageChange(e) {
            let obj = {}
            if (this.$refs['formindex']) {
                obj = this.$refs['formindex'].getFieldValue()
            }
            this.prams.pageNumber = e.current;
            this.prams.pageSize = e.pageSize;
            this.pagination.current = e.current;
            this.pagination.pageSize = e.pageSize;
            this.aqxjlist(Object.assign({},this.prams,obj))
        },
    }
}
</script>

<style lang="less" scoped>
.bg1 {
    background: linear-gradient(180deg, rgba(242, 249, 254, 1) 0%, rgba(230, 244, 254, 1) 99%);
}

.bg2 {
    background: linear-gradient(180deg, rgba(245, 254, 242, 1) 0%, rgba(230, 254, 238, 1) 99%);
}


.c_i_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;

    .c_i_h_center {
        padding: 16px;
        border-radius: 4px;
        width: 180px;
        height: 130px;
        margin-right: 50px;

        div:nth-child(1) {
            font-size: 16px;
        }

        div:nth-child(2) {
            margin-top: 26px;
            font-size: 24px;
        }
    }
}

.sTables {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      width: 100%;
      text-align: center;
      cursor: pointer;
    }

    div:nth-child(1) {
      color: #307dfa;
    }

    div:nth-child(2) {
      color: #999999;
    }

    div:nth-child(3) {
      color: #307dfa;
    }
  }
</style>
