// 测试环境相关账号 officeId
const ShiZhengOfficeTestEnum = {
  // 集团账号
  GROUP: ['60f3dfc6d76225103fba6544d5f99ee4'],
  // 公司账号
  COMPANY: ['374fb1c63ba02954bf8459c83f7ae690']
}

// 生产环境相关账号 officeId
const ShiZhengOfficeProdEnum = {
  // 集团账号
  GROUP: ['652e5ce7a1ecead7a48f760eb31a57ff'],
  // 公司账号
  COMPANY: [
    // "广州市市政集团有限公司工程总承包分公司"
    '41eddc1f86ad76eb381c596d4480a210',
    // 广州市第二市政工程有限公司
    '586adbcb6bf2dead125fd7297ddf96e8',
    // 广州市第三市政工程有限公司
    '7b52b1707b822e06f3e9dadf57ef19d6',
    // 广西钦州建工建设集团有限公司
    '9743b7383b0c01fdfb652767fc071506',
    // 广州市市政工程机械施工有限公司
    'a571548ec0ccd741430bd175b53a393c',
    // 广建（清远）
    'adb303fdadb4fe2204d27a6bbf3bd482',
    // 广州市第一市政工程有限公司
    'b6fc66cf09247ce8a0671ea1b0b865d4',
    // 湖南省直建安公司
    'ef8431eca06ae3c2424931c89d9dddcf'
  ]
}

// officeId 枚举
export const ShiZhengOfficeEnum = {
  // 集团账号的测试环境、生产环境
  GROUP: [...ShiZhengOfficeTestEnum.GROUP, ...ShiZhengOfficeProdEnum.GROUP],
  // 公司账号的测试环境、生产环境
  COMPANY: [...ShiZhengOfficeTestEnum.COMPANY, ...ShiZhengOfficeProdEnum.COMPANY],
}