import { render, staticRenderFns } from "./AStepItem.vue?vue&type=template&id=45f62939&scoped=true"
import script from "./AStepItem.vue?vue&type=script&lang=js"
export * from "./AStepItem.vue?vue&type=script&lang=js"
import style0 from "./AStepItem.vue?vue&type=style&index=0&id=45f62939&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45f62939",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\web-project\\jiananyi\\suijian_client\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('45f62939')) {
      api.createRecord('45f62939', component.options)
    } else {
      api.reload('45f62939', component.options)
    }
    module.hot.accept("./AStepItem.vue?vue&type=template&id=45f62939&scoped=true", function () {
      api.rerender('45f62939', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/tool/AStepItem.vue"
export default component.exports