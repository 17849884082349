<template>
  <div class="wrap-cantainer ant-card ant-card-bordered ant-card-body">
    <a-row :gutter="16">
      <a-col :span="13">
        <!-- 地图 -->
        <mapOverview></mapOverview>
        <a-card class="mt-15 card-radius" style="height:310px;min-height: 599px;" :tab-list="awardTabList" :active-tab-key="awardTabKey" @tabChange="onAwardTabChange">
          <div v-if="awardTabKey === 'hjqk'" style="float:right" slot="tabBarExtraContent">
            <span>年度：</span>
            <a-range-picker
              style="width: 200px;"
              :mode="['year', 'year']"
              :default-value="[moment(awardParams[0], 'YYYY'), moment(awardParams[1], 'YYYY')]"
              :format="'YYYY'"
              @change="awardYearChange"
              @openChange="awardOpenChange"              
              @panelChange="awardPanelChange"
              :open="awardYearOpen"
              v-model="awardParams"
            />
          </div>
          <div v-if="awardTabKey === 'jpqk'" style="float:right" slot="tabBarExtraContent">
            <span>月份：</span>
            <a-range-picker
              style="width: 200px;"
              :mode="['month', 'month']"
              :default-value="[moment(awardMonParams[0], 'YYYY-MM'), moment(awardMonParams[1], 'YYYY-MM')]"
              :format="'YYYY-MM'"
              @change="awardMonChange"
              @openChange="awardMonOpenChange"              
              @panelChange="awardMonPanelChange"
              :open="awardMonOpen"
              v-model="awardMonParams"
            />
            
          </div>
          <template v-if="awardTabKey === 'jpqk'">
            <a-table
            size="middle"
            :bordered="true"
            :columns="awardColumns"
            :dataSource="awardDataSource"
            :pagination="false"
            >
            </a-table>
          </template>
          <template v-if="awardTabKey === 'hjqk'">
            <div class="award-box" v-for="(item,index) in cateStatics" :key="index">
              <div class="award-title mb-1 ml-1">
                {{item.title}}（{{getNums(item.nums,item.otherNums)}}）
              </div>
              <vue-scroll :ops="ops" style="width:100%;height:152px" class="scroll-container">
                <a-row :gutter="[12,8]">
                  <a-col :span="8" v-for="(items,index2) in item.cateList" :key="items.certificateCode + index2">
                    <!-- 市级创优-其他证书 -->
                    <a-popover v-if="items.certificateLevel == 4" title="其他证书">
                      <template slot="content">
                        <div v-for="other in items.otherList.cateList" :key="other.certificateCode" class="flex j-between" style="min-width: 220px">
                          <template v-if="other.nums">
                            <span>{{ other.certificateName }}</span>
                            <span class="font-bold ml-auto">{{ other.nums }}</span>
                          </template>
                        </div>
                      </template>
                      <div class="flex award-tab">
                        <div class="ellipsis-2 award-tab-left">其他证书</div>
                        <div class="award-tab-center"></div>
                        <div class="award-tab-right font-bold text-center" :style="{fontSize: item.otherNums ? 'normal': '17px'}">{{ item.otherNums ? item.otherNums : '-'}}</div>
                      </div>
                    </a-popover>
                    <div v-else class="flex award-tab">
                      <div class="award-tab-left ellipsis-2">{{items.certificateName}}</div>
                      <div class="award-tab-center"></div>
                      <div :style="{fontSize: items.nums ? 'normal': '17px'}" class="award-tab-right font-bold text-center">{{ items.nums ? items.nums : '-'}}</div>
                    </div>
                  </a-col>
                </a-row>
              </vue-scroll>
            </div>
          </template>
        </a-card>
      </a-col>
      <a-col :span="11">
        <a-card
            class="card-radius"
            style="width:100%;height:405px;"
            :tab-list="tabListNoTitle"
            :active-tab-key="noTitleKey"
            @tabChange="key => onTabChange(key, 'noTitleKey')"
        >
          <p v-if="noTitleKey === 'office'">
            <a-row :gutter="16" style="padding-left:5%">
              <a-col :span="6">
                <a-statistic
                    title="检查项目总数"
                    :value="gkObj.projects"
                    suffix="个"
                    :value-style="{ fontSize: '28px',  }"
                    style="margin-bottom: 35px"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="一般隐患数"
                    :value="yhzs > gkObj.zdyhs ? yhzs - gkObj.zdyhs : 0"
                    suffix="个"
                    :value-style="{ color: '#cf1322',fontSize: '28px',  }"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="重大隐患数"
                    suffix="个"
                    :value="gkObj.zdyhs"
                    :value-style="{ color: '#cf1322',fontSize: '28px',  }"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="隐患整改率"
                    :value="gkObj.zgl"
                    :value-style="{ fontSize: '28px', }"
                />
              </a-col>

              <!--                <a-col :span="6">-->
              <!--                  <a-statistic-->
              <!--                    title="超时未整改数"-->
              <!--                    :value="`${gkObj.cswzgs|0}`"-->
              <!--                    suffix="个"-->
              <!--                    :value-style="{ color: '#cf1322' }"-->
              <!--                  />-->
              <!--                </a-col>-->

            </a-row>
            <a-row :gutter="16" style="padding-left:5%">
              <a-col :span="6">
                <a-statistic
                    title="检查总数"
                    :value="totalChecks"
                    suffix="次"
                    :value-style="{ fontSize: '28px',  }"
                    style="margin-bottom: 35px"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="日常检查总数"
                    :value="`${gkObj.checks - gkObj.zxjc - gkObj.tgdcnt}`"
                    suffix="次"
                    :value-style="{ fontSize: '28px',  }"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="专项检查总数"
                    :value="`${gkObj.zxjc|0}`"
                    suffix="次"
                    :value-style="{ fontSize: '28px',  }"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="带班检查总数"
                    :value="`${gkObj.dbCnt|0}`"
                    suffix="次"
                    :value-style="{ fontSize: '28px',  }"
                />
              </a-col>
            </a-row>
            <a-row :gutter="16" style="padding-left:5%">
               <a-col :span="6">
                <a-statistic
                    title="安全验收总数"
                    :value="`${gkObj.aqyscnt|0}`"
                    suffix="份"
                    :value-style="{ fontSize: '28px',  }"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="停工通知书总数"
                    :value="`${gkObj.tgdcnt|0}`"
                    suffix="份"
                    :value-style="{ fontSize: '28px',  }"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="处罚通知书总数"
                    :value="`${gkObj.cfdcnt|0}`"
                    suffix="份"
                    :value-style="{ fontSize: '28px',  }"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="安全生产约谈总数"
                    :value="`${gkObj.ytcnt|0}`"
                    suffix="次"
                    :value-style="{ fontSize: '28px',  }"
                />
              </a-col>

            </a-row>
          </p>
          <p v-else-if="noTitleKey === 'gcglb'">
            <a-row :gutter="16" style="padding-left:5%">
              <a-col :span="6">
                <a-statistic
                    title="检查项目总数"
                    :value="gcglbObj.projects"

                    suffix="个"
                    :value-style="{ color: 'blue' }"
                    style="margin-bottom: 40px"
                />
              </a-col>
              <!-- <a-col :span="6">
                <a-statistic
                  title="隐患总数"
                  :value="gcglbYhzs"
                  suffix="个"
                  :value-style="{ color: '#cf1322' }"
                />
              </a-col> -->
              <a-col :span="6">
                <a-statistic
                    title="一般隐患"
                    :value="`${(gcglbObj.yhs-gcglbObj.zdyhs)|0}`"
                    suffix="个"
                    :value-style="{ color: '#cf1322' }"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="重大隐患"
                    :value="`${gcglbObj.zdyhs|0}`"
                    suffix="个"
                    :value-style="{ color: '#cf1322' }"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="隐患整改率"
                    :value="gcglbObj.zgl"
                />
              </a-col>

              <!-- <a-col :span="6">
                <a-statistic
                  title="超时未整改数"
                  :value="`${gcglbObj.cswzgs|0}`"
                  suffix="个"
                  :value-style="{ color: '#cf1322' }"
                />
              </a-col> -->

            </a-row>
            <a-row :gutter="16" style="padding-left:5%">
              <a-col :span="6">
                <a-statistic
                    title="安全验收总数"
                    :value="`${gkObj.aqyscnt|0}`"
                    suffix="份"
                    :value-style="{ color: 'green' }"
                    style="margin-bottom: 40px"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="检查总数"
                    :value="gcglbObj.checks"
                    suffix="次"
                    :value-style="{ color: 'green' }"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="日常检查总数"
                    :value="`${isNaN(gcglbObj.checks - gcglbObj.zxjc - gkObj.tgdcnt) ? 0 : gcglbObj.checks - gcglbObj.zxjc - gkObj.tgdcnt}`"
                    suffix="次"
                    :value-style="{ color: 'green' }"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="专项检查总数"
                    :value="`${gcglbObj.zxjc|0}`"
                    suffix="次"
                    :value-style="{ color: 'purple' }"
                />
              </a-col>
            </a-row>
          </p>
          <p v-else-if="noTitleKey === 'xmzj'">
            <a-row :gutter="16" style="padding-left:5%">
              <a-col :span="6">
                <a-statistic
                    title="检查项目总数"
                    :value="gcglbObj.projects"

                    suffix="个"
                    :value-style="{ color: 'blue' }"
                    style="margin-bottom: 40px"
                />
              </a-col>
              <!-- <a-col :span="6">
                <a-statistic
                  title="隐患总数"
                  :value="gcglbYhzs"
                  suffix="个"
                  :value-style="{ color: '#cf1322' }"
                />
              </a-col> -->
              <a-col :span="6">
                <a-statistic
                    title="一般隐患"
                    :value="`${gcglbYhzs-gcglbObj.zdyhs}`"
                    suffix="个"
                    :value-style="{ color: '#cf1322' }"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="重大隐患"
                    :value="`${gcglbObj.zdyh|0}`"
                    suffix="个"
                    :value-style="{ color: '#cf1322' }"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="隐患整改率"
                    :value="gcglbObj.zgl"
                />
              </a-col>

              <!-- <a-col :span="6">
                <a-statistic
                  title="超时未整改数"
                  :value="`${gcglbObj.cswzgs|0}`"
                  suffix="个"
                  :value-style="{ color: '#cf1322' }"
                />
              </a-col> -->

            </a-row>
            <a-row :gutter="16" style="padding-left:5%">
              <a-col :span="6">
                <a-statistic
                    title="安全验收总数"
                    :value="`${gkObj.aqyscnt|0}`"
                    suffix="份"
                    :value-style="{ color: 'green' }"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="检查总数"
                    :value="gcglbObj.checks"
                    suffix="次"
                    :value-style="{ color: 'green' }"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="日常检查总数"
                    :value="`${isNaN(gcglbObj.checks - gcglbObj.zxjc - gkObj.tgdcnt) ? 0 : gcglbObj.checks - gcglbObj.zxjc - gkObj.tgdcnt}`"
                    suffix="次"
                    :value-style="{ color: 'green' }"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="专项检查总数"
                    :value="`${gcglbObj.zxjc|0}`"
                    suffix="次"
                    :value-style="{ color: 'purple' }"
                />
              </a-col>
            </a-row>
          </p>
          <div slot="tabBarExtraContent" style="float:right;max-width:350px">
            <div style="max-width: 60%;float:right">
              <a-range-picker v-if="monthQueryParams.queryType==3"  v-model="monthQueryParams.plan_date" :default-value="[moment(firstDay, dateFormat), moment(nowDay, dateFormat)]"
                              :format="dateFormat"  @change="mtChangeDate"/>
              <a-month-picker style="width: 100px" v-if="monthQueryParams.queryType==2"  @change="mtChangeDate" placeholder="请选择月份" :default-value="moment(nowMonth, monthFormat)" :format="monthFormat"  v-model="monthQueryParams.plan_date">
                <a-icon slot="suffixIcon" type="smile"/>
              </a-month-picker>
              <a-date-picker
                  v-if="monthQueryParams.queryType==1"
                  mode="year"
                  v-model="monthQueryParams.plan_date"
                  placeholder="请输入年份"
                  :default-value="moment(nowYear, yearFormat)" :format="yearFormat"
                  style="width: 100px"
                  :open="monthQueryParams.yearShowOne"
                  @openChange="monthOpenChangeOne"
                  @panelChange="monthPanelChangeOne"/>
            </div>
            <a-select  v-model="monthQueryParams.queryType" style="width: 80px;float:right;top:12px" @change="mtChangeTypeDate">
              <a-select-option :key="1"> 年 </a-select-option>
              <a-select-option :key="2"> 月 </a-select-option>
              <a-select-option :key="3">其他</a-select-option>
            </a-select>
          </div>
        </a-card>
      </a-col>
      <a-col :span="11" class="mt-15">
        <!-- 检查隐患类型统计 -->
        <checkDangerOverview fatherType="office"></checkDangerOverview>
      </a-col>
    </a-row>
    <a-row v-if="false" :gutter="16">
      <a-col :span="24" class="mt-15">
        <a-row :gutter="16">
          <a-col :span="13">
            <a-card class="card-radius">
              <span slot="title">{{gcglbtitle||'公司' }}检查次数统计（总计：{{checkTimeTotal}}次）</span>
              <div style="float:right" slot="extra">
                <div style="max-width: 60%;float:right">
                  <a-range-picker v-if="xjTimesQueryParams.queryType==3"  v-model="xjTimesQueryParams.plan_date" :default-value="[moment(firstDay, dateFormat), moment(nowDay, dateFormat)]"
                                  :format="dateFormat"  @change="xjTimesChangeDate"/>
                  <a-month-picker style="width: 100px" v-if="xjTimesQueryParams.queryType==2"  @change="xjTimesChangeDate" placeholder="请选择月份" :default-value="moment(nowMonth, monthFormat)" :format="monthFormat"  v-model="xjTimesQueryParams.plan_date">
                    <a-icon slot="suffixIcon" type="smile"/>
                  </a-month-picker>
                  <a-date-picker
                      v-if="xjTimesQueryParams.queryType==1"
                      mode="year"
                      v-model="xjTimesQueryParams.plan_date"
                      placeholder="请输入年份"
                      :default-value="moment(nowYear, yearFormat)" :format="yearFormat"
                      style="width: 100px"
                      :open="xjTimesQueryParams.yearShowOne"
                      @openChange="xjTimesOpenChangeOne"
                      @panelChange="xjTimesPanelChangeOne"/>
                </div>
                <a-select  v-model="xjTimesQueryParams.queryType" style="width: 80px;float:right" @change="checkTimeTypeChange">
                  <a-select-option :key="1"> 年 </a-select-option>
                  <a-select-option :key="2"> 月 </a-select-option>
                  <a-select-option :key="3">其他</a-select-option>
                </a-select>

              </div>
              <!-- <div style="float:right" slot="extra">
                <a-select v-model="checkTimeType" style="width: 100px" @change="checkTimeChange">
                  <a-select-option :key="1"> 当月每天 </a-select-option>
                  <a-select-option :key="2"> 当年每月 </a-select-option>
                </a-select>
              </div> -->
              <div id="checkTimesChart" style="width:98%;height:300px"></div>
            </a-card>
          </a-col>
          <a-col :span="11">
            <a-card class="card-radius">
              <div style="float:right" slot="extra">
                <a-date-picker
                    mode="year"
                    v-model="zglParams.plan_date"
                    placeholder="请输入年份"
                    :default-value="moment(nowYear, yearFormat)" :format="yearFormat"
                    style="width: 100px"
                    :open="zglParams.yearShowOne"
                    @openChange="zglOpenChangeOne"
                    @panelChange="zglPanelChangeOne"/>
              </div>
              <span slot="title">{{gcglbtitle||'公司' }}每月隐患整改率统计情况  （整改率：{{yhzglTotal||0}}）</span>
              <div id="yhzglChart" style="width:98%;height:300px"></div>
            </a-card>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="24" class="mt-15">
            <a-card class="card-radius" title='项目月度自检情况'>
              <div style="float:right" slot="extra">
                <!-- <div style="max-width: 60%;float:right"> -->
                <!-- <a-range-picker v-if="proQueryParams.queryType==3"  v-model="proQueryParams.plan_date" :default-value="[moment(firstDay, dateFormat), moment(nowDay, dateFormat)]"
                      :format="dateFormat"  @change="proChangeDate"/> -->
                <a-month-picker style="width: 100px"  @change="proChangeDate" placeholder="请选择月份" :default-value="moment(nowMonth, monthFormat)" :format="monthFormat"  v-model="proQueryParams.plan_date">
                  <a-icon slot="suffixIcon" type="smile"/>
                </a-month-picker>
                <!-- <a-date-picker
                  v-if="proQueryParams.queryType==1"
                  mode="year"
                  v-model="proQueryParams.plan_date"
                  placeholder="请输入年份"
                  :default-value="moment(nowYear, yearFormat)" :format="yearFormat"
                  style="width: 100px"
                  :open="proQueryParams.yearShowOne"
                  @openChange="proOpenChangeOne"
                  @panelChange="proPanelChangeOne"/> -->
                <!-- </div> -->
                <!-- <a-select  v-model="proQueryParams.queryType" style="width: 80px;float:right" @change="proTop10Change">
                  <a-select-option :key="1"> 年 </a-select-option>
                  <a-select-option :key="2"> 月 </a-select-option>
                  <a-select-option :key="3">其他</a-select-option>
                </a-select> -->

              </div>
              <vue-scroll :ops="ops" style="width:100%;height:258px">
                <a-list bordered :data-source="dataSource" item-layout="horizontal" >
                  <a-list-item slot="renderItem" slot-scope="item,index">
                    <a-list-item-meta>
                      <template slot="description">{{index+1}}.{{item.projectName}}</template>

                    </a-list-item-meta>
                    <a>{{item.cnt}}次</a>
                  </a-list-item>
                </a-list>
              </vue-scroll>
            </a-card>
      </a-col>
      <a-col :span="24" class="mt-15">
        <a-card class="card-radius">
          <a-card 
                  :tab-list="tabNoTitle"
                  :active-tab-key="TitleKey"
                  @tabChange="key => TabChange(key, 'TitleKey')" >
            <div slot="tabBarExtraContent" style="float:right;max-width:350px">
              <div style="float:right;max-width: 60%;">
                <a-range-picker v-if="gcglbQueryParams.queryType==3"  v-model="gcglbQueryParams.plan_date" :default-value="[moment(firstDay, dateFormat), moment(nowDay, dateFormat)]"
                                :format="dateFormat"  @change="gcglbChangeDate"/>
                <a-month-picker style="width: 100px" v-if="gcglbQueryParams.queryType==2"  @change="gcglbChangeDate" placeholder="请选择月份" :default-value="moment(nowMonth, monthFormat)" :format="monthFormat"  v-model="gcglbQueryParams.plan_date">
                  <a-icon slot="suffixIcon" type="smile"/>
                </a-month-picker>
                <a-date-picker
                    v-if="gcglbQueryParams.queryType==1"
                    mode="year"
                    v-model="gcglbQueryParams.plan_date"
                    placeholder="请输入年份"
                    :default-value="moment(nowYear, yearFormat)" :format="yearFormat"
                    style="width: 100px"
                    :open="gcglbQueryParams.yearShowOne"
                    @openChange="gcglbOpenChangeOne"
                    @panelChange="gcglbPanelChangeOne"/>
              </div>
              <a-select  v-model="gcglbQueryParams.queryType" style="width: 100px;float:right;top:12px" @change="gcglbTimeChange">
                <a-select-option :key="1"> 年 </a-select-option>
                <a-select-option :key="2"> 月 </a-select-option>
                <a-select-option :key="3">其他</a-select-option>
              </a-select>
            </div>
          </a-card>
          <p v-if="TitleKey==='office'">
            <a-table
                :bordered="true"
                :columns="columns1"
                :dataSource="gcglbDatas"
                :pagination="false"
            >
            </a-table>
          </p>
          <p v-else-if="TitleKey==='gcglb'">
            <a-table
                :bordered="true"
                :columns="columns2"
                :dataSource="gcglbDatas2"
                :pagination="false"
            >
            </a-table>
          </p>
          <p v-else-if="TitleKey==='tgcfd'">
            <a-table
                :bordered="true"
                :columns="columns3"
                :dataSource="tgcfDatas"
                :pagination="false"
            >
            </a-table>
          </p>
        </a-card>
      </a-col>
    </a-row>
    
  </div>



</template>

<script>
const awardColumns = [
  {
    title: '安全创优奖项',
    dataIndex: 'cyLevelName',
    align: 'center',
    scopedSlots: { customRender: 'cyLevelName' }
  },
  // {
  //   title: '获奖项目 (个)',
  //   dataIndex: 'projectNum',
  //   align: 'center',
  //   scopedSlots: { customRender: 'projectNum' },
  // },
  {
    title: '奖项总数(项)',
    dataIndex: 'totalNum',
    align: 'center',
    scopedSlots: { customRender: 'totalNum' },
  },
  {
    title: '房建（项）',
    dataIndex: 'house',
    align: 'center',
    scopedSlots: {customRender: 'house'},
  },
  {
    title: '市政（项）',
    dataIndex: 'government',
    align: 'center',
    scopedSlots: {customRender: 'government'},
  },
]
const columns1=[
  {
    title: '工程管理部',
    dataIndex: 'officeName',
    align: 'center',
    scopedSlots: { customRender: 'officeName' }
  },
  {
    title: '在建项目',
    dataIndex: 'totalProjs',
    align: 'center',
    scopedSlots: { customRender: 'totalProjs' }
  },
  {
    title: '排查项目数量',
    dataIndex: 'checkProjs',
    align: 'center',
    scopedSlots: { customRender: 'checkProjs' }
  },
  {
    title: '安全隐患检查次数',
    dataIndex: 'checkTimes',
    align: 'center',
    scopedSlots: { customRender: 'checkTimes' }
  },
  {
    title: '检查覆盖率',
    dataIndex: 'checkProjFgl',
    align: 'center',
    scopedSlots: { customRender: 'checkProjFgl' }
  },
  {
    title: '隐患总数',
    dataIndex: 'yhzs',
    align: 'center',
    scopedSlots: { customRender: 'yhzs' }
  },
  {
    title: '隐患整改率',
    dataIndex: 'yhzgl',
    align: 'center',
    scopedSlots: { customRender: 'yhzgl' }
  },
]
const columns2=[
  {
    title: '项目名称',
    dataIndex: 'projectName',
    align: 'left',
    scopedSlots: { customRender: 'projectName' }
  },
  {
    title: '安全隐患检查次数',
    dataIndex: 'checkCnt',
    align: 'center',
    scopedSlots: { customRender: 'checkCnt' }
  },
  {
    title: '隐患总数',
    dataIndex: 'wtzs',
    align: 'center',
    scopedSlots: { customRender: 'wtzs' }
  },
  {
    title: '检查覆盖率',
    dataIndex: 'yhzgl',
    align: 'center',
    scopedSlots: { customRender: 'yhzgl' }
  },
]
const columns3=[
  {
    title: '工程管理部',
    dataIndex: 'officeName',
    align: 'center',
    scopedSlots: { customRender: 'officeName' }
  },
  {
    title: '停工通知书总数',
    dataIndex: 'stops',
    align: 'center',
    scopedSlots: { customRender: 'stops' }
  },
  {
    title: '处罚通知书总数',
    dataIndex: 'cfds',
    align: 'center',
    scopedSlots: { customRender: 'cfds' }
  },
  {
    title: '安全生产约谈总数',
    dataIndex: 'yts',
    align: 'center',
    scopedSlots: { customRender: 'yts' }
  },
]
const categoryTitle = ['安全管理','文明施工','脚手架','基坑与模板','高处作业','施工用电','大型设备','起重吊装','施工机具']
import {
  getAwardList, getAwardMonList,getAwardMonStatic,getExcellentCerDic,getExcellentDetail
} from "@/services/labourProjectExcellent";
import {mapGetters, mapMutations} from 'vuex';
import { aqxjprojectsdetail } from '@/services/projectManagement';
import { QUERY_PROJECT_GK,
  GCGLB_AQXJ_DETAILS,
  CURRENT_CHECKTIMES_CHART,
  PROJECT_getStopCfdCnts,
  PROJECT_AQXJ_GCGLB_EXPORT,
  PROBLEMS_BY_TOTAL_QUERY,
  PROJECT_CHECK_NO8,
  CURRENT_YHZGL_CHART} from '@/services/api'
import {request} from '@/utils/request'
import { storage } from '@/utils/storage'
import moment from 'moment';
import { getIsGcglb, getDeptDepth } from '@/services/user'
import mapOverview from '@/components/safeHome/mapOverview/index.vue'
import checkDangerOverview from '@/components/safeHome/checkDangerOverview/index.vue'

export default {
  name: "newSafeHomePage",
  components: { mapOverview, checkDangerOverview },
  data() {
    return {
      arrRoles:['admin', 'OFFICE_LEADER', 'OFFICE_AQMGLY', 'OFFICE_AQ_LEADER', 'OFFICE_GC_LEADER'], // 查看详情权限
      categoryTitle, 
      currentGk: {}, // 当月安全管理统计汇总
      workData: {}, // 劳务数据汇总
      projectList: [],
      defaultProject: '全部',
      gcObj: {}, // 工程类型守护
      ManagCWA: {}, // 管理人员数据
      sevenKq: [],
      projectData: [], // table表格数据
      pagination: {
        y:'200px'
      },
      projectColumns: [
        {
          title: '项目',
          dataIndex: 'projectName',
          key: 'projectName',
          width: '35%',
          scopedSlots: { customRender: 'projectName' },
        },
        {
          title: '项目经理',
          dataIndex: 'xmjl',
          width: '25%',
          align: 'center',
          key: 'xmjl',
          scopedSlots: { customRender: 'xmjl' },
        },
        {
          title: '电话',
          dataIndex: 'xmjlPhone',
          width: '25%',
          align: 'center',
          key: 'xmjlPhone',
          scopedSlots: { customRender: 'xmjlPhone' },
        },

      ],
      awardColumns,
      awardDataSource: [{
          "cyLevelName": "市级",
          "cyLevel": "1",
          "totalNum": 0,
          "house": 0,
          "projectNum": 0,
          "government": 0
        },
        {
          "cyLevelName": "省级",
          "cyLevel": "2",
          "totalNum": 0,
          "house": 0,
          "projectNum": 0,
          "government": 0
        },
        {
          "cyLevelName": "国家级",
          "cyLevel": "3",
          "totalNum": 0,
          "house": 0,
          "projectNum": 0,
          "government": 0
        }],
      awardParams: [moment().format('YYYY'), moment().format('YYYY')],
      awardMonParams: [moment().format('YYYY-MM'), moment().add(1, "months").format('YYYY-MM')],
      awardYearOpen: false,
      awardMonOpen: false,
      columns1,
      columns2,
      columns3,
      yhzs:0,
      gcglbYhzs:0,
      gkObj:{},
      gcglbObj:{},
      gcglbDatas:[],
      tgcfDatas:[],
      dataSourceTypeCount:[],
      dataSource:[],
      dataSourceYhs:[],
      dataSourceYhzgl:[],
      checkTimeType:1,
      checkTimeText:"当月",
      checkTimeTotal:0,
      yhzsType:1,
      yhzsText:"当月",
      yhzglType:2,
      yhzglText:"当年",
      yhzglTotal:0,
      cswzgType:1,
      cswzgText:"当月",
      cswzgsTotal:0,
      monthFormat: 'YYYY-MM',
      nowMonth:null,
      dateFormat: 'YYYY-MM-DD',
      nowYear:null,
      yearFormat:'YYYY',
      firstDay:null,
      nowDay:null,
      yearShowOne:false,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#4a86e8",//滚动条颜色
          opacity: 0.8,//滚动条透明度
          "overflow-x": "hidden"
        }
      },
      proQueryParams:{
        queryType:2,
        plan_date:null,
        yearShowOne:false,
        startDate:moment(new Date()).format('YYYY-MM')+"-01",
        endDate:moment(new Date()).format('YYYY-MM-DD'),
        month:moment(new Date()).format('YYYY-MM'),
      },
      xjTimesQueryParams:{
        queryType:1,
        plan_date:null,
        startDate:moment(new Date()).format('YYYY-MM')+"-01",
        endDate:moment(new Date()).format('YYYY-MM-DD'),
        month:moment(new Date()).format('YYYY'),
        yearShowOne:false,
      },
      zglParams:{
        yearShowOne:false,
        plan_date:null,
        month:moment(new Date()).format('YYYY'),
      },
      monthQueryParams:{
        isGcglb:0,
        queryType:2,
        plan_date:null,
        startDate:moment(new Date()).format('YYYY-MM')+"-01",
        endDate:moment(new Date()).format('YYYY-MM-DD'),
        month:moment(new Date()).format('YYYY-MM'),
        yearShowOne:false,
      },
      gcglbtype:false,
      gcglbQueryParams:{
        queryType:2,
        plan_date:null,
        startDate:moment(new Date()).format('YYYY-MM')+"-01",
        endDate:moment(new Date()).format('YYYY-MM-DD'),
        month:moment(new Date()).format('YYYY-MM'),
        yearShowOne:false,
      },
      tabListNoTitle: [
        {
          key: 'office',
          tab: '公司检查',
        },
        {
          key: 'gcglb',
          tab: '工程管理部检查',
        },
        // {
        //   key: 'xmzj',
        //   tab: '项目部自检',
        // },
      ],
      awardTabList: [
        {
          key: 'hjqk',
          tab: '安全创优获奖情况',
        },
        {
          key: 'jpqk',
          tab: '安全创优检评情况',
        },
      ],
      awardTabKey: 'hjqk',
      tabNoTitle:[
        {
          key: 'office',
          tab: `工程管理部检查情况`,

        },
        {
          key: 'tgcfd',
          tab: '停工/处罚/约谈情况',
        }
      ],
      TitleKey:'office',
      key: 'office',
      noTitleKey: 'office',
      gcglbtitle:'',
      cateStatics: [], // 创优安全证书列表
      cerDic: [], // 创优证书字典
      cyLevelTitle: [{cyLevel: 1,title:'市级安全创优奖项'},{cyLevel: 2,title:'省级安全创优奖项'},{cyLevel: 3,title:'国家级安全创优奖项'},{cyLevel: 4,title:'其他'}],
      creList: [],
      goViewSwitch: false,
    }
  },
  computed: {
    ...mapGetters('account', ['user']),
    canOperate() {
      return this.user.nowRoleKey.some(role => this.arrRoles.includes(role));
    },
    totalChecks() {
      let total = 0;
      if (this.gkObj.checks && this.gkObj.checks != null) {
        total = total + this.gkObj.checks;
      }
      if (this.gkObj.dbCnt && this.gkObj.dbCnt != null) {
        total = total + this.gkObj.dbCnt;
      }
      return total
    },
  },
    filters: {
      ellipsis(value) {
        if (!value) return ''
        if (value.length > 80) {
          return value.slice(0, 80) + '...'
        }
        return value
      }
    },
    created() {
      getIsGcglb().then(res => {
        if (res.data.code == 0) {
          let user = this.user
          user.isGcglb = res.data.data
          this.setUser(user)
        } else {
          this.$message.error(res.data.msg)
        }
      })

      //获取公司层级
      const tenantId = storage.get('Tenant-Id') ? storage.get('Tenant-Id') : '1'
      if (tenantId != 1) {
        getDeptDepth().then(res => {
          if (res.data.code == 0) {
            let user = this.user
            user.checkLevel = res.data.data
            this.setUser(user)
          } else {
            this.$message.error(res.data.msg)
          }
        })
      }

      this.nowMonth = moment(new Date()).format('YYYY-MM');
      this.firstDay = moment(new Date()).format('YYYY-MM') + "-01";
      this.nowDay = moment(new Date()).format('YYYY-MM-DD');
      this.nowYear = moment(new Date()).format('YYYY');
      let type = this.$store.getters["account/user"].isGcglb
      if (type === 1) {
        this.gcglbtype = true
        this.isleader=this.$store.getters["account/user"].nowRoleKey.includes('OFFCIE_DZBSJ')
        if(this.isleader){
          this.tabNoTitle=[{key: 'office', tab: `工程管理部检查情况`},{key: 'gcglb', tab: `项目部检查情况`},]
        }else{
          this.TitleKey='gcglb'
          this.tabNoTitle=[{key: 'gcglb', tab: `项目部检查情况`}]
        }
        this.tabListNoTitle = [{key: 'office', tab: '工程管理部检查概况'}];
        this.key = 'office';
        this.noTitleKey = 'office';
        this.gcglbtitle = '工程管理部'
      }
      this.queryXjGk();
      this.doProCheckQuery();
      // this.queryYhzsTop10();
      // this.queryYhzglTop10();
      this.gcglbAqxjQuery();
      this.$nextTick(function () {
        this.checkTimeChange();
        // this.yhzsChange();
        // this.cswzgChange(1);
        this.yhzglChange();
      })
      // this.getAwardList()
    },
    mounted(){
      // this.getExcellentCerDic()
      // this.getAwardList()
      this.getAwardMonStatic()
    },
    methods: {
      ...mapMutations("account", ["setUser"]),
      getNums(nums1,nums2) { 
        if (typeof nums2 !== 'undefined') {
          nums1 += nums2;
        }  
         return nums1
      },
      getExcellentCerDic() {
        getExcellentCerDic({}).then((res) => {
          if (res.data.code=== 0) {
              this.cerDic = res.data.data
              this.creList = this.setCateInit(res.data.data)
            }
        });
      },
      setCateInit(data) {
        // 初始化证书格式
       let setData = JSON.parse(JSON.stringify(this.cyLevelTitle)) 
       setData.forEach(item => {
          item.certificateStatics = []
          item.cateList = []
          item.nums = 0
          data.forEach(items => {
            if (item.cyLevel == items.certificateLevel) {
              item.cateList.push(items)
            }
          })
        })
        return setData
      },
      setCateData(data) {
       this.creList.forEach(item => {
          data.forEach(items => {
            if (item.cyLevel == items.cyLevel) {
              item.certificateStatics = items.certificateStatics
              item.cateList.forEach(cates => {
                if (items.certificateStatics.length == 0) {
                  cates.nums = 0
                } else {
                  items.certificateStatics.forEach(cres => {
                    if (cates.certificateCode == cres.certificateCode) {
                      cates.nums = cres.nums
                    }
                  })
                }
              })
            }
          })
        })
        this.$nextTick(() => {
          this.creList.forEach(list => {
            list.nums = 0
            list.cateList.forEach(nums => {
              if (nums.nums) {
                list.nums += nums.nums
              }
            })
          })
        })
      },
      /**
       * 安全创优-首页证书统计
       * */
      getAwardMonStatic() {
        let params = new FormData();
        params.append("startYear", this.awardParams[0]);
        params.append("endYear", this.awardParams[1]);
        getExcellentCerDic({}).then((res) => {
          if (res.data.code === 0) {
              this.cerDic = res.data.data
              this.creList = this.setCateInit(res.data.data)
              getAwardMonStatic(params).then(result => {
                if (result.data.code === 0) {
                  const otherData = JSON.parse(JSON.stringify(result.data.data)) 
                  this.setCateData(otherData)
                  const data = this.creList
                  this.$nextTick(() => {
                    // 组装数据-市级其他证书
                    const otherList = data.filter(item => item.cyLevel == 4)
                    data[0].otherNums = data.pop().nums // 移除并返回最后一项-其他证书
                    data[0].cateList.push({certificateLevel: 4,otherList:otherList[0]})
                    this.cateStatics = data
                  })
                }
              })
          }
        });
      },
      /**
       * 获取安全创优获奖情况
       * */
      getAwardList() {
        let params = new FormData();
        params.append("startYear", this.awardParams[0]);
        params.append("endYear", this.awardParams[1]);
        getAwardList(params).then(res => {
          if (res.data.code=== 0) {
            res.data.data.forEach(item => {
              this.awardDataSource.forEach(el => {
                if (item.cyLevel == el.cyLevel) {
                  el.totalNum = item.totalNum
                  el.house = item.house
                  el.government = item.government
                  el.projectNum = item.projectNum
                }
              })
            })
          }
        })
      },
      awardYearChange(dates, dateStrings) {
        this.awardParams = dateStrings
        // this.getAwardList()
        this.getAwardMonStatic()
      },
      awardOpenChange(status) { 
        this.awardYearOpen = status
      },
      awardPanelChange(value) {
        if (value && value.length > 0) {
          this.awardParams = value.map(el => {
            return moment(el).format('YYYY')
          })
          this.awardYearOpen = false
          this.getAwardMonStatic()
        }
      },
      awardMonChange(dates, dateStrings) {
        console.log(dates, dateStrings)
      },
      awardMonOpenChange(status) {
        this.awardMonOpen = status
      },
      awardMonPanelChange(value) {
        if (value && value.length > 0) {
          this.awardMonParams = value.map(el => {
            return moment(el).format('YYYY-MM')
          })
          this.awardMonOpen = false
          this.getAwardMonList()
        }
      },
      onAwardTabChange(key) { 
        this.awardTabKey = key
        if (key === 'hjqk') {
          this.getAwardMonStatic()
        } else if (key === 'jpqk') {
          this.getAwardMonList()
          // this.awardColumns.splice(1, 1)
          this.awardColumns[0].title = '安全创优检评'
          this.awardColumns[1].title = '总数（次）'
          this.awardColumns[2].title = '房建（次）'
          this.awardColumns[3].title = '市政（次）'
        }
      },
      getAwardMonList() {
        let params = new FormData();
        params.append("startDate", this.awardMonParams[0]);
        params.append("endDate", this.awardMonParams[1]);
        getAwardMonList(params).then(res => {
          if (res.data.code=== 0) {
            res.data.data.forEach(item => {
              this.awardDataSource.forEach(el => {
                if (item.cyLevel == el.cyLevel) {
                  el.totalNum = item.totalNum
                  el.house = item.house
                  el.government = item.government
                }
              })
            })
          }
        })
      },
      onTabChange(key, type) {
        this[type] = key;
        if (key == 'gcglb') { 
          this.monthQueryParams.isGcglb = 1;
        } else {
          this.monthQueryParams.isGcglb = 0;
        }
        this.queryXjGk();
      },
      TabChange(key,type){
        this[type] = key;
      },
      /**
       * 获取项目自查排名前十
       */
      doProCheckQuery() {
        if (this.proQueryParams.queryType == 1 && this.proQueryParams.plan_date != null) {
          this.proQueryParams.month = this.proQueryParams.plan_date.format('YYYY')
        }
        if (this.proQueryParams.queryType == 2 && this.proQueryParams.plan_date != null) {
          this.proQueryParams.month = this.proQueryParams.plan_date.format('YYYY-MM')
        }
        if (this.proQueryParams.queryType == 3 && this.proQueryParams.plan_date && this.proQueryParams.plan_date.length > 0) {
          this.proQueryParams.startDate = this.proQueryParams.plan_date[0].format('YYYY-MM-DD')
          this.proQueryParams.endDate = this.proQueryParams.plan_date[1].format('YYYY-MM-DD')
        }
        request(PROJECT_CHECK_NO8, "post", this.proQueryParams).then(res => {
          if (res.data.code == 0) {
            this.dataSource = res.data.data;
          }
        })
      },

      gcglbAqxjQuery() {
        if (this.gcglbQueryParams.queryType == 1 && this.gcglbQueryParams.plan_date != null) {
          this.gcglbQueryParams.month = this.gcglbQueryParams.plan_date.format('YYYY')
        }
        if (this.gcglbQueryParams.queryType == 2 && this.gcglbQueryParams.plan_date != null) {
          this.gcglbQueryParams.month = this.gcglbQueryParams.plan_date.format('YYYY-MM')
        }
        if (this.gcglbQueryParams.queryType == 3 && this.gcglbQueryParams.plan_date && this.gcglbQueryParams.plan_date.length > 0) {
          this.gcglbQueryParams.startDate = this.gcglbQueryParams.plan_date[0].format('YYYY-MM-DD')
          this.gcglbQueryParams.endDate = this.gcglbQueryParams.plan_date[1].format('YYYY-MM-DD')
        }
        if (this.gcglbtype) {
          request(PROJECT_AQXJ_GCGLB_EXPORT, "post", this.gcglbQueryParams).then(res => {
            if (res.data.code == 0) {
              res.data.data.forEach(e => {
                if (this.gcglbQueryParams.queryType == 1) {
                  e.yhzgl = Number(e.checkCnt / 48 * 100).toFixed(2) >= 100 ? '100%' : Number(e.checkCnt / 48 * 100).toFixed(2) + '%'
                } else if (this.gcglbQueryParams.queryType == 2) {
                  e.yhzgl = Number(e.checkCnt / 4 * 100).toFixed(2) >= 100 ? '100%' : Number(e.checkCnt / 4 * 100).toFixed(2) + '%'
                } else {
                  e.yhzgl = ' '
                }
              })
              this.gcglbDatas2 = res.data.data;
            }
          })
        } else {
          request(PROJECT_getStopCfdCnts,"post",this.gcglbQueryParams).then(res => {
            if(res.data.code==0){
              this.tgcfDatas = res.data.data;
            }
          })
        }
        if(this.isleader||!this.gcglbtype){
          request(GCGLB_AQXJ_DETAILS, "post", this.gcglbQueryParams).then(res => {
            if (res.data.code == 0) {
              this.gcglbDatas = res.data.data;
            }
          })
        }

      },

      /**
       * //查询概况中的隐患总数
       */
      doGkYhzsQuery() {
        let obj = {
          type: this.monthQueryParams.queryType,
          xjTimeStr: this.monthQueryParams.month,
          xjTimeStart: this.monthQueryParams.startDate,
          xjTimeEnd: this.monthQueryParams.endDate
        }
        request(PROBLEMS_BY_TOTAL_QUERY, "post", obj).then(res => {
          if (res.data.code == 0) {
            res.data.data.forEach(obj => {
              this.yhzs = obj.total;
            })
          }
        })
      },
      getGcglbYhzs() {
        let obj = {
          queryType: this.monthQueryParams.queryType,
          type: this.monthQueryParams.queryType,
          month: this.monthQueryParams.month,
          startDate: this.monthQueryParams.startDate,
          endDate: this.monthQueryParams.endDate
        }
        request(GCGLB_AQXJ_DETAILS, "post", obj).then(res => {
          let total = 0;
          if (res.data.code == 0) {
            res.data.data.forEach(o => {
              total += o.yhzs
            });
          }
          this.gcglbYhzs = total;
        })
      },

      moment,
      initCheckTimesChart(data1, data2) {
        // 基于准备好的dom，初始化echarts实例
        let myChart = this.$echarts.init(document.getElementById("checkTimesChart"));
        // 指定图表的配置项和数据
        let option = {
          color: ['#9BCA63'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: data1,
              axisTick: {
                alignWithLabel: true
              },
              axisLabel: {interval: 0, rotate: 60, formatter: '{value}'}
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: ['检查次数'],
              type: 'bar',
              barWidth: '60%',
              data: data2,
              label: {
                show: true,
                position: 'outside'
              },
            }
          ]
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      },
      initYhzsChart(data1, data2) {
        // 基于准备好的dom，初始化echarts实例
        let myChart = this.$echarts.init(document.getElementById("yhzsChart"));
        // 指定图表的配置项和数据
        let option = {
          color: ['#6087c7'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: data1,
              axisTick: {
                alignWithLabel: true
              },
              axisLabel: {interval: 0, rotate: 60, formatter: '{value}'}
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: '隐患数',
              type: 'bar',
              barWidth: '60%',
              data: data2,
              label: {
                show: true,
                position: 'outside'
              },
            }
          ]
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      },
      initCswzgsChart(data1, data2, type) {
        let text = "";
        if (type == 2) {
          text = "月";
        }
        // 基于准备好的dom，初始化echarts实例
        let myChart = this.$echarts.init(document.getElementById("cswzgsChart"));
        // 指定图表的配置项和数据
        let option = {
          color: ['#8c493c'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: data1,
              axisTick: {
                alignWithLabel: true
              },
              axisLabel: {interval: 0, rotate: 60, formatter: '{value}' + text}
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: '超时未整改数',
              type: 'bar',
              barWidth: '60%',
              data: data2,
              label: {
                show: true,
                position: 'outside'
              },
            }
          ]
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      },
      initYhzglChart(data1, data2, type) {
        let text = "";
        if (type == 2) {
          text = "月";
        }
        // 基于准备好的dom，初始化echarts实例
        let myChart = this.$echarts.init(document.getElementById("yhzglChart"));
        // 指定图表的配置项和数据
        let option = {
          color: ['#bcd749'],
          tooltip: {
            trigger: 'axis',
            formatter: "{a} <br/>{b}: {c}%",
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: data1,
              axisTick: {
                alignWithLabel: true
              },
              axisLabel: {interval: 0, rotate: 60, formatter: '{value}' + text}
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: '整改率',
              type: 'bar',
              barWidth: '60%',
              data: data2,
              label: {
                show: true,
                position: 'outside',
                formatter: '{c}%'
              },
            }
          ]
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      },
      checkTimeChange() {
        if (this.xjTimesQueryParams.queryType == 1 && this.xjTimesQueryParams.plan_date != null) {
          this.xjTimesQueryParams.month = this.xjTimesQueryParams.plan_date.format('YYYY')
        }
        if (this.xjTimesQueryParams.queryType == 2 && this.xjTimesQueryParams.plan_date != null) {
          this.xjTimesQueryParams.month = this.xjTimesQueryParams.plan_date.format('YYYY-MM')
        }
        if (this.xjTimesQueryParams.queryType == 3 && this.xjTimesQueryParams.plan_date && this.xjTimesQueryParams.plan_date.length > 0) {
          this.xjTimesQueryParams.startDate = this.xjTimesQueryParams.plan_date[0].format('YYYY-MM-DD')
          this.xjTimesQueryParams.endDate = this.xjTimesQueryParams.plan_date[1].format('YYYY-MM-DD')
        }
        this.checkTimeTotal = 0;
        request(CURRENT_CHECKTIMES_CHART, "post", this.xjTimesQueryParams).then(res => {
          if (res.data.code == 0) {
            let obj = [];
            let obj2 = [];
            res.data.data.forEach(o => {
              obj.push(o.timeDate);
              obj2.push({value:o.cnt, name: '哈喽'})
              this.checkTimeTotal = this.checkTimeTotal + Number(o.cnt)
            })
            this.initCheckTimesChart(obj, obj2);
          }
        })
      },

      yhzglChange() {
        if (this.zglParams.plan_date != null) {
          this.zglParams.month = this.zglParams.plan_date.format('YYYY')
        }
        request(CURRENT_YHZGL_CHART, "post", this.zglParams).then(res => {
          if (res.data.code == 0) {
            this.yhzglTotal = res.data.msg + "%";
            let obj = [];
            let obj2 = [];
            res.data.data.forEach(o => {
              obj.push(o.timeDate);
              obj2.push(o.cnt);
            })
            this.initYhzglChart(obj, obj2);
          } else {
            this.yhzglTotal = "0%";
          }
        })
      },
      queryXjGk() {
        if (this.monthQueryParams.queryType == 1 && this.monthQueryParams.plan_date != null) {
          this.monthQueryParams.month = this.monthQueryParams.plan_date.format('YYYY')
        }
        if (this.monthQueryParams.queryType == 2 && this.monthQueryParams.plan_date != null) {
          this.monthQueryParams.month = this.monthQueryParams.plan_date.format('YYYY-MM')
        }
        if (this.monthQueryParams.queryType == 3 && this.monthQueryParams.plan_date && this.monthQueryParams.plan_date.length > 0) {
          this.monthQueryParams.startDate = this.monthQueryParams.plan_date[0].format('YYYY-MM-DD')
          this.monthQueryParams.endDate = this.monthQueryParams.plan_date[1].format('YYYY-MM-DD')
        }
        request(QUERY_PROJECT_GK, "post", this.monthQueryParams).then(res => {
          if (res.data.code == 0) {
            if (this.monthQueryParams.isGcglb == 0) {
              this.gkObj = res.data.data;
            } else {
              this.gcglbObj = res.data.data;
            }

          }
        })
        this.doGkYhzsQuery();
        this.getGcglbYhzs();

      },
      proOpenChangeOne(status) {
        if (status) {
          this.proQueryParams.yearShowOne = true;
        }
      },
      zglOpenChangeOne(status) {
        if (status) {
          this.zglParams.yearShowOne = true;
        }
      },
      xjTimesOpenChangeOne(status) {
        if (status) {
          this.xjTimesQueryParams.yearShowOne = true;
        }
      },
      monthOpenChangeOne(status) {
        if (status) {
          this.monthQueryParams.yearShowOne = true;
        }
      },
      gcglbOpenChangeOne(status) {
        if (status) {
          this.gcglbQueryParams.yearShowOne = true;
        }
      },
      gcglbPanelChangeOne(value) {
        this.gcglbQueryParams.yearShowOne = false;
        this.gcglbQueryParams.plan_date = value;
        this.$nextTick(function () {
          this.gcglbAqxjQuery();
        })
      },
      monthPanelChangeOne(value) {
        this.monthQueryParams.yearShowOne = false;
        this.monthQueryParams.plan_date = value;
        this.$nextTick(function () {
          this.queryXjGk();
        })
      },
      // 得到年份选择器的值
      proPanelChangeOne(value) {
        this.proQueryParams.yearShowOne = false;
        this.proQueryParams.plan_date = value;
        this.$nextTick(function () {
          this.doProCheckQuery();
        })
      },
      zglPanelChangeOne(value) {
        this.zglParams.yearShowOne = false;
        this.zglParams.plan_date = value;
        this.$nextTick(function () {
          this.yhzglChange();
        })
      },
      // 得到年份选择器的值
      xjTimesPanelChangeOne(value) {
        this.xjTimesQueryParams.yearShowOne = false;
        this.xjTimesQueryParams.plan_date = value;
        this.$nextTick(function () {
          this.checkTimeChange();
        })
      },
      proChangeDate(value) {
        this.proQueryParams.plan_date = value;
        this.$nextTick(function () {
          this.doProCheckQuery();
        })

      },
      xjTimesChangeDate(value) {
        this.xjTimesQueryParams.plan_date = value;
        this.$nextTick(function () {
          this.checkTimeChange();
        })
      },
      proTop10Change() {
        if (this.proQueryParams.queryType == 1 || this.proQueryParams.queryType == 2) {
          this.proQueryParams.plan_date = moment(new Date());
        } else {
          this.proQueryParams.plan_date = null;
        }
        this.doProCheckQuery();
      },
      checkTimeTypeChange() {
        if (this.xjTimesQueryParams.queryType == 1 || this.xjTimesQueryParams.queryType == 2) {
          this.xjTimesQueryParams.plan_date = moment(new Date());
        } else {
          this.xjTimesQueryParams.plan_date = null;
        }
        if (this.xjTimesQueryParams.queryType == 1) {
          this.xjTimesQueryParams.month = moment(new Date()).format('YYYY')
        }
        if (this.xjTimesQueryParams.queryType == 2) {
          this.xjTimesQueryParams.month = moment(new Date()).format('YYYY-MM')
        }
        this.checkTimeChange();
      },
      gcglbTimeChange() {
        if (this.gcglbQueryParams.queryType == 1 || this.gcglbQueryParams.queryType == 2) {
          this.gcglbQueryParams.plan_date = moment(new Date());
        } else {
          this.gcglbQueryParams.plan_date = null;
        }
        if (this.gcglbQueryParams.queryType == 1) {
          this.gcglbQueryParams.month = moment(new Date()).format('YYYY')
        }
        if (this.gcglbQueryParams.queryType == 2) {
          this.gcglbQueryParams.month = moment(new Date()).format('YYYY-MM')
        }
        this.gcglbAqxjQuery();
      },
      mtChangeTypeDate() {
        if (this.monthQueryParams.queryType == 1 || this.monthQueryParams.queryType == 2) {
          this.monthQueryParams.plan_date = moment(new Date());
        } else {
          this.monthQueryParams.plan_date = null;
        }
        if (this.monthQueryParams.queryType == 1) {
          this.monthQueryParams.month = moment(new Date()).format('YYYY')
        }
        if (this.monthQueryParams.queryType == 2) {
          this.monthQueryParams.month = moment(new Date()).format('YYYY-MM')
        }
        this.queryXjGk();
      },
      mtChangeDate(value) {
        this.monthQueryParams.plan_date = value;
        this.queryXjGk();
      },
      gcglbChangeDate(value) {
        this.gcglbQueryParams.plan_date = value;
        this.gcglbAqxjQuery();
      },
      onInfo(item,data) {
          // 点击详情
          aqxjprojectsdetail(data.id).then((res) => {
            if (res.data.code == 0) {
              if (item.status == 1 || item.status == 5) {
                this.$router.push({
                name: "项目详情",
                query: {
                  data: res.data.data,
                  type: "list",
                  random: Math.random()
                },
               })
              } else {
                getExcellentDetail(res.data.data.projectId).then(resEx =>{
                  if (resEx.data.stat == 1) {
                      if (resEx.data.data) {
                          // 赋值  cylevel cyyq
                          let p = res.data.data
                          p.cylevel = resEx.data.data.excellentInfo.cylevel
                          p.cyyq = resEx.data.data.excellentInfo.cyyq
                          this.$router.push({
                            name:'项目详情',
                            query:{
                                data:res.data.data,
                                type:'table',
                                exData: resEx.data.data,
                                random: Math.random()
                            }
                          })
                      } else {
                        this.$router.push({
                            name:'项目详情',
                            query:{
                                data:res.data.data,
                                type:'table',
                                exData: 0,
                                random: Math.random()
                                }
                            })
                      }
                  }
                  })
              }
            }
          });
      }
    },
    
}
</script>

<style>
.anchorBL{
  display: none;
}
</style>
<style lang="less" scoped>
.ant-card-body {
  padding: 10px;
  background: #F5F8FE;
  border-radius: 10px;
  border: none
}
.card-radius {
  border-radius: 10px;
}
.card-padding-top {
  /deep/ .ant-card-body {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.px-1{
  padding: 0 10px;
}
.p-1{
  padding: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mb-1{
  margin-bottom: 10px;
}
.data-wrap{
}
.text-center{
  text-align: center;
}
.text-right{
  text-align: right;
}
.flex{
  display: flex;
}
.flex-1{
  flex: 1;
}
.a-center{
  align-items: center;
}
.j-center{
  justify-content: center;
}
.orange{
  background: rgb(242,168,45);
}
.blue{
  background: rgb(39,130,203);
}
.laowu-data-box{
  height: 150px;
  font-size: 14px;
  box-sizing: border-box;
  border-radius: 5px;
  color: #FFFFFF;
}
.laowu-data-box h5{
  color: #ffffff;
  border-bottom: 1px solid #fff;
}
.relative{
  position: relative;
}
.ant-select-selection-selected-value {
  margin-left: 30px;
}
.gc-box{
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ffffff;
  padding: 0 5px;
}
.gc-box-item{
  min-width: 120px;
  color: deepskyblue;
  padding: 5px 0px;
}
.gc-box-img{
  width: 18px;
  height: 18px;
}
.border-right{
  border-right: 1px solid #E4E4E4;
}
</style>

<style lang="less" scoped>
.search{
  margin-bottom: 54px;
}
.fold{
  width: calc(100% - 216px);
  display: inline-block
}
.operator{
  margin-bottom: 18px;
}
@media screen and (max-width: 900px) {
  .fold {
    width: 100%;
  }
}
// 滚动条位置
/deep/.__bar-is-vertical {
  right: -1px !important;
}
// 隐藏横向滚动条
/deep/.__bar-is-horizontal {
  display: none !important;
}
/deep/ th.aqglclass{
  background-color: #2F7CF9 !important;
}
/deep/ th.wmsgclass{
  background-color: #2F51F9 !important;
}
/deep/ th.jsjclass{
  background-color: #6FA6FF !important;
}
/deep/ th.jkymbclass{
  background-color:  #0E9BCA !important;
}
/deep/ th.gczyclass{
  background-color: #00BBF4!important;
}
/deep/ th.sgydclass{
  background-color: #6ED9FA !important;
}
/deep/ th.dxsbclass{
  background-color: #BECCFF !important;
}
/deep/ th.qzdzclass{
  background-color: #93AAFD !important;
}
/deep/ th.sgjjclass{
  background-color: #6184FF !important;
}

  .yh-total {
    box-sizing: border-box;
    border: 1px solid rgb(229, 229, 239);
    border-radius: 6px;
    width: 144px;
    text-align: center;
    line-height: 37px;
    position: absolute;
    top: 53%;
    z-index: 1;
  }
  .award-box {
    font-family: 微软雅黑;
    padding: 10px 15px 5px 15px;
    margin-bottom: 10px;
    // height: 208px;
    min-height: 100px;
    border-radius: 10px;
    background: rgb(244, 246, 255);
    width: 100%;
    .award-title {
      font-size: 16px;
      color: rgb(33, 33, 33);
    }
    .award-tab {
      width: 100%;
      height: 68px;
      border-radius: 6px;
      background: #E2E7FE;
      padding: 15px 0 15px 0;
      justify-content: space-around;
    } 
    .award-tab-left {
      // width: 169px;
      font-size:14px;
      color: #999999;
      line-height:normal;
      flex: 1;
      margin-left: 15px;
    }
    .award-tab-center {
      border: 1px solid rgb(193, 203, 248);
      height: 30px;
      margin: 5px 0 0 10px
    }
    .award-tab-right {
      width: 50px;
      font-size: 16px;
      line-height: 38px;
    }
  }
  .award-box:nth-child(1) {
    background-color: #F4F6FF;
    .award-tab {
      background-color: #E2E7FE;
    }
  }
  .award-box:nth-child(2) {
    background-color: #F0FAFE;
    .scroll-container {
      height: 74px !important;
    }
    .award-tab {
      background-color: #D5F3FF;
    }
  }
  .award-box:nth-child(3) {
    background-color: #F3FDFD;
    .scroll-container {
      height: 74px !important;
    }
    .award-tab {
      background-color: #D3EFEC;
    }
  }

  .ml-auto {
    margin-left: auto;
  }
</style>
