import {hasAuthority} from '@/utils/authority-utils'
import {loginIgnore} from '@/router/index'
import { checkAuthorization } from '@/utils/request'
import NProgress from 'nprogress'
import { Modal } from 'ant-design-vue';
import { storage } from '@/utils/storage.js'

NProgress.configure({ showSpinner: false })

/**
 * 进度条开始
 * @param to
 * @param form
 * @param next
 */
const progressStart = (to, from, next) => {
  // start progress bar
  if (!NProgress.isStarted()) {
    NProgress.start()
  }
  next()
}

/**
 * 登录守卫
 * @param to
 * @param form
 * @param next
 * @param options
 */
const loginGuard = (to, from, next, options) => {
  const {message} = options

  if (to.path !== '/login' && !checkAuthorization()) {
    message.warning('登录已失效，请重新登录')
    next({path: '/login'})
  } else {
    next()
  }
}

/**
 * 权限守卫
 * @param to
 * @param form
 * @param next
 * @param options
 */
// const authorityGuard = (to, from, next, options) => {
//   const {store, message} = options
//   const permissions = store.getters['account/permissions']
//   const roles = store.getters['account/roles']
//   if (!hasAuthority(to, permissions, roles)) {
//     message.warning(`对不起，您无权访问页面: ${to.fullPath}，请联系管理员`)
//     next({path: '/403'})
//     // NProgress.done()
//   } else {
//     next()
//   }
// }

/**
 * 权限守卫-首页提示修改密码
 * @param to
 * @param form
 * @param next
 * @param options
 */
const setPasswordInfo = (to, from, next, options) => {
  const {store} = options
  const user = store.getters['account/user']
  if (user?.isNeedUpdatePwd == 1 && (to.name == '项目首页' || to.name == '公司首页')) {
    Modal.confirm({
      title: '您的密码已超过 90 天未修改了，请修改密码！',
      okText: '确定',
      cancelText: '取消',
      centered: true,
      onOk() {
        // 用户点击了确认按钮，继续导航
        next({name: '修改密码'})
      },
      onCancel() {
        // next()
      }
    })
  }
  next()
}

/**
 * 权限守卫-项目首页去除多页签
 * @param to
 * @param form
 * @param options
 */
const removeHomeMultiPage = (to, from, options) => {
  const homePages = ['项目首页', '项目首页2', '公司首页', '新机施首页'] // 需要去除多页签的页面名称
  const {store} = options
  if (homePages.includes(to.name)) {
    if (store.state.setting.multiPage == false) return
    store.commit('setting/setMultiPage',false)
  } else {
    if (store.state.setting.multiPage == false) {
     store.commit('setting/setMultiPage',true)
    }
  }
}
/**
 * 混合导航模式下一级菜单跳转重定向
 * @param to
 * @param from
 * @param next
 * @param options
 * @returns {*}
 */
const redirectGuard = (to, from, next, options) => {
  const {store} = options
  const getFirstChild = (routes) => {
    const route = routes[0]
    if (!route.children || route.children.length === 0) {
      return route
    }
    return getFirstChild(route.children)
  }
  if (store.state.setting.layout === 'mix') {
    const firstMenu = store.getters['setting/firstMenu']
    if (firstMenu.find(item => item.fullPath === to.fullPath)) {
      store.commit('setting/setActivatedFirst', to.fullPath)
      const subMenu = store.getters['setting/subMenu']
      if (subMenu.length > 0) {
        const redirect = getFirstChild(subMenu)
        return next({path: redirect.fullPath})
      }
    }
  }
  next()
}

/**
 * 进度条结束
 * @param to
 * @param form
 * @param options
 */
const progressDone = () => {
  // finish progress bar
  NProgress.done()
}

const dynamicGuard = async (to, from, next, options) => {
  const { store, router, message, i18n } = options

  const isGenerate = store.getters['route/isGenerate']
  if (isGenerate || to.path === '/login') {
    next()
  } else {
    console.log(
      `%c 🚀 %c 开始生成动态路由 %c`,
      'background:#35495e ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff',
      'background:#41b883 ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff',
      'background:transparent',
      
    )
    await store.dispatch('route/buildRoutesAction', options)
    // 动态路由生成并注册后，重新进入当前路由
    next({
      path: to.path,
      query: to.query,
      replace: true,
    })
  }
}

export default {
  beforeEach: [progressStart,loginGuard, dynamicGuard, redirectGuard,setPasswordInfo],
  afterEach: [progressDone,removeHomeMultiPage]
}
