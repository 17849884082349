<template>
  <div class="a_Approval">
    <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="带班检查记录" >
        <a-spin :spinning="spinning" :tip="loadingText">
          <a-form class="clearfix">
            <a-col :span="5">
              <a-form-item label="统计方式" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
                <a-radio-group v-model="queryParams.queryType">
                  <a-radio-button :value="1">
                    月份
                  </a-radio-button>
                  <a-radio-button :value="2">
                    年份
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col :span="5">
              <a-form-item label="月份/年份" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
                <a-month-picker placeholder="请选择月份" :default-value="moment(monthDay, monthFormat)" :format="monthFormat" v-if="queryParams.queryType==1" v-model="queryParams.plan_date">
                  <a-icon slot="suffixIcon" type="smile" />
                </a-month-picker>

                <a-date-picker
                  v-if="queryParams.queryType==2"
                  mode="year"
                  v-model="queryParams.plan_date"
                  placeholder="请输入年份"
                  :default-value="moment(nowYear, yearFormat)" :format="yearFormat"
                  style="width: 200px"
                  :open="yearShowOne"
                  @openChange="openChangeOne"
                  @panelChange="panelChangeOne"/>
              </a-form-item>
            </a-col>

            <a-col :span="4">
              <a-space class="btnBox">
                <a-button type="primary" @click="doQuery()">查询</a-button>
                <a-button @click="doReset()">重置</a-button>
              </a-space>
            </a-col>
          </a-form>
        <div>
          <standard-table
            :bordered="true"
            :columns="columns"
            :dataSource="dataSource"
            :selectedRows.sync="selectedRows"
            :isAlert="false"
            :pagination="pagination"
            :loading="tableLoading"
            @clear="onClear"
            @change="onPageChange"
            :btnArr="tableBtnArr"
          >
            <template slot="index" slot-scope="{ index }">
              <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
            </template>
            <div slot="action" slot-scope="{record}">
              <a @click="doFileDowload(record)">下载资料</a>
            </div>
            <div slot="do-status" slot-scope="{text}">
              <label v-if="text==2"><a-icon style="font-size:15px;color:green" type="check" /></label>
              <label v-else ><a-icon style="font-size:15px;color:red" type="close" /></label>
            </div>
            <template slot="statusTitle">
              <a-icon @click.native="onStatusTitleClick" type="info-circle" />
            </template>
            <template slot="sceneImage" slot-scope="{record}">
              <img alt="" style="width: 32px" :src="record.sceneImage"
                @click="handlePreview(record.sceneImage)" />
            </template>
          </standard-table>
        </div>
        <Export-loading :cPercent="cPercent" :isExporting="isExporting" :loadingText="loadingText"></Export-loading>
        </a-spin>
      </a-tab-pane>
      <a-tab-pane key="2" tab="领导带班详情" force-render v-if="isGcglb!=1">
        <div style="max-width: 65%;">
          年份：
          <a-date-picker
            mode="year"
            v-model="officeParams.plan_date"
            placeholder="请输入年份"
            :default-value="moment(thisYear, yearFormat)" :format="yearFormat"
            style="width: 100px"
            :open="officeParams.yearShowOne"
            @openChange="gcglbOpenChangeOne"
            @panelChange="gcglbPanelChangeOne"/>
        </div>
        <div style="margin-top:15px"></div>
        <a-table
          :bordered="true"
          :columns="columns2"
          :dataSource="dataSource2"
          :pagination="false"
        >
        </a-table>
      </a-tab-pane>
    </a-tabs>
    <!-- 预览图片 -->
    <a-modal :visible="previewVisible" :footer="false" title="预览" @cancel="previewVisible= false">
        <img :src="previewImage" style="width: 100%" />
    </a-modal>
  </div>

</template>

<script>
import StandardTable from '@/components/table/StandardTable'
import {
  DB_CHECK_QUERY,
  DB_CHECK_QUERY_EXPORT,
  DB_CHECK_OFFCIE_LEADER_EXPORT,
  DB_CHECK_DOWNLOAD,
  DB_QUERY_OFFICE_LEADER_DETAIL
  } from '@/services/api'

import { exportProgress } from '@/pages/aqjyzl/export' // 导出方法
import ExportLoading from '@/components/ExportLoading/ExportLoading' // progress组件
import {request} from '@/utils/request'
import moment from 'moment';
import { useRender } from '@/hooks/useRender.js'
const columns = [
  {
    title: "序号",
    align: "center",
    scopedSlots: { customRender: "index" },
    width: 65,
  },
  {
    title: '项目名称',
    dataIndex: 'projectName',
    width:280,
    ellipsis: true,
    tooltip: true
  },
  {
    title: '单位',
    dataIndex: 'officeName',
    align: 'center',
    width:110,
    scopedSlots: { customRender: 'officeName' }
  },
  {
    title: '检查时间',
    dataIndex: 'xjTimeStr',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '带班领导',
    dataIndex: 'ogFzrName',
    align: 'center',
    scopedSlots: {customRender: 'ogFzrName'}
  },
  {
    title: '限期整改时间',
    dataIndex: 'zgqx',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '整改回复时间',
    dataIndex: 'pmTime',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '复检时间',
    dataIndex: 'fjsj',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '是否闭环',
    dataIndex: 'status',
    align: 'center',
    scopedSlots: {customRender: 'do-status'}
  },
  {
    title: '检查现场图片',
    dataIndex: 'sceneImage',
    align: 'center',
    scopedSlots: {customRender: 'sceneImage'}
  },
  // {
  //   title: '备注',
  //   dataIndex: 'remark',
  //   align: 'center',
  //   customRender: (text) => {
  //     return "";
  //   }
  // },
  {
    title: '操作',
    align: 'center',
    scopedSlots: { customRender: 'action' }
  }
]
const columns2 = [
  {
    title: '公司领导',
    dataIndex: 'userName',
    width:200,
    align: 'center',
    scopedSlots: { customRender: 'userName' }
  },
  {
    title: '1月',
    dataIndex: 'month1',
    align: 'center',
    scopedSlots: { customRender: 'month1' }
  },
  {
    title: '2月',
    dataIndex: 'month2',
    align: 'center',
   scopedSlots: { customRender: 'month2' }
  },
  {
    title: '3月',
    dataIndex: 'month3',
    align: 'center',
    scopedSlots: {customRender: 'month3'}
  },
  {
    title: '4月',
    dataIndex: 'month4',
    align: 'center',
    scopedSlots: {customRender: 'month4'}
  },
  {
    title: '5月',
    dataIndex: 'month5',
    align: 'center',
    scopedSlots: {customRender: 'month5'}
  },
  {
    title: '6月',
    dataIndex: 'month6',
    align: 'center',
    scopedSlots: {customRender: 'month6'}
  },
  {
    title: '7月',
    dataIndex: 'month7',
    align: 'center',
    scopedSlots: {customRender: 'month7'}
  },
  {
    title: '8月',
    dataIndex: 'month8',
    align: 'center',
    scopedSlots: {customRender: 'month8'}
  },
  {
    title: '9月',
    dataIndex: 'month9',
    align: 'center',
    scopedSlots: {customRender: 'month9'}
  },
  {
    title: '10月',
    dataIndex: 'month10',
    align: 'center',
    scopedSlots: {customRender: 'month10'}
  },
  {
    title: '11月',
    dataIndex: 'month11',
    align: 'center',
    scopedSlots: {customRender: 'month11'}
  },
  {
    title: '12月',
    dataIndex: 'month12',
    align: 'center',
    scopedSlots: {customRender: 'month12'}
  },
  {
    title: '合计',
    dataIndex: 'total',
    align: 'center',
    scopedSlots: {customRender: 'total'}
  },
  {
    title: '剩余次数',
    dataIndex: 'lastTotal',
    align: 'center',
    scopedSlots: {customRender: 'lastTotal'}
  },
  {
    title: '带班率',
    dataIndex: 'dbl',
    align: 'center',
    scopedSlots: {customRender: 'dbl'}
  }
  // ,
  // {
  //   title: '备注',
  //   dataIndex: 'remark',
  //   align: 'center',
  //   customRender: (text) => {
  //     return "";
  //   }
  // },
  // {
  //   title: '操作',
  //   align: 'center',
  //   scopedSlots: { customRender: 'action' }
  // }
]

export default {
  mixins: [exportProgress],
  name: 'QueryList',
  components: {StandardTable,ExportLoading},
  data () {
    return {
      dataSource:[],
      dataSource2:[],
      advanced: false,
      columns: columns,
      columns2,
      yearShowOne:false,
      selectedRows: [],
      tableLoading: false,
      spinning:false,
      loadingText:"数据加载中...",
      loadKeys:[],
      visible:false,
      monthFormat: 'YYYY-MM',
      yearFormat: 'YYYY',
      overDownIndex:0,
      monthDay:null,
      nowYear:null,
      queryParams:{
        xjTimeStr:null,
        queryType:1,
        projectName:null,
        plan_date:null,
        pageNumber:1,
      },
      officeParams:{
        plan_date:null,
        yearShowOne:false
      },
      thisYear:moment(new Date()).format('YYYY'),
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true,
        showTotal: (total, range) => `共 ${total} 条`,
      },
      cPercent:0,
      isExporting:false,
      tableBtnArr: [
        {
            name: '导出带班记录',
            attrs: {
                type: 'primary'
            },
            click: this.doDowload
        },
        {
            name: '批量导出带班资料',
            attrs: {
                type: 'primary'
            },
            click: this.doFileBatchDowload
        },
        // {
        //   name: '导出领导带班情况',
        //   attrs: {
        //     type: 'primary'
        //   },
        //   click: this.doDowloadOffiice
        // }
      ],
      previewVisible: false,
      previewImage: '',
    }
  },
  watch: {
    cPercent:{
      handler(newName, oldName) {
        this.cPercent=newName;
      },
      immediate: true,
    },
    loadingText:{
      handler(newName, oldName) {
        this.loadingText=newName;
      },
      immediate: true,
    }
  },
  created() {
    this.isGcglb= this.$store.getters["account/user"].isGcglb
    this.monthDay = moment(new Date()).format('YYYY-MM');
    this.nowYear = moment(new Date()).format('YYYY');
    this.doQuery();
    this.doQuery2();
  },
  authorize: {
    deleteRecord: 'delete'
  },
  methods: {
    moment,
    deleteRecord(key) {
      this.dataSource = this.dataSource.filter(item => item.key !== key)
      this.selectedRows = this.selectedRows.filter(item => item.key !== key)
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    remove () {
      this.dataSource = this.dataSource.filter(item => this.selectedRows.findIndex(row => row.key === item.key) === -1)
      this.selectedRows = []
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
    changeDate(e){
      if(e==null||e.length<=0){
        this.queryParams.startDate = null;
        this.queryParams.endDate = null
      }
    },

    /**
     * 获取时间段的台账信息
     */
    doQuery(){
      this.tableLoading = true;
      if(this.queryParams.queryType==1){
        if(this.queryParams.plan_date==null){
          this.queryParams.xjTimeStr = this.monthDay
        }else{
          this.queryParams.xjTimeStr = this.queryParams.plan_date.format('YYYY-MM')
        }
      }
      if(this.queryParams.queryType==2){
        if(this.queryParams.plan_date==null){
          this.queryParams.xjTimeStr = this.nowYear
        }else{
          this.queryParams.xjTimeStr = this.queryParams.plan_date.format('YYYY')
        }
      }
      request(DB_CHECK_QUERY,"post",this.queryParams).then(res => {
        if(res.data.code==0){
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          this.dataSource.forEach(obj=>{
            obj.key = obj.id;
          })
        }
        this.tableLoading = false;
      })
    },
    /**
     * 获取时间段的台账信息
     */
    doQuery2(){
      this.tableLoading = true;
      if(this.officeParams.plan_date==null){
          this.officeParams.years = this.nowYear
        }else{
          this.officeParams.years = this.officeParams.plan_date.format('YYYY')
        }
      request(DB_QUERY_OFFICE_LEADER_DETAIL,"post",this.officeParams).then(res => {
        if(res.data.code==0){
          this.dataSource2 = res.data.data;
          this.dataSource2.forEach(obj=>{
            obj.key = obj.id;
          })
        }
        this.tableLoading = false;
      })
    },
    doReset(){
      this.queryParams={};
      this.doQuery();
    },
    //分页查询
    onPageChange(e){
      this.queryParams.pageNumber = e.current;
      this.pagination.current=e.current;
      this.doQuery();
    },
    /**
     * 导出excel
     */
    doDowload(){
      this.loadingText = "正在导出EXCEL文件，请稍候 ····";
      let name = ""
      if(this.queryParams.queryType==1){
        if(this.queryParams.plan_date==null){
          this.queryParams.xjTimeStr = this.monthDay
        }else{
          this.queryParams.xjTimeStr = this.queryParams.plan_date.format('YYYY-MM')
        }
        name = name+this.queryParams.xjTimeStr+"月"
      }
      if(this.queryParams.queryType==2){
        if(this.queryParams.plan_date==null){
          this.queryParams.xjTimeStr = this.nowYear
        }else{
          this.queryParams.xjTimeStr = this.queryParams.plan_date.format('YYYY')
        }
        name = name+this.queryParams.xjTimeStr+"年"
      }
      this.isExporting=true;
      this.cPercent=0;
      let fileName=name+"-带班检查记录台账.xlsx";
      this.exportFunNomal2(DB_CHECK_QUERY_EXPORT,this.queryParams,fileName,'application/vnd.ms-excel')
    },
    doDowloadOffiice(){
      this.loadingText = "正在导出文件，请稍候 ····";
      let name = ""
      if(this.queryParams.queryType==1){
        if(this.queryParams.plan_date==null){
          this.queryParams.xjTimeStr = this.monthDay
        }else{
          this.queryParams.xjTimeStr = this.queryParams.plan_date.format('YYYY-MM')
        }
        name = name+this.queryParams.xjTimeStr+"月"
      }
      if(this.queryParams.queryType==2){
        if(this.queryParams.plan_date==null){
          this.queryParams.xjTimeStr = this.nowYear
        }else{
          this.queryParams.xjTimeStr = this.queryParams.plan_date.format('YYYY')
        }
        name = name+this.queryParams.xjTimeStr+"年"
      }
      this.isExporting=true;
      this.cPercent=0;
      let fileName=name+"-公司领导带班情况统计表.xlsx";
      this.exportFunNomal2(DB_CHECK_OFFCIE_LEADER_EXPORT,this.queryParams,fileName,'application/vnd.ms-excel')
    },
    handleMenuClick (e) {
      if (e.key === 'delete') {
        this.remove()
      }
    },
    doSureModal(){
      this.visible = true;
    },
    onChange(dataStr){
      this.queryParams.plan_date=null;
    },
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true;
      }
    },
    gcglbOpenChangeOne(status) {
      if (status) {
        this.officeParams.yearShowOne = true;
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.yearShowOne = false;
      this.queryParams.plan_date = value;
    },
    // 得到年份选择器的值
    gcglbPanelChangeOne(value) {
      this.officeParams.yearShowOne = false;
      this.officeParams.plan_date = value;
      this.doQuery2();
    },
    doFileDowload(recode){
      let selectedRows = this.dataSource.filter(item => item.key == recode.id);
      this.loadingText = "正在准备下载文件，请稍候 ····";
      this.loadKeys = [];
      this.loadKeys.push(recode.id);
      this.isExporting=true;
      this.cPercent=0;
      let projectName = recode.projectName;
      if(recode.projectName.length>15){
        projectName = recode.projectName.slice(0,15)+"……";
      }
      let fileName=projectName+"-"+moment(recode.xjTime).format('YYYY-MM-DD')+"-带班检查资料下载.zip";
      this.exportFun(DB_CHECK_DOWNLOAD,this.loadKeys,fileName,selectedRows)
    },
    doFileBatchDowload(){
      if(this.selectedRows&&this.selectedRows.length>0){
        this.isExporting=true
        this.loadingText = "正在下载资料...";
        this.selectedRows.map((item) => {
          this.loadKeys = [];
          this.loadKeys.push(item.key);
          let fileName = ""
          let projectName = item.projectName;
          if(item.projectName.length>15){
            projectName = item.projectName.slice(0,15)+"……";
          }
          fileName=projectName+"-"+moment(item.xjTime).format('YYYY-MM-DD')+"-带班检查资料下载.zip";
          this.exportFun(DB_CHECK_DOWNLOAD,this.loadKeys,fileName,item)
        })
      }else{
        this.$message.info('请选择记录下载');
        return;
      }
    },
    handlePreview(file) {
      this.previewImage = file;
      this.previewVisible = true;
    },
  }
}
</script>

<style lang="less" scoped>
  .operator{
    margin-bottom: 18px;
  }
</style>
