import { ADMIN } from '@/services/api'
import {request, METHOD, removeAuthorization} from '@/utils/request'

// const BASE_URL = process.env.VUE_APP_API_BASE_URL + '/zk-edu'
const ADMIN_API = `${ADMIN}/thdj`

// 危大新建
// /constructplan/create
export async function constructplanAdd(data) {
    return request(ADMIN_API + '/constructplan/create', METHOD.POST, data)
}
// /constructplan/update
export async function constructplanUpdate(data) {
    return request(ADMIN_API + `/constructplan/update`, METHOD.PUT, data)
}
// /constructplan/get
export async function constructplanByID(params) {
    return request(ADMIN_API + `/constructplan/get`, METHOD.GET, params)
}
// /constructplan/page
export async function constructplanList(data) {
    return request(ADMIN_API + `/constructplan/page`, METHOD.POST, data)
}
// /constructplan/delete
export async function constructplanDelete(params) {
    return request(ADMIN_API + `/constructplan/delete`, METHOD.DELETE, params)
}
// /constructplan/export-excel
export const constructplanExport = ADMIN_API + '/constructplan/export-excel'

// 危大确认
// /wdgcsummary/template
export async function wdgcsummaryGet(params) {
    return request(ADMIN_API + `/wdgcsummary/template`, METHOD.GET, params)
}
// /wdgcsummary/create
export async function wdgcsummaryAdd(data) {
    return request(ADMIN_API + '/wdgcsummary/create', METHOD.POST, data)
}
// /wdgcsummary/get
export async function wdgcsummaryByID(params) {
    return request(ADMIN_API + `/wdgcsummary/get`, METHOD.GET, params)
}
// /wdgcsummary/update
export async function wdgcsummaryUpdate(data) {
    return request(ADMIN_API + `/wdgcsummary/update`, METHOD.PUT, data)
}
// /wdgcsummary/page
export async function wdgcsummaryList(data) {
    return request(ADMIN_API + `/wdgcsummary/page`, METHOD.POST, data)
}
// /wdgcsummary/delete
export async function wdgcsummaryDelete(params) {
    return request(ADMIN_API + `/wdgcsummary/delete`, METHOD.DELETE, params)
}
// /wdgcsummary/export-excel
export const wdgcsummaryExport = ADMIN_API + '/wdgcsummary/export-excel'
// /wdgcsummary/download
export const wdgcsummaryDownload = ADMIN_API + '/wdgcsummary/download'
// /wdgcsummary/batchDownload
export const wdgcsummaryBatchDownload = ADMIN_API + '/wdgcsummary/batchDownload'

// 危大工程管理
// /wdgcplan/create
export async function wdgcplanAdd(data) {
    return request(ADMIN_API + '/wdgcplan/create', METHOD.POST, data)
}
// /wdgcplan/update
export async function wdgcplanUpdate(data) {
    return request(ADMIN_API + `/wdgcplan/update`, METHOD.PUT, data)
}
// /wdgcplan/get
export async function wdgcplanByID(params) {
    return request(ADMIN_API + `/wdgcplan/get`, METHOD.GET, params)
}
export async function wdgcplanByIDToYj(params) {
    return request(ADMIN_API + `/wdgcplan/view`, METHOD.GET, params)
}
// /wdgcplan/list
export async function wdgcplanList(data) {
    return request(ADMIN_API + `/wdgcplan/list`, METHOD.POST, data)
}
// /wdgcplan/delete
export async function wdgcplanDelete(params) {
    return request(ADMIN_API + `/wdgcplan/delete`, METHOD.DELETE, params)
}
// /wdgcplan/download
export const wdgcplanDownload = ADMIN_API + '/wdgcplan/download'
// /wdgcplan/export-excel
export const wdgcplanExport = ADMIN_API + '/wdgcplan/export-excel'

// /earlywarn/page
export async function earlywarnList(data) {
    return request(ADMIN_API + `/earlywarn/page`, METHOD.GET, data)
}