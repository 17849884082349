var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c("div", { staticClass: "plug-Overview" }, [_vm._v("标准化评分汇总表")]),
      _c("a-divider"),
      _c("form-index", {
        ref: "formindexRef",
        attrs: { formData: _vm.formConfig, flexSubmit: "" },
        on: {
          handleSubmit: _vm.onSearch,
          changeYear: _vm.changeYear,
          handleChange: _vm.handleChange,
          handlSelect: _vm.handlSelect,
        },
        scopedSlots: _vm._u([
          {
            key: "flexSubmit",
            fn: function () {
              return [
                _c(
                  "a-space",
                  { staticClass: "btnBox j-start ml-4" },
                  [
                    _c(
                      "a-button",
                      { attrs: { type: "primary", "html-type": "submit" } },
                      [_vm._v(" 查询 ")]
                    ),
                    _c("a-button", { on: { click: _vm.onReset } }, [
                      _vm._v(" 重置 "),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "flex j-between" },
        [
          _vm.isGroupAccount
            ? _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.openModal(_vm.DetailPageModeEnum.ADD)
                        },
                      },
                    },
                    [_vm._v(" 新增 ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "a-upload",
                    {
                      attrs: {
                        name: "file",
                        multiple: false,
                        "before-upload": _vm.beforeUpload,
                        showUploadList: false,
                      },
                    },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.importLoading,
                          },
                        },
                        [_vm._v(" 导入 ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: { type: "link" },
                      on: {
                        click: function ($event) {
                          return _vm.downloadFile(
                            "https://sj-file-bak.oss-cn-guangzhou.aliyuncs.com/aac473c2e575098373fbbdb0cd08eb0c1b2fc416c6a54fdd514c7ef3a669dabd.xlsx"
                          )
                        },
                      },
                    },
                    [_vm._v(" 下载导入模板 ")]
                  ),
                ],
                1
              )
            : _c("div"),
          _c(
            "a-radio-group",
            {
              on: {
                change: function () {
                  return _vm.onSearch()
                },
              },
              model: {
                value: _vm.standQuarter,
                callback: function ($$v) {
                  _vm.standQuarter = $$v
                },
                expression: "standQuarter",
              },
            },
            [
              _c("a-radio-button", { attrs: { value: 1 } }, [
                _vm._v(" 第一季度 "),
              ]),
              _c("a-radio-button", { attrs: { value: 2 } }, [
                _vm._v(" 第二季度 "),
              ]),
              _c("a-radio-button", { attrs: { value: 3 } }, [
                _vm._v(" 第三季度 "),
              ]),
              _c("a-radio-button", { attrs: { value: 4 } }, [
                _vm._v(" 第四季度 "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "text-center font-20 font-bold mb-1" }, [
        _vm._v("集团对项目标准化考评汇总表"),
      ]),
      _c("standard-table", {
        attrs: {
          columns: _vm.columns,
          dataSource: _vm.tableData,
          rowKey: "id",
          pagination: _vm.pagination,
          loading: _vm.tableLoading,
          isAlert: false,
          bordered: true,
        },
        on: { change: _vm.onPageChange },
        scopedSlots: _vm._u([
          {
            key: "action",
            fn: function (ref) {
              var record = ref.record
              return [
                _c(
                  "a-space",
                  [
                    _vm.isGroupAccount
                      ? [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "link" },
                              on: {
                                click: function ($event) {
                                  return _vm.openModal(
                                    _vm.DetailPageModeEnum.EDIT,
                                    record.id
                                  )
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                          _c(
                            "a-popconfirm",
                            {
                              attrs: {
                                title: "是否确认删除",
                                "ok-text": "删除",
                                "cancel-text": "取消",
                                okType: "danger",
                              },
                              on: {
                                confirm: function ($event) {
                                  return _vm.onDelete(record.id)
                                },
                              },
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  staticStyle: { color: "red" },
                                  attrs: { type: "link" },
                                },
                                [_vm._v("删除")]
                              ),
                            ],
                            1
                          ),
                        ]
                      : [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "link" },
                              on: {
                                click: function ($event) {
                                  return _vm.openModal(
                                    _vm.DetailPageModeEnum.VIEW,
                                    record.id
                                  )
                                },
                              },
                            },
                            [_vm._v("查看")]
                          ),
                        ],
                  ],
                  2
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "full-modal",
        { attrs: { visible: _vm.modalVisible }, on: { close: _vm.closeModal } },
        [
          _c("detailPage", {
            attrs: { pageMode: _vm.pageMode, id: _vm.editId },
            on: { save: _vm.onSaveModal, close: _vm.closeModal },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }