import { ADMIN, GET_OFFICE_TREE, GET_OFFICE_TYPE_LIST, SUBMIT_OFFICE,
    DEL_OFFICE, GET_OFFICE_TREE_SYS_USER, GET_ROLE_LIST,
    GET_MENU_LIST, SAVE_MENU, MENU_DEL_BY_ID, GET_MENU_LIST_BY_ROLE_LIST,
    GET_MENU_LIST_BY_ROLE_ID, SAVE_ROLE, GET_ROLE_LIST_BY_SYS_USER_LIST,
    GET_ROLE_BY_OLD_USER_ID, SAVE_LABOUR_USER, DEL_SYS_USER, DEL_ROLE, SYNC_SYS_USER,
    GET_PROJECT_LIST_BY_OFFICE_ID, SEARCH_LOGIN_NAME, GET_PROJECT_LIST, SAVE_PROJECT,
    GET_DIRECTOR_USER_LIST, GET_PROJECT_INFO, GET_PROJECT_RELATION_OFFICE_LIST, PROJECT_BING_USER,
    SEARCH_PHONE_USER, GET_PROJECT_USER_LIST, PROJECT_RELATION_OFFICE, SEARCH_LOGIN_NAME_BY_PROJECT,
    GET_PROJECT_DICT_TYPE_LIST, USER_DEL_BIND_LAO_WU_USER, DEL_PROJECT_BIND_USER, MODIFY_JI_SHI_BY_LABOUR,
    MODIFY_JI_SHI_BY_LAO_WU,GET_OFFICE_SYS_USER, GET_LAST_VERSION, PROJECT_VIDEO_VIEW, PROJECT_VIDEO_TREE } from '@/services/api'
import {request, METHOD} from '@/utils/request'
// const BASE_URL = process.env.VUE_APP_API_BASE_URL

/**
 * 获取公司树形结构
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getOfficeTree(params) {
    return request(GET_OFFICE_TREE, METHOD.POST, params)
}

/**
 * 获取视频监控
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getVideoView(params) {
    return request(PROJECT_VIDEO_VIEW, METHOD.POST, params)
}

/**
 * 获取视频监控——项目
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getVideoProject(params) {
    return request(PROJECT_VIDEO_TREE, METHOD.GET, params)
}

/**
 * 获取公司相关类型关联
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getOfficeTypeList() {
    return request(GET_OFFICE_TYPE_LIST, METHOD.GET)
}

/**
 * 提交公司信息
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function submitOffice(params) {
    return request(SUBMIT_OFFICE, METHOD.POST, params)
}

/**
 * 删除公司
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delOffice(params) {
    return request(DEL_OFFICE, METHOD.GET, params)
}

/**
 * 获取公司树对应的用户列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getOfficeTreeSysUser(params) {
    return request(GET_OFFICE_TREE_SYS_USER, METHOD.GET, params)
}
/**
 * 用户列表获取所有角色
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getRoleListBySysUserList(params) {
    return request(GET_ROLE_LIST_BY_SYS_USER_LIST, METHOD.GET, params)
}

/**
 * 单个用户对应的角色id
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getRoleByOldUserId(params) {
    return request(GET_ROLE_BY_OLD_USER_ID, METHOD.GET, params)
}

/**
 * 新增/更新用户
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function saveLabourUser(params) {
    return request(SAVE_LABOUR_USER, METHOD.POST, params)
}

/**
 * 根据旧劳务系统用户名查询劳务账号
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function searchLoginName(params) {
    return request(SEARCH_LOGIN_NAME, METHOD.GET, params)
}



/**
 * 删除用户
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delSysUser(params) {
    return request(DEL_SYS_USER, METHOD.GET, params)
}

/**
 * 根据公司id获取该公司及其子公司的所有项目
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProjectListByOfficeId(params) {
    return request(GET_PROJECT_LIST_BY_OFFICE_ID, METHOD.GET, params)
}

/**
 * 项目关联公司
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function projectRelationOffice(params) {
    return request(PROJECT_RELATION_OFFICE, METHOD.POST, params)
}

/**
 * 根据旧劳务系统用户名查询劳务账号--项目管理
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function searchLoginNameByProject(params) {
    return request(SEARCH_LOGIN_NAME_BY_PROJECT, METHOD.GET, params)
}

/**
 * 获取角色list
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getRoleList(params) {
    return request(GET_ROLE_LIST, METHOD.GET, params)
}

/**
 * 角色管理获取所有权限列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getMenuListByRoleList(params) {
    return request(GET_MENU_LIST_BY_ROLE_LIST, METHOD.GET, params)
}

/**
 * 角色id获取对应拥有权限
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getMenuListByRoleId(params) {
    return request(GET_MENU_LIST_BY_ROLE_ID, METHOD.GET, params)
}

/**
 * 新增/更新角色
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function saveRole(params) {
    return request(SAVE_ROLE, METHOD.POST, params)
}

/**
 * 删除角色
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delRole(params) {
    return request(DEL_ROLE, METHOD.GET, params)
}

/**
 * 获取菜单列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getMenuList(params) {
    return request(GET_MENU_LIST, METHOD.GET, params)
}

/**
 * 保存菜单配置
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function saveMenu(params) {
    return request(SAVE_MENU, METHOD.POST, params)
}

/**
 * 删除菜单配置
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delMenuById(params) {
    return request(MENU_DEL_BY_ID, METHOD.GET, params)
}


/**
 * 获取项目列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProjectList(params) {
    return request(GET_PROJECT_LIST, METHOD.POST, params)
}

/**
 * 新增/更新项目信息
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function saveProject(params) {
    return request(SAVE_PROJECT, METHOD.POST, params)
}

/**
 * 获取项目类型字典列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProjectDictTypeList(params) {
    return request(GET_PROJECT_DICT_TYPE_LIST, METHOD.GET, params)
}

/**
 * 获取公司负责人角色列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getDirectorUserList(params) {
    return request(GET_DIRECTOR_USER_LIST, METHOD.GET, params)
}

/**
 * 获取项目信息详情
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProjectInfo(params) {
    return request(GET_PROJECT_INFO, METHOD.GET, params)
}

/**
 * 获取项目关联公司
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProjectRelationOfficeList(params) {
    return request(GET_PROJECT_RELATION_OFFICE_LIST, METHOD.GET, params)
}

/**
 * 新增/更新项目绑定用户
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function projectBingUser(params) {
    return request(PROJECT_BING_USER, METHOD.POST, params)
}

/**
 * 新账号接触绑定旧劳务账号
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function userDelBindLaoWuUser(params) {
    return request(USER_DEL_BIND_LAO_WU_USER, METHOD.GET, params)
}



/**
 * 查询手机号对应的用户及已关联旧账号
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function searchPhoneUser(params) {
    return request(SEARCH_PHONE_USER, METHOD.GET, params)
}

/**
 * 获取项目关联的账号列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProjectUserList(params) {
    return request(GET_PROJECT_USER_LIST, METHOD.POST, params)
}


export async function delProjectBindUser(params){
    return request(DEL_PROJECT_BIND_USER, METHOD.GET, params)
}







/**
 * 同步系统账号到新劳务表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function syncSysUser(params) {
    return request(SYNC_SYS_USER, METHOD.GET, params)
}
/**
 * 同步某个项目的机施数据到另一个公司 旧劳务数据
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function modifyJiShiByLaoWu(params) {
    return request(MODIFY_JI_SHI_BY_LAO_WU, METHOD.GET, params)
}

/**
 * 同步某个项目的机施数据到另一个公司 新劳务数据
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function modifyJiShiByLabour(params) {
    return request(MODIFY_JI_SHI_BY_LABOUR, METHOD.GET, params)
}

/**
 * 组织机构获取公司用户管理
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getOfficeSysUser(params,query) {
    if (query) {
        return request(ADMIN + `/system/user/page?${query}`, METHOD.GET, params)
    }
    return request(ADMIN + `/system/user/page`, METHOD.GET, params)
}

/**
 * 系统版本发布-新增
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function addVersionHistroy(params) {
    return request(ADMIN + `/labour/systemVersionHistroy/insert`, METHOD.POST, params)
}

/**
 * 系统版本发布-查询列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getVersionHistroyList(params) {
    return request(ADMIN + `/labour/systemVersionHistroy/getList`, METHOD.POST, params)
}

/**
 * 获取最新的版本记录
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getVersionHistroyLast(params) {
    return request(ADMIN + `/labour/systemVersionHistroy/getLastVersion`, METHOD.POST, params)
}

/**
 * 获取版本记录详情
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getVersionHistroyDetail(params) {
    return request(ADMIN + `/labour/systemVersionHistroy/detail/${params.id}`, METHOD.GET)
}

/**
 * 删除版本记录
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delVersionHistroy(params) {
    return request(ADMIN + `/labour/systemVersionHistroy/del/${params.id}`, METHOD.DELETE)
}

/**
 * 修改版本记录
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function setVersionHistroy(params) {
    return request(ADMIN + `/labour/systemVersionHistroy/update`, METHOD.POST, params)
}

/**
 * 登入登出日志
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getLogGingList(params, query) {
    if (query) {
        return request(ADMIN + `/system/operate-log/page?${query}`, METHOD.GET, params)
    }
    return request(ADMIN + `/system/operate-log/page`, METHOD.GET, params)
}

/**
 * 分配角色
 * @params userId
 * @params roleIds []
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function assignUserRole(params) {
    return request(ADMIN + `/system/permission/assign-user-role`, METHOD.POST, params)
}

/**
 * 查询角色
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getUserRole(params) {
    return request(ADMIN + `/system/permission/list-user-roles`, METHOD.GET, params)
}

/**
 * 重置密码
 * @params id
 * @params password
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updatePassword(params) {
    return request(ADMIN + `/system/user/update-password`, METHOD.PUT, params)
}

/**
 * 更新用户
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updateUser(params) {
    return request(ADMIN + `/system/user/update`, METHOD.PUT, params)
}

/**
 * 新增用户
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function createUser(params) {
    return request(ADMIN + `/system/user/create`, METHOD.POST, params)
}

/**
 *  删除用户
 */
export async function deleteUser(params) {
    return request(ADMIN + `/system/user/delete`, METHOD.DELETE, params)
}

/**
 *  用户详情
 */
export async function getUser(params) {
    return request(ADMIN + `/system/user/get`, METHOD.GET, params)
}

/**
 *  查询企业库-只查询自己
 */
export async function getOfficesSelfPage(params) {
    return request(ADMIN + `/system/dept/getOfficesSelfPage`, METHOD.POST, params)
}

/**
 *  企业库-新增
 */
export async function insertOffice(params) {
    return request(ADMIN + `/system/dept/insert`, METHOD.POST, params)
}

/**
 *  查询企业库-详情
 */
export async function getOfficeDetail(params) {
    return request(ADMIN + `/system/dept/detail/${params.id}`, METHOD.GET)
}

/**
 *  企业库-修改
 */
export async function setOfficeUpdate(params) {
    return request(ADMIN + `/system/dept/update`, METHOD.POST, params)
}


export default {
    getOfficeSysUser,
    getOfficeTree,
    getOfficeTypeList,
    submitOffice,
    delOffice,
    getOfficeTreeSysUser,
    getRoleList,
    getMenuList,
    saveMenu,
    delMenuById,
    getMenuListByRoleList,
    getMenuListByRoleId,
    saveRole,
    getRoleListBySysUserList,
    getRoleByOldUserId,
    saveLabourUser,
    delSysUser,
    delRole,
    getProjectListByOfficeId,
    searchLoginName,
    getProjectList,
    saveProject,
    getDirectorUserList,
    getProjectInfo,
    getProjectRelationOfficeList,
    projectBingUser,
    searchPhoneUser,
    getProjectUserList,
    projectRelationOffice,
    getProjectDictTypeList,
    userDelBindLaoWuUser,
    delProjectBindUser,
    modifyJiShiByLaoWu,
    modifyJiShiByLabour
}
