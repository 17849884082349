<template>
    <div class="container">
            <div id="components-form-demo-advanced-search">
                <!-- <a-form-model
                        :model="queryData"
                        layout="inline"
                        class="ant-advanced-search-form"
                >
                    <a-form-model-item ref="applyType" label="类型" prop="applyType">
                        <a-select v-model="queryData.applyType" placeholder="请选择类型">
                            <a-select-option value="0">
                                全部
                            </a-select-option>
                            <a-select-option value="1">
                                专户工资
                            </a-select-option>
                            <a-select-option value="2">
                                普户工资
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item ref="month" label="发放月份" prop="month">
                        <a-month-picker v-model="queryData.month" placeholder="请选择发放月份"
                                        @change="queryDataMonthChange"  style="width: 100%;" format="yyyyMM"
                        />
                    </a-form-model-item>
                    <a-form-model-item ref="searchWorkerName" label="工人姓名" prop="searchWorkerName">
                        <a-input v-model="queryData.searchWorkerName" />
                    </a-form-model-item>
                    <a-form-model-item ref="projectName" label="项目名称" prop="projectName">
                        <a-input v-model="queryData.projectName" />
                    </a-form-model-item>
                    <a-form-model-item ref="teamName" label="班组名称" prop="teamName">
                        <a-input v-model="queryData.teamName" />
                    </a-form-model-item>
                    <a-form-model-item ref="summaryStatus" label="汇总状态" prop="summaryStatus">
                        <a-select v-model="queryData.summaryStatus " placeholder="请选择汇总状态" @change="summaryStatusChange">
                            <a-select-option value="0">
                                全部
                            </a-select-option>
                            <a-select-option value="1">
                                已汇总
                            </a-select-option>
                            <a-select-option value="2">
                                未汇总
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-button type="primary" style="margin-top:4px" icon="search" :loading="loading" @click="getData(true)">
                        查询
                    </a-button>
                    <br/>
                    <a-button v-if="queryData.applyType>0" type="primary" style="margin-left: 10px" icon="download" :loading="loading" :disabled="selectedApplyIdList.length<=0"
                              @click="batchDownloadTeamPayroll(selectedApplyIdList)">
                        批量导出Excel报表
                    </a-button>
                    <a-button type="primary" style="margin-left: 10px" icon="download" :loading="loading" :disabled="selectedApplyIdList.length<=0"
                              @click="showTabulationMonthModel">
                        汇总制表
                    </a-button>
                    <a-button type="primary" style="margin-left: 10px" icon="enter" :loading="loading" :disabled="selectedApplyIdList.length<=0"
                              @click="batchFinanceRepulse(selectedApplyIdList)">
                        批量退回
                    </a-button>

                </a-form-model> -->
                <form-index
                    ref="formindex"
                    :formData="formData"
                    @handleSubmit="handleSubmit"
                    :flexSubmit="true"
                    >
                    <!-- 下部分按钮插槽 -->
                    <template #flexSubmit>
                        <a-space  style="margin-left: 90px; margin-top: 5px">
                            <a-button type="primary" icon="search" :loading="loading" html-type="submit"> 查询 </a-button>
                        <a-space class="operator">

                            <a-button @click="close"> 重置 </a-button>
                        </a-space>
                        </a-space>
                    </template>
                </form-index>
                <div class="a_a_footer mb-1">
                    <a-space class="operator">
                        <a-button v-if="queryData.applyType>0" type="primary" style="margin-left: 10px" icon="download" :loading="loading" :disabled="selectedApplyIdList.length<=0"
                                    @click="batchDownloadTeamPayroll(selectedApplyIdList)">
                            批量导出Excel报表
                        </a-button>
                    </a-space>
                    <a-space class="operator">
                        <a-button type="primary" style="margin-left: 10px" icon="download" :loading="loading" :disabled="selectedApplyIdList.length<=0"
                                    @click="showTabulationMonthModel">
                            汇总制表
                        </a-button>
                    </a-space>
                    <a-space class="operator">
                        <a-button type="primary" style="margin-left: 10px" icon="enter" :loading="loading" :disabled="selectedApplyIdList.length<=0"
                                    @click="batchFinanceRepulse(selectedApplyIdList)">
                            批量退回
                        </a-button>
                    </a-space>
                 </div>
            <a-table
                    :row-selection="{ selectedRowKeys: selectedApplyIdList, onChange: selectApplyChange }"
                    row-key="id"
                    :columns="columns"
                     :data-source="data"
                     :pagination="pagination"
                    @change="teamApplyTableChange"
                     :loading="loading">
                <template slot="action" slot-scope="text, record">

                    <a-popover title="操作" trigger="click" placement="bottom">
                        <template slot="content">
                            <a @click="showStaffPayrollModel(record)" >查看详情</a>
                            <br/>
                            <a @click="downloadTeamPayroll(record)" style="margin-top: 10px">导出Excel报表</a>
                          <br/>
                            <a @click="repulseApplyStatus(record)" style="margin-top: 10px">有误，退回劳务员处</a>
                        </template>
                        <a type="primary">
                            操作<a-icon type="down" />
                        </a>
                    </a-popover>
<!--                    <span>-->
<!--                      <a @click="showStaffPayrollModel(record)">查看详情</a>-->
<!--                      <a-divider type="vertical" />-->
<!--                      <a @click="downloadTeamPayroll(record)">导出Excel报表</a>-->
<!--                      <a-divider type="vertical" />-->
<!--                      <a @click="repulseApplyStatus(record)">退回</a>-->
<!--                    </span>-->
                </template>
                <template slot="workStatusText" slot-scope="text">
                    <div style="white-space: pre-wrap;">{{text}}</div>
                </template>
                <template slot="footer">
                    <div style="text-align: center" :key="tabTotal.timeKey">
                        <a-row>
                            <a-col :span="10">
                                合计：
                            </a-col>
                            <a-col :span="2">
                                {{tabTotal.payableMoneySum}}
                            </a-col>
                            <a-col :span="2">
                                {{tabTotal.actualMoneySum}}
                            </a-col>
                            <a-col :span="2">
                                {{tabTotal.taxSum}}
                            </a-col>
                            <a-col :span="2">
                                {{tabTotal.highTempSubsidySum}}
                            </a-col>
                            <a-col :span="5">

                            </a-col>
                        </a-row>
                    </div>
                </template>


            </a-table>
        </div>


        <a-modal
                title="工资单记录"
                :visible="staffPayrollModelVisible"
                width="50%"
                :footer="null"
                @cancel="staffPayrollModelCancel"
                :keyboard="true"
                :maskClosable="true"
        >
            <a-form-model
                    :model="staffPayrollQueryData"
                    layout="inline"
                    :label-col="{span: 6}"
                    :wrapper-col="{span: 16}"
            >
                <a-form-model-item ref="name" label="工人姓名" prop="name">
                    <a-input v-model="staffPayrollQueryData.name" />
                </a-form-model-item>
                <a-form-model-item ref="idCard" label="身份证号" prop="idCard">
                    <a-input v-model="staffPayrollQueryData.idCard" />
                </a-form-model-item>
                <a-button type="primary" icon="search" :loading="loading" @click="searchStaffPayroll">查询</a-button>
                <a-button type="primary" icon="download" :loading="loading" style="margin-left: 10px;" @click="downloadStaffPayroll">
                    导出工资单
                </a-button>
            </a-form-model>

            <a-table :columns="staffPayrollColumns"
                     :data-source="staffPayrollData"
                     :pagination="staffPayrollPagination"
                     @change="staffPayrollTableChange"
                     :loading="staffPayrollLoading">
            </a-table>
        </a-modal>




        <a-modal
                title="制表月份"
                :visible="tabulationMonthModelVisible"
                width="30%"
                @cancel="tabulationMonthModelCancel"
                :keyboard="true"
                :maskClosable="true"
                :okText="'制表并下载'"
                @ok="payrollApplySummary(selectedApplyIdList)"
        >

            <a-form-model
                    :model="tabulationData"
                    layout="inline"
            >

                <a-form-model-item ref="month" label="月份" prop="month">
                    <a-month-picker v-model="tabulationData.month" placeholder="请选择制表月份"
                                    @change="tabulationMonthChange"  style="width: 100%;" format="yyyyMM"
                    />
                </a-form-model-item>
                <br/>

            </a-form-model>
            <template slot="footer">
                <a-button key="back" @click="tabulationMonthModelCancel">
                取消
                </a-button>
                <a-button key="submit" type="primary" :loading="monthModelLoading" @click="payrollApplySummary(selectedApplyIdList)">
                    制表并下载
                </a-button>
            </template>
        </a-modal>


    </div>
</template>
<script>
    import {getTeamPayrollApplyList, payrollApplySummary, downloadTeamPayroll, getStaffPayrollList, downloadStaffPayroll, summaryExport, batchFinanceRepulse} from '@/services/payroll'
    import {UPLOAD_STAFF_PAYROLL} from '@/services/api'
    import {mapGetters} from 'vuex'
    import moment from 'moment'
    import { storage } from '@/utils/storage'
    import { TOKEN_KEY } from '@/utils/request.js'
    import formIndex from "@/pages/components/form/index";
    const formData = [
        {
            label: "类型",
            placeholder: "请选择",
            decorator: [
                "applyType",
                { initialValue: "", rules: [{ required: false, message: "请选择" }] },
            ],
            type: "select",
            key: "applyType",
            selectlist: [
                {
                    value: '',
                    name: '全部'
                },
                {
                    value: '1',
                    name: '专户工资'
                }, {
                    value: '2',
                    name: '普户工资'
                }
            ],
            col: 6,
            labelCol: 6,
            wrapperCol: 14,
            display: true,
        },
        {
            label: "发放月份",
            placeholder: "请选择",
            decorator: [
            "month",
            {
                rules: [{ required: false, message: "请选择" }],
            },
            ],
            type: "monthPicker",
            key: "month",
            col: 6,
            labelCol: 6,
            wrapperCol: 14,
            display: true,
            format: "YYYYMM",
            styles: {width: '100%'}
        },
        {
            label: "工人姓名",
            placeholder: "请填写",
            decorator: [
            "searchWorkerName",
                { rules: [{ required: false, message: "请填写" }] },
            ],
            type: "input",
            key: "searchWorkerName",
            col: 6,
            labelCol: 6,
            wrapperCol: 14,
            display: true,
        },
        {
            label: "项目名称",
            placeholder: "请填写",
            decorator: [
            "projectName",
                { rules: [{ required: false, message: "请填写" }] },
            ],
            type: "input",
            key: "projectName",
            col: 6,
            labelCol: 6,
            wrapperCol: 14,
            display: true,
        },
        {
            label: "班组名称",
            placeholder: "请填写",
            decorator: [
            "teamName",
                { rules: [{ required: false, message: "请填写" }] },
            ],
            type: "input",
            key: "teamName",
            col: 6,
            labelCol: 6,
            wrapperCol: 14,
            display: true,
        },
        {
            label: "汇总状态",
            placeholder: "请选择",
            decorator: [
            "summaryStatus",
            { rules: [{ required: false, message: "请选择" }] },
            ],
            type: "select",
            key: "summaryStatus",
            selectlist: [{
                value: '0',
                name: '全部'
            }, {
                value: '1',
                name: '已汇总'
            }, {
                value: '2',
                name: '未汇总'
            }],
            col: 6,
            labelCol: 6,
            wrapperCol: 14,
            display: true,
        },
    ];
    const columns = [
        {
            title: '申请id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '月份',
            dataIndex: 'month',
            key: 'month',
        },
        {
            title: '班组名称',
            dataIndex: 'teamName',
            key: 'teamName',
        },
        {
            title: '项目名称',
            dataIndex: 'projectName',
            key: 'projectName',
            ellipsis: true,
        },
        {
            title: '申请类型',
            dataIndex: 'applyType',
            key: 'applyType',
            customRender: (text) => {
                return text == 1? "专户工资" : text == 2? "普户工资" : ""
            }
        },
        {
            title: '应发工资',
            dataIndex: 'payableMoney',
            key: 'payableMoney',
        },

        {
            title: '实发工资',
            dataIndex: 'actualMoney',
            key: 'actualMoney',
        },
        {
            title: '个税',
            dataIndex: 'tax',
            key: 'tax',
        },
        {
            title: '高温补贴',
            dataIndex: 'highTempSubsidy',
            key: 'highTempSubsidy',
        },
        {
            title: '汇总状态',
            dataIndex: 'summaryId',
            key: 'summaryId',
            customRender: (text) => {
                return text >0 ? "已汇总" : "未汇总"
            }
        },
        // {
        //     title: '状态',
        //     dataIndex: 'status',
        //     key: 'status',
        //     customRender: (text) => {
        //         return text  === 0 ? "已生成" : text  === 1? "已流转财务" : text  === 2? "已结束" : "未知"
        //     }
        // },
        {
            title: '人员状态人数',
            dataIndex: 'workStatusText',
            key: 'workStatusText',
            width: 130,
            scopedSlots: { customRender: 'workStatusText' },
        },
        {
            title: '操作',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' },
        },

    ];

    const staffPayrollColumns = [
        {
            title: '工人名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
        },
        {
            title: '电话',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: '基本工资',
            dataIndex: 'baseMoney',
            key: 'baseMoney',
        },
        {
            title: '绩效奖金',
            dataIndex: 'achievementsMoney',
            key: 'achievementsMoney',
        },
        {
            title: '加班工资',
            dataIndex: 'overtimeMoney',
            key: 'overtimeMoney',
        },
        {
            title: '高温津贴',
            dataIndex: 'highTempSubsidy',
            key: 'highTempSubsidy',
        },
        {
            title: '应发金额',
            dataIndex: 'payableMoney',
            key: 'payableMoney',
        },
        {
            title: '个税',
            dataIndex: 'tax',
            key: 'tax',
        },
        {
            title: '实发金额',
            dataIndex: 'actualMoney',
            key: 'actualMoney',
        },
        {
        title: '在场状态',
        dataIndex: 'workStatus',
        key: 'workStatus',
        align: "center",
        customRender: (text) => {
            return text == '' ?  text : text == 1 ? '在场' : (text == 2 ? '离场' : '未录入')
        }
      },
    ]

    export default {
        components: {
            formIndex,
        },
        data() {
            return {
                formData: formData,
                headers: {
                    [TOKEN_KEY]: storage.get(TOKEN_KEY),
                    'Tenant-Id': storage.get('Tenant-Id') ? storage.get('Tenant-Id') : ''
                },

                queryData: {
                    officeName: '',
                    projectName: '',
                    month: '',
                    teamName: '',
                    applyType: '',
                    summaryStatus: '0'

                },
                modalApplyType: '',//保存详情里面的申请类型
                data: [],
                tabTotal:{
                    timeKey: 0,
                    payableMoneySum:0,
                    actualMoneySum:0,
                    taxSum:0,
                    highTempSubsidySum:0,
                },
                pagination: {
                    current: 1,
                    pageSize: 20,
                    total: 0,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
                },
                loading: false,
                columns,
                selectedApplyIdList: [],

                staffPayrollColumns,
                staffPayrollData: [],
                staffPayrollPagination: {
                    current: 1,
                    pageSize: 20,
                    total: 0,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
                },
                staffPayrollModelVisible: false,
                staffPayrollLoading: false,
                staffPayrollQueryData: {
                    applyId: 0,
                },
                uploadStaffPayrollUrl: UPLOAD_STAFF_PAYROLL,

                tabulationMonthModelVisible: false,
                tabulationData:{
                    month: ''
                },
                monthModelLoading: false
            };
        },
        computed: {
            ...mapGetters('account', ['user']),
        },
        created() {
            this.queryData.officeId = this.user.officeId
            this.queryData.status = 1
            this.getData(false);
        },
        methods: {
            handleSubmit(e) {
                this.queryData = Object.assign(this.queryData, e)
                this.getData(true);
            },
            getData(isSearch){
                this.loading = true;
                if (isSearch){
                    this.pagination.current = 1
                }
                this.queryData.pageNumber = this.pagination.current;
                this.queryData.pageSize = this.pagination.pageSize;
                getTeamPayrollApplyList(this.queryData).then(res => {
                    this.data = res.data.data.list;
                    this.pagination.total = res.data.data.total;
                    let payableMoneySum = 0;
                    let actualMoneySum = 0;
                    let highTempSubsidySum = 0;
                    let taxSum = 0;
                    this.data.forEach(function (item){
                        payableMoneySum += item.payableMoney
                        taxSum += item.tax
                        highTempSubsidySum += item.highTempSubsidy
                        actualMoneySum += item.actualMoney
                    })
                    this.tabTotal.payableMoneySum = payableMoneySum.toFixed(2);
                    this.tabTotal.actualMoneySum = actualMoneySum.toFixed(2);
                    this.tabTotal.highTempSubsidySum = highTempSubsidySum.toFixed(2);
                    this.tabTotal.taxSum = taxSum.toFixed(2);
                    this.tabTotal.timeKey = new Date().getTime()
                }).finally(() => {
                    this.selectedApplyIdList = []
                    this.loading = false;
                })
            },
            queryDataMonthChange(dateString){
                console.log(dateString)
                if (dateString === '' || dateString === undefined || dateString === null) {
                    this.queryData.month = ''
                    return
                }
                this.queryData.month = moment(dateString).format('YYYYMM')
            },
            selectApplyChange(selectedRowKeys) {
                this.selectedApplyIdList = selectedRowKeys;
                console.log( this.selectedApplyIdList);
            },

            showStaffPayrollModel(record){
                this.staffPayrollModelVisible = true;
                this.staffPayrollQueryData.applyId = record.id
                this.modalApplyType = record.applyType
                this.getStaffPayrollList()
            },
            staffPayrollModelCancel(){
                this.staffPayrollModelVisible = false;
            },
            searchStaffPayroll(){
                this.staffPayrollPagination.current = 1
                this.getStaffPayrollList()
            },
            getStaffPayrollList(){
                this.loading = true;
                this.staffPayrollQueryData.pageNumber = this.staffPayrollPagination.current;
                this.staffPayrollQueryData.pageSize = this.staffPayrollPagination.size;
                getStaffPayrollList(this.staffPayrollQueryData).then(res => {
                    this.staffPayrollData = res.data.data.list;
                    this.staffPayrollPagination.total = res.data.data.total;
                }).finally(() => {
                    this.loading = false;
                })

            },
            downloadStaffPayroll(){
                this.loading = true;
                downloadStaffPayroll(this.staffPayrollQueryData).then(res => {
                    let applyTypeText = this.modalApplyType == '1' ? "专户" : "普户";
                    // let teamName = this.showTeamData.teamName;
                    // let month = this.showTeamData.month;
                    let fileName = applyTypeText + "员工工资表.xlsx";
                    let downloadElement = document.createElement('a')//创建dom
                    let href = window.URL.createObjectURL(res.data) //创建下载链接
                    downloadElement.href = href
                    downloadElement.download = fileName //下载后文件名
                    document.body.appendChild(downloadElement)//添加创建的节点
                    downloadElement.click() //点击下载
                    document.body.removeChild(downloadElement) //下载完成移除元素
                    window.URL.revokeObjectURL(href) //释放掉blob对象
                }).finally(() => {
                    this.loading = false;
                })
            },
            staffPayrollFileChange(info){
                this.loading=true;
                console.log("staffPayrollFileChange..", info)
                if (info.file.status === 'done') {
                    const stat = info.file.response.code
                    if (stat === 0){
                        this.getStaffPayrollList()
                    }else {
                        this.$message.error(info.file.response.msg)
                    }
                    this.loading=false;
                }
            },
            batchDownloadTeamPayroll(idList,applyType){
                // 循环idList，重新组装applyIdList
                let applyIds = [];
                idList.forEach(id => {
                    applyIds.push(parseInt(id))
                })
                let data = {
                    "applyIdListStr": encodeURIComponent(JSON.stringify(applyIds)),
                    "applyType": applyType ? applyType : this.queryData.applyType
                }
                this.loading = true
                downloadTeamPayroll(data).then(res => {
                    let text = applyType == '1' ? "专户" : "普户"
                    let applyTypeText = this.queryData.applyType === '0' ? "" : this.queryData.applyType === '1'? "专户" : "普户";
                    let fileName = (applyType ? text : applyTypeText) + "班组工资表.xlsx";
                    // let fileName = res.headers['Content-Disposition'].split(';')[1].split('=')[1];
                    let downloadElement = document.createElement('a')//创建dom
                    let href = window.URL.createObjectURL(res.data) //创建下载链接
                    downloadElement.href = href
                    downloadElement.download = fileName //下载后文件名
                    document.body.appendChild(downloadElement)//添加创建的节点
                    downloadElement.click() //点击下载
                    document.body.removeChild(downloadElement) //下载完成移除元素
                    window.URL.revokeObjectURL(href) //释放掉blob对象
                }).finally(() => {
                    this.loading = false;
                })
            },
            downloadTeamPayroll(record){
                let idList = [record.id];
                this.batchDownloadTeamPayroll(idList, record.applyType);
            },
            payrollApplySummary(applyIdList){
                if (this.tabulationData.month === undefined || this.tabulationData.month===null || this.tabulationData.month===""){
                    this.$message.error("请先选择汇总月份！")
                    return
                }
                let params = {
                    applyIdList,
                    'month': this.tabulationData.month
                }
                this.loading = true;
                payrollApplySummary(params).then(res => {
                    if (res.data.code === 0){
                        this.$message.success('操作成功！')
                        this.getData(false)
                        this.summaryExport(res.data.data.id, res.data.data.applyType)
                    }else{
                        this.$message.error(res.data.msg)
                    }
                }).finally(() => {
                    this.loading = false;
                })

            },
            summaryExport(summaryId, applyType){

                let data = {
                    "summaryId": summaryId,
                    "applyType": applyType
                }
                this.loading = true;
                this.monthModelLoading = true
                summaryExport(data).then(res => {
                    let fileName = summaryId + "_" + this.tabulationData.month + "工人工资申请汇总.xlsx";
                    let downloadElement = document.createElement('a')//创建dom
                    let href = window.URL.createObjectURL(res.data) //创建下载链接
                    downloadElement.href = href
                    downloadElement.download = fileName //下载后文件名
                    document.body.appendChild(downloadElement)//添加创建的节点
                    downloadElement.click() //点击下载
                    document.body.removeChild(downloadElement) //下载完成移除元素
                    window.URL.revokeObjectURL(href) //释放掉blob对象
                }).finally(() => {
                    this.loading = false;
                    this.monthModelLoading = false;
                })
            },

            repulseApplyStatus(record){
                let idList = [record.id]
                this.batchFinanceRepulse(idList)
            },
            batchFinanceRepulse(idList){
                let applyIds = [];
                idList.forEach(id => {
                    applyIds.push(parseInt(id))
                })
                this.loading = true;
                batchFinanceRepulse(applyIds).then(res => {
                    if (res.data.code === 0){
                        this.$message.success('操作成功！')
                    }else {
                        this.$message.error(res.data.msg)
                    }
                }).finally(() => {
                    this.getData(false);
                    this.loading = false;
                })
            },
            teamApplyTableChange(pagination){
                this.pagination.current = pagination.current
                this.pagination.pageSize = pagination.pageSize
                this.getData(false)
            },
            staffPayrollTableChange(pagination){
                this.staffPayrollPagination.current = pagination.current
                this.staffPayrollPagination.pageSize = pagination.pageSize
                this.getStaffPayrollList()
            },
            summaryStatusChange(value){
                this.queryDate.summaryStatus = value
            },
            showTabulationMonthModel(){
                this.tabulationMonthModelVisible = true;
                const date = new Date;
                this.tabulationData.month = ""
            },
            tabulationMonthModelCancel(){
                this.tabulationMonthModelVisible = false;
            },
            tabulationMonthChange(dateString){
                if (dateString === '' || dateString === undefined || dateString === null) {
                    this.tabulationData.month = ''
                    return
                }
                this.tabulationData.month = moment(dateString).format('YYYYMM')
            },
            // 重置
            close() {
                this.$refs["formindex"].resetFields();
                this.queryData = {
                    officeName: '',
                    projectName: '',
                    month: '',
                    teamName: '',
                    applyType: '',
                    summaryStatus: '0'
                },
                this.getData(true);
            },

        },


    };

</script>
<style lang="less" scoped>

.a_a_footer {
  padding: 0 0px;
  background: #ffffff;

  .operator {
    margin-right: 8px;

    .ant-btn {
      color: #307dfa;
      background: #fff;
    }
  }

  .sTables {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      width: 100%;
      text-align: center;
      cursor: pointer;
    }

    div:nth-child(1) {
      color: #307dfa;
    }

    // div:nth-child(2) {
    //   color: #ff5c77;
    // }

    div:nth-child(2) {
      color: #615e83;
    }
  }

}

    .ant-advanced-search-form {
        padding: 24px;
        background: #fff;
        border-radius: 6px;
    }

    tr{
        background-color: #ffffff;
    }

    .steps-content {
        margin-top: 16px;
        border: 1px dashed #e9e9e9;
        border-radius: 6px;
        background-color: #fafafa;
        padding-top: 10px;
    }

    .steps-action {
        margin-top: 24px;
    }
</style>
