import { render, staticRenderFns } from "./inspectionRecord.vue?vue&type=template&id=fa33a5d2&scoped=true"
import script from "./inspectionRecord.vue?vue&type=script&lang=js"
export * from "./inspectionRecord.vue?vue&type=script&lang=js"
import style0 from "./inspectionRecord.vue?vue&type=style&index=0&id=fa33a5d2&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa33a5d2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\web-project\\jiananyi\\suijian_client\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fa33a5d2')) {
      api.createRecord('fa33a5d2', component.options)
    } else {
      api.reload('fa33a5d2', component.options)
    }
    module.hot.accept("./inspectionRecord.vue?vue&type=template&id=fa33a5d2&scoped=true", function () {
      api.rerender('fa33a5d2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/equipmentLedger/detail/inspectionRecord.vue"
export default component.exports