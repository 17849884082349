var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "common-layout",
    { class: _vm.isLaowu ? "laowu-login-container" : "login-container" },
    [
      _vm.isSafe
        ? _c("img", {
            staticClass: "logo2",
            attrs: { alt: "logo", src: require("@/assets/img/logo2.png") },
          })
        : _vm._e(),
      _vm.isLaowu
        ? _c(
            "div",
            [
              [
                _c("div", { staticClass: "login-new-box" }, [
                  _c(
                    "div",
                    { staticClass: "laowu-box" },
                    [
                      _c("div", { staticClass: "top" }, [
                        _c("div", { staticClass: "header" }, [
                          _c("span", { staticClass: "title2" }, [
                            _vm._v("欢迎使用"),
                          ]),
                          _c("br"),
                          _c("span", { staticClass: "title2" }, [
                            _vm._v("建工一号 ● 劳务管家"),
                          ]),
                        ]),
                      ]),
                      !_vm.isBindPhone
                        ? [
                            !_vm.isForgetPwd
                              ? _c(
                                  "div",
                                  { staticClass: "login" },
                                  [
                                    _c(
                                      "a-form",
                                      {
                                        attrs: { form: _vm.form },
                                        on: { submit: _vm.onSubmit },
                                      },
                                      [
                                        _c(
                                          "a-tabs",
                                          {
                                            staticClass: "new-tabs",
                                            staticStyle: { padding: "0 2px" },
                                            attrs: {
                                              size: "large",
                                              tabBarStyle: {
                                                textAlign: "center",
                                              },
                                            },
                                            on: { change: _vm.changeLogin },
                                          },
                                          [
                                            _c(
                                              "a-tab-pane",
                                              {
                                                key: "2",
                                                attrs: { tab: "手机号登录" },
                                              },
                                              [
                                                _vm.error
                                                  ? _c("a-alert", {
                                                      staticStyle: {
                                                        "margin-bottom": "24px",
                                                      },
                                                      attrs: {
                                                        type: "error",
                                                        "after-close":
                                                          _vm.handleClose,
                                                        closable: true,
                                                        message: _vm.error,
                                                        showIcon: "",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _c(
                                                  "a-form-item",
                                                  [
                                                    _c(
                                                      "a-input",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "decorator",
                                                            rawName:
                                                              "v-decorator",
                                                            value: [
                                                              "mobile",
                                                              {
                                                                rules: [
                                                                  {
                                                                    required: true,
                                                                    message:
                                                                      "请输入手机号",
                                                                  },
                                                                  {
                                                                    pattern:
                                                                      /^1[3-9]\d{9}$/,
                                                                    message:
                                                                      "请输入有效的手机号码",
                                                                  },
                                                                ],
                                                              },
                                                            ],
                                                            expression:
                                                              "[\n                        'mobile',\n                        {\n                          rules: [\n                            { required: true, message: '请输入手机号' },\n                            { pattern: /^1[3-9]\\d{9}$/, message: '请输入有效的手机号码' }\n                          ],\n                        },\n                      ]",
                                                          },
                                                        ],
                                                        attrs: {
                                                          maxLength: 11,
                                                          size: "large",
                                                          placeholder:
                                                            "请输入手机号",
                                                          name: "mobile",
                                                        },
                                                      },
                                                      [
                                                        _c("a-icon", {
                                                          attrs: {
                                                            slot: "prefix",
                                                            type: "mobile",
                                                          },
                                                          slot: "prefix",
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm.isPwdLogin
                                                  ? _c(
                                                      "a-form-item",
                                                      [
                                                        _c(
                                                          "a-input-password",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "decorator",
                                                                rawName:
                                                                  "v-decorator",
                                                                value: [
                                                                  "pwd",
                                                                  {
                                                                    rules: [
                                                                      {
                                                                        required: true,
                                                                        message:
                                                                          "请输入密码",
                                                                        whitespace: true,
                                                                      },
                                                                    ],
                                                                  },
                                                                ],
                                                                expression:
                                                                  "[\n                        'pwd',\n                        {\n                          rules: [\n                            {\n                              required: true,\n                              message: '请输入密码',\n                              whitespace: true,\n                            },\n                          ],\n                        },\n                      ]",
                                                              },
                                                            ],
                                                            attrs: {
                                                              maxLength: 16,
                                                              size: "large",
                                                              placeholder:
                                                                "请输入密码",
                                                              autocomplete:
                                                                "autocomplete",
                                                              type: "password",
                                                            },
                                                          },
                                                          [
                                                            _c("a-icon", {
                                                              attrs: {
                                                                slot: "prefix",
                                                                type: "lock",
                                                              },
                                                              slot: "prefix",
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "a-form-item",
                                                      [
                                                        _c(
                                                          "a-input",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "decorator",
                                                                rawName:
                                                                  "v-decorator",
                                                                value: [
                                                                  "smsCode",
                                                                  {
                                                                    rules: [
                                                                      {
                                                                        required: true,
                                                                        message:
                                                                          "请输入验证码",
                                                                      },
                                                                    ],
                                                                  },
                                                                ],
                                                                expression:
                                                                  "[\n                        'smsCode',\n                        {\n                          rules: [{ required: true, message: '请输入验证码' }],\n                        },\n                      ]",
                                                              },
                                                            ],
                                                            attrs: {
                                                              size: "large",
                                                              placeholder:
                                                                "请输入验证码",
                                                              name: "smsCode",
                                                            },
                                                          },
                                                          [
                                                            _c("a-icon", {
                                                              attrs: {
                                                                slot: "prefix",
                                                                type: "lock",
                                                              },
                                                              slot: "prefix",
                                                            }),
                                                            _c(
                                                              "a-button",
                                                              {
                                                                attrs: {
                                                                  slot: "addonAfter",
                                                                  type: "link",
                                                                  disabled:
                                                                    _vm.countdown >
                                                                    0,
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.getCode,
                                                                },
                                                                slot: "addonAfter",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.countdown >
                                                                        0
                                                                        ? _vm.countdown +
                                                                            "秒后重新获取"
                                                                        : "获取验证码"
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "a-tab-pane",
                                              {
                                                key: "1",
                                                attrs: { tab: "账户密码登录" },
                                              },
                                              [
                                                _vm.error
                                                  ? _c("a-alert", {
                                                      staticStyle: {
                                                        "margin-bottom": "24px",
                                                      },
                                                      attrs: {
                                                        type: "error",
                                                        "after-close":
                                                          _vm.handleClose,
                                                        closable: true,
                                                        message: _vm.error,
                                                        showIcon: "",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _c(
                                                  "a-form-item",
                                                  [
                                                    _c(
                                                      "a-input",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "decorator",
                                                            rawName:
                                                              "v-decorator",
                                                            value: [
                                                              "name",
                                                              {
                                                                rules: [
                                                                  {
                                                                    required: true,
                                                                    message:
                                                                      "请输入账户名",
                                                                    whitespace: true,
                                                                  },
                                                                ],
                                                              },
                                                            ],
                                                            expression:
                                                              "[\n                        'name',\n                        {\n                          rules: [\n                            {\n                              required: true,\n                              message: '请输入账户名',\n                              whitespace: true,\n                            },\n                          ],\n                        },\n                      ]",
                                                          },
                                                        ],
                                                        attrs: {
                                                          maxLength: 16,
                                                          autocomplete:
                                                            "autocomplete",
                                                          size: "large",
                                                          placeholder:
                                                            "请输入登录账号",
                                                        },
                                                      },
                                                      [
                                                        _c("a-icon", {
                                                          attrs: {
                                                            slot: "prefix",
                                                            type: "user",
                                                          },
                                                          slot: "prefix",
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "a-form-item",
                                                  [
                                                    _c(
                                                      "a-input-password",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "decorator",
                                                            rawName:
                                                              "v-decorator",
                                                            value: [
                                                              "password",
                                                              {
                                                                rules: [
                                                                  {
                                                                    required: true,
                                                                    message:
                                                                      "请输入密码",
                                                                    whitespace: true,
                                                                  },
                                                                ],
                                                              },
                                                            ],
                                                            expression:
                                                              "[\n                        'password',\n                        {\n                          rules: [\n                            {\n                              required: true,\n                              message: '请输入密码',\n                              whitespace: true,\n                            },\n                          ],\n                        },\n                      ]",
                                                          },
                                                        ],
                                                        attrs: {
                                                          maxLength: 16,
                                                          size: "large",
                                                          placeholder:
                                                            "请输入密码",
                                                          autocomplete:
                                                            "autocomplete",
                                                          type: "password",
                                                        },
                                                      },
                                                      [
                                                        _c("a-icon", {
                                                          attrs: {
                                                            slot: "prefix",
                                                            type: "lock",
                                                          },
                                                          slot: "prefix",
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "min-height": "28px",
                                            },
                                          },
                                          [
                                            _vm.loginType == 2
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "pointer sys-blue",
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.isPwdLogin =
                                                          !_vm.isPwdLogin
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(_vm.btnText))]
                                                )
                                              : _vm._e(),
                                            _vm.isPwdLogin ||
                                            this.loginType == 1
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "pointer sys-blue",
                                                    staticStyle: {
                                                      float: "right",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.isForgetPwd =
                                                          !_vm.isForgetPwd
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("忘记密码")]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c(
                                          "a-form-item",
                                          [
                                            _c(
                                              "a-button",
                                              {
                                                staticStyle: {
                                                  width: "100%",
                                                  "margin-top": "24px",
                                                },
                                                attrs: {
                                                  loading: _vm.logging,
                                                  size: "large",
                                                  htmlType: "submit",
                                                  type: "primary",
                                                },
                                              },
                                              [_vm._v("登录")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("Verify", {
                                      ref: "verify",
                                      attrs: {
                                        "captcha-type": "blockPuzzle",
                                        "img-size": {
                                          width: "330px",
                                          height: "180px",
                                        },
                                      },
                                      on: { success: _vm.handleSuccessVerify },
                                    }),
                                  ],
                                  1
                                )
                              : _c("forget-password", {
                                  staticClass: "login",
                                  attrs: { loginType: _vm.loginType },
                                  on: {
                                    back: function ($event) {
                                      _vm.isForgetPwd = !_vm.isForgetPwd
                                    },
                                  },
                                }),
                          ]
                        : _c("bind-phone", {
                            staticClass: "login",
                            attrs: { account: _vm.account },
                          }),
                    ],
                    2
                  ),
                ]),
              ],
            ],
            2
          )
        : _c("div", [
            _c(
              "div",
              { staticClass: "login-box" },
              [
                _c("div", { staticClass: "top" }, [
                  _c("div", { staticClass: "header" }, [
                    _c("span", { staticClass: "title2" }, [
                      _vm._v("Hi,欢迎来到"),
                    ]),
                    _c("br"),
                    _c("span", { staticClass: "title2" }, [
                      _vm._v(_vm._s(_vm.systemFullname)),
                    ]),
                  ]),
                ]),
                !_vm.isBindPhone
                  ? [
                      !_vm.isForgetPwd
                        ? _c(
                            "div",
                            { staticClass: "login" },
                            [
                              _c(
                                "a-form",
                                {
                                  attrs: { form: _vm.form },
                                  on: { submit: _vm.onSubmit },
                                },
                                [
                                  _vm.error
                                    ? _c("a-alert", {
                                        staticStyle: {
                                          "margin-bottom": "24px",
                                        },
                                        attrs: {
                                          type: "error",
                                          "after-close": _vm.handleClose,
                                          closable: true,
                                          message: _vm.error,
                                          showIcon: "",
                                        },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "a-form-item",
                                    [
                                      _c(
                                        "a-input",
                                        {
                                          directives: [
                                            {
                                              name: "decorator",
                                              rawName: "v-decorator",
                                              value: [
                                                "mobile",
                                                {
                                                  rules: [
                                                    {
                                                      required: true,
                                                      message: "请输入手机号",
                                                    },
                                                  ],
                                                },
                                              ],
                                              expression:
                                                "[\n                  'mobile',\n                  { rules: [{ required: true, message: '请输入手机号' }] },\n                ]",
                                            },
                                          ],
                                          staticClass: "test",
                                          attrs: {
                                            maxLength: 11,
                                            size: "large",
                                            placeholder: "请输入手机号",
                                            name: "mobile",
                                          },
                                        },
                                        [
                                          _c("a-icon", {
                                            attrs: {
                                              slot: "prefix",
                                              type: "mobile",
                                            },
                                            slot: "prefix",
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.isPwdLogin
                                    ? _c(
                                        "a-form-item",
                                        [
                                          _c(
                                            "a-input-password",
                                            {
                                              directives: [
                                                {
                                                  name: "decorator",
                                                  rawName: "v-decorator",
                                                  value: [
                                                    "pwd",
                                                    {
                                                      rules: [
                                                        {
                                                          required: true,
                                                          message: "请输入密码",
                                                          whitespace: true,
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                  expression:
                                                    "[\n                  'pwd',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: '请输入密码',\n                        whitespace: true,\n                      },\n                    ],\n                  },\n                ]",
                                                },
                                              ],
                                              staticClass: "test",
                                              attrs: {
                                                maxLength: 16,
                                                size: "large",
                                                placeholder: "请输入密码",
                                                autocomplete: "autocomplete",
                                                type: "password",
                                              },
                                            },
                                            [
                                              _c("a-icon", {
                                                attrs: {
                                                  slot: "prefix",
                                                  type: "lock",
                                                },
                                                slot: "prefix",
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "a-form-item",
                                        [
                                          _c(
                                            "a-input",
                                            {
                                              directives: [
                                                {
                                                  name: "decorator",
                                                  rawName: "v-decorator",
                                                  value: [
                                                    "smsCode",
                                                    {
                                                      rules: [
                                                        {
                                                          required: true,
                                                          message:
                                                            "请输入验证码",
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                  expression:
                                                    "[\n                  'smsCode',\n                  {\n                    rules: [{ required: true, message: '请输入验证码' }],\n                  },\n                ]",
                                                },
                                              ],
                                              attrs: {
                                                size: "large",
                                                placeholder: "请输入验证码",
                                                name: "smsCode",
                                              },
                                            },
                                            [
                                              _c("a-icon", {
                                                attrs: {
                                                  slot: "prefix",
                                                  type: "lock",
                                                },
                                                slot: "prefix",
                                              }),
                                              _c(
                                                "a-button",
                                                {
                                                  attrs: {
                                                    slot: "addonAfter",
                                                    type: "link",
                                                    disabled: _vm.countdown > 0,
                                                  },
                                                  on: { click: _vm.getCode },
                                                  slot: "addonAfter",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.countdown > 0
                                                          ? _vm.countdown +
                                                              "秒后重新获取"
                                                          : "获取验证码"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                  _c(
                                    "div",
                                    { staticStyle: { "min-height": "28px" } },
                                    [
                                      _vm.loginType == 2
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "pointer sys-blue",
                                              on: {
                                                click: function ($event) {
                                                  _vm.isPwdLogin =
                                                    !_vm.isPwdLogin
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(_vm.btnText))]
                                          )
                                        : _vm._e(),
                                      _vm.isPwdLogin || this.loginType == 1
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "pointer sys-blue",
                                              staticStyle: { float: "right" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.isForgetPwd =
                                                    !_vm.isForgetPwd
                                                },
                                              },
                                            },
                                            [_vm._v("忘记密码")]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "a-form-item",
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            "margin-top": "24px",
                                          },
                                          attrs: {
                                            loading: _vm.logging,
                                            size: "large",
                                            htmlType: "submit",
                                            type: "primary",
                                          },
                                        },
                                        [_vm._v("登录")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("Verify", {
                                ref: "verify",
                                attrs: {
                                  "captcha-type": "blockPuzzle",
                                  "img-size": {
                                    width: "330px",
                                    height: "180px",
                                  },
                                },
                                on: { success: _vm.handleSuccessVerify },
                              }),
                            ],
                            1
                          )
                        : _c("forget-password", {
                            staticClass: "login",
                            attrs: { loginType: _vm.loginType },
                            on: {
                              back: function ($event) {
                                _vm.isForgetPwd = !_vm.isForgetPwd
                              },
                            },
                          }),
                    ]
                  : _c("bind-phone", {
                      staticClass: "login",
                      attrs: { account: _vm.account },
                    }),
              ],
              2
            ),
          ]),
      _c("choice-type", {
        attrs: { visible: _vm.choice.visible },
        on: {
          "update:visible": function ($event) {
            return _vm.$set(_vm.choice, "visible", $event)
          },
          doCloseChoice: _vm.doCloseChoice,
          doSure: function (e) {
            return _vm.gotoHome(e.type)
          },
        },
      }),
      _c(
        "a-modal",
        {
          attrs: { title: "请选择租户" },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
          model: {
            value: _vm.tenantVisible,
            callback: function ($$v) {
              _vm.tenantVisible = $$v
            },
            expression: "tenantVisible",
          },
        },
        [
          _c(
            "a-radio-group",
            {
              model: {
                value: _vm.tenantId,
                callback: function ($$v) {
                  _vm.tenantId = $$v
                },
                expression: "tenantId",
              },
            },
            [
              _c(
                "div",
                { staticClass: "flex flex-column" },
                _vm._l(_vm.tenantIdList, function (item, index) {
                  return _c(
                    "a-radio",
                    {
                      key: index,
                      staticClass: "mb-1",
                      attrs: { value: item.id },
                    },
                    [_vm._v(" " + _vm._s(item.name) + " ")]
                  )
                }),
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }