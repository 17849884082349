import { ADMIN } from '@/services/api'
import {request, METHOD, removeAuthorization} from '@/utils/request'

// const BASE_URL = process.env.VUE_APP_API_BASE_URL + '/zk-edu'
const ADMIN_API = `${ADMIN}/thdj`


// 风险清单汇总管理
// /riskcontrol/create
export async function riskcontrolAdd(data) {
    return request(ADMIN_API + '/riskcontrol/create', METHOD.POST, data)
}
// /riskcontrol/page
export async function riskcontrolList(data) {
    return request(ADMIN_API + `/riskcontrol/page`, METHOD.POST, data)
}
// /riskcontrol/get?id=4
export async function riskcontrolByID(params) {
    return request(ADMIN_API + `/riskcontrol/get`, METHOD.GET, params)
}
// /riskcontrol/delete
export async function riskcontrolDelete(params) {
    return request(ADMIN_API + `/riskcontrol/delete`, METHOD.GET, params)
}
// 风险清单
// /riskcontrol/submit
export async function riskcontrolSubmit(params) {
    return request(ADMIN_API + '/riskcontrol/submit', METHOD.GET, params)
}
// /riskcontroldetail/page
export async function riskcontroldetailList(data) {
    return request(ADMIN_API + `/riskcontroldetail/page`, METHOD.POST, data)
}
// /riskcontroldetail/create
export async function riskcontroldetailAdd(data) {
    return request(ADMIN_API + '/riskcontroldetail/create', METHOD.POST, data)
}
export async function riskcontroldetailAddBatch(data) {
    return request(ADMIN_API + '/riskcontroldetail/createlist', METHOD.POST, data)
}
// /riskcontroldetail/delete
export async function riskcontroldetailDelete(params) {
    return request(ADMIN_API + `/riskcontroldetail/delete`, METHOD.GET, params)
}
// /riskcontroldetail/get
export async function riskcontroldetailByID(params) {
    return request(ADMIN_API + `/riskcontroldetail/get`, METHOD.GET, params)
}
// /riskcontroldetail/update
export async function riskcontroldetailUpdate(data) {
    return request(ADMIN_API + `/riskcontroldetail/update`, METHOD.POST, data)
}
// /riskcontroldetail/export-excel
export const riskcontroldetailExport = ADMIN_API + '/riskcontroldetail/export-excel'

// 风险地图
// /riskmap/page
export async function riskmapList(data) {
    return request(ADMIN_API + `/riskmap/page`, METHOD.POST, data)
}
// /riskmap/create
export async function riskmapAdd(data) {
    return request(ADMIN_API + '/riskmap/create', METHOD.POST, data)
}
// /admin-api/riskmap/update
export async function riskmapUpdate(data) {
    return request(ADMIN_API + `/riskmap/update`, METHOD.POST, data)
}
// /riskmap/delete
export async function riskmapDelete(params) {
    return request(ADMIN_API + `/riskmap/delete`, METHOD.GET, params)
}
// /riskmap/get
export async function riskmapByID(params) {
    return request(ADMIN_API + `/riskmap/get`, METHOD.GET, params)
}
// 风险地图明细
// /riskmapdetail/page
export async function riskmapdetailList(data) {
    return request(ADMIN_API + `/riskmapdetail/page`, METHOD.POST, data)
}
// riskmapdetail/create
export async function riskmapdetailAdd(data) {
    return request(ADMIN_API + '/riskmapdetail/create', METHOD.POST, data)
}
// /riskmapdetail/update
export async function riskmapdetailUpdate(data) {
    return request(ADMIN_API + `/riskmapdetail/update`, METHOD.POST, data)
}
// /riskmapdetail/delete
export async function riskmapdetailDelete(params) {
    return request(ADMIN_API + `/riskmapdetail/delete`, METHOD.GET, params)
}
// /riskmapdetail/get
export async function riskmapdetailByID(params) {
    return request(ADMIN_API + `/riskmapdetail/get`, METHOD.GET, params)
}