<template>
    <a-card :bordered="false">
        <a-table
                :columns="columns"
                :data-source="data"
                :pagination="pagination"

                @change="onPageChange"
                :loading="tableLoading"
        >
            <template slot="organizationName" slot-scope="text, record, index">
                <div :key="index">
                    <a-input
                            v-if="record.editable"
                            style="margin: -5px 0"
                            :value="text"
                            @change="e => handleChange(e.target.value, record.id, col)"
                    />
                    <template v-else>
                        {{ text }}
                    </template>
                </div>
            </template>
            <template slot="organizationCode" slot-scope="text, record, index">
                <div :key="index">
                    <a-input
                            v-if="record.editable"
                            style="margin: -5px 0"
                            :value="text"
                            @change="e => handleChange(e.target.value, record.id, 'organizationCode')"
                    />
                    <template v-else>
                        {{ text }}
                    </template>
                </div>
            </template>
            <template slot="organizationLicense" slot-scope="text, record, index">
                <div :key="index">

                    <img v-if="text" :src="text" style="width: 120px; height: 80px;" @click="showImg(record)">

                    <a-upload
                            v-if="record.editable"
                            name="file"
                            :action="uploadOrganizationLicenseImg+ '?id=' + record.id"
                            :headers="headers"
                            @change="handleChangeOrganizationLicenseImg"
                            :showUploadList="false"
                    >

                        <a-divider type="vertical" />
                        <a-button>  <a-icon type="upload" /> {{record.organizationLicense==''? '上传' : '重新上传'}} </a-button>
                    </a-upload>

                </div>
            </template>
            <template slot="userName" slot-scope="text, record, index">
                <div :key="index">
                    <a-input
                            v-if="record.editable"
                            style="margin: -5px 0"
                            :value="text"
                            @change="e => handleChange(e.target.value, record.id, 'userName')"
                    />
                    <template v-else>
                        {{ text }}
                    </template>
                </div>
            </template>
            <template slot="userPhone" slot-scope="text, record, index">
                <div :key="index">
                    <a-input
                            v-if="record.editable"
                            style="margin: -5px 0"
                            :value="text"
                            @change="e => handleChange(e.target.value, record.id, 'userPhone')"
                    />
                    <template v-else>
                        {{ text }}
                    </template>
                </div>
            </template>
            <template slot="userIdCard" slot-scope="text, record, index">
                <div :key="index">
                    <a-input
                            v-if="record.editable"
                            style="margin: -5px 0"
                            :value="text"
                            @change="e => handleChange(e.target.value, record.id, 'userIdCard')"
                    />
                    <template v-else>
                        {{ text }}
                    </template>
                </div>
            </template>


            <template slot="authorization" slot-scope="authorization, record">
                <a href="https://res.ess.myqcloud.com/web-sign/docs/%E3%80%90%E8%85%BE%E8%AE%AF%E7%94%B5%E5%AD%90%E7%AD%BE%E3%80%91%E8%B6%85%E7%BA%A7%E7%AE%A1%E7%90%86%E5%91%98%E6%8E%88%E6%9D%83%E4%B9%A6%E6%A8%A1%E6%9D%BF.docx"
                   v-if="!user.nowRoleKey.includes('admin') ">下载模板
                    <a-divider type="vertical" />
                </a>
                <a v-if="authorization != ''" :href="authorization">
                    {{authorization == "" ? "" : "授权书.xls(点击下载)" }} </a>
                <a-upload
                        v-if="record.editable"
                        name="file"
                        :action="uploadAuthorizationFileUrl+ '?id=' + record.id"
                        :headers="headers"
                        @change="authorizationChange"
                        :showUploadList="false"
                >

                    <a-divider type="vertical" />
                    <a-button>  <a-icon type="upload" /> {{authorization==''? '上传' : '重新上传'}} </a-button>
                </a-upload>

            </template>

            <template slot="operation" slot-scope="text, record">
                <div class="editable-row-operations">
                    <span v-if="record.editable">
                      <a @click="() => save(record.id)">保存</a>
                      <a-divider type="vertical" />
                      <a-popconfirm title="是否取消保存?" @confirm="() => cancel(record.id)">
                        <a>取消</a>
                      </a-popconfirm>
                    </span>
                    <span v-else>
                      <a :disabled="editingKey !== ''" @click="() => edit(record.id)">修改</a>
                      <a v-if="record.status != 1 && record.status != 2" @click="applyEss(record)">
                        <a-divider type="vertical" />
                          申请开通
                      </a>
                      <a v-if="record.status == 2 || (record.status >=1 && user.nowRoleKey.includes('admin')) " @click="createConsoleLoginUrl(record)">
                        <a-divider type="vertical" />
                          进入控制台
                      </a>
                    </span>
                </div>
            </template>
        </a-table>

        <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancelOrganizationLicenseImg()">
            <img alt="example" style="width: 100%" :src="previewVisibleImg" />
        </a-modal>
    </a-card>


</template>


<script>
    import {getOfficeEssList, officeEssUpdate, applyEss, createConsoleLoginUrl} from '@/services/ess'
    import {UPLOAD_AUTHORIZATION_FILE, UPLOAD_ORGANIZATION_LICENSE_IMG} from '@/services/api'
    import {mapGetters} from 'vuex'
    import moment from 'moment';
    import { storage } from '@/utils/storage'
    import { TOKEN_KEY } from '@/utils/request.js'

    const columns = [
        {
            title: '公司名称',
            dataIndex: 'organizationName',
            key: 'organizationName',
            slots: { title: 'organizationName' },
            scopedSlots: { customRender: 'organizationName' },
        },
        {
            title: '企业appid',
            dataIndex: 'proxyAppId',
            key: 'proxyAppId',
            ellipsis: true,
            slots: { title: 'proxyAppId' },
            scopedSlots: { customRender: 'proxyAppId' },
        },
        {
            title: '企业openId',
            dataIndex: 'proxyOrganizationOpenId',
            key: 'proxyOrganizationOpenId',
            scopedSlots: { customRender: 'proxyOrganizationOpenId' },
        },
        {
            title: '统一社会信用代码',
            dataIndex: 'organizationCode',
            key: 'organizationCode',
            scopedSlots: { customRender: 'organizationCode' },
        },
        {
            title: '营业执照',
            dataIndex: 'organizationLicense',
            key: 'organizationLicense',
            scopedSlots: { customRender: 'organizationLicense' },
        },
        {
            title: '管理员id',
            dataIndex: 'userId',
            key: 'userId',
            scopedSlots: { customRender: 'userId' },
        },
        {
            title: '管理员姓名',
            dataIndex: 'userName',
            key: 'userName',
            slots: { title: 'userName' },
            scopedSlots: { customRender: 'userName' },
        },
        {
            title: '管理员手机号',
            dataIndex: 'userPhone',
            key: 'userPhone',
            scopedSlots: { customRender: 'userPhone' },
        },
        {
            title: '管理员身份证',
            dataIndex: 'userIdCard',
            key: 'userIdCard',
            slots: { title: 'userIdCard' },
            scopedSlots: { customRender: 'userIdCard' },
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            customRender: (text) => {
                return text === 0? "未申请" : text === 1? "待审核" : text === 2? "已开通" : "审核失败"
            }
        },
        {
            title: '授权证书',
            dataIndex: 'authorization',
            scopedSlots: { customRender: 'authorization' },
        },
        {
            title: '操作',
            dataIndex: 'operation',
            scopedSlots: { customRender: 'operation' },
        },
    ];

    export default {
        data() {
            return {

                // 筛选
                searchForm: {
                    dataRange: "0"
                },
                loading: false,
                editingKey: '',

                previewVisible: false,
                previewVisibleImg: "",

                qrImg: "",

                // 数据表格
                columns,
                data: [],
                cacheData: [],
                pagination:{
                    current:1,
                    pageSize:10,
                    total:0,
                },
                tableLoading: false,
                exportLoading: false,
                dateFormat: 'YYYY-MM-DD',
                uploadAuthorizationFileUrl: UPLOAD_AUTHORIZATION_FILE,
                uploadOrganizationLicenseImg: UPLOAD_ORGANIZATION_LICENSE_IMG,
                headers: {
                    [TOKEN_KEY]: storage.get(TOKEN_KEY),
                    'Tenant-Id': storage.get('Tenant-Id') ? storage.get('Tenant-Id') : ''
                },
                organizationLicenseImg: []

            };
        },
        computed: {
            ...mapGetters('account', ['user']),
        },
        created() {
            console.log(this.user.nowRoleKey)
            if (!this.user.nowRoleKey.includes( "admin")){
                this.searchForm.officeId = this.user.officeId
                this.searchForm.proxyOrganizationOpenId = this.user.officeId
            }
            this.initData()
        },
        methods: {
            moment,
            initData(){
                this.getOfficeEssList()
            },
            getOfficeEssList(){
                this.data = []
                this.detailsData = []
                this.loading = true
                this.tableLoading = true
                getOfficeEssList(this.searchForm).then(res=>{
                    this.data = res.data.data.list
                    this.cacheData = this.data
                    this.pagination.total = res.data.data.total
                }).finally(()=>{
                    this.loading = false
                    this.tableLoading = false
                })
            },
            handleChange(value, key, column) {
                const newData = [...this.data];
                const target = newData.filter(item => key === item.id)[0];
                if (target) {
                    target[column] = value;
                    this.data = newData;
                }
            },
            edit(key) {
                const newData = [...this.data];
                const target = newData.filter(item => key === item.id)[0];
                this.editingKey = key;
                if (target) {
                    target.editable = true;
                    this.data = newData;
                }
            },
            save(key) {
                const newData = [...this.data];
                const newCacheData = [...this.cacheData];
                const target = newData.filter(item => key === item.id)[0];
                const targetCache = newCacheData.filter(item => key === item.id)[0];
                if (target && targetCache) {

                    this.loading = true
                    this.tableLoading = true
                    officeEssUpdate(target).then(res=>{
                        delete target.editable;
                        this.data = newData;
                        Object.assign(targetCache, target);
                        this.cacheData = newCacheData;
                    }).finally(()=>{
                        this.loading = false
                        this.tableLoading = false
                    })
                }
                this.editingKey = '';
            },
            cancel(key) {
                const newData = [...this.data];
                const target = newData.filter(item => key === item.id)[0];
                this.editingKey = '';
                if (target) {
                    Object.assign(target, this.cacheData.filter(item => key === item.id)[0]);
                    delete target.editable;
                    this.data = newData;
                }
            },
            applyEss(record){
                this.loading = true
                this.tableLoading = true
                applyEss({officeEssInfoId: record.id}).then(res=>{

                }).finally(()=>{
                    this.loading = false
                    this.tableLoading = false
                    this.initData()
                })
            },
            createConsoleLoginUrl(record){
                createConsoleLoginUrl({officeEssInfoId: record.id}).then(res=>{
                    window.open(res.data.msg);
                })
            },
            authorizationChange(info){
                if (info.file.status === 'done') {
                    const stat = info.file.response.code
                    if (stat === 0){
                        const data = info.file.response.data;
                        const id = data.id;
                        this.data.forEach((item,index)=>{
                            if(item.id == id ){
                                this.data[index].authorization = data.authorization //修改
                            }
                        })
                    }
                }
            },

            handleCancelOrganizationLicenseImg() {
                // this.data.forEach((item,index)=>{
                //     if(item.id == record.id ){
                //         this.data[index].previewVisible = false
                //     }
                // })

                this.previewVisible = false;
            },
            showImg(record){
                // this.data.forEach((item,index)=>{
                //     if(item.id == record.id ){
                //         this.data[index].previewVisible = true
                //     }
                // })
                this.previewVisible = true;
                this.previewVisibleImg = record.organizationLicense
                console.log(this.data)
            },
            handleChangeOrganizationLicenseImg(info){
                if (info.file.status === 'done') {
                    const stat = info.file.response.code
                    if (stat === 0){
                        const data = info.file.response.data;
                        const id = data.id;
                        this.data.forEach((item,index)=>{
                            if(item.id == id ){
                                this.data[index].organizationLicense = data.organizationLicense //修改
                            }
                        })
                    }
                }
            },

            onPageChange(e){
                this.pagination.current=e.current;
            },



        },
    };


</script>

<style scoped>
    .ant-advanced-search-form {
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        margin-bottom: 10px;
    }

    .ant-advanced-search-form .ant-form-item {
        display: flex;
    }

    .ant-advanced-search-form .ant-form-item-control-wrapper {
        flex: 1;
    }

    #components-form-demo-advanced-search .ant-form {
        max-width: none;
    }

    tr{
        background-color: #ffffff;
    }

</style>
