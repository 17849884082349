const cacheUtil = require('@/utils/storage')
//跨域代理前缀
// const BASE_URL = process.env.VUE_APP_API_BASE_URL
const ADMIN = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_PROXY_PREFIX : process.env.VUE_APP_API_BASE_URL
const AUTH = "/auth" // 登录认证模块
const SYSTEM = "/system"  // 这里是 /system
const EDU = "/edu"  //安全教育模块
const LABOUR = "/labour" // labour模块
const LAOWU = "/laowu" // laowu模块
const JOB = "/job" // laowu模块
const ONLINE = "/online" // laowu模块
const WISDOMSITE = "/wisdomsite" // wisdomsite模块
const DMGR = "/dmgr" // dmgr模块
const SAFE = '/safe' // safe安全管理 8022
const MDP = '/mdp' //mdp项目库 8023
const PRODUCT = '/product' // product产品模块
module.exports = {
  ADMIN,
  SummaryMonthOffice: `${ADMIN}${LABOUR}/payrollApplySummary/getSummaryMonthOffice`,
  GET_LAST_VERSION: `${ADMIN}${SYSTEM}/systemVersionHistroy/getLastVersion`,
  GET_CAPTCHA: `${ADMIN}${SYSTEM}/captcha/get`,
  CHECK_CAPTCHA: `${ADMIN}${SYSTEM}/captcha/check`,
  SEND_SMS_CODE: `${ADMIN}${SYSTEM}/auth/send-sms-code`,
  CHECK_SMS_CODE: `${ADMIN}${SYSTEM}/auth/laowu/checkSmsCode`,
  FIRST_SAVE_BIND_PHONE: `${ADMIN}${SYSTEM}/auth/laowu/firstSaveBindPhone`,
  UPDATE_PHONE_PWD: `${ADMIN}${SYSTEM}/auth/laowu/updatePhonePwd`,
  CHECK_PHONE: `${ADMIN}${SYSTEM}/auth/laowu/checkPhoneIsBindMultiple`,
  // LOGIN_PHONE: `${ADMIN}${AUTH}/web/loginPhone`,
  LOGIN_PHONE: `${ADMIN}/system/auth/laowu/loginPhone`,
  ISGCGLB: `${ADMIN}${SAFE}/aqxjProjects/nowIsGcglb`,
  DEPTDEPTH: `${ADMIN}${SAFE}/aqxjProjects/nowDeptDepth`,
  CHANGE_ACCOUNT: `${ADMIN}/system/auth/laowu/changeAccount`,
  // LOGIN: `${ADMIN}${AUTH}/web/login`,
  LOGIN: `${ADMIN}/system/auth/laowu/login`,
  LOGINOUT: `${ADMIN}${AUTH}/web/loginOutPhone`,
  PERMISSION_INFO: `${ADMIN}/system/auth/laowu/get-permission-info`,
  CHANGE_LOGIN_TYPE: `${ADMIN}/system/auth/laowu/changeLoginType`,
  ROUTES: `${ADMIN}/routes`,
  GOODS_COLUMNS: `${ADMIN}/columns`,
  GET_JSJD_WORD_LIST: `${ADMIN}${EDU}/wordConfig/getJsjdWordList`,
  SUBMIT_JSJD: `${ADMIN}${EDU}/wordConfig/submitJsjd`,
  INIT_JSJD_WORD: `${ADMIN}${EDU}/wordConfig/initJsjdWord`,
  SHOW_DOC: `${ADMIN}${EDU}/wordConfig/showDoc`,
  GET_ATTEND_SHEET: `${ADMIN}${SYSTEM}/attendSheet/getAttendSheet`,
  EXPORT_ATTEND_SHEET: `${ADMIN}${SYSTEM}/attendSheet/exportAttendSheet`,
  GET_PAYROLL_LIST: `${ADMIN}${LABOUR}/payroll/getPayrollList`,
  UPLOAD_ATTEND_SHEET_FILE: `${ADMIN}${LABOUR}/payroll/uploadAttendSheetFile`,
  UPLOAD_ATTEND_SHEET_IMG: `${ADMIN}${LABOUR}/payroll/uploadAttendSheetImg`,
  SUBMIT_PAYROLL_DATA: `${ADMIN}${LABOUR}/payroll/submitPayrollData`,
  EXPORT_FILE: `${ADMIN}${LABOUR}/payroll/exportFile`,
  GET_OFFICE_LIST_BY_PROJECT_ID: `${ADMIN}${LABOUR}/project/getOfficeListByProjectId`,
  GET_TEAM_LIST_BY_OFFICE_ID: `${ADMIN}${LAOWU}/project/getTeamListByOfficeId`,
  GET_OFFICE_TREE: `${ADMIN}${SYSTEM}/dept/getOfficeTree`,
  GET_OFFICE_TYPE_LIST: `${ADMIN}${LAOWU}/sysDict/getOfficeTypeList`,
  SUBMIT_OFFICE: `${ADMIN}${SYSTEM}/sys/office/submitOffice`,
  DEL_OFFICE: `${ADMIN}${SYSTEM}/sys/office/delOffice`,
  GET_OFFICE_TREE_SYS_USER: `${ADMIN}${SYSTEM}/sys/sysUser/getOfficeTreeSysUser`,
  GET_ROLE_LIST_BY_SYS_USER_LIST: `${ADMIN}${SYSTEM}/role/list-all-simple`,
  GET_ROLE_BY_OLD_USER_ID: `${ADMIN}${SYSTEM}/sys/sysUser/getRoleByOldUserId`,
  SAVE_USER: `${ADMIN}${SYSTEM}/sys/sysUser/saveUser`,
  SAVE_LABOUR_USER: `${ADMIN}${SYSTEM}/sys/sysUser/saveLabourUser`,
  DEL_SYS_USER: `${ADMIN}${SYSTEM}/sys/sysUser/delSysUser`,
  GET_PROJECT_LIST_BY_OFFICE_ID: `${ADMIN}${LABOUR}/project/getProjectListByParentOfficeId`,
  SEARCH_LOGIN_NAME: `${ADMIN}${SYSTEM}/sys/sysUser/searchLoginName`,


  GET_ROLE_LIST: `${ADMIN}${SYSTEM}/sys/role/getRoleList`,
  GET_MENU_LIST_BY_ROLE_LIST: `${ADMIN}${SYSTEM}/sys/role/getMenuListByRoleList`,
  GET_MENU_LIST_BY_ROLE_ID: `${ADMIN}${SYSTEM}/sys/role/getMenuListByRoleId`,
  DEL_ROLE: `${ADMIN}${SYSTEM}/sys/role/delRole`,
  SAVE_ROLE: `${ADMIN}${SYSTEM}/sys/role/saveRole`,


  // GET_PROJECT_LIST: `${ADMIN}${SYSTEM}/sys/project/getProjectList`,
  GET_PROJECT_LIST: `${ADMIN}${LABOUR}/project/getList`,
  SAVE_PROJECT: `${ADMIN}${SYSTEM}/sys/project/saveProject`,
  GET_DIRECTOR_USER_LIST: `${ADMIN}${SYSTEM}/sys/project/getDirectorUserList`,
  GET_PROJECT_INFO: `${ADMIN}${SYSTEM}/sys/project/getProjectInfo`,
  GET_PROJECT_RELATION_OFFICE_LIST: `${ADMIN}${SYSTEM}/sys/project/getProjectRelationOfficeList`,
  PROJECT_BING_USER: `${ADMIN}${LABOUR}/sysUser/projectBingUser`,
  DEL_PROJECT_BIND_USER: `${ADMIN}${LABOUR}/projectUser/delProjectBindUser`,
  USER_DEL_BIND_LAO_WU_USER: `${ADMIN}${SYSTEM}/sys/project/userDelBindLaoWuUser`,
  SEARCH_PHONE_USER: `${ADMIN}${LABOUR}/sysUser/searchPhoneUser`,
  GET_PROJECT_USER_LIST: `${ADMIN}${LABOUR}/projectUser/getProjectUserList`,
  PROJECT_RELATION_OFFICE: `${ADMIN}${SYSTEM}/sys/project/projectRelationOffice`,
  SEARCH_LOGIN_NAME_BY_PROJECT: `${ADMIN}${SYSTEM}/sys/project/searchLoginName`,

  GET_PROJECT_DICT_TYPE_LIST: `${ADMIN}${LAOWU}/sysDict/getProjectDictTypeList`,


  SYNC_SYS_USER: `${ADMIN}${SYSTEM}/sys/config/syncSysUser`,

  //系统设置中的分页查询列表
  CONTRACT_LIST: `${ADMIN}${LAOWU}/contract/getList`,

  //合同密钥的新增，修改保存接口
  CONTRACT_SAVE: `${ADMIN}${LAOWU}/contract/save`,

  //
  CONTRACT_DEL: `${ADMIN}${LAOWU}/contract/del`,

  GET_MENU_LIST: `${ADMIN}${SYSTEM}/sys/menu/getMenuList`,
  SAVE_MENU: `${ADMIN}${SYSTEM}/sys/menu/saveMenu`,
  MENU_DEL_BY_ID: `${ADMIN}${SYSTEM}/sys/menu/delById`,

  FIND_NO_ATTACH: `${ADMIN}${LAOWU}/staffLib/findNoAttach`,
  FIND_NO_ATTACH_PERSONS: `${ADMIN}${LAOWU}/staffLib/findNoAttachPersons`,


  /**  edu安全教育模块接口==========>start     */
  SAFEMEANS_QUERYPAGE:`${ADMIN}${EDU}/safeMeans/queryPage`,  //查询签名劳工列表

  STATISS_ONE:`${ADMIN}${EDU}/safeMeans/getStatissOne`,  //获取资料缺陷数据人数

  SAFEMEANS_DOWNLOAD:`${ADMIN}${EDU}/safeMeans/download`,    //下载劳工资料

  STAFF_WORK_MEANS_QUERYPAGE:`${ADMIN}${EDU}/staffMeansPrint/getListByCode`,    //工人打印资料下载

  STAFF_QUERYPAGE:`${ADMIN}${EDU}/staffMeansPrint/getUserList`,    //工人列表查询

  STAFF_DOREJSJDSIGN:`${ADMIN}${EDU}/aqjy/doReJsjdSign/`,    //重新生成入场技术交底

  /**  edu安全教育模块接口==========>end     */

  /** online-edu 在线教育管理模块===========>start */
  //考证拿证分页查询
  ONLINE_EXAM_CERTIFICATE_QUERY:`${ADMIN}${ONLINE}/examCertificate/getList`,
  //考证拿证新增
  ONLINE_EXAM_CERTIFICATE_INSERT:`${ADMIN}${ONLINE}/examCertificate/insert`,
  //考证拿证修改
  ONLINE_EXAM_CERTIFICATE_UPDATE:`${ADMIN}${ONLINE}/examCertificate/update`,
  //考证拿证发布关闭
  ONLINE_EXAM_CERTIFICATE_PUBLISH:`${ADMIN}${ONLINE}/examCertificate/publish`,

  //获取课程类型接口
  ONLINE_TYPE_QUERY:`${ADMIN}${ONLINE}/courseType/getList`,
  //获取课程列表ALL接口
  ONLINE_QUERY_COURSE_GETALL:`${ADMIN}${ONLINE}/course/getList`,
  //检查公司是否已设置了三级安全教育标识
  ONLINE_QUERY_COURSE_CHECKTHREEEDU:`${ADMIN}${ONLINE}/course/checkThreeEdu/`,
  //课程详情
  ONLINE_QUERY_COURSE_DETAIL:`${ADMIN}${ONLINE}/course/detail/`,
  //获取课时列表
  ONLINE_CLASS_HOUR_GETALL:`${ADMIN}${ONLINE}/classHour/getList`,
  //检测设置三级安全教育课时
  ONLINE_CLASS_HOUR_CHECKEDUTYPE:`${ADMIN}${ONLINE}/classHour/checkEduType/`,
  //新增课时
  ONLINE_CLASS_HOUR_INSERT:`${ADMIN}${ONLINE}/classHour/insert`,
  //修改课时
  ONLINE_CLASS_HOUR_UPDATE:`${ADMIN}${ONLINE}/classHour/update`,
  //删除课时
  ONLINE_CLASS_HOUR_DELETE:`${ADMIN}${ONLINE}/classHour/del/`,
  //获取课时任务列表
  ONLINE_CALSS_TASK_GETALL:`${ADMIN}${ONLINE}/classHourTask/getList`,
  //新增课程接口
  ONLINE_COURSE_INSERT:`${ADMIN}${ONLINE}/course/insert`,
  //修改课程
  ONLINE_COURSE_UPDATE:`${ADMIN}${ONLINE}/course/update`,
  //删除课程
  ONLINE_COURSE_DELETE:`${ADMIN}${ONLINE}/course/del/`,
  //选择题题库查询
  ONLINE_QUESTION_CHOOSE_QUERY:`${ADMIN}${ONLINE}/questionChoose/getList`,
  //判断题题库查询
  ONLINE_QUESTION_JUDGE_QUERY:`${ADMIN}${ONLINE}/questionJudge/getList`,
  //选择题修改
  ONLINE_QUESTION_CHOOSE_UPDATE:`${ADMIN}${ONLINE}/questionChoose/update`,
  //选择题新增
  ONLINE_QUESTION_CHOOSE_ADD:`${ADMIN}${ONLINE}/questionChoose/insert`,
  //选择题删除
  ONLINE_QUESTION_CHOOSE_DELETE:`${ADMIN}${ONLINE}/questionChoose/del/`,
  //选择题导入
  ONLINE_QUESTION_CHOOSE_IMPORT:`${ADMIN}${ONLINE}/paperQuestions/importChoiceExcel`,
  //判断题导入
  ONLINE_QUESTION_JUDGE_IMPORT:`${ADMIN}${ONLINE}/paperQuestions/importJudgeExcel`,
  //选择题答题选项列表查询
  ONLINE_QUESTION_OPTIONS_QUERY:`${ADMIN}${ONLINE}/questChooseOptions/getList`,
  //判断题修改
  ONLINE_QUESTION_JUDGE_UPDATE:`${ADMIN}${ONLINE}/questionJudge/update`,
  //判断题新增
  ONLINE_QUESTION_JUDGE_ADD:`${ADMIN}${ONLINE}/questionJudge/insert`,
  //判断题删除
  ONLINE_QUESTION_JUDGE_DELETE:`${ADMIN}${ONLINE}/questionJudge/del/`,
  //试卷查询
  ONLINE_TEST_PAPER_QUERY:`${ADMIN}${ONLINE}/testPaperInfo/getList`,
  //试卷修改
  ONLINE_TEST_PAPER_UPDATE:`${ADMIN}${ONLINE}/testPaperInfo/update`,
  //试卷修改状态
  ONLINE_TEST_PAPER_MODIFY:`${ADMIN}${ONLINE}/testPaperInfo/modify`,
  //试卷新增
  ONLINE_TEST_PAPER_ADD:`${ADMIN}${ONLINE}/testPaperInfo/insert`,
  //试卷删除
  ONLINE_TEST_PAPER_DELETE:`${ADMIN}${ONLINE}/testPaperInfo/del/`,
  //试卷查询题目列表
  ONLINE_PAPER_QUEST_QUERY:`${ADMIN}${ONLINE}/paperQuestions/getQuestByPaper/`,
  //查询题目类型数量
  ONLINE_QUEST_LEVEL_QUERY:`${ADMIN}${ONLINE}/paperAutoSetting/getTypeLevelCnt`,
  //新增自动生成的配置
  ONLINE_AUTO_SETTING_ADD:`${ADMIN}${ONLINE}/paperAutoSetting/insert`,
  //修改自动生成的配置
  ONLINE_AUTO_SETTING_UPDATE:`${ADMIN}${ONLINE}/paperAutoSetting/update`,
  //自动获取题目数量
  ONLINE_AUTO_FEACH_QUEST:`${ADMIN}${ONLINE}/paperAutoSetting/doFeachQuests`,
  //获取任务列表
  ONLINE_CLASS_HOUR_TASK_QUERY:`${ADMIN}${ONLINE}/classHourTask/getList`,
  //获取任务列表
  ONLINE_CLASS_HOUR_TASK_ADD:`${ADMIN}${ONLINE}/classHourTask/insert`,
  //获取任务列表
  ONLINE_CLASS_HOUR_TASK_UPDATE:`${ADMIN}${ONLINE}/classHourTask/update`,
  //根据题目IDs获取答题选项
  ONLINE_QUESTION_OPTIONS:`${ADMIN}${ONLINE}/questChooseOptions/geMaptList`,

  //查询已下发学习任务的数据
  ONLINE_QUERY_TASK_LIST:`${ADMIN}${ONLINE}/taskPublishRecode/getList`,
  //获取学习任务新增
  ONLINE_TASK_ADD:`${ADMIN}${ONLINE}/taskPublishRecode/insert`,
  //获取学习任务更新
  ONLINE_TASK_UPDATE:`${ADMIN}${ONLINE}/taskPublishRecode/update`,
  //获取学习任务删除
  ONLINE_TASK_DELETE:`${ADMIN}${ONLINE}/taskPublishRecode/del/`,
  //获取可发任务的所有课程
  ONLINE_TASK_COURSE_LIST:`${ADMIN}${ONLINE}/course/queryAllCourse`,
  //修改课时的顺序状态
  ONLINE_COURSE_HOUR_INDEX_UPDATE:`${ADMIN}${ONLINE}/classHour/updateIndex`,

  //查询可发布课程任务的课程
  ONLINE_COURSE_QUERY_ALL:`${ADMIN}${ONLINE}/course/queryAllCourse`,

  /** online-edu 在线教育管理模块===========>end */

  /** labour====================>start */
  //导出无电子合同的人员名单
  EXPORT_NO_CONTRACTS:`${ADMIN}${LABOUR}/staffsNoContracts/export`,
  //查询无电子合同的人员名单
  QUERY_NO_CONTRACTS:`${ADMIN}${LABOUR}/staffsNoContracts/getList`,
  //生成花名册批次号
  DO_STAFF_CODE_ESS:`${ADMIN}${LABOUR}/essContractRecord/doStaffCode`,
  //公司盖章
  DO_OFFICE_SEAL_ESS:`${ADMIN}${LABOUR}/ess/officeSeal`,
  //消息设置新增
  MESSAGE_MODULE_SET_INSERFT:`${ADMIN}${LABOUR}/messageModuleSet/insert`,
  //消息设置编辑
  MESSAGE_MODULE_SET_UPDATE:`${ADMIN}${LABOUR}/messageModuleSet/update`,
  //分页查询消息设置
  MESSAGE_MODULE_SET_GETLIST:`${ADMIN}${LABOUR}/messageModuleSet/getList`,
  //消息设置查询详情
  MESSAGE_MODULE_SET_DETAIL:`${ADMIN}${LABOUR}/messageModuleSet/detail/`,
  //下载验收记录文档
  GET_CHECK_ACCEPT_FILES:`${ADMIN}${SAFE}/aqxjCheckAcceptApplyRecode/downLoadFile`,
  //导出验收申请记录
  CHECK_ACCEPT_EXPORT:`${ADMIN}${SAFE}/aqxjCheckAcceptApplyRecode/findByUsersExport`,
  //查询验收记录信息
  QUERY_CHECK_ACCEPT_FINDBYUSER:`${ADMIN}${SAFE}/aqxjCheckAcceptApplyRecode/findByUsers`,
  // 查询验收申请记录信息
  GET_CHECK_ACCEPT_LIST:`${ADMIN}${SAFE}/aqxjCheckAcceptApplyRecode/getList`,
  //项目主要隐患台账
  GET_YHBW_STAND_BOOK:`${ADMIN}${SAFE}/aqxjProblem/getYhbwStandBook`,
  //导出项目主要隐患台账
  EXPORT_YHBW_STAND_BOOK:`${ADMIN}${SAFE}/aqxjProblem/getYhbwStandBook/export`,
  //工程管理部巡检统计信息
  GCGLB_AQXJ_DETAILS:`${ADMIN}${SAFE}/aqxjPlan/gcglbXjDetails`,
  //处罚台账
  DISPOSITION_NOTICE_LIST:`${ADMIN}${SAFE}/aqxjDispositionNotice/getList`,
  //月份未自查满8次的项目
  PROJECT_CHECK_NO8:`${ADMIN}${SAFE}/aqxjProjectCheck/getNo8TimesProjCheck`,
  //项目自查排名前十
  PROJECT_CHECK_TOP10:`${ADMIN}${SAFE}/aqxjProjectCheck/getProCheckTop10`,
  //带班检查领导导出检查情况表
  DB_CHECK_OFFCIE_LEADER_EXPORT:`${ADMIN}${SAFE}/aqxjPlan/officeLeader/export`,
  //带班检查资料下载接口
  DB_CHECK_DOWNLOAD:`${ADMIN}${SAFE}/aqxjRecode/dbList/downLoadFile`,
  //带班巡查台账查询
  DB_CHECK_QUERY_EXPORT:`${ADMIN}${SAFE}/aqxjPlan/dbList/export`,
  //带班巡查台账查询
  DB_CHECK_QUERY:`${ADMIN}${SAFE}/aqxjPlan/getDbList`,
  //领导带班详情
  DB_QUERY_OFFICE_LEADER_DETAIL:`${ADMIN}${SAFE}/aqxjPlan/officeLeaderDbList`,
  //验收记录导出
  ACCEP_STAND_BOOK_QUERY:`${ADMIN}${SAFE}/aqxjAccepRecode/accepStandBook`,
  //验收记录导出
  ACCEP_STAND_BOOK_EXPORT:`${ADMIN}${SAFE}/aqxjAccepRecode/accepStandBook/export`,
  //巡查记录查询
  PROBLEMS_BY_PRO_QUERY:`${ADMIN}${SAFE}/aqxjPlan/findProblemsByPro`,
  //巡查记录查询
  PROBLEMS_BY_TOTAL_QUERY:`${ADMIN}${SAFE}/aqxjPlan/findProbTotal`,
  //项目-巡查记录查询
  PROJECT_PROBLEMS_BY_TOTAL_QUERY:`${ADMIN}${SAFE}/aqxjPlan/findProbTotalByProject`,
  //巡查记录导出
  PROBLEMS_BY_PRO_REPORT:`${ADMIN}${SAFE}/aqxjPlan/findProblemsByPro/export`,
  //创建安全巡检周报
  AQXJ_CREATE_WEEK_REPORT:`${ADMIN}${SAFE}/aqxjWeekReport/createWeekReport`,
  //查询安全巡检周报列表
  AQXJ_WEEK_REPORT_QUERY:`${ADMIN}${SAFE}/aqxjWeekReport/getList`,
  //查询考勤上报异常列表数据
  ATTENDANCE_QUERYPAGE_ERROR:`${ADMIN}${LABOUR}/reportResultError/query/error`,
  //考勤上报异常再次上报（可批量）
  ATTENDANCE_DOREPORTCWA:`${ADMIN}${LABOUR}/reportResultError/doReportCwa`,

  GET_CONTRACT_FILE_URL:`${ADMIN}${LABOUR}/essContractRecord/getContractFileUrl`,
  //批量下载电子合同
  DOWN_BATCH_ESS_RECORD:`${ADMIN}${LABOUR}/essContractRecord/batchDownFile`,
  //批量下载退场凭证
  DOWN_BATCH_WORK_RECORD:`${ADMIN}${LAOWU}/team/downloadQuitFiles`,

  //项目工作台账
  PROJECT_AQXJ_GZTZ:`${ADMIN}${SAFE}/aqxjPlan/getProjectAqxj`,
  //导出项目工作台账
  PROJECT_AQXJ_GZTZ_EXPORT:`${ADMIN}${SAFE}/aqxjPlan/projectAqxjExport`,
  //安全验收台账新接口
  PROJECT_AQYS_GETLIST:`${ADMIN}${SAFE}/aqxjCheckAcceptApplyRecode/getRecodeList`,
  //安全验收台账导出新接口
  PROJECT_AQYS_EXPORT:`${ADMIN}${SAFE}/aqxjCheckAcceptApplyRecode/recodeList/export`,
  //项目部自查台账  labour/aqxjProjectCheck/getProjectZjStatiss
  PROJECT_AQXJ_XMBZJ_EXPORT:`${ADMIN}${SAFE}/aqxjProjectCheck/downLoadFile`,
  //工程管理部检查记录
  PROJECT_AQXJ_GCGLB_EXPORT:`${ADMIN}${SAFE}/aqxjProjectCheck/getProjectZjStatiss`,
  //查询工程管理部
  PROJECT_OFFICE_yt:`${ADMIN}${SAFE}/aqxjJdjc/getGcglbOffices`,
  //查询工程管理部
  PROJECT_OFFICE_CHILD:`${ADMIN}/system/dict-data/type`,
  //查询安全部处罚停工等单据
  PROJECT_getStopCfdCnts:`${ADMIN}${SAFE}/aqxjPlan/getStopCfdCnts`,
  //查询移动考勤参数设置
  ATTENDANCE_QUERY_SETTING:`${ADMIN}${LABOUR}/mobileClockSetting/query`,
  //保存移动考勤参数设置
  ATTENDANCE_SAVE:`${ADMIN}${LABOUR}/mobileClockSetting/save`,
  //获取技术交底的模板数据（公司）
  MYJSJD_WORDLIST:`${ADMIN}${EDU}/wordConfig/getMyJsjdWordList`,
  //用工计划分页查询
  LOOKJOB_QUERY:`${ADMIN}${LABOUR}/lookJob/getList`,
  //用工计划详情
  LOOKJOB_DETAIL:`${ADMIN}${LABOUR}/lookJob/detail/`,
  //用工计划新增
  LOOKJOB_INSERT:`${ADMIN}${LABOUR}/lookJob/insert`,
  //用工计划修改
  LOOKJOB_UPDATE:`${ADMIN}${LABOUR}/lookJob/update`,
  //用工计划导入
  PERSONS_IMPORT_EXCEL:`${ADMIN}${LABOUR}/lookJobPersons/importExcel`,
  //直播待审批查询
  LIVE_BROADCAST_QUERY:`${ADMIN}${LABOUR}/liveBroadcast/getApproveList`,
  //直播详情
  LIVE_BROADCAST_DETAIL:`${ADMIN}${LABOUR}/liveBroadcast/detail/`,
  //直播审批
  LIVE_BROADCAST_APPROVE:`${ADMIN}${LABOUR}/liveBroadcast/approve`,
  //进出场确认单查询
  STAFF_INOUT_QUERY:`${ADMIN}${LABOUR}/InoutOrder/findList`,
  //进出场确认单导出
  STAFF_INOUT_EXPORT:`${ADMIN}${LABOUR}/InoutOrder/export`,
  //分页查询公司项目模板列表
  FILE_TEMPL_FIND_PAGE:`${ADMIN}${LABOUR}/templateFile/getList`,
  //公司项目模板详情
  FILE_TEMPL_DETAIL:`${ADMIN}${LABOUR}/templateFile/detail/`,
  //公司项目模板新增
  FILE_TEMPL_INSERT:`${ADMIN}${LABOUR}/templateFile/insert`,
  //公司项目模板修改
  FILE_TEMPL_UPDATE:`${ADMIN}${LABOUR}/templateFile/update`,
  //上传附件
  ATTACHMENT_UPLOAD:`${ADMIN}${LABOUR}/attachment/upload`,
  //上传附件加水印
  ATTACHMENT_UPLOAD_WATER:`${ADMIN}${LABOUR}/attachment/doUploadImgWater`,
  //巡检汇总明细查询
  QUERY_PROJECT_CHECK:`${ADMIN}${SAFE}/aqxjPlan/queryProjectCheck`,
  //巡检本月概况查询
  QUERY_PROJECT_GK:`${ADMIN}${SAFE}/aqxjPlan/queryCurrentGk`,
  //巡检隐患总数排行top10
  QUERY_AQXJ_YHZSTOP10:`${ADMIN}${SAFE}/aqxjPlan/getYhzsTop10`,
  //巡检隐患整改率排行top10
  QUERY_AQXJ_YHZGLTOP10:`${ADMIN}${SAFE}/aqxjPlan/getYhzglTop10`,
  //查询安全巡检次数情况统计echart
  CURRENT_CHECKTIMES_CHART:`${ADMIN}${SAFE}/aqxjPlan/currentCheckTimesChart`,
  //查询安全巡检隐患总数量情况统计echart
  CURRENT_YHZSL_CHART:`${ADMIN}${SAFE}/aqxjPlan/currentYhzslChart`,
  //查询安全巡检超时未整改数量情况统计echart
  CURRENT_CSWZGS_CHART:`${ADMIN}${SAFE}/aqxjPlan/currentCswzgsChart`,
  //查询安全巡检隐患整改率情况统计echart
  CURRENT_YHZGL_CHART:`${ADMIN}${SAFE}/aqxjPlan/currentYhzglChart`,
  //查询安全巡检统计详情
  QUERY_DETAIL_DATAS:`${ADMIN}${SAFE}/aqxjPlan/queryDetailDatas`,
  //下载安全巡检记录
  DOWN_LOAD_FILE:`${ADMIN}${SAFE}/aqxjPlan/doOpenOrderWord/`,
  //查询全部的安全巡检记录
  AQXJ_QUERY_GETLIST:`${ADMIN}${SAFE}/aqxjPlan/getList`,
  //下载巡检记录文件
  SAFE_VIEW_DOWNLOAD:`${ADMIN}${SAFE}/aqxjRecode/downLoadFile`,
  //项目自查巡检列表查询
  PROJECT_CHECK_AQXJ_QUERY:`${ADMIN}${SAFE}/aqxjProjectCheck/getList`,
  //项目自查巡检导出
  PROJECT_CHECK_AQXJ_EXPORT:`${ADMIN}${SAFE}/aqxjProjectCheck/downLoadFile`,
  //印章查询
  SEAL_MANAGE_QUERY:`${ADMIN}${LABOUR}/seal/manage/getList`,
  //印章详情
  SEAL_MANAGE_DETAIL:`${ADMIN}${LABOUR}/seal/manage/detail/`,
  //印章新增
  SEAL_MANAGE_INSERT:`${ADMIN}${LABOUR}/seal/manage/insert`,
  //印章修改
  SEAL_MANAGE_UPDATE:`${ADMIN}${LABOUR}/seal/manage/update`,
  //印章删除
  SEAL_MANAGE_DELETE:`${ADMIN}${LABOUR}/seal/manage/del/`,
  //公司对项目的检查的工作台账
  OFFICE_STAND_BOOK:`${ADMIN}${SAFE}/aqxjPlan/getStandBook`,
  //公司对项目的检查的工作台账导出
  OFFICE_STAND_BOOK_EXPORT:`${ADMIN}${SAFE}/aqxjPlan/standBookExport`,
  //公司对项目的检查的类型统计
  OFFICE_TYPE_COUNT_QUERY:`${ADMIN}${SAFE}/aqxjProblemsBig/getTypeCount`,
  //公司对项目的检查的类型统计导出EXCEL
  OFFICE_TYPE_COUNT_EXPORT:`${ADMIN}${SAFE}/aqxjProblemsBig/typeCountExport`,
  //根据公司查询机施安全的在建项目
  OFFICE_PROJECT_BYOFFICEID:`${ADMIN}${SAFE}/aqxjProjects/getThList`,
    //查询企业人才库的班组列表
  TEAM_JOBTITLES_QUERY:`${ADMIN}${LABOUR}/teamInfoTag/getList`,
  //查询企业人才库的班组列表
  TEAM_INFO_TAG_INSERT:`${ADMIN}${LABOUR}/teamInfoTag/insert`,
  //查询企业人才库的班组列表
  TEAM_INFO_TAG_UPDATE:`${ADMIN}${LABOUR}/teamInfoTag/update`,
  //删除企业人才库的班组列表
  TEAM_INFO_TAG_DELETE:`${ADMIN}${LABOUR}/teamInfoTag/del/`,
  //查询企业人才库信息
  PERSON_ENTERPRISE_QUERY:`${ADMIN}${LABOUR}/personnelDatas/getListPro`,
  //更新企业人才的在场离场状态
  PERSON_ENTERPRISE_UPDATE:`${ADMIN}${LABOUR}/personnelDatas/updateStatus`,
  //企业库移除某人在班组中的信息
  PERSON_ENTERPRISE_REMOVE:`${ADMIN}${LABOUR}/personTeamTag/remove`,
  //企业库人才库人员调班
  PERSON_TEAM_CHANGE:`${ADMIN}${LABOUR}/personTeamTag/changeTeam`,
  //企业人才库人员导入
  PERSON_ENTERPRISE_IMPORT:`${ADMIN}${LABOUR}/personnelDatas/importExcel`,
  //生成派工单
  OUTWORK_ORDER_CREARTE:`${ADMIN}${LABOUR}/enterpriseOutworkOrder/insert`,
  //派工单列表查询
  OUTWORK_ORDER_QUERY:`${ADMIN}${LABOUR}/enterpriseOutworkOrder/getList`,
  //派工单花名册导出
  OUTWORK_ORDER_PERSONS_EXPORT:`${ADMIN}${LABOUR}/enterpriseOutworkOrder/export`,
  //派工单花名册查询
  OUTWORK_ORDER_PERSONS_QUERY:`${ADMIN}${LABOUR}/enterpriseOutworkOrder/queryList`,
  //电子签签署位置配置查询
  ESS_TEMPL_RECIPIENT_QUERY:`${ADMIN}${LABOUR}/essRecipientTempl/getList`,
  //电子签签署位置配置保存
  ESS_TEMPL_RECIPIENT_SAVE:`${ADMIN}${LABOUR}/essRecipientTempl/save`,
  //电子签获取腾讯模板信息
  ESS_TEMPL_RECIPIENT_MSG:`${ADMIN}${LABOUR}/ess/describeTemplates/`,
  //系统日志查询
  SYS_OPER_LOG_QUERY:`${ADMIN}${SYSTEM}/operate-log/page`,
  //根绝角色，公司，项目去查询已实名的管理端用户
  QUERY_USERLIST_ROLE:`${ADMIN}${LABOUR}/sysUserInfo/getUsersByRole`,

   //项目参数设置查询
   PROJECT_PARAM_SETTING_FIND:`${ADMIN}${LABOUR}/projectParamsSetting/findAllList`,
   //项目参数设置保存
   PROJECT_PARAM_SAVE:`${ADMIN}${LABOUR}/projectParamsSetting/save`,

   //项目技术交底参数设置查询
   PROJECT_JSJD_SETTING_FIND:`${ADMIN}${LABOUR}/projectJsjdSetting/findAllList`,
   //项目技术交底参数设置保存
   PROJECT_JSJD_SAVE:`${ADMIN}${LABOUR}/projectJsjdSetting/save`,

   //宿舍楼查询
   SAFEWORK_BUILD_QUERYPAGE:`${ADMIN}${LABOUR}/staffWorkBuild/getList`,
   //宿舍楼新增
   SAFEWORK_BUILD_INSERT:`${ADMIN}${LABOUR}/staffWorkBuild/insert`,
   //宿舍楼修改
   SAFEWORK_BUILD_UPDATE:`${ADMIN}${LABOUR}/staffWorkBuild/update`,
    //宿舍楼删除
   SAFEWORK_BUILD_DELETE:`${ADMIN}${LABOUR}/staffWorkBuild/del/`,
    //宿舍房间修改
   BUILD_ROOM_UPDATE:`${ADMIN}${LABOUR}/staffWorkRoom/update`,
    //宿舍房间新增
   BUILD_ROOM_INSERT:`${ADMIN}${LABOUR}/staffWorkRoom/insert`,
   //宿舍房间查询
   BUILD_ROOM_QUERYPAGE:`${ADMIN}${LABOUR}/staffWorkRoom/getList`,
   //宿舍房间删除
   BUILD_ROOM_DELETE:`${ADMIN}${LABOUR}/staffWorkRoom/del/`,

   //宿舍房间简单查询
   BUILD_SIMPLE_QUERYALL:`${ADMIN}${LABOUR}/staffWorkBuild/getAllList`,

   //人员分配
   PERSON_ALLOT_SAVE:`${ADMIN}${LABOUR}/staffWorkPersons/doAllotRooms`,

   //退房
   PERSON_ALLOT_REMOVE:`${ADMIN}${LABOUR}/staffWorkPersons/removeRooms`,

   //项目房间统计使用情况
   BUILD_ROOM_STATICSS:`${ADMIN}${LABOUR}/staffWorkRoom/findStaticss`,

   //技术交底文档资料查询
   TECHNICAL_DISCLOSURE_QUERYPAGE:`${ADMIN}${LABOUR}/technicalDisclosure/getList`,
   //技术交底人员完成情况列表
   TECHNICAL_DISCLOSURE_PERSONS:`${ADMIN}${LABOUR}/technicalDisclosure/getPersonList/`,
  //技术交底下载资料
   TECHNICAL_DISCLOSURE_DOWNLOAD:`${ADMIN}${LABOUR}/technicalDisclosure/downloadFile`,
   //分公司绑定查询接口
   PROJECT_OFFICE_CHILDRELATION:`${ADMIN}${LABOUR}/projectOfficeChildRelation/getList`,

   //分公司绑定保存接口
   PROJECT_OFFICE_CHILDRELATION_SAVE:`${ADMIN}${LABOUR}/projectOfficeChildRelation/update`,

   //设置是否上报建委状态
   SET_JW_REPORT_STATUS:`${ADMIN}${LABOUR}/teamStaffRecord/setJwReportStatus`,

  //上报建委
  REPORT_JW_STAFF:`${ADMIN}${LABOUR}/teamStaffRecord/reportJwStaff`,

  //上报建委离场
  REPORT_JW_EXIT_STAFF:`${ADMIN}${LABOUR}/teamStaffRecord/reportJwExitStaff`,

  //获取待审核人员信息
  GET_APPROVE_PERSON_INFO:`${ADMIN}${LABOUR}/teamPerson/getApprovePersonInfo`,

  //获取证书类型列表
  GET_CERTIFICATE_TYPE_LIST:`${ADMIN}${LABOUR}/certificateType/getList`,

  //新增事件记录
  ADD_NEW_EVENT_RECORD:`${ADMIN}${LABOUR}/staffEventRecord/insert`,

  //查询事件记录列表
  GET_EVENT_RECORD_LIST:`${ADMIN}${LABOUR}/staffEventRecord/getList`,

  //删除事件记录
  DEL_EVENT_RECORD:`${ADMIN}${LABOUR}/staffEventRecord/del/`,

  //修改事件记录
  UPDATE_EVENT_RECORD:`${ADMIN}${LABOUR}/staffEventRecord/update`,

  //事件记录详情
  EVENT_RECORD_DETAIL:`${ADMIN}${LABOUR}/staffEventRecord/detail`,

  //退场审批列表查询
  EXIT_APPROVAL_LIST:`${ADMIN}${LABOUR}/exitApply/getList`,

  //批量下发管理人员到考勤厂商平台
  BATCH_SYNC_PERSON_TO_PLATFORM:`${ADMIN}${LABOUR}/sysUser/batchSyncPersonToPlatform`,

  //批量删除管理人员权限
  BATCH_DEL_PERSON_TO_PLATFORM:`${ADMIN}${LABOUR}/sysUser/batchDelPersonToPlatform`,

  //获取失败人员列表
  GET_DISTRIBUTE_PERSON_ERR_LIST:`${ADMIN}${LAOWU}/team/getHkFailPersonList`,

  //清理失败人员
  CLEAR_FAIL_PERSON:`${ADMIN}${LAOWU}/team/clearHkFailPersonToDevice`,

  //查询下发考勤机任务数及明细
  GET_MANAGER_TASH:`${ADMIN}${LABOUR}/sysUser/getManagerTask`,

  /** labour====================>end */

  /** laowu====================>start */
  EXPORT_PROJECT_STAFF_HMC:`${ADMIN}${LAOWU}/staffLib/exportStaffHmc`,//导出项目人员花名册
  EXPORT_PROJECT_STAFF_ALL_HMC:`${ADMIN}${LAOWU}/staffLib/exportStaffAllHmc`,//导出全部人员花名册（组合）
  FIND_PROJECT_STAFF_HMC:`${ADMIN}${LAOWU}/staffLib/getStaffHmc`,//获取项目人员花名册
  FIND_PROJECT_OFFICE:`${ADMIN}${LAOWU}/project/getProject`,    //获取
  //导出未考勤人员EXCEl
  EXPORT_NOATTACH_EXCEL:`${ADMIN}${LAOWU}/staffLib/exportNoAttachPersons`,
  //根据资料信息重新生成合同
  RESTART_CCONTRACT:`${ADMIN}${LAOWU}/staffLib/restartContract`,
  //根据资料信息重新生成合同
  RESTART_RCXZ:`${ADMIN}${LAOWU}/staffLib/restartRcxz`,
  //根据资料信息重新生成电子签
  RESTART_ESS_CONTRACT:`${ADMIN}${LAOWU}/staffLib/restartEssContract`,
  //查询所有公司，list
  FIND_ALL_OFFICE_LIST:`${ADMIN}${SYSTEM}/dept/findAllOffice`,
  //根据公司ID查询所有的项目
  FIND_PROJECT_BY_OFFICEID:`${ADMIN}${LAOWU}/project/getProjectByOfficeId/`,
  //根据项目ID查询项目信息
  FIND_PROJECT_BY_ID:`${ADMIN}${LAOWU}/project/project/msg/`,
  //根据查询所有班组
  FIND_TEAMS_BY_USER:`${ADMIN}${LAOWU}/team/findAllTeams`,
  // 根据项目查公司
  FIND_OFFICE_BY_PROJECT_ID:`${ADMIN}${LAOWU}/sysOffice/find/`,

  //班组和入场日期查询班组和人员信息
  FIND_TEAMS_AND_STAFFS:`${ADMIN}${LAOWU}/staffLib/findTeamStaff`,
  //导出上报建委的人员资料列表
  FIND_TEAMS_AND_STAFFS_EXPORT:`${ADMIN}${LAOWU}/staffLib/exportTeamStaff`,

  PERSON_QUERY_LIST:`${ADMIN}${LAOWU}/staffLib/findStaffLibs`,

  QUERY_JOBTITLE_ALL:`${ADMIN}${LAOWU}/jobtitle/findSatffJob`,

  // QUERY_OFFICE_BY_USER:`${ADMIN}${LAOWU}/sysOffice/getOfficesByUser`,

  //在场-退场人员列表
  QUERY_PROJECT_STAFF_LIST:`${ADMIN}${LAOWU}/team/selectProjectStaffList`,
  // 人员列表-海康评分
  QUERY_STAFF_HK_FACE:`${ADMIN}${LAOWU}/team/hkPersonFaceScore`,
  // 管理人员-海康评分
  QUERY_MANGE_HK_FACE:`${ADMIN}${LABOUR}/sysUser/hkPersonFaceScore`,
  //获取工人的工种列表
  QUERY_JOB_BY_WORKER_TYPE:`${ADMIN}${LAOWU}/jobtitle/getJobByWorkerType`,
   //根据条件获取工人的工种列表
  QUERY_JOB_FIND_WORKER_TYPE:`${ADMIN}${LAOWU}/jobtitle/findByCond`,

  //保存编辑个人信息
  SAVE_STAFF_INFO:`${ADMIN}${LAOWU}/team/pcSaveStaffInfo`,

  //获取身份证信息
  QUERY_ID_CARD_DATA:`${ADMIN}${LAOWU}/common/getIdCardDataByPc`,

  //上传图片
  UPLOAD_IMAGE:`${ADMIN}${LAOWU}/person/upload`,

  //批量下发人员到考勤厂商平台
  BATCH_SYNC_PERSON_TO_ATTEND_PLATFORM:`${ADMIN}${LAOWU}/team/batchSyncPersonToAttendPlatform`,
  //管理人员-批量下发人员到考勤厂商平台
  MANAGE_BATCH_SYNC_PERSON_TO_ATTEND_PLATFORM:`${ADMIN}${LAOWU}/sysUserToAttePlatform/batchSyncPersonToAttendPlatform`,
  //批量下发人员权限到考勤厂商平台
  BATCH_SYNC_PERSON_AUTH_TO_ATTEND_PLATFORM:`${ADMIN}${LAOWU}/team/batchSyncPersonAuthToAttendPlatform`,
  //管理人员-批量下发人员权限到考勤厂商平台
  MANAGE_BATCH_SYNC_PERSON_AUTH_TO_ATTEND_PLATFORM:`${ADMIN}${LAOWU}/sysUserToAttePlatform/batchSyncPersonAuthToAttendPlatform`,
  //批量同步人员到考勤机（目前海康需要这一步）
  BATCH_SYNC_PERSON_AUTH_TO_DEVICE:`${ADMIN}${LAOWU}/team/batchSyncPersonAuthToDevice`,
  //管理人员-批量同步人员到考勤机（目前海康需要这一步）
  MANAGE_BATCH_SYNC_PERSON_AUTH_TO_DEVICE:`${ADMIN}${LAOWU}/sysUserToAttePlatform/batchSyncPersonAuthToDevice`,
  //考勤设备设置-批量同步人员到考勤机（目前海康需要这一步）
  DEVICE_BATCH_SYNC_PERSON_AUTH_TO_CODE:`${ADMIN}${LAOWU}/sysUserToAttePlatform/batchSyncPersonAuthToDeviceByDeviceCodeList`,
  //查询任务执行进度
  GET_TASK_PROGRESS:`${ADMIN}${LAOWU}/team/getTaskProgress`,
  //管理人员-查询任务执行进度
  GET_MANGE_TASK_PROGRESS:`${ADMIN}${LAOWU}/sysUserToAttePlatform/getTaskProgress`,
  //同步闸机到hk平台
  SYNC_MACHINE_TO_HK:`${ADMIN}${LAOWU}/machine/mapping/syncMachineToHk`,
  //查询HK任务执行进度
  GET_HK_TASK_PROGRESS:`${ADMIN}${LAOWU}/team/getHkTaskProgress`,
  //查询管理人员HK任务执行进度
  GET_MANAGE_HK_TASK_PROGRESS:`${ADMIN}${LAOWU}/sysUserToAttePlatform/getHkTaskProgress`,
  //查询-考勤设备设置-HK任务执行进度
  GET_DEVICE_HK_TASK_PROGRESS:`${ADMIN}${LAOWU}/sysUserToAttePlatform/getHkTaskProgressByDevice`,
  //删除管理人员信息
  DEL_MANAGE_BATCH_INFO:`${ADMIN}${LAOWU}/sysUserToAttePlatform/batchDeletePersonInfo`,
  //删除管理人员考勤权限
  DEL_MANAGE_BATCH_DEVICE:`${ADMIN}${LAOWU}/sysUserToAttePlatform/batchDeleteDeviceAuth`,
  //批量退场
  EXIT_STAFF:`${ADMIN}${LAOWU}/team/exitStaff`,

  //重新进场
  RE_ENTER_STAFF:`${ADMIN}${LAOWU}/team/reEnterStaff`,

  //查询编辑个人信息
  STAFF_INFO:`${ADMIN}${LAOWU}/team/selectEditStaffInfo`,

  //查询证书管理列表(分页)
  GET_CERTIFICATE_MANAGEMENT_LIST:`${ADMIN}${LAOWU}/staffCertication/getList`,

  //批量删除设备权限
  BATCH_DEL_DEVICE_AUTH:`${ADMIN}${LAOWU}/team/batchDeleteDeviceAuth`,

  //批量删除人员信息
  BATCH_DEL_PERSON_INFO:`${ADMIN}${LAOWU}/team/batchDeletePersonInfo`,

  //同步项目
  SYNC_PROJECT_TO_HK:`${ADMIN}${LAOWU}/team/syncProjectToHk`,

  //查询工人下发考勤机任务数及明细
  GET_STAFF_TASH:`${ADMIN}${LAOWU}/team/getStaffTask`,

  //批量下发工人人员到考勤厂商平台
  BATCH_SYNC_STAFF_TO_PLATFORM:`${ADMIN}${LAOWU}/team/batchSyncPersonToPlatform`,

  //查询银行
  GET_BANK:`${ADMIN}${LAOWU}/person/findBank`,

  //银行卡识别
  GET_BANK_CARD:`${ADMIN}${LAOWU}/common/getBankData`,

  //政治面貌
  POLITICAL_OUTLOOK:`${ADMIN}${LAOWU}/sysDict/getDictListByType`,

  //
  SELECT_STAFF_INFO_BY_ID_CARD:`${ADMIN}${LAOWU}/team/selectStaffInfoByIdCard`,

  // 在场工人-导出上报建委平台资料
  EXPORT_JW_STAFFS:`${ADMIN}${LAOWU}/staffLib/exportJwStaffs`,


  /** laowu====================>end */


  /** job===============================start */
  //查询工种类型
  JOB_TYPE_WORK_QUERY:`${ADMIN}${JOB}/typeWork/getList`,
  //查询工种列表
  JOB_JOBTITLES_QUERY:`${ADMIN}${JOB}/titles/getList`,


  /** job===============================end*/

  GET_EPIDEMIC_LIST_BY_PROJECT:`${ADMIN}${LABOUR}/epidemic/situation/report/getEpidemicListByProject`,
  DAILY_REPORT_STATISTICS:`${ADMIN}${LABOUR}/epidemic/situation/report/dailyReportStatistics`,
  ABNORMAL_REPORT_STATISTICS:`${ADMIN}${LABOUR}/epidemic/situation/report/abnormalReportStatistics`,
  GET_EPIDEMIC_QR_CODE:`${ADMIN}${LABOUR}/epidemic/situation/report/getEpidemicQrCode`,
  EXPORT_EPIDEMIC_LIST_BY_PROJECT:`${ADMIN}${LABOUR}/epidemic/situation/report/exportEpidemicListByProject`,
  STATISTICS_BY_OFFICE:`${ADMIN}${LABOUR}/epidemic/situation/report/office/statistics`,
  STATISTICS_BY_OFFICE_PROJECT:`${ADMIN}${LABOUR}/epidemic/situation/report/project/statistics`,
  EXPORT_DAILY_REPORT_STATISTICS:`${ADMIN}${LABOUR}/epidemic/situation/report/exportDailyReportStatistics`,
  EXPORT_ABNORMAL_REPORT_STATISTICS:`${ADMIN}${LABOUR}/epidemic/situation/report/exportAbnormalReportStatistics`,
  GET_OFFICE_REPORT_RECODE:`${ADMIN}${LABOUR}/epidemic/situation/report/getOfficeRecode`,
  GET_OFFICE_PROJECT_STATICS:`${ADMIN}${LABOUR}/epidemic/situation/report/getOfficeProjectStatics`,
  EXPORT_OFFICE_PROJECT_STATICS:`${ADMIN}${LABOUR}/epidemic/situation/report/exportOfficeProjectStatics`,


  GET_OFFICE_TREE_BY_EPIDEMIC:`${ADMIN}${LAOWU}/sysOffice/getOfficeTree`,
  GET_PROJECT_LIST_BY_PARENT_OFFICE_ID_BY_EPIDEMIC:`${ADMIN}${LAOWU}/project/getProjectListByParentOfficeId`,
  GET_PROJECT_LIST_BY_PARENT_OFFICE_ID_AND_PROJECT_NAME:`${ADMIN}${LAOWU}/project/remote/getProjectListByParentOfficeIdAndProjectName`,



  GET_OFFICE_AUTH_LIST:`${ADMIN}${JOB}/officeAuth/getOfficeAuthList`,
  AUTH_OFFICE_STATUS:`${ADMIN}${JOB}/officeAuth/authOfficeStatus`,

  OFFICE_ESS_LIST:`${ADMIN}${LABOUR}/officeEssInfo/getList`,
  OFFICE_ESS_UPDATE:`${ADMIN}${LABOUR}/officeEssInfo/update`,
  UPLOAD_AUTHORIZATION_FILE: `${ADMIN}${LABOUR}/officeEssInfo/uploadAuthorizationFile`,
  UPLOAD_ORGANIZATION_LICENSE_IMG: `${ADMIN}${LABOUR}/officeEssInfo/uploadOrganizationLicenseImg`,


  GET_ESS_TEMPLATE_LIST:`${ADMIN}${LABOUR}/essTemplate/getList`,
  ESS_TEMPLATE_UPDATE:`${ADMIN}${LABOUR}/essTemplate/update`,
  ESS_TEMPLATE_DEL:`${ADMIN}${LABOUR}/essTemplate/del`,
  ESS_TEMPLATE_INSERT:`${ADMIN}${LABOUR}/essTemplate/insert`,

  APPLY_ESS:`${ADMIN}${LABOUR}/ess/applyEss`,
  CREATE_CONSOLE_LOGIN_URL:`${ADMIN}${LABOUR}/ess/createConsoleLoginUrl`,
  GET_ACCOUNT_CUSTOM_TEMPLATE_LIST:`${ADMIN}${LABOUR}/templateFile/getAccountCustomTemplateList`,

  GET_PROJECT_ACTIVATION_QR_CODE:`${ADMIN}${LABOUR}/qrCodeRecord/getProjectActivationQrCode`,

  GET_ESS_CONTRACT_RECORD_LIST:`${ADMIN}${LABOUR}/essContractRecord/getListBySys`,

  GET_CONSOLE_CONTRACT_RECORD_URL:`${ADMIN}${LABOUR}/essContractRecord/getConsoleContractRecordUrl`,

  MODIFY_JI_SHI_BY_LABOUR:`${ADMIN}${LABOUR}/test/modifyJiShiByLabour`,
  MODIFY_JI_SHI_BY_LAO_WU:`${ADMIN}${LAOWU}/test/remote/modifyJiShiByLaoWu`,


  GET_CROSS_PROJECT_LIST:`${ADMIN}${EDU}/safeMeans/getCrossProjectList`,
  MESSAGE_NOTIFY_GET_LIST:`${ADMIN}${SYSTEM}/notify-message/my-page`,
  GET_MY_SEND_MESSAGE_LIST_BY_SYS:`${ADMIN}${LABOUR}/messageNotify/getMySendMessageListBySys`,



  SYS_USER_GET_LIST:`${ADMIN}${SYSTEM}/user/page`,
  GET_LIST_BY_SYS_MESSAGE:`${ADMIN}${LABOUR}/staff/getListBySysMessage`,
  MESSAGE_NOTIFY_INSERT:`${ADMIN}${LABOUR}/messageNotify/batchInsert`,
  GET_MESSAGE_NOTIFY_BY_ID:`${ADMIN}${LABOUR}/messageNotify/detail`,
  UPDATE_MESSAGE_NOTIFY_BY_ID:`${ADMIN}${LABOUR}/messageNotify/update`,
  GET_SYS_USER_LIST_BY_SYS_MSG:`${ADMIN}${LABOUR}/sysUser/getListBySysMsg`,

  /*首页统计*/
  // GET_OFFICE_LIST:`${ADMIN}${LAOWU}/sysOffice/remote/getOfficeListByParentOffice`,
  GET_OFFICE_LIST:`${ADMIN}${MDP}/projectOfficeMsg/getOfficeListByParentId`,
  GET_PROJECT_BY_LIST:`${ADMIN}${LAOWU}/project/getProjectsByOffice`,
  DETAIL_OBJ:`${ADMIN}${LABOUR}/project/detailObj`,
  GET_PROJECT_BY_LIST_SAFE:`${ADMIN}${SAFE}//aqxjPlan/getProjectsByOffice`,
  GET_QUERY_CURRENTGK:`${ADMIN}${SAFE}/aqxjPlan/queryCurrentGk`,
  GET_TYPE_COUNT:`${ADMIN}${SAFE}/aqxjProblemsBig/getTypeCount`,
  GET_WORK_TOTAL:`${ADMIN}${LAOWU}/workerTimesheet/getWorkTotal`,
  GET_MANAG_CWA:`${ADMIN}${LAOWU}/workerTimesheet/getManagCWA`,
  GET_SEVEN_CWA:`${ADMIN}${LAOWU}/workerTimesheet/get7DaysCWA`,
  GET_WORK_AGE_FB:`${ADMIN}${LAOWU}/staffLib/getWorkAgeFb`,
  GET_JOB_TITLE_FB:`${ADMIN}${LAOWU}/staffLib/getJobTitleFb`,
  GET_PROJECT_STATLS_KQ:`${ADMIN}${LAOWU}/project/projectStatissKq`,

  //因为建安易需要，所以要这样改
  QUERY_OFFICE_BY_USER: cacheUtil.storage.get('Tenant-Id') !== 1 ? `${ADMIN}${SYSTEM}/dept/getSubOfficesByUser` : `${ADMIN}${SYSTEM}/dept/getOfficesByUser`,

  QUERY_OFFICE_BY_USER2: `${ADMIN}${SYSTEM}/dept/getOfficesByUser`,

  GET_LOGIN_JEEPLUS:`${ADMIN}${LAOWU}/jeeplus/getLoginJeeplus`,


  GET_PROJECT_DEVICE_LIST:`${ADMIN}${WISDOMSITE}/projectDevice/getList`,
  INSERT_PROJECT_DEVICE:`${ADMIN}${WISDOMSITE}/projectDevice/insert`,
  DEL_PROJECT_DEVICE:`${ADMIN}${WISDOMSITE}/projectDevice/del`,
  UPDATE_PROJECT_DEVICE:`${ADMIN}${WISDOMSITE}/projectDevice/update`,

  EXPORT_OFFICE_RECODE:`${ADMIN}${LABOUR}/epidemic/situation/report/exportOfficeRecode`,

  CHANGE_PWD:`${ADMIN}${LABOUR}/wxUser/changePwd`,


  GET_STAFF_APPEAL_LIST:`${ADMIN}${LABOUR}/staffAppeal/getList`,
  SET_APPEAL_STATUS:`${ADMIN}${LABOUR}/staffAppeal/update`,
  CHANGE_OPEN_APPEAL:`${ADMIN}${LABOUR}/projectConfig/changeOpenAppeal`,
  GET_OPEN_APPEAL_CONFIG:`${ADMIN}${LABOUR}/projectConfig/getOpenAppealConfig`,

  GET_TEAM_LIST_BY_SYS_MSG: `${ADMIN}${LAOWU}/team/getTeamListBySysMsg`,

  GET_GROUP_LIST: `${ADMIN}${DMGR}/group/getList`,
  ADD_GROUP: `${ADMIN}${DMGR}/group/insert`,
  DEL_GROUP: `${ADMIN}${DMGR}/group/del`,
  UPDATE_GROUP: `${ADMIN}${DMGR}/group/update`,

  GET_DEVICE_LIST: `${ADMIN}${DMGR}/attendanceDevice/getList`,
  ADD_DEVICE: `${ADMIN}${DMGR}/attendanceDevice/insert`,
  DEL_DEVICE: `${ADMIN}${DMGR}/attendanceDevice/del`,
  UPDATE_DEVICE: `${ADMIN}${DMGR}/attendanceDevice/update`,
  DEVICE_ADD_GROUP: `${ADMIN}${DMGR}/attendanceDevice/deviceAddGroup`,

  GET_PERSON_FACE_RECORD_LIST: `${ADMIN}${DMGR}/personFaceRecord/getList`,
  GET_MANAGER_PERSON_LIST: `${ADMIN}${DMGR}/personFaceRecord/getManagerPersonList`,
  ISSUE_GROUP_FACE: `${ADMIN}${DMGR}/personFaceRecord/issueGroupFace`,
  RE_ISSUE_GROUP_FACE: `${ADMIN}${DMGR}/personFaceRecord/reIssueGroupFace`,
  DEL_GROUP_PERSON: `${ADMIN}${DMGR}/personFaceRecord/delGroupPerson`,
  BATCH_ISSUE_GROUP_FACE: `${ADMIN}${DMGR}/personFaceRecord/batchIssueGroupFace`,
  DEL_LEAVE_PERSON: `${ADMIN}${DMGR}/personFaceRecord/delLeavePerson`,
  GET_STATISTICS: `${ADMIN}${DMGR}/atteRecord/getAtteStatistics`,
  GET_MANAGER_ATTE_STATISTICS: `${ADMIN}${DMGR}/atteRecord/getManagerAtteStatistics`,
  EXPORT_ATTE_STATISTICS: `${ADMIN}${DMGR}/atteRecord/exportAtteStatistics`,
  EXPORT_MANAGER_ATTE_STATISTICS: `${ADMIN}${DMGR}/atteRecord/exportManagerAtteStatistics`,
  GET_BACK_ATTE_RECORD: `${ADMIN}${DMGR}/backAtteRecord/getList`,
  BACK_ATTE_RE_RECORD: `${ADMIN}${DMGR}/backAtteRecord/reReport`,
  DEL_BACK_ATTE_RE_RECORD: `${ADMIN}${DMGR}/backAtteRecord/del`,
  JW_BATCH_APPLY_ADD_MANAGER: `${ADMIN}${DMGR}/jwDirect/JwBatchApplyAddManager`,

  GET_ATTE_FAIL_RECORD_LIST: `${ADMIN}${DMGR}/atteFailRecord/getList`,


  GET_PROJECT_CONFIG: `${ADMIN}${LABOUR}/projectConfig/getProjectConfig`,
  UPDATE_PROJECT_CONFIG: `${ADMIN}${LABOUR}/projectConfig/update`,

  GET_REPORT_STATISTICS: `${ADMIN}${LABOUR}/epidemic/situation/report/getReportStatistics`,
  GET_TEAM_PAYROLL_APPLY_LIST: `${ADMIN}${LABOUR}/teamPayrollApply/getList`,
  SELECT_TEAM_AND_TEAM_WORKER_STAFF_BY_PROJECT_ID: `${ADMIN}${LAOWU}/team/selectTeamAndTeamWorkerStaffByProjectId`,
  SUBMIT_PAYROLL_APPLY: `${ADMIN}${LABOUR}/staffPayroll/submitPayrollApply`,
  GET_STAFF_PAYROLL_LIST: `${ADMIN}${LABOUR}/staffPayroll/getList`,
  DOWNLOAD_STAFF_PAYROLL: `${ADMIN}${LABOUR}/staffPayroll/downloadStaffPayroll`,
  DEL_STAFF_PAYROLL: `${ADMIN}${LABOUR}/staffPayroll/del`,
  UPDATE_STAFF_PAYROLL: `${ADMIN}${LABOUR}/staffPayroll/update`,

  UPLOAD_STAFF_PAYROLL: `${ADMIN}${LABOUR}/staffPayroll/uploadStaffPayroll`,
  UPLOAD_STAFF_PAYROLL_BY_MANUAL: `${ADMIN}${LABOUR}/staffPayroll/uploadStaffPayrollByManual`,

  DOWNLOAD_TEAM_PAYROLL: `${ADMIN}${LABOUR}/teamPayrollApply/downloadTeamPayroll`,
  PAYROLL_APPLY_SUMMARY: `${ADMIN}${LABOUR}/payrollApplySummary/summary`,
  SUMMARY_EXPORT: `${ADMIN}${LABOUR}/payrollApplySummary/summaryExport`,
  GET_PROJECT_SUMMARY_APPLY_BY_SUMMARY_ID: `${ADMIN}${LABOUR}/payrollApplySummary/getProjectSummaryApplyBySummaryId`,
  SUMMARY_PROJECT_EXPORT: `${ADMIN}${LABOUR}/payrollApplySummary/summaryProjectExport`,
  TAX_RECORD_LIST: `${ADMIN}${LABOUR}/splitTaxRecord/getList`,
  TAX_RECORD_EXPORT: `${ADMIN}${LABOUR}/splitTaxRecord/exportList`,

  GET_SUMMARY_LIST: `${ADMIN}${LABOUR}/payrollApplySummary/getList`,
  IMPORT_STAFF_PAYROLL_TAX: `${ADMIN}${LABOUR}/payrollApplySummary/importStaffPayrollTax`,
  BATCH_UP_STATUS: `${ADMIN}${LABOUR}/teamPayrollApply/batchUpStatus`,
  BATCH_FINANCE_REPULSE: `${ADMIN}${LABOUR}/teamPayrollApply/batchFinanceRepulse`,
  TEAM_PAYROLL_APPLY_DEL: `${ADMIN}${LABOUR}/teamPayrollApply/del`,

  AQXJ_ZXJC: `${ADMIN}${SYSTEM}/dict-data/type/AQXJ_ZXJC`,

  GET_SYS_OFFICE: `${ADMIN}${SYSTEM}/dept/detail`,

  UPLOAD_EPIDEMIC:`${ADMIN}${LABOUR}/epidemic/situation/report/uploadEpidemic`,
  GET_NOT_NUCLEIN_STATISTICS:`${ADMIN}${LABOUR}/epidemic/situation/report/getNotNucleinStatistics`,

  FUN_PRICE_LIST:`${ADMIN}${SYSTEM}/funPrice/getList`,
  FUN_PRICE_INSERT_OR_UPDATE:`${ADMIN}${SYSTEM}/funPrice/insertOrUpdate`,
  FUN_PRICE_UPLOAD:`${ADMIN}${SYSTEM}/funPrice/upload`,
  FUN_PRICE_DEL:`${ADMIN}${SYSTEM}/funPrice/del`,
  FUN_PRICE_UPDATE:`${ADMIN}${SYSTEM}/funPrice/update`,
  PAY_FUN:`${ADMIN}${SYSTEM}/funPrice/buyFuns`,
  GET_FUN_ORDER_LIST:`${ADMIN}${SYSTEM}/funsOrder/getList`,
  ORDER_FUN_DISTRIBUTION_PROJECT:`${ADMIN}${SYSTEM}/funsOrder/distributionProject`,


  TX_GET_PERSON_LIST: `${ADMIN}${LABOUR}/txFacePool/getPersonList`,
  TX_CREATE_GROUP: `${ADMIN}${LABOUR}/txFacePool/createGroup`,
  TX_CREATE_PERSON: `${ADMIN}${LABOUR}/txFacePool/createPerson`,
  TX_DELETE_PERSON: `${ADMIN}${LABOUR}/txFacePool/deletePerson`,
  UPLOAD_FACE: `${ADMIN}${LABOUR}/txFacePool/uploadFace`,

  JW_BATCH_APPLY_ADD: `${ADMIN}${DMGR}/jwDirect/JwBatchApplyAdd`,
  JW_BATCH_APPLY_DEL: `${ADMIN}${DMGR}/directAtteFailRecord/del/`,
  JW_BATCH_APPLY_LIST: `${ADMIN}${DMGR}/directAtteFailRecord/getJwBatchApplyList`,
  // 导入管理人员考勤数据
  STAFF_APPLY_BATCH_MANAGE: `${ADMIN}${DMGR}/atteFailRecord/batchManagerAtteApply`,
  // 导入工人考勤数据
  STAFF_APPLY_BATCH_WORK: `${ADMIN}${DMGR}/atteFailRecord/batchStaffAtteApply`,
  // 新公司首页
  // 查詢各項目狀態的總數量
  GET_HOME_OFFICE_PROJS: `${ADMIN}${LABOUR}/homePage/office/getOfficeProjs`,
  // 查詢各項目狀態的项目清单
  GET_HOME_OFFICE_PROJS_STATUS: `${ADMIN}${LABOUR}/homePage/office/getProjStatics/`,
  // 查詢項目的工人和班組總數
  GET_HOME_OFFICE_PROJS_TOTALS: `${ADMIN}${LABOUR}/homePage/office/getProjTotals/`,
  // 管理人员公司出勤数据统计
  GET_HOME_OFFICE_MANAGE: `${ADMIN}${LABOUR}/homePage/office/getManageStaticss`,
  // 工人公司出勤数据统计
  GET_HOME_OFFICE_STAFF: `${ADMIN}${LABOUR}/homePage/office/getStaffLibStaticss`,
  // 查询班组考勤情况
  GET_HOME_OFFICE_TEAM_KQ: `${ADMIN}${LABOUR}/homePage/office/getTeamsKq`,
  // 班组考勤情况
  GET_HOME_OFFICE_TEAM_DATA: `${ADMIN}${LABOUR}/homePage/office/getKQTeamDatas`,

  /** aqxjJdjc===============================start */
    //创建约谈台账
    ADD_JDJC_INSERT:`${ADMIN}${SAFE}/aqxjJdjc/insert`,
    //查询约谈列表
    GET_JDJC_QUERY:`${ADMIN}${SAFE}/aqxjJdjc/getList`,
    //删除约谈
    DELETE_JDJC:`${ADMIN}${SAFE}/aqxjJdjc/del/`,
    //导出约谈台账excel
    EXPORT_JDJC:`${ADMIN}${SAFE}/aqxjJdjc/export`,
    //修改约谈台账excel
    UPDATE_JDJC_EDIT:`${ADMIN}${SAFE}/aqxjJdjc/update`,
  /** aqxjJdjc===============================end*/

   /** 安全责任管理===============================start */
    // 查询所有已注册账户选择人员
    GET_ALL_USER:`${ADMIN}${SYSTEM}/user/getSysUsersChild`,
    // 查询安全责任管理
    GET_SAFEDUTY_LIST:`${ADMIN}${SAFE}/projectSafeDuty/getList`,
    //查询安全责任筛选人员
    GET_STFE_FIND_USER: `${ADMIN}${SAFE}/aqxjPlan/findXmLeaderAqUser`,
    // //修改安全责任管理
    UPDATE_SAFEDUTY:`${ADMIN}${SAFE}/projectSafeDuty/update`,
    // //修改安全责任管理
    UPDATE_PROJECT_SAFEDUTY_DETAIL:`${ADMIN}${SAFE}/projectSafeDutyDetail/update`,
    // 新增安全员
    ADD_SAFE_AQYUSER:`${ADMIN}${SAFE}/projectSafeDuty/doAddManageUser`,
    // 项目查询类表
    PROJECT_MSG_GET_LIST:`${ADMIN}${LABOUR}/project/getList`,
    // 建设单位查询
    GETOFFICELIST:`${ADMIN}${MDP}/projectOfficeMsg/getOfficeList`,
    // 班组新增，修改，查看，下拉选项列表用这个接口
    PROJ_CONTACTS:`${ADMIN}${LAOWU}/contract/getProjContacts`,
    // 新增
    AQXJ_PROJECTS_INSERT:`${ADMIN}${SAFE}/aqxjProjects/insert`,
    //更新
    AQXJPROJECTSUPDATE:`${ADMIN}${SAFE}/aqxjProjects/update`,
    // 查询列表
    AQXJ_PROJECTS_GETLIST:`${ADMIN}${SAFE}/aqxjProjects/getList`,
    // 项目历史名称
    PROJECT_NAME_HISTORY_INFO:`${ADMIN}${SAFE}/projectNameHistoryInfo/getList`,
    // 查询详情
    AQXJ_PROJECTS_DETAIL:`${ADMIN}${SAFE}/aqxjProjects/detail`,
     // 导出
    AQXJ_PROJECTS_EXPORT_ZAIJIAN:`${ADMIN}${SAFE}/aqxjProjects/exportZaiJian`,
    // 删除
    QXJ_PROJECTS_DEL:`${ADMIN}${SAFE}/aqxjProjects/del`,
    SAFE_PROJECTS_DEL:`${ADMIN}${SAFE}/projectSafeDutyDetail/del/`,
    // 新增项目经理
    ADD_SAFE_DUTY_XMJL:`${ADMIN}${SAFE}/projectSafeDuty/addXmjl`,
    // 删除项目经理
    DEL_SAFE_DUTY_XMJL:`${ADMIN}${SAFE}/projectSafeDuty/delXmjl`,

  /** 参见单位管理===============================*/
  // 保存参见单位
  MDPOFFICE_SAVE_OFFICES: `${ADMIN}${LABOUR}/projectOffice/saveOffices`,
  // 查询项目所有的参见单位
  // MDPOFFICE_PROJECT_ALLOFFICES: `${ADMIN}${LABOUR}/mdpOffice/getProjectOffices`,
  // 参建单位根据项目和公司查询用户列表
  SYSUSER_PROID_OFFICE: `${ADMIN}${LABOUR}/sysUser/getSysUserByProIdOfficeId`,
   // 管理人员列表查询
  SYSUSER_PROID_USER: `${ADMIN}${LABOUR}/sysUser/getSysUserByProId`,
  // 劳务员审批人
  TEAMPERSONAPPROVE: `${ADMIN}/labour/teamPerson/approve`,
  // 查询待审核人员列表
  GETAPPROVEPERSONLIST: `${ADMIN}/labour/teamPerson/getApprovePersonList`,
  // 查询项目待审核人员列表
  GETAPPROVEPERSONLISTBYPROJECTID: `${ADMIN}/labour/teamPerson/getApprovePersonListByProjectId`,
  // 查询待审核人员列表
  BATCH_MODIFYPERSON_TEAMID: `${ADMIN}/labour/teamPerson/modify/batchModifyPersonTeamId`,
  MDPOFFICE_PROJECT_ALLOFFICES: `${ADMIN}${LABOUR}/projectOffice/getProjectOffices`,
  // 查询参建单位详情
  MDPOFFICE_DETAIL_OFFICES: `${ADMIN}${LABOUR}/projectOffice/detailOffice`,
  // 根据条件查询公司
  MDPOFFICE_CHECK_OFFICECODE:`${ADMIN}${LAOWU}/mdpOffice/checkOfficeCode`,
  // 公司和班组导出花名册接口
  STAFFLIB_EXPORT:`${ADMIN}${LAOWU}/staffLib/excelStaffExport`,
  // 查询参建班组评分列表
  TEAM_OFFICE_POINT_LIST:`${ADMIN}${LAOWU}/teamOfficePoint/getList`,
  // 新增参建班组评分
  TEAM_OFFICE_POINT_INSERT:`${ADMIN}${LAOWU}/teamOfficePoint/insert`,
  // 修改参建班组评分
  TEAM_OFFICE_POINT_UPDATE:`${ADMIN}${LAOWU}/teamOfficePoint/update`,
  // 删除参建单位
  MDP_OFFICE_DEL:`${ADMIN}${LABOUR}/projectOffice/delOffices`,
  // 删除项目用户
  MDP_OFFICE_USER_DEL:`${ADMIN}${LABOUR}/sysUser/delSysUsers`,

  /** 参见单位管理===============================end*/

  /** 参见班组管理===============================*/
  // 保存班组信息
  TEAMINFO_SAVE: `${ADMIN}${LAOWU}/team/save`,
  // 查询班组信息
  TEAMINFO_FIND_BYID: `${ADMIN}${LAOWU}/team/findById`,
  // 查询班组评分列表
  TEAMPOINT_LIST: `${ADMIN}${LAOWU}/teamPoint/getList`,
  // 根据项目公司保存管理人员 == 参建单位保存管理人员
  SYS_USER_SAVE_MAGE_USERS: `${ADMIN}${LABOUR}/sysUser/saveMageUsers`,
  // 根据项目和用户ID查看用户详情
  GET_SYS_USER_DETAILE: `${ADMIN}${LABOUR}/sysUser/getSysUser`,
  // 根据公司ID查询设置的门禁通道
  GET_GROUP_OFFICE: `${ADMIN}${LABOUR}/doorChannelSetting/getGroupByOfficeId`,
  // 勾选分组新增
  ADD_GROUP_OFFICE: `${ADMIN}${LABOUR}/doorChannelSetting/insert`,
  // 去掉分组
  UPDATE_GROUP_OFFICE: `${ADMIN}${LABOUR}/doorChannelSetting/update`,
  // 根据班组ID查询设置的门禁通道
  GET_GROUP_TEAM: `${ADMIN}${LABOUR}/doorChannelSetting/getGroupByTeamId`,
  // 查询班组
  GET_TEAMS_BY_USER:`${ADMIN}${LAOWU}/team/findTeams`,
  // 删除班组
  DELETE_TEAMS_BY_USER:`${ADMIN}${LAOWU}/team/deleteTeamById`,
  /** 参见班组管理===============================end*/

  /** 劳务项目管理===============================start*/
  // 查询劳务项目列表
  GET_LW_PROJECT_LIST:`${ADMIN}${LABOUR}/lwProjects/getLwProjects`,
  // 创建劳务项目
  INSET_LW_PROJECT: `${ADMIN}${LABOUR}/lwProjects/insert`,
  // 创建新增劳务项目
  ADD_LW_PROJECT: `${ADMIN}${LABOUR}/project/createProject`,
  // 查询项目详情
  GET_LW_PROJECT_DETAIL: `${ADMIN}${LABOUR}/lwProjects/getProjectById`,
  // 查询机施项目详情
  GET_SAFE_PROJECT_DETAIL: `${ADMIN}${SAFE}/aqxjProjects/detailProjId`,
  // 查询劳务系统项目详情
  GET_LW_DETAIL: `${ADMIN}${LABOUR}/lwProjects/detail`,
  // 获取劳务系统项目列表
  GET_LW_PROJECT_ALL: `${ADMIN}${LABOUR}/lwProjects/getList`,
  // 劳务系统公司的树形结构
  GET_LW_TREE_OFFICE: `${ADMIN}${SYSTEM}/dept/getOfficesPage`,
  // 保存或者修改劳务项目接口
  SAVE_LW_PROJECT: `${ADMIN}${LABOUR}/lwProjects/saveLwProject`,
  // 删除劳务系统项目
  DELETE_LW_PROJECT: `${ADMIN}${LABOUR}/lwProjects/del`,
  // 修改劳务项目接口
  UPDATE_LW_PROJECT:`${ADMIN}${LABOUR}/lwProjects/update`,
   /** 劳务项目管理===============================end*/

  /** 公司机构管理===============================start*/
  // 创建与修改公司机构接口
  ADD_OFFICE_MASTER:`${ADMIN}${MDP}/projectOfficeMsg/createOfficeByMaster`,
  // 删除公司机构接口
  DELETE_OFFICE_MASTER:`${ADMIN}${MDP}/projectOfficeMsg/del`,
  // 查询公司机构详情
  GET_OFFICE_MASTER:`${ADMIN}${MDP}/projectOfficeMsg/getSysOffice`,
  /** 公司机构管理===============================end*/

  /** 三期系统设置===============================start*/
  // 项目风控列表
  GET_FKSET_LIST:`${ADMIN}${LABOUR}/projectFkSet/getList`,
  // 项目风控查询详情
  GET_FKSET_DETAIL: `${ADMIN}${LABOUR}/projectFkSet/getSetByProjId`,
  // 新增项目风控
  SAVE_FKSET:`${ADMIN}${LABOUR}/projectFkSet/insert`,
  // 修改项目风控
  EDIT_FKSET:`${ADMIN}${LABOUR}/projectFkSet/update`,
  // 参建单位风控管理
  EDIT_OFFICE_FKSET: `${ADMIN}${LABOUR}/projectFkSet/getSetting`,
  // 新增证书管理
  ADD_CERTIFICATE_TYPE: `${ADMIN}${LABOUR}/certificateType/insert`,
  // 查询证书管理类型详情
  GET_CERTIFICATE_TYPE: `${ADMIN}${LABOUR}/certificateType/detail`,
  // 删除证书管理类型
  DELETE_CERTIFICATE_TYPE: `${ADMIN}${LABOUR}/certificateType/del`,
  // 修改证书管理类型
  UPDATE_CERTIFICATE_TYPE: `${ADMIN}${LABOUR}/certificateType/update`,
  /** 三期系统设置===============================end*/

  /** 公司申请法大大电子签===============================start*/
  // 申请开通法大大电子签
  APPLY_ESS_OFFICE:`${ADMIN}${LABOUR}/fddOfficeEss/applyEssOffice`,
  // 获取自动授权签署链接
  APPLY_ESS_AUTHSIGN:`${ADMIN}${LABOUR}/fddOfficeEss/applyAuthSign`,
  // 查询实名详情状态
  QUERY_DETAIL_OFFICE_STATUS:`${ADMIN}${LABOUR}/fddOfficeEss/detailEssOffice`,
  /** 公司申请法大大电子签===============================end*/
  // 获取建委预警数据
  GET_NO_CLOSE_WARN:`${ADMIN}${LABOUR}/homePage/getNoCloseWarnData`,
  /** 新改版组织机构===============================start*/
  // 查询企业库
  GET_OFFICES_LIST:`${ADMIN}${SYSTEM}/dept/getOfficesPage`,
  // 新增企业库
  ADD_OFFICES:`${ADMIN}${SYSTEM}/dept/insert`,
  // 修改企业库
  EDIT_OFFICES:`${ADMIN}${MDP}/projectOfficeMsg/update`,
  // 删除企业库
  DEL_OFFICES:`${ADMIN}${MDP}/projectOfficeMsg/del`,
  // 查询企业库详情
  GET_OFFICES_DETAIL:`${ADMIN}${SYSTEM}/dept/detail`,
  // 企业获取分公司树形结构
  GET_OFFICES_TREE:`${ADMIN}${SYSTEM}/dept/getOfficesPage`,
  // 企业获取参建项目列表
  GET_PROJECT_OFFICE:`${ADMIN}${LABOUR}/projectOffice/getProjectByOfficeId`,
  // 获取公司的班组列表
  GET_OFFICES_TEAMS:`${ADMIN}${LAOWU}/team/getTeamsByOfficeId`,
  // 根据项目ID获取参数配置
  GET_PROJECT_ID_CONFIG:`${ADMIN}${LABOUR}/projectConfig/getConfigByProjId`,
  // 保存项目参数配置
  SAVE_PROJECT_ID_CONFIG:`${ADMIN}${LABOUR}/projectConfig/save`,
  // 用户管理查询公司用户
  GET_OFFICE_SYS_USER: `${ADMIN}${LABOUR}/sysUser/remote/getUsersByOfficeId`,
  /** 在线运营方案===============================start*/
  // 查询产品库列表
  GET_PRODUCT_LIST:`${ADMIN}${PRODUCT}/product/getList`,
  // 查询子产品库列表
  GET_PRODUCT_DETAIL_LIST:`${ADMIN}${PRODUCT}/productDetail/getList`,
  // 创建子产品
  ADD_PRODUCT:`${ADMIN}${PRODUCT}/productDetail/insert`,
  // 查看子产品详情
  GET_PRODUCT_DETAIL:`${ADMIN}${PRODUCT}/productDetail/detail`,
  // 修改子产品
  EDIT_PRODUCT_DETAIL:`${ADMIN}${PRODUCT}/productDetail/update`,
  // 删除子产品
  DEL_PRODUCT_DETAIL:`${ADMIN}${PRODUCT}/productDetail/del`,
  // 查询报价单列表
  GET_QUOTATION_LIST:`${ADMIN}${PRODUCT}/quotationList/getList`,
  // 删除(逻辑)报价单
  DEL_QUOTATION:`${ADMIN}${PRODUCT}/quotationList/del`,
  // 查询报价单详情
  GET_QUOTATION_DETAIL:`${ADMIN}${PRODUCT}/quotationList/detail`,
  /** 在线运营方案===============================end*/
  // 劳务管理-风控人员管理
  GET_STAFF_FK_LIST:`${ADMIN}${LABOUR}/stafffkRecode/getList`,

  /** 预警哨兵===============================start*/
  // 根据手机号查询通知人
  GET_ALERT_USER_PHONE: `${ADMIN}${LABOUR}/sysUser/getUserByPhone`,
  // 查询短信模板List
  GET_ALERT_NOTIFY_LIST: `${ADMIN}${LABOUR}/funNotify/getSmsTempList`,
  // 预警设置-新增或修改通知人
  EDIT_ALERT_NOTIFY_SAVE: `${ADMIN}${LABOUR}/funNotify/insertOrSave`,
   // 预警设置-删除通知人
  DEL_ALERT_NOTIFY: `${ADMIN}${LABOUR}/funNotify/del`,
  // 获取通知人列表
  GET_NOTIFY_LIST: `${ADMIN}${LABOUR}/funNotify/getList`,
  /** 预警机器人===================================**/
  // 查询建委预警提示
  GET_JW_WARN_TIP: `${ADMIN}${LABOUR}/jwWarnSolveRecord/getJwWarnTip`,
  // 查询处理状态
  GET_JW_WARN_SOLVE: `${ADMIN}${LABOUR}/jwWarnSolveRecord/getSolve`,
  // 修改预警处理信息
  UPDATE_JW_WARN_RECORD: `${ADMIN}${LABOUR}/jwWarnSolveRecord/insert`,
  /** 预警哨兵===============================end*/
  // 批量获取列表班组二维码
  GET_QR_CODE_LIST: `${ADMIN}${LABOUR}/qrCodeRecord/getTeamIdsQrCode`,

  /** 项目侧新首页===============================start*/
  // 获取项目预警列表
  GET_PRO_WARN_LIST: `${ADMIN}${LABOUR}/jwWarnSolveRecord/getList`,
  // 首页班组出勤情况
  GET_PRO_TEAM_DATA: `${ADMIN}${LAOWU}/staffLib/getTeamsDataKQ`,
  // 查询班组考勤排名
  GET_PRO_TEAM_KQ: `${ADMIN}${LAOWU}/staffLib/getTeamsKq`,
  // 获取管理人员数据
  GET_PRO_MANGER_DATA: `${ADMIN}${LABOUR}/homePage/getMangerData`,
  // 获取工人数据
  GET_PRO_STAFF_DATA: `${ADMIN}${LAOWU}/team/getStaffData`,
  // 获取工资数据
  GET_PRO_PAYROLL_DATA: `${ADMIN}${LABOUR}/staffPayroll/getPayrollData`,
  // 获取设备数据
  GET_PRO_DEVICE_DATA: `${ADMIN}${DMGR}/attendanceDevice/getAtteDeviceData`,
  // 获取近七日出勤数据
  GET_PRO_SEVEN_DAYS: `${ADMIN}${LAOWU}/staffLib/doGet7Days`,
   /** 项目侧新首页===============================end*/

  /** 异常考勤补报===============================start*/
  // 是否当天补报
  REPORT_ATTE_FAIL: `${ADMIN}${DMGR}/atteFailRecord/reportAtte`,
  /** 异常考勤补报===============================end*/

  /** 设备台账===============================start*/
  //
  GET_FACILITY_STATUS_STATIC:`${ADMIN}${SAFE}/facilityInfo/getFacilityStatusStatic`,
  //建筑起重机械汇总列表(分页)
  GET_FACILITY_STATIC_LIST:`${ADMIN}${SAFE}/facilityInfo/getFacilityStaticList`,
  //建筑起重机械汇总列表-总计部分
  GET_FACILITY_STATIC_TOTAL:`${ADMIN}${SAFE}/facilityInfo/getFacilityStaticTotal`,
  //查询预警设备列表(分页)
  GET_WARNING_LIST:`${ADMIN}${SAFE}/facilityInfo/getWarnList`,
  //机械设备汇总列表-导出
  EXPORT_SPLIT_FACILITY:`${ADMIN}${SAFE}/facilityInfo/splitFacilityExport`,
  //查询设备列表(分页)
  FACILITY_INFO_LIST:`${ADMIN}${SAFE}/facilityInfo/getList`,
  //设备导出
  EXPORT_FACILITY:`${ADMIN}${SAFE}/facilityInfo/facilityExport`,
  //设备新增
  FACILITY_INSERT:`${ADMIN}${SAFE}/facilityInfo/insert`,
  //查询详情
  FACILITY_DETAIL:`${ADMIN}${SAFE}/facilityInfo/detail`,
  //修改
  FACILITY_UPDATE:`${ADMIN}${SAFE}/facilityInfo/update`,
  //使用登记记录表——查询列表(分页)
  FACILITY_USING_RECORD_LIST:`${ADMIN}${SAFE}/facilityUsingRecord/getList`,
  //设备台账——删除
  FACILITY_DEL:`${ADMIN}${SAFE}/facilityInfo/del`,
  //使用记录——新增
  FACILITY_USING_RECORD_INSERT:`${ADMIN}${SAFE}/facilityUsingRecord/insert`,
  //使用记录——删除
  FACILITY_USING_RECORD_DEL:`${ADMIN}${SAFE}/facilityUsingRecord/del`,
  //使用记录——详情
  FACILITY_USING_RECORD_DETAIL:`${ADMIN}${SAFE}/facilityUsingRecord/detail`,
  //使用记录——修改
  FACILITY_USING_RECORD_UPDATE:`${ADMIN}${SAFE}/facilityUsingRecord/update`,
  //自检记录列表
  FACILITY_CHECK_RECORD_LIST:`${ADMIN}${SAFE}/facilityCheckRecord/getList`,
  //
  FACILITY_CHECK_RECORD_INSERT:`${ADMIN}${SAFE}/facilityCheckRecord/insert`,
  //
  FACILITY_CHECK_RECORD_DETAIL:`${ADMIN}${SAFE}/facilityCheckRecord/detail`,
  //
  FACILITY_CHECK_RECORD_UPDATE:`${ADMIN}${SAFE}/facilityCheckRecord/update`,
  //
  FACILITY_CHECK_RECORD_DEL:`${ADMIN}${SAFE}/facilityCheckRecord/del`,
  //自检记录列表
  FACILITY_MAINTENANCE_RECORD_LIST:`${ADMIN}${SAFE}/facilityMaintenanceRecord/getList`,
  //
  FACILITY_MAINTENANCE_RECORD_INSERT:`${ADMIN}${SAFE}/facilityMaintenanceRecord/insert`,
  //
  FACILITY_MAINTENANCE_RECORD_DETAIL:`${ADMIN}${SAFE}/facilityMaintenanceRecord/detail`,
  //
  FACILITY_MAINTENANCE_RECORD_UPDATE:`${ADMIN}${SAFE}/facilityMaintenanceRecord/update`,
  //
  FACILITY_MAINTENANCE_RECORD_DEL:`${ADMIN}${SAFE}/facilityMaintenanceRecord/del`,
  //自检记录列表
  FACILITY_SELF_CHECK_RECORD_LIST:`${ADMIN}${SAFE}/facilitySelfCheckRecord/getList`,
  //
  FACILITY_SELF_CHECK_RECORD_INSERT:`${ADMIN}${SAFE}/facilitySelfCheckRecord/insert`,
  //
  FACILITY_SELF_CHECK_RECORD_DETAIL:`${ADMIN}${SAFE}/facilitySelfCheckRecord/detail`,
  //
  FACILITY_SELF_CHECK_RECORD_UPDATE:`${ADMIN}${SAFE}/facilitySelfCheckRecord/update`,
  //
  FACILITY_SELF_CHECK_RECORD_DEL:`${ADMIN}${SAFE}/facilitySelfCheckRecord/del`,
  //
  FACILITY_MAINTENANCE_RECORD_EXPORT:`${ADMIN}${SAFE}/facilityMaintenanceRecord/maintenanceRecordExport`,
  //自检记录列表
  FACILITY_FALLING_CHECK_RECORD_LIST:`${ADMIN}${SAFE}/facilityFallingCheckRecord/getList`,
  //
  FACILITY_FALLING_CHECK_RECORD_INSERT:`${ADMIN}${SAFE}/facilityFallingCheckRecord/insert`,
  //
  FACILITY_FALLING_CHECK_RECORD_DETAIL:`${ADMIN}${SAFE}/facilityFallingCheckRecord/detail`,
  //
  FACILITY_FALLING_CHECK_RECORD_UPDATE:`${ADMIN}${SAFE}/facilityFallingCheckRecord/update`,
  //
  FACILITY_FALLING_CHECK_RECORD_DEL:`${ADMIN}${SAFE}/facilityFallingCheckRecord/del`,
  //自检记录列表
  FACILITY_FALLING_RECORD_LIST:`${ADMIN}${SAFE}/facilityFallingRecord/getList`,
  //
  FACILITY_FALLING_RECORD_INSERT:`${ADMIN}${SAFE}/facilityFallingRecord/insert`,
  //
  FACILITY_FALLING_RECORD_DETAIL:`${ADMIN}${SAFE}/facilityFallingRecord/detail`,
  //
  FACILITY_FALLING_RECORD_UPDATE:`${ADMIN}${SAFE}/facilityFallingRecord/update`,
  //
  FACILITY_FALLING_RECORD_DEL:`${ADMIN}${SAFE}/facilityFallingRecord/del`,
  //自检记录列表
  FACILITY_MAINTENANCE_EVALUATE_LIST:`${ADMIN}${SAFE}/facilityMaintenanceEvaluate/getEvaluateList`,
  //
  FACILITY_MAINTENANCE_EVALUATE_INSERT:`${ADMIN}${SAFE}/facilityMaintenanceEvaluate/insert`,
  //
  FACILITY_MAINTENANCE_EVALUATE_DETAIL:`${ADMIN}${SAFE}/facilityMaintenanceEvaluate/detail`,
  //
  FACILITY_MAINTENANCE_EVALUATE_SELF_DETAIL:`${ADMIN}${SAFE}/facilityMaintenanceEvaluate/getDetailSelf`,
  //
  FACILITY_MAINTENANCE_EVALUATE_UPDATE:`${ADMIN}${SAFE}/facilityMaintenanceEvaluate/update`,
  //
  FACILITY_MAINTENANCE_EVALUATE_DEL:`${ADMIN}${SAFE}/facilityMaintenanceEvaluate/del`,
  //
  FACILITY_INFO_BREAK:`${ADMIN}${SAFE}/facilityInfo/break`,
  //
  FACILITY_INFO_WARNING_TOTAL:`${ADMIN}${SAFE}/facilityInfo/getWarnTotal`,
  //
  DETAIL_PROJ_ID:`${ADMIN}${SAFE}/aqxjProjects/detailProjId`,
  //
  EXPORT_FACILITY_BREAK:`${ADMIN}${SAFE}/facilityInfo/facilityStaticExport`,
  // 导出
  FACILITY_INFO_WARNING_EXPORT:`${ADMIN}${SAFE}/facilityInfo/warnFacilityExport`,
  //
  FALLING_WARN_LIST:`${ADMIN}${SAFE}/facilityFallingCheckRecord/getFallingLWarnList`,
  //获得起重机械塔吊-升降机状态监测信息
  FACILITY_MONITOR_INFO:`${ADMIN}${SAFE}/facility-monitor-info/getByPropertyNum`,
  //获得起重机械塔吊-升降机状态监测信息
  FACILITY_MONITOR_INFO2:`${ADMIN}${SAFE}/facility-monitor-info/getMonitorByFactoryNumAndProjectId`,
  //获得起重机械塔吊-升降机状态监测异常信息分页
  FACILITY_EXCEPTION_INFO:`${ADMIN}${SAFE}/exception-info/page`,
  //获得建委同步项目配置分页
  SYNC_FACILITY_PROJECT_CONFIG_PAGE:`${ADMIN}${SAFE}/sync-facility-project-config/page`,
  //删除建委同步项目配置
  SYNC_FACILITY_PROJECT_CONFIG_DELETE:`${ADMIN}${SAFE}/sync-facility-project-config/delete`,
  //更新建委同步项目配置
  SYNC_FACILITY_PROJECT_CONFIG_UPDATE:`${ADMIN}${SAFE}/sync-facility-project-config/update`,
  //创建建委同步项目配置
  SYNC_FACILITY_PROJECT_CONFIG_CREATE:`${ADMIN}${SAFE}/sync-facility-project-config/create`,
  /** 设备台账===============================end*/

  /** 动态扣分===============================start*/
  //查询动态扣分列表(分页)
  SUB_POINT_PAGE:`${ADMIN}${SAFE}/projectSubPoint/getSubPointPage`,
  //查询动态扣分列表(总计部分)
  SUB_POINT_TOTAL:`${ADMIN}${SAFE}/projectSubPoint/getSubPointTotal`,
  //新增(初始化添加扣分项，编辑没有这个按钮出现)
  INSERT_SUB_POINT:`${ADMIN}${SAFE}/projectSubPoint/insert`,
  //提交确定动态扣分
  SUBMIT_SUB_POINT:`${ADMIN}${SAFE}/projectSubPoint/submitSubPoint`,
  //删除
  DEL_SUB_POINT:`${ADMIN}${SAFE}/projectSubPoint/del`,
  //查询详情
  SUB_POINT_DETAIL:`${ADMIN}${SAFE}/projectSubPoint/detail`,
  //导出
  SUB_POINT_EXPORT:`${ADMIN}${SAFE}/projectSubPoint/subPointExport`,
  //扣分项
  SUB_POINT_ITEM:`${ADMIN}${SAFE}/projectSubPointItem/getList`,
  /** 动态扣分===============================end*/

  /** 项目安全责任组织架构===============================start*/
  //查询岗位列表
  GET_POST_LIST:`${ADMIN}${SAFE}/projectDutyStructureDic/getList`,
  //新增岗位
  INSERT_POST:`${ADMIN}${SAFE}/projectDutyStructureDic/insert`,
  //删除岗位
  DEL_POST:`${ADMIN}${SAFE}/projectDutyStructureDic/del`,
  //新增人员
  INSERT_PEOPLE:`${ADMIN}${SAFE}/projectDutyStructure/insert`,
  //项目列表
  LIST_WITH_PROJECT:`${ADMIN}${SAFE}/projectDutyStructure/getListWithProject`,
  //删除项目人员
  DEL_PEOPLE:`${ADMIN}${SAFE}/projectDutyStructure/del`,
  //项目人员详情
  PEOPLE_DETAIL:`${ADMIN}${SAFE}/projectDutyStructure/detail`,
  //修改人员详情
  PEOPLE_UPDATE:`${ADMIN}${SAFE}/projectDutyStructure/update`,
  //公司级获取组织架构列表
  GET_COMPANY_STRUCTURE_LIST:`${ADMIN}${SAFE}/projectDutyStructure/getStructListWithCompany`,
  //分公司获取组织架构审核列表(分页)
  GET_APPROVE_LIST:`${ADMIN}${SAFE}/projectDutyStructure/getApproveListWithCompany`,
  //审核提交
  APPROVE_STRUCT:`${ADMIN}${SAFE}/projectDutyStructure/approveStruct`,
  /** 项目安全责任组织架构===============================end*/
  // 导出人员信息
  EXPORT_STAFF_ALL_MSG:`${ADMIN}${LAOWU}/staffLib/exportStaffAllMsg`,
  /** 在场工人预退场===============================start*/
  // 发起预退场功能
  STAFF_LEAVE_DOSEND:`${ADMIN}${LABOUR}/staffLeaveRecode/doSendLeaveNotice`,
  // 获取退场界面的基本信息
  // STAFF_LEAVE_:`${LABOUR}${LABOUR}/staffLeaveRecode/getPageMsg/{newResumeId}/{oldResumeId}`,
  /** 在场工人预退场===============================end*/

  /** 文施创优===============================start*/
  //查询列表(分页)
  CIVILIZED_EXCELLENT_LIST:`${ADMIN}${SAFE}/excellentCheckInfo/getExcellentCheckPage`,
  //获取创优项目
  EXCELLENT_PROJECT:`${ADMIN}${SAFE}/labourProjectExcellent/getExcellentProject`,
  //专家查询列表(分页)
  EXCELLENT_MASTER_LIST:`${ADMIN}${SAFE}/excellentCheckMaster/getList`,
  //新增
  INSERT_CIVILIZED_EXCELLENT:`${ADMIN}${SAFE}/excellentCheckInfo/insert`,
  //删除
  DEL_CIVILIZED_EXCELLENT:`${ADMIN}${SAFE}/excellentCheckInfo/del`,
  //查询详情
  CIVILIZED_EXCELLENT_DETAIL:`${ADMIN}${SAFE}/excellentCheckInfo/detail`,
  //修改
  CIVILIZED_EXCELLENT_UPDATE:`${ADMIN}${SAFE}/excellentCheckInfo/update`,
  //新增专家
  INSERT_EXCELLENT_MASTER:`${ADMIN}${SAFE}/excellentCheckMaster/insert`,
  //修改专家
  EXCELLENT_MASTER_UPDATE:`${ADMIN}${SAFE}/excellentCheckMaster/update`,
  //删除专家
  DEL_EXCELLENT_MASTER:`${ADMIN}${SAFE}/excellentCheckMaster/del`,
  //专家详情
  EXCELLENT_MASTER_DETAIL:`${ADMIN}${SAFE}/excellentCheckMaster/detail`,
  /** 文施创优===============================end*/

  /** 优质班组===============================start*/
  // 查询列表(分页)
  HG_TEAM_LIST:`${ADMIN}${JOB}/hqTeam/getHqTeamList`,
  // 审批优质班组
  APPROVE_HG_TEAM:`${ADMIN}${JOB}/hqTeam/approveHqTeam`,
  // 查询优质班组详情
  HG_TEAM_DETAIL:`${ADMIN}${JOB}/hqTeam/detailBySys`,
  // 查询所属大班优质班组信息
  GET_PRO_TEAM_BY_TEAM_ID:`${ADMIN}${JOB}/hqTeam/getAffiliationSmallHqTeam`,
  // 查询所属小班优质班组信息
  GET_PRO_TEAM_BY_BIG_TEAM_ID:`${ADMIN}${JOB}/hqTeam/getAffiliationBigHqTeam`,
  // 导出已通过的优质班组列表
  EXPORT_HG_TEAM_LIST: `${ADMIN}${JOB}/hqTeam/exportHqTeamList`,
  // 优质班组库工作内容
  HG_TEAM_WORK_TYPE: `${ADMIN}${JOB}/hqTeam/getHqTeamWorkType`,
  /** 优质班组===============================end*/

  // 管理人员详情-校验手机号
  QUERY_USER_CHECK_PHONE:`${ADMIN}${LABOUR}/sysUser/checkPhoneHasProj`,
  // 管理人员详情-校验身份证号
  QUERY_USER_CHECK_ID_CARD:`${ADMIN}${LABOUR}/sysUser/getManageSysUserMsg`,
   // 管理人员列表-退场功能
  SET_MANGE_USER_LEAVE:`${ADMIN}${LABOUR}/projectUser/leaveManage`,

  // 班组申请人员导出
  EXPORT_TEAM_STAFF_APPLY:`${ADMIN}${LABOUR}/staff/exportTeamStaffApply`,
  // 项目申请人员导出
  EXPORT_PRO_STAFF_APPLY:`${ADMIN}${LABOUR}/staff/exportProjStaffApply`,
  // 导出项目工资汇总、勾选、全部
  EXPORT_PRO_SUMMARY:`${ADMIN}${LABOUR}/payrollApplySummary/exportProjectSummary`,
  // 导出人员 + 项目汇总详情 勾选、全部
  EXPORT_PRO_PERSON_SUMMARY:`${ADMIN}${LABOUR}/payrollApplySummary/exportPersonAndProjectSummary`,
  // 总包审批工人
  APPROVE_STAFF:`${ADMIN}${LAOWU}/team/approveStaff`,
  // 单个班组导入考勤
  IMPORT_TEAM_KQ_EXCEL:`${ADMIN}${LABOUR}/wages-team/importTeamKqExcel`,
  //视频监控import { storage } from './../utils/storage';

  PROJECT_VIDEO_VIEW:`${ADMIN}${SAFE}/proejct-video-view/page`,
  // 批量导出班前教育资料
  BATCHEXPORTEDUCATION:`${ADMIN}${EDU}/work-education-info/batchExportEducation`,
  // 导出统计表
  CHECK_RATE_INFO_EXCEL:`${ADMIN}${SAFE}/check-rate-info/export-static`,
  // 导出隐患排查覆盖率固化数据 Excel
  CHECK_RATE_INFO_EXPORT_EXCEL:`${ADMIN}${SAFE}/check-rate-info/export-excel`,

  PROJECT_VIDEO_TREE:`${ADMIN}${SAFE}/proejct-video-view/getTree`,
}
