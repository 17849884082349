<template>
  <div class="a_Approval">
    <div class="plug-Overview">安全隐患排查工作台账</div>
    <a-divider />
    <a-spin :spinning="spinning" :tip="loadingText">
    <a-form class="clearfix">
      <a-col :span="2">
        <a-form-item>
          <a-radio-group v-model="queryParams.type">
            <a-radio-button :value="1">
              月份
            </a-radio-button>
            <a-radio-button :value="2">
              年份
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
      </a-col>

      <a-col :span="isRate ? 4 : 5">
        <a-form-item label="月份/年份" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-month-picker placeholder="请选择月份" :default-value="moment(monthDay, monthFormat)" :format="monthFormat" v-if="queryParams.type==1" v-model="queryParams.plan_date">
            <a-icon slot="suffixIcon" type="smile" />
          </a-month-picker>

          <a-date-picker
            v-if="queryParams.type==2"
            mode="year"
            v-model="queryParams.plan_date"
            placeholder="请输入年份"
            :default-value="moment(nowYear, yearFormat)" :format="yearFormat"
            :open="yearShowOne"
            @openChange="openChangeOne"
            @panelChange="panelChangeOne"/>
        </a-form-item>
      </a-col>

      <a-col :span="isRate ? 4 : 5" v-if="this.queryParams.qtType == 1">
        <a-form-item label="巡检单位" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-select v-model="queryParams.gcglbOfficeId"  >
            <a-select-option value="">全部</a-select-option>
            <a-select-option
              v-for="item in gcglbList"
              :key="item.value"
              :value="item.value"
              :title="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="isRate ? 4 : 5">
        <a-form-item label="是否闭环" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-select v-model="queryParams.status">
            <a-select-option :value="0">全部</a-select-option>
            <a-select-option :value="1">未闭环</a-select-option>
            <a-select-option :value="2">已闭环</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="isRate ? 4 : 5" v-if="isRate">
        <a-form-item label="下发时间" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-select v-model="queryParams.isRate">
            <a-select-option value="true">是</a-select-option>
            <a-select-option value="false">否</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="isRate ? 4 : 5">
        <a-form-item label="检查类别" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-select v-model="queryParams.zjType">
            <a-select-option :value="0">全部</a-select-option>
            <a-select-option :value="1">日常检查</a-select-option>
            <a-select-option :value="2">专项检查</a-select-option>
            <!-- <a-select-option :value="3">带班检查</a-select-option>
            <a-select-option :value="4">停工单</a-select-option> -->
          </a-select>
        </a-form-item>
      </a-col>


      <a-col :span="2">
        <a-space class="btnBox">
          <a-button type="primary" @click="doQueryBtn()">查询</a-button>
          <a-button @click="resetQuery"> 重置 </a-button>
        </a-space>
      </a-col>
    </a-form>
    <div>
      <a-tabs @change="callback" v-model="qtType">
        <a-tab-pane key="1" tab="公司安全部">
          <standard-table
            :bordered="true"
            :columns="columns"
            :dataSource="dataSource"
            :selectedRows.sync="selectedRows"
            :isAlert="false"
            :pagination="pagination"
            :loading="tableLoading"
            @clear="onClear"
            @change="onPageChange"
            :btnArr="tableBtnArr"
          >
            <template slot="btnAfter">
              限期预警说明：
              <span><i class="y-circle" />逾期3天内</span>
              <span><i class="o-circle" />逾期3-5天</span>
              <span><i class="r-circle" />逾期5天以上</span>
            </template>
            <template slot="index" slot-scope="{ index }">
              <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
            </template>
            <template slot="officeName" slot-scope="{ record }">
              <div v-for="(item, index) in record.officeName.split(',')" :key="index">
                <span>{{ item }}</span>
              </div>
            </template>
            <div slot="do-status" slot-scope="{text}">
              <label v-if="text==2"><a-icon style="font-size:15px;color:green" type="check" /></label>
              <label v-else-if="text==99"></label>
              <label v-else ><a-icon style="font-size:15px;color:red" type="close" /></label>
            </div>
            <div slot="outLine" slot-scope="{text}">
              <span>{{ text && text == 1 ? '已线下回复' : ''}}</span>
            </div>
            <div slot="action" slot-scope="{record}">
              <a @click="doFileDowload(record)">下载资料</a>
            </div>
            <template slot="statusTitle">
              <a-icon @click.native="onStatusTitleClick" type="info-circle" />
            </template>
            <!-- 限期整改时间 -->
            <template slot="zgqxStr" slot-scope="{ text, record }">
              <!-- 是否未闭环 -->
               <span v-if="record.status != 2">
                 <i :class="getCircleClass(record.zgqxStr)" />
               </span>
               {{ useRender.renderDate(text) }}
            </template>
          </standard-table>
        </a-tab-pane>
        <!-- <a-tab-pane key="2" :tab="$store.state.setting.realmType ? '工程管理部' : '分公司'">
          <standard-table
            :bordered="true"
            :columns="columns"
            :dataSource="dataSource"
            :selectedRows.sync="selectedRows"
            :isAlert="false"
            :pagination="pagination"
            :loading="tableLoading"
            @clear="onClear"
            @change="onPageChange"
            :btnArr="tableBtnArr"
          >
            <template slot="btnAfter">
              限期预警说明：
              <span><i class="y-circle" />逾期3天内</span>
              <span><i class="o-circle" />逾期3-5天</span>
              <span><i class="r-circle" />逾期5天以上</span>
            </template>
            <template slot="index" slot-scope="{ index }">
              <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
            </template>
            <div slot="do-status" slot-scope="{text}">
              <label v-if="text==2"><a-icon style="font-size:15px;color:green" type="check" /></label>
              <label v-else-if="text==99"></label>
              <label v-else ><a-icon style="font-size:15px;color:red" type="close" /></label>
            </div>
            <div slot="outLine" slot-scope="{text}">
              <span>{{ text && text == 1 ? '已线下回复' : ''}}</span>
            </div>
            <div slot="action" slot-scope="{record}">
              <a @click="doFileDowload(record)">下载资料</a>
            </div>
            <template slot="statusTitle">
              <a-icon @click.native="onStatusTitleClick" type="info-circle" />
            </template>
            <template slot="zgqxStr" slot-scope="{ text, record }">
               <span v-if="record.status != 2">
                 <i :class="getCircleClass(record.zgqxStr)" />
               </span>
               {{ useRender.renderDate(text) }}
            </template>
          </standard-table>
        </a-tab-pane> -->
        <a-tab-pane key="3" tab="项目部">
          <standard-table
            :bordered="true"
            :columns="columns"
            :dataSource="dataSource"
            :selectedRows.sync="selectedRows"
            :isAlert="false"
            :pagination="pagination"
            :loading="tableLoading"
            @clear="onClear"
            @change="onPageChange"
            :btnArr="tableBtnArr"
          >
            <template slot="btnAfter">
              限期预警说明：
              <span><i class="y-circle" />逾期3天内</span>
              <span><i class="o-circle" />逾期3-5天</span>
              <span><i class="r-circle" />逾期5天以上</span>
            </template>
            <template slot="index" slot-scope="{ index }">
              <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
            </template>
            <div slot="do-status" slot-scope="{text}">
              <label v-if="text==2"><a-icon style="font-size:15px;color:green" type="check" /></label>
              <label v-else-if="text==99"></label>
              <label v-else ><a-icon style="font-size:15px;color:red" type="close" /></label>
            </div>
            <div slot="outLine" slot-scope="{text}">
              <span>{{ text && text == 1 ? '已线下回复' : ''}}</span>
            </div>
            <div slot="action" slot-scope="{record}">
              <a @click="doFileDowload(record)">下载资料</a>
            </div>
            <template slot="statusTitle">
              <a-icon @click.native="onStatusTitleClick" type="info-circle" />
            </template>
            <!-- 限期整改时间 -->
            <template slot="zgqxStr" slot-scope="{ text, record }">
              <!-- 是否未闭环 -->
               <span v-if="record.status != 2">
                 <i :class="getCircleClass(record.zgqxStr)" />
               </span>
               {{ useRender.renderDate(text) }}
            </template>
          </standard-table>
        </a-tab-pane>
      </a-tabs>
    </div>
    <Export-loading :cPercent="cPercent" :isExporting="isExporting" :loadingText="loadingText"></Export-loading>
    </a-spin>
  </div>
</template>

<script>
import StandardTable from '@/components/table/StandardTable'
import {
  OFFICE_STAND_BOOK,
  OFFICE_STAND_BOOK_EXPORT,
  SAFE_VIEW_DOWNLOAD,
  PROJECT_AQXJ_XMBZJ_EXPORT,
  PROJECT_OFFICE_CHILD,
  QUERY_OFFICE_BY_USER
  } from '@/services/api'

import { exportProgress } from '@/pages/aqjyzl/export' // 导出方法
import ExportLoading from '@/components/ExportLoading/ExportLoading' // progress组件
import {request} from '@/utils/request'
import moment from 'moment';
import TagSelectOption from "../../components/tool/TagSelectOption";
import { useRender } from '@/hooks/useRender.js'
const columns = [
  {
    title: "序号",
    align: "center",
    scopedSlots: { customRender: "index" },
    width: 65,
  },
  {
    title: '巡检单位',
    dataIndex: 'officeName',
    align: 'center',
    scopedSlots: { customRender: 'officeName' },
    width:120,
  },
  {
    title: '项目名称',
    dataIndex: 'projectName',
    width:280,
    ellipsis: true,
    tooltip: true,
    sorter: true,
  },
  {
    title: '检查时间',
    dataIndex: 'checkDateStr',
    align: 'center',
    sorter: true,
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '限期整改时间',
    dataIndex: 'zgqxStr',
    align: 'center',
    width: 150,
    sorter: true,
    scopedSlots: { customRender: 'zgqxStr' }
  },
  {
    title: '下发时间',
    dataIndex: 'downTimeStr',
    align: 'center',
    sorter: true,
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '整改单编号',
    dataIndex: 'charCode',
    align: 'center',
    scopedSlots: {customRender: 'charCode'}
  },
  {
    title: '整改回复时间',
    dataIndex: 'pmTimeStr',
    align: 'center',
    sorter: true,
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '复检时间',
    dataIndex: 'fhsjStr',
    align: 'center',
    sorter: true,
    customRender: (text) => useRender.renderDate(text)
  },
  // {
  //   title: '整改到期时间',
  //   dataIndex: 'zgqxStr',
  //   align: 'center',
  //   scopedSlots: {customRender: 'zgqxStr'}
  // },
  {
    title: '是否闭环',
    dataIndex: 'status',
    align: 'center',
    scopedSlots: {customRender: 'do-status'}
  },
  {
    title: '检查类别',
    dataIndex: 'remark',
    align: 'center',
    width: 155,
    ellipsis: true,
    tooltip: true
  },
  {
    title: '备注',
    dataIndex: 'outLine',
    scopedSlots: {customRender: 'outLine'},
    align: 'center',
    width: 155,
    ellipsis: true,
    tooltip: true
  }
  // {
  //   title: '带班/常规',
  //   dataIndex: 'planType',
  //   align: 'center',
  //   scopedSlots: {customRender: 'do-status'}
  // }
  ,
  {
    title: '操作',
    align: 'center',
    scopedSlots: { customRender: 'action' }
  }
];

const columns2 = [
  {
    title: '公司',
    dataIndex: 'parentOffice',
    align: 'center',
    scopedSlots: { customRender: 'parentOffice' }
  },
  {
    title: '分公司',
    dataIndex: 'childOffice',
    align: 'center',
  },
  {
    title: '工程名称',
    dataIndex: 'projectName',
    width:300,
    align: 'center',
    ellipsis: true,
    tooltip: true
  },
  {
    title: '检查时间',
    dataIndex: 'checkDateStr',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '限期整改时间',
    dataIndex: 'zgqxStr',
    align: 'center',
    width: 150,
    scopedSlots: { customRender: 'zgqxStr' }
  },
  {
    title: '整改单编号',
    dataIndex: 'charCode',
    align: 'center',
    scopedSlots: {customRender: 'charCode'}
  },
  {
    title: '复检时间',
    dataIndex: 'fhsjStr',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '是否闭环',
    dataIndex: 'status',
    align: 'center',
    scopedSlots: {customRender: 'do-status'}
  },
  {
    title: '检查类别',
    dataIndex: 'remark',
    align: 'center',
    width: 155,
    ellipsis: true,
    tooltip: true
  },
  {
    title: '备注',
    dataIndex: 'outLine',
    scopedSlots: {customRender: 'outLine'},
    align: 'center',
    width: 155,
    ellipsis: true,
    tooltip: true
  },
  {
    title: '操作',
    align: 'center',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  mixins: [exportProgress],
  name: 'QueryList',
  components: { StandardTable,ExportLoading},
  data () {
    return {
      isRate: this.$route.query.isRate,
      dataSource:[],
      advanced: false,
      columns: columns,
      yearShowOne:false,
      selectedRows: [],
      tableLoading: false,
      spinning:false,
      loadingText:"数据加载中...",
      loadKeys:[],
      visible:false,
      monthFormat: 'YYYY-MM',
      yearFormat: 'YYYY',
      overDownIndex:0,
      monthDay:null,
      nowYear:null,
      gcglbtype:false,
      dowloadtype:false,
      queryParams:{
        orderByType: '',
        orderByKey: '',
        date:null,
        type:1,
        projectName:null,
        charCode:null,
        plan_date:null,
        zjType:0,
        status:0,
        qtType:1,
        gcglbOfficeId:'',
        pageNumber:1,
        isRate: this.$route.query.isRate
      },
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true,
        showTotal: (total, range) => `共 ${total} 条`,
      },
      options:[],
      cPercent:0,
      isExporting:false,
      tableBtnArr: [
        {
            name: '台账导出',
            attrs: {
                type: 'primary'
            },
            click: this.doDowload
        },
        {
            name: '批量下载',
            attrs: {
                type: 'primary'
            },
            click: this.doDowloadBatch
        }
      ],
      qtType: '1',
      gcglbList:[],
      useRender,
      sortTypeMap: {
        ascend: 'ASC',
        descend: 'DESC'
      },
      sortKeyMap: {
        projectName: 'pl.project_name',
        checkDateStr: 'pl.xj_time',
        zgqxStr: 're.zgqx',
        downTimeStr: 're.down_time',
        pmTimeStr: 're.pm_time',
        fhsjStr: 're.fhsj'
      },
    }
  },
  watch: {
    cPercent:{
      handler(newName, oldName) {
        this.cPercent=newName;
      },
      immediate: true,
    },
    loadingText:{
      handler(newName, oldName) {
        this.loadingText=newName;
      },
      immediate: true,
    }
  },
  created() {
    this.monthDay = moment(new Date()).format('YYYY-MM');
    this.nowYear = moment(new Date()).format('YYYY');
    let type=this.$store.getters["account/user"].isGcglb
    if(type===1){
      this.gcglbtype=true
      this.queryParams.qtType=1
    }
    this.getgcglbList(QUERY_OFFICE_BY_USER)
    // this.doQuery();
  },
  activated() {
    if (this.$route.query.isRate) {
      this.queryParams.qtType = this.$route.query.qtType
      if (this.$route.query.qtType == 3) {
        this.dowloadtype=true
        this.columns = columns2;
      } else {
        this.columns = columns;
      }
      this.qtType = this.$route.query.qtType // tab切换
      this.queryParams.date = this.$route.query.date
      this.queryParams.plan_date = moment(this.$route.query.date)
      this.queryParams.gcglbOfficeId = this.$route.query.gcglbId
      this.queryParams.projectId = this.$route.query.recordProjectId
      this.queryParams.id = this.$route.query.recordId
    }
    this.doQuery();
  },
  mounted() {},
  authorize: {
    deleteRecord: 'delete'
  },
  methods: {
    moment,
    callback(key){
      this.queryParams.qtType = key;
      this.queryParams.pageNumber = 1;
      this.pagination.current=1;
      this.doQuery();
      if(this.queryParams.qtType==3){
        this.dowloadtype=true
        this.columns = columns2;
      } else if (this.queryParams.qtType==2) {
        this.columns = columns;
      } else{
        this.columns = columns;
        const keyToDelete = ['downTimeStr']
        this.columns = this.columns.filter(item => !keyToDelete.includes(item.dataIndex));
        this.dowloadtype=false
      }
    },
    deleteRecord(key) {
      this.dataSource = this.dataSource.filter(item => item.key !== key)
      this.selectedRows = this.selectedRows.filter(item => item.key !== key)
    },
    getgcglbList(path){
      request(path,'get').then(res => {
        if (res.data.code === 0) {
          let data = res.data.data.filter(item => item.id != "3da0f5533f87425c9a9e8a11a7923300")
          const arr = [];
          data.forEach((item) => {
            arr.push({
              name: item.master,
              value: item.id,
            });
          });
          this.gcglbList = arr;
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },

    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    remove () {
      this.dataSource = this.dataSource.filter(item => this.selectedRows.findIndex(row => row.key === item.key) === -1)
      this.selectedRows = []
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
    changeDate(e){
      if(e==null||e.length<=0){
        this.queryParams.startDate = null;
        this.queryParams.endDate = null
      }
    },

    doQueryBtn(){
      this.queryParams.pageNumber = 1;
      this.pagination.pageNumber = this.queryParams.pageNumber;
      this.pagination.current = 1
      if(this.queryParams.qtType==3){
        this.dowloadtype=true
        if(!this.gcglbtype){
          this.dataSource=[]
          // if(!this.queryParams.gcglbOfficeId){
          //   this.$message.error('请选择工程管理部');
          //   return
          // }
        }
      }else{
        this.dowloadtype=false
      }
      this.doQuery();
    },

    /**
     * 获取时间段的台账信息
     */
    doQuery(){
      this.tableLoading = true;
      if(this.queryParams.type==1){
        if(this.queryParams.plan_date==null){
          this.queryParams.date = this.monthDay
        }else{
          this.queryParams.date = this.queryParams.plan_date.format('YYYY-MM')
        }
      }
      if(this.queryParams.type==2){
        if(this.queryParams.plan_date==null){
          this.queryParams.date = this.nowYear
        }else{
          this.queryParams.date = this.queryParams.plan_date.format('YYYY')
        }
      }
      request(OFFICE_STAND_BOOK,"post",this.queryParams).then(res => {
        this.tableLoading = false;
        if(res.data.code==0){
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          this.dataSource.forEach(obj=>{
            obj.key = obj.id;
          })
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // doReset(){
    //   this.queryParams={};
    //   this.queryParams.type==1
    //   this.doQuery();
    // },
    //分页查询
    onPageChange(pagination, filters, sorter){
      console.log(pagination, filters, sorter);
      if (sorter && sorter.order && sorter.column) {
        this.columns.forEach(item => {
          if (item.dataIndex === sorter.column.dataIndex) {
            item.sortOrder = sorter.order
            item = sorter.column
          }
        })
        console.log('this.columns', this.columns)
        this.queryParams.orderByType = this.sortTypeMap[sorter.order]
        this.queryParams.orderByKey = this.sortKeyMap[sorter.field]
      } else {
        this.columns = this.columns.map(item => ({ ...item, sortOrder: false }))
        this.queryParams.orderByType = ''
        this.queryParams.orderByKey = ''
      }
      this.queryParams.pageNumber = pagination.current;
      this.pagination.current = pagination.current;
      this.doQuery();
    },
    /**
     * 导出excel
     */
    doDowload(){
      this.loadingText = "正在导出EXCEL文件，请稍候 ····";
      let name = ""
      if(this.queryParams.type==1){
        if(this.queryParams.plan_date==null){
          this.queryParams.date = this.monthDay
        }else{
          this.queryParams.date = this.queryParams.plan_date.format('YYYY-MM')
        }
        name = name+this.queryParams.date+"月"
      }
      if(this.queryParams.type==2){
        if(this.queryParams.plan_date==null){
          this.queryParams.date = this.nowYear
        }else{
          this.queryParams.date = this.queryParams.plan_date.format('YYYY')
        }
        name = name+this.queryParams.date+"年"
      }
      this.isExporting=true;
      this.cPercent=0;
      let fileName=name+"-安全检查台账.xls";
      this.exportFunNomal2(OFFICE_STAND_BOOK_EXPORT,this.queryParams,fileName,'application/vnd.ms-excel')
    },
    handleMenuClick (e) {
      if (e.key === 'delete') {
        this.remove()
      }
    },
    doSureModal(){
      this.visible = true;
    },
    onChange(dataStr){
      this.queryParams.plan_date=null;
    },
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true;
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.yearShowOne = false;
      this.queryParams.plan_date = value;
    },
    //下载巡检资料文件
    doFileDowload(recode){
      const date = new Date()
      const hour = date.getHours(); // 时
      const minutes = date.getMinutes(); // 分
      const seconds = date.getSeconds() //秒
      const dateTime = `${hour}${minutes}${seconds}`
      let selectedRows = this.dataSource.filter(item => item.key == recode.key);
      this.loadingText = "正在下载文件，请稍候 ····";
      this.loadKeys = [];
      this.loadKeys.push(recode.key);
      this.isExporting=true;
      this.cPercent=0;
      let fileName = ""
      if(recode.title){
        fileName=recode.title;
      }
      let typeName = "公司常规检查"
      if(recode.planType==4){
        typeName = "停工处罚";
      }else if(recode.planType==3){
        typeName = "带班检查";
      }else{
        typeName="公司常规检查"
      }
      let projectName = recode.projectName;
      if(recode.projectName.length>15){
        projectName = recode.projectName.slice(0,15)+"……";
      }
      fileName=projectName+"-"+recode.checkDateStr+"-"+typeName+".zip";
      if(this.dowloadtype){
        fileName=projectName+"-"+recode.checkDateStr+"-"+`项目自检整改单${dateTime}.zip`;
        this.exportFun(PROJECT_AQXJ_XMBZJ_EXPORT,this.loadKeys,fileName,selectedRows)
      }else{
        fileName=projectName+"-"+recode.checkDateStr+"-"+typeName+".zip";
        this.exportFun(SAFE_VIEW_DOWNLOAD,this.loadKeys,fileName,selectedRows)
      }

    },
    doDowloadBatch(){
      if(this.selectedRows&&this.selectedRows.length>0){
        this.isExporting=true
        this.loadingText = "正在下载资料...";
        this.selectedRows.map((item) => {
          this.loadKeys = [];
          this.loadKeys.push(item.key);
          this.cPercent=0;
          let fileName = ""
          if(item.title){
            fileName=item.title;
          }
          // let typeName = "公司常规检查"
          // if(item.planType==4){
          //   typeName = "停工处罚";
          // }else if(item.planType==3){
          //   typeName = "带班检查";
          // }else{
          //   typeName="公司常规检查"
          // }
          let projectName = item.projectName;
          if(item.projectName.length>15){
            projectName = item.projectName.slice(0,15)+"……";
          }
          fileName=projectName+"-"+item.checkDateStr+"-安全检查.zip";
          // this.exportFunAlone(SAFEMEANS_DOWNLOAD,itemsKeys,fileName,items,this.selectedRows)
          if(this.dowloadtype){
            this.exportFun(PROJECT_AQXJ_XMBZJ_EXPORT,this.loadKeys,fileName,item)
          }else{
            this.exportFun(SAFE_VIEW_DOWNLOAD,this.loadKeys,fileName,item)
          }

        })
      }else{
        this.$message.info('请选择记录下载');
        return;
      }
    },
    getCircleClass(targetDateStr) {
      if (!targetDateStr) {
        return ""; // 如果目标日期字符串为空，返回空字符串
      }
      
      // 获取当前日期
      const currentDate = new Date();

      // 目标日期字符串转换为 Date 对象
      const targetDate = new Date(targetDateStr);

      // 计算时间差，单位为毫秒
      const timeDifference = currentDate - targetDate;  // 当前日期减去目标日期

      // 将时间差转换为天数
      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

      // 判断并返回相应的类名
      if (daysDifference <= 0) {
        // 逾期0天内
        return ""; // 如果目标日期在当前日期之后，则不渲染
      } else if (daysDifference <= 3) {
        // 逾期3天以内
        return "y-circle"; // 逾期3天内
      } else if (daysDifference <= 5) {
        // 逾期3到5天
        return "o-circle"; // 超期3-5天
      } else {
        // 逾期5天以上
        return "r-circle"; // 超期5天以上
      }
    },
    resetQuery() {
      this.columns = this.columns.map(item => ({ ...item, sortOrder: false }))
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.queryParams.orderByKey = ''
      this.queryParams.orderByType = ''
      this.queryParams.date = null
      this.queryParams.type = 1
      this.queryParams.plan_date = null
      this.queryParams.zjType = 0
      this.queryParams.status = 0
      this.queryParams.gcglbOfficeId = ''
      this.queryParams.projectName = null
      this.queryParams.charCode = null
      this.queryParams.pageNumber = 1
      this.queryParams.isRate = this.$route.query.isRate
      this.doQuery();
    },
  }
}
</script>

<style lang="less" scoped>
  .search{
    margin-bottom: 54px;
  }
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  .operator{
    margin-bottom: 18px;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
  .y-circle, .o-circle, .r-circle {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 5px;
  }

  .y-circle {
    background: #facc14;
  }

  .o-circle {
    background: #f56a00;
  }

  .r-circle {
    background: #f5222d;
  }
</style>
