<template>
  <div class="create_index">
    <!-- 表单封装 
            projectObj：下拉弹出框第一项对象
            Overview：项目名称
            baiduMapBoolen：控制地图显示
            topSubmit：控制上部分按钮显示
            bottomSubmit：控制下部分按钮显示
            handleSubmit：表单提交
            handleChange：输入出发
            handlSelect：输入下拉选择触发
            defaultFileList：上传图片回显链接
            query：路由传过来的数据
        -->
    <form-index
      ref="formindex"
      :formData="formData"
      Overview="项目概况"
      @handleChange="handleChange"
      :baiduMapBoolen="true"
      :topSubmit="true"
      :projectList="projectList"
      @handleSubmit="handleSubmit"
      @handlSelect="handlSelect"
      @handleSearch="handleSearch"
      :defaultFileList="defaultFileList"
    >
      <!-- 上部分按钮插槽 -->
      <template #topSubmit>
        <a-space>
          <a-button type="primary" html-type="submit"> 提交 </a-button>
          <a-button @click="close"> 关闭 </a-button>
        </a-space>
      </template>
      <template :slot="item.officeType" v-for="(item, index) in officeList" >
          <a-input v-model="item.officeName" placeholder="请输入" :key="index" />
      </template>
    </form-index>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import {
  projectMsgGetList,
  aqxjprojectsinsert,
  getofficelist,
} from "@/services/projectManagement";
import { request } from "@/utils/request";
import { PROJECT_OFFICE_CHILD } from "@/services/api";
import { sysUserGetList } from "@/services/message";
import { readSync } from "fs";
import Vue from "vue";
const formData = [
  {
    label: "项目名称",
    placeholder: "请输入项目名称",
    decorator: [
      "projectName",
      { rules: [{ required: true, message: "请输入项目名称" }] },
    ],
    type: "inputGroup",
    key: "projectName",
    dataSource: [],
    labelCol: 3,
    wrapperCol: 19,
    col: 12,
    display: true,
  },
  {
    label: "是否报建",
    placeholder: "请选择是否报建",
    decorator: [
      "isReport",
      { rules: [{ required: true, message: "请选择是否报建" }] },
    ],
    type: "select",
    key: "isReport",
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
    selectlist: [
      {
        value: 0,
        name: "不报建",
      },
      {
        value: 1,
        name: "报建",
      },
    ],
  },
  {
    label: "项目代码",
    placeholder: "请输入项目代码",
    decorator: [
      "projectNum",
      { rules: [{ required: true, message: "请输入项目代码" }] },
    ],
    type: "input",
    key: "projectNum",
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  // {
  //     label: '项目简称',
  //     placeholder: '项目简称',
  //     decorator: ['shortName', { rules: [{required: false, message: '请输入项目简称' }] }],
  //     type: 'input',
  //     key: 'shortName',
  //     col:6,
  //     display:true,
  // },
  {
    label: "工程管理部",
    placeholder: "请选择工程管理部",
    decorator: [
      "gcglbId",
      { rules: [{ required: true, message: "请选择工程管理部" }] },
    ],
    type: "select",
    key: "gcglbId",
    selectlist: [],
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "工期",
    placeholder: "请输入工期",
    decorator: [
      "gqDays",
      { rules: [{ required: false, message: "请输入工期" }] },
    ],
    type: "input",
    key: "gqDays",
    suffix: "天",
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "工程状态",
    placeholder: "请选择工程状态",
    decorator: [
      "status",
      { rules: [{ required: true, message: "请选择工程状态" }] },
    ],
    type: "select",
    key: "status",
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
    selectlist: [
      {
        value: 3,
        name: "未开工",
      },
      {
        value: 0,
        name: "在建",
      },
      {
        value: 1,
        name: "完工",
      },
      {
        value: 2,
        name: "停缓建",
      },
    ],
  },
  {
    label: "工程类别",
    placeholder: "请选择工程类别",
    decorator: [
      "ptype",
      { rules: [{ required: true, message: "请选择工程类别" }] },
    ],
    type: "select",
    key: "ptype",
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
    selectlist: [
      {
        value: 1,
        name: "房建",
      },
      {
        value: 2,
        name: "市政",
      },
      {
        value: 3,
        name: "装饰装修",
      },
    ],
  },
  {
    label: "建设单位",
    placeholder: "请选择建设单位",
    decorator: [
      "buildOfficeName",
      { rules: [{ required: false, message: "请选择建设单位" }] },
    ],
    // showSearch:true,//暂时注释
    type: "inputGroup",
    dataSource: [],
    key: "buildOfficeName",
    col: 12,
    labelCol: 3,
    wrapperCol: 19,
    display: true,
  },
  {
    label: "监理单位",
    placeholder: "请选择监理单位",
    decorator: [
      "effcOfficeName",
      { rules: [{ required: false, message: "请选择监理单位" }] },
    ],
    // showSearch:true,//暂时注释
    type: "inputGroup",
    dataSource: [],
    key: "effcOfficeName",
    col: 12,
    labelCol: 3,
    wrapperCol: 19,
    display: true,
  },
  {
    label: "施工企业",
    type: "customAction",
    slot: '1',
    col: 12,
    labelCol: 3,
    wrapperCol: 19,
    display: true,
  },
  {
    label: "专业分包",
    type: "customAction",
    slot: '2',
    col: 12,
    labelCol: 3,
    wrapperCol: 19,
    display: true,
  },
  {
    label: "劳务分包",
    type: "customAction",
    slot: '3',
    col: 12,
    labelCol: 3,
    wrapperCol: 19,
    display: true,
  },
  {
    label: "项目经理",
    placeholder: "请输入项目经理",
    decorator: [
      "xmjl",
      { rules: [{ required: true, message: "请输入项目经理" }] },
    ],
    type: "inputGroup",
    key: "xmjl",
    dataSource: [],
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "电话",
    placeholder: "请输入项目经理电话",
    decorator: [
      "xmjlPhone",
      { rules: [{ required: true, message: "请输入项目经理电话" }] },
    ],
    type: "input",
    key: "xmjlPhone",
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  // {
  //   label: "形象进度",
  //   placeholder: "请输入形象进度",
  //   decorator: [
  //     "xxjd",
  //     { rules: [{ required: false, message: "请输入形象进度" }] },
  //   ],
  //   type: "input",
  //   col: 12,
  //   suffix: "%",
  //   labelCol: 3,
  //   wrapperCol: 19,
  //   key: "xxjd",
  //   display: true,
  // },
  {
    label: "工程概况",
    placeholder: "请输入工程概况",
    decorator: [
      "projDesc",
      { rules: [{ required: false, message: "请输入工程概况" }] },
    ],
    type: "textarea",
    key: "projDesc",
    labelCol: 3,
    wrapperCol: 19,
    col: 12,
    display: true,
  },
  {
    label: "工程造价",
    placeholder: "请输入工程造价",
    decorator: [
      "projBudget",
      { rules: [{ required: false, message: "请输入工程造价" }] },
    ],
    type: "input",
    inputType: "number",
    suffix: "万元",
    key: "projBudget",
    col: 12,
    labelCol: 3,
    wrapperCol: 19,
    display: true,
  },
  {
    label: "是否附属项目",
    placeholder: "请选择是否附属项目",
    decorator: [
      "isAttached",
      {
        initialValue: 0,
        rules: [{ required: false, message: "请选择附属项目" }],
      },
    ],
    type: "select",
    key: "isAttached",
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
    selectlist: [
      {
        value: 0,
        name: "否",
      },
      {
        value: 1,
        name: "是",
      },
    ],
  },
  {
    label: "工程地址",
    placeholder: "请輸入工程地址",
    decorator: [
      "projAddress",
      { rules: [{ required: false, message: "请輸入工程地址" }] },
    ],
    type: "input",
    key: "projAddress",
    col: 12,
    labelCol: 3,
    wrapperCol: 19,
    display: true,
  },
  {
    label: "工程经纬度",
    placeholder: "113.274 , 23.123",
    decorator: [
      "lanAndLat",
      { rules: [{ required: false, message: "请输入经纬度或从地图选择" }] },
    ],
    type: "input",
    key: "lanAndLat",
    disabled: true,
    col: 12,
    labelCol: 3,
    wrapperCol: 19,
    display: true,
  },
];
export default {
  data() {
    return {
      formData: formData,
      // form: this.$form.createForm(this, { name: 'coordinated' }),
      projectArr: [], //项目名称
      projectList: [], //获取当前项目数据
      defaultFileList: [], //图片数组
      officeList: [
        {
          officeName:'',
          officeType: '1',
        },
        {
          officeName:'',
          officeType: '2',
        },
        {
          officeName:'',
          officeType: '3',
        }
      ]
    };
  },
  components: {
    formIndex,
  },
  inject: ["fatherMethod"],
  methods: {
    // 关闭当前页面
    close() {
      let path = this.$route.path
      this.fatherMethod(`${path}`)
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = data;
        }
      });
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        if (res.data.code === 0) {
          const data = res.data.data.filter(
            (item) => item.id != "3da0f5533f87425c9a9e8a11a7923300"
          );
          const arr = [];
          data.forEach((item) => {
            arr.push({
              name: item.label,
              value: item.value,
            });
          });
          this.formDataFn("gcglbId", arr);
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    // 提交表单数据
    handleSubmit(e) {
      delete e.lanAndLat;
      const params = Object.assign({}, e, { officeList: this.officeList });
      aqxjprojectsinsert(params).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("新增成功", 10);
          this.$refs["formindex"].resetFields();
        } else {
          this.$message.error(res.data.msg, 10);
        }
      })
      this.close()
    },
    // 下拉接口的处理
    getZdata(msg, e, name, key) {
      let data = {
        [name]: e.value,
      };
      msg(data).then((res) => {
        if (res.status == 200) {
          const data = res.data.data.list ? res.data.data.list : res.data.data
          const arr = [];
          if (name == 'nickName') {
            name = 'nickname'
          }
          data.forEach((item) => {
            this.projectArr.push({
              title: item[name],
              id: item.id,
            });
            arr.push({
              name: item[name],
              value: item.id,
            });
          });
          this.projectList = data;
          if (key) {
            this.formDataFn(key, arr);
          }
        }
      });
    },
    // 输入选择框触发
    handleChange(e) {
      this.projectArr = [];
      const name = {
        projectName: () => {
          this.getZdata(projectMsgGetList, e, "fullName");
        },
        xmjl: () => {
          this.getZdata(sysUserGetList, e, "nickName");
        },
        buildOfficeName: () => {
          this.getZdata(getofficelist, e, "name");
        },
        effcOfficeName: () => {
          this.getZdata(getofficelist, e, "name");
        },
      }[e.item.key](); //匹配对应调用对应接口
      const arr = ["projectId", "buildOffice", "effcOffice"];
      arr.forEach((item) => {
        this.$refs["formindex"].setFieldsValue("", {}, item);
      });
      // // 获取当前匹配key，赋值相对于数组
      this.formData.forEach((item) => {
        if (e.item.key === item.key) {
          item.dataSource = this.projectArr;
        }
      });
    },
    // 特殊数据处理
    handlSelect({ val, keys }) {
      console.log(val,'val')
      console.log(keys,'keys')
      if (keys == "isReport") {
        this.formData.forEach((item) => {
          if (item.key == "projectNum") {
            if (val == 1) {
              item.label = "项目代码";
            } else {
              item.label = "工程编码";
            }
          }
        });
      }
       // 处理是否是附属项目key
       if (keys == "ptype") {
        this.formData.forEach((item) => {
          if (val == 3 && item.key == 'isAttached') {
            item.decorator.forEach((items) => {
              if (items instanceof Object) {
                items.rules[0].required = true;
              }
            });
          } else if (item.key == 'isAttached') {
            item.decorator.forEach((items) => {
              if (items instanceof Object) {
                items.rules[0].required = false;
              }
            });
          }
        });
      }
      this.projectList.forEach((item) => {
        if (keys == "xmjl" && item.id == val) {
          this.$refs["formindex"].setFieldsValue(
            val,
            { xmjlPhone: item.mobile },
            "userId"
          );
          this.$refs["formindex"].setFieldsValue(
            item.nickname,
            { xmjl: item.nickname },
            "xmjl"
          );
        } else if (keys == "projectName" && item.id == val) {
          this.$refs["formindex"].setFieldsValue(val, {}, "projectId");
          this.$refs["formindex"].setFieldsValue(
            item.fullName,
            {},
            "projectName"
          );
          this.$refs["formindex"].setFieldsValue("", {
            lanAndLat: `${item.latitude},${item.longitude}`,
          });
        } else if (keys === "buildOfficeName" && item.id == val) {
          this.$refs["formindex"].setFieldsValue(val, {}, "buildOffice");
          this.$refs["formindex"].setFieldsValue(
            item.name,
            {},
            "buildOfficeName"
          );
        } else if (keys === "effcOfficeName" && item.id == val) {
          this.$refs["formindex"].setFieldsValue(val, {}, "effcOffice");
          this.$refs["formindex"].setFieldsValue(
            item.name,
            {},
            "effcOfficeName"
          );
        }
      });
    },
    handleSearch(e) {
      if (e.key == "buildOfficeName" || e.key == "effcOfficeName") {
        this.getZdata(getofficelist, e, "name", e.key);
      }
    },
  },
  created() {
    this.getgcglbList(PROJECT_OFFICE_CHILD + "/OFFICE_CHILD");
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.create_index {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-bottom: 10px;
}
</style>
