<template>
    <div>
        <a-card :body-style="{padding: '0px'}" :bordered="false">
            <div style="margin-left: 10px;margin-top: 10px;margin-bottom: 10px;">
                <a-form>
                    <a-button type="primary" icon="plus" :loading="loading" @click="showPersonModel">
                        添加考勤人员
                    </a-button>
                </a-form>
            </div>
            <a-table
                    :columns="columns"
                    :data-source="data"
                    :pagination="pagination"
                    :loading="loading">
                <template slot="action" slot-scope="text, record">
                    <span>
                      <a @click="del(record)">删除</a>
                    </span>
                </template>

            </a-table>
        </a-card>



        <a-modal
                title="新增人员"
                :visible="personModelVisible"
                width="560px"
                @cancel="personModelCancel"
                @ok="modelOk"
                :keyboard="true"
                :maskClosable="true"
        >

            <a-form-model
                    ref="personForm"
                    :model="personForm"
                    :label-col="labelCol"
                    :wrapper-col="wrapperCol"
            >
                <a-form-model-item ref="personName" label="姓名" prop="personName" required>
                    <a-input v-model="personForm.personName"  />
                </a-form-model-item>
                <a-form-model-item ref="personId" label="身份证号" prop="personId" required>
                    <a-input v-model="personForm.personId"  />
                </a-form-model-item>
                <a-form-model-item ref="url" label="人脸照片" prop="url">
                    <a-upload
                            :action="uploadFaceUrl + '?personId=' + personForm.personId"
                            :headers="headers"
                            list-type="picture-card"
                            :file-list="fileList"
                            @preview="handlePreview"
                            @change="handleChange"
                            :accept="accept"
                            :beforeUpload="beforeUpload"
                    >
                        <div v-if="fileList.length < 1">
                            <a-icon type="plus" />
                            <div class="ant-upload-text">
                                上传人脸照片
                            </div>
                        </div>
                    </a-upload>
                    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                        <img alt="example" style="width: 100%" :src="previewImage" />
                    </a-modal>
                </a-form-model-item>
            </a-form-model>
        </a-modal>



    </div>


</template>
<script>
    import { getPersonList, createPerson, deletePerson, createGroup} from '@/services/txPersonPool'
    import {mapGetters} from 'vuex'
    import moment from 'moment'
    import { storage } from '@/utils/storage'
    import { TOKEN_KEY } from '@/utils/request.js'
    import {UPLOAD_FACE} from '@/services/api'


    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    const columns = [
        {
            title: '人员id',
            dataIndex: 'personId',
            key: 'personId',
        },
        {
            title: '人员名',
            dataIndex: 'personName',
            key: 'personName',
        },
        // {
        //     title: '人脸照片',
        //     dataIndex: 'payableMoney',
        //     key: 'payableMoney',
        // },
        {
            title: '操作',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' },
        },

    ];
    export default {
        data() {
            return {
                headers: {
                    [TOKEN_KEY]: storage.get(TOKEN_KEY),
                    'Tenant-Id': storage.get('Tenant-Id') ? storage.get('Tenant-Id') : ''
                },
                labelCol: { span: 4 },
                wrapperCol: { span: 16 },

                queryData: {

                },
                data: [],
                pagination: {
                    current: 1,
                    pageSize: 20,
                    total: 0,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
                    showTotal: (total, range) => `共 ${total} 条`
                },
                loading: false,
                columns,
                personForm: {
                    personName: '',
                    personId: '',
                    url: '',
                },
                personModelVisible: false,
                previewVisible: false,
                uploadFaceUrl: UPLOAD_FACE,
                fileList: [],
                previewImage: '',
                accept:'.png,.PNG,.jpg,.JPG',//图片上传限制


            };
        },
        computed: {
            hasTeamSelected() {
                return this.selectedTeamIds.length > 0;
            },
            ...mapGetters('account', ['user']),
        },
        activated() {
            this.getData();
        },
        methods: {
            // 图片上传限制处理
            beforeUpload(file){
                const accept = '.' + file.name.substring(file.name.lastIndexOf(".") + 1);
                if (this.accept.split(',').indexOf(accept) == -1) {
                    this.$message.error("只能上传图片");
                    return false
                }
            },
            getData(){
                createGroup().then(res => {
                    console.log(res,'res ====')
                })
                getPersonList({"page": this.pagination.current, "pageSize": this.pagination.pageSize}).then(res => {
                    this.data = res.data.data.list;
                    this.pagination.total = res.data.data.total;
                })
                
            },
            showPersonModel() {
                this.personModelVisible = true;
            },
            personModelCancel() {
                this.personModelVisible = false;
            },

            handleCancel() {
                this.previewVisible = false;
            },
            async handlePreview(file) {
                if (!file.url && !file.preview) {
                    file.preview = await getBase64(file.originFileObj);
                }
                this.previewImage = file.url || file.preview;
                this.previewVisible = true;
            },
            handleChange({ fileList }) {
                console.log(fileList)
                if (fileList.length <= 0){
                    this.fileList = fileList;
                    return
                }
                let info = fileList[0]
                if (info.status === 'done') {
                    if (info.response.code == 0){
                        this.fileList = fileList;
                        this.personForm.url = info.response.data
                    }else {
                        this.fileList = []
                        this.$message.error(info.response.msg)
                    }

                }else {
                    this.fileList = fileList;
                }
            },
            modelOk() {

                createPerson(this.personForm).then(res => {
                    if (res.data.code === 0){
                        this.$message.success(res.data.msg)
                        this.personModelVisible = false;
                        this.getData();
                    }else {
                        this.$message.error(res.data.msg)
                    }
                })
            },
            del(record){
                deletePerson({"personId": record.personId}).then(res => {
                    if (res.data.code === 0){
                        this.$message.success(res.data.msg)
                        this.getData();
                    }else {
                        this.$message.error(res.data.msg)
                    }
                })
            },
        },


    };
</script>
<style>

</style>
