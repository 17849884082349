var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: "班前教育记录详情" } },
    [
      _vm.recordType === _vm.TYPE_ENUM.MANUAL
        ? _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("现场照片")]),
              _c("form-index", {
                ref: "formSitePhoto",
                attrs: { formData: _vm.formDataPhoto },
                on: {
                  resourceUpload: _vm.resourceUpload,
                  upRemove: _vm.upRemove,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.recordType === _vm.TYPE_ENUM.AI
        ? _c("div", [
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("div", { staticClass: "title" }, [_vm._v("视频内容")]),
                _vm.aiVideo.length > 0
                  ? _vm._l(_vm.aiVideo, function (item) {
                      return _c("video", {
                        key: item.id,
                        staticStyle: { width: "600px", height: "300px" },
                        attrs: { controls: "", src: item.file },
                      })
                    })
                  : [
                      _c("a-empty", {
                        attrs: {
                          description: "未能从第三方AI摄像头获取到内容",
                        },
                      }),
                    ],
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("div", { staticClass: "title" }, [_vm._v("音频内容")]),
                _vm.aiAudio.length > 0
                  ? _vm._l(_vm.aiAudio, function (item) {
                      return _c("audio", {
                        key: item.id,
                        staticStyle: { width: "600px" },
                        attrs: { controls: "", src: item.file },
                      })
                    })
                  : [
                      _c("a-empty", {
                        attrs: {
                          description: "未能从第三方AI摄像头获取到内容",
                        },
                      }),
                    ],
              ],
              2
            ),
          ])
        : _vm._e(),
      _c("a-divider", { staticStyle: { margin: "20px 0px" } }),
      _c("div", { staticClass: "title" }, [_vm._v("教育记录信息")]),
      _c("form-index", {
        ref: "formRef",
        attrs: { formData: _vm.formData },
        on: { handlSelect: _vm.handleSelect, onDateChange: _vm.onDateChange },
        scopedSlots: _vm._u([
          {
            key: "customAction",
            fn: function () {
              return [
                _c("div", { staticClass: "flex a-center" }, [
                  _c("div", { staticStyle: { "line-height": "32px" } }, [
                    _c(
                      "div",
                      [
                        _c("a-icon", {
                          staticClass: "mr-1",
                          style: { fontSize: "18px" },
                          attrs: { type: "user" },
                        }),
                        _vm._l(_vm.workEducationPersons, function (item) {
                          return item.workerId
                            ? _c("a-tag", { key: item.idCard }, [
                                _vm._v(_vm._s(item.workerName || item.name)),
                              ])
                            : _vm._e()
                        }),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "a-tooltip",
                          { attrs: { placement: "bottom" } },
                          [
                            _c("template", { slot: "title" }, [
                              _c(
                                "span",
                                [
                                  _c("a-icon", {
                                    staticClass: "mr-1",
                                    style: {
                                      fontSize: "18px",
                                      color: "#F69707",
                                    },
                                    attrs: { type: "user" },
                                  }),
                                  _vm._v("此类人员未在本系统录入实名制信息"),
                                ],
                                1
                              ),
                            ]),
                            _c("a-icon", {
                              staticClass: "mr-1",
                              style: { fontSize: "18px", color: "#F69707" },
                              attrs: { type: "user" },
                            }),
                          ],
                          2
                        ),
                        _vm._l(_vm.workEducationPersons, function (item) {
                          return (item.workerId === null ||
                            item.workerId === undefined) &&
                            (item.workNum === null ||
                              item.workNum === undefined)
                            ? _c("a-tag", { key: item.idCard }, [
                                _vm._v(_vm._s(item.workerName || item.name)),
                              ])
                            : _vm._e()
                        }),
                      ],
                      2
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex-1 ml-1" },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", disabled: _vm.isReadonly },
                          on: { click: _vm.onPersonsModal },
                        },
                        [_vm._v(" 请选择 ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "thirdDataAiRecordDOList",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "flex a-center" },
                  [
                    _c("standard-table", {
                      attrs: {
                        columns: _vm.columns,
                        dataSource: _vm.dataSource,
                        rowKey: "id",
                        pagination: false,
                        scroll: { x: "100%" },
                        isAlert: false,
                        bordered: true,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "skillType",
                          fn: function (ref) {
                            var text = ref.text
                            return _c(
                              "div",
                              {},
                              [
                                _c("a-tag", { attrs: { color: "orange" } }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.skillTypeMap[text]) + " "
                                  ),
                                ]),
                              ],
                              1
                            )
                          },
                        },
                        {
                          key: "teamQr",
                          fn: function (ref) {
                            var text = ref.text
                            var record = ref.record
                            return _c("div", {}, [
                              _c(
                                "div",
                                {
                                  staticClass: "flex a-center j-center",
                                  staticStyle: { height: "40px" },
                                },
                                [
                                  text != "" && text != null
                                    ? _c("div", [
                                        _c("img", {
                                          staticStyle: {
                                            width: "40px",
                                            height: "40px",
                                            cursor: "pointer",
                                          },
                                          attrs: { src: text },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showImg(text, record)
                                            },
                                          },
                                        }),
                                      ])
                                    : _vm._e(),
                                  _c("a-spin", { attrs: { spinning: !text } }),
                                ],
                                1
                              ),
                            ])
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticStyle: { display: "flex", "justify-content": "flex-end" } },
        [
          _c(
            "a-space",
            [
              _c("a-button", { on: { click: _vm.handleClose } }, [
                _vm._v("关闭"),
              ]),
              _c(
                "a-button",
                {
                  attrs: { type: "primary", disabled: _vm.isReadonly },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "选择参与人员", width: 600 },
          on: { ok: _vm.onConfirm, cancel: _vm.onReset },
          model: {
            value: _vm.personsModal,
            callback: function ($$v) {
              _vm.personsModal = $$v
            },
            expression: "personsModal",
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "relative mb-1" },
                [
                  _c(
                    "a-checkbox",
                    {
                      staticClass: "position-all",
                      attrs: {
                        indeterminate: _vm.indeterminate,
                        checked: _vm.checkAll,
                      },
                      on: { change: _vm.onCheckAllChange },
                    },
                    [_vm._v(" 全选 ")]
                  ),
                  _c("div", { staticClass: "text-center" }, [
                    _c("div", { staticClass: "font-bold black" }, [
                      _vm._v(
                        " " + _vm._s(_vm.teamName.name || "未匹配班组") + " "
                      ),
                    ]),
                    _c("div", { staticClass: "black-three" }, [
                      _vm._v(
                        " (教育日期 " +
                          _vm._s(_vm.educateDateValue) +
                          " 班组在场人员) "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c("a-checkbox-group", {
                staticClass: "ml-2",
                attrs: { options: _vm.presenceList },
                on: { change: _vm.onChange },
                model: {
                  value: _vm.checkedList1,
                  callback: function ($$v) {
                    _vm.checkedList1 = $$v
                  },
                  expression: "checkedList1",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _vm.noPresenceList.length > 0
                ? _c("div", { staticClass: "text-center mb-1" }, [
                    _c("div", { staticClass: "font-bold black" }, [
                      _vm._v("非此班组识别人员"),
                    ]),
                    _c("div", { staticClass: "black-three" }, [
                      _vm._v("(橙色名称人员在系统内无实名信息)"),
                    ]),
                  ])
                : _vm._e(),
              _c(
                "a-checkbox-group",
                {
                  staticClass: "ml-2",
                  on: { change: _vm.onChange },
                  model: {
                    value: _vm.checkedList2,
                    callback: function ($$v) {
                      _vm.checkedList2 = $$v
                    },
                    expression: "checkedList2",
                  },
                },
                _vm._l(_vm.noPresenceList, function (item) {
                  return _c(
                    "a-checkbox",
                    { key: item.value, attrs: { value: item.value } },
                    [
                      _c("span", { style: _vm.computedStyle(!item.workerId) }, [
                        _vm._v(
                          _vm._s(item.label || item.name || item.workerName)
                        ),
                      ]),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: {
            destroyOnClose: true,
            visible: _vm.modalVisible,
            footer: null,
          },
          on: { cancel: _vm.handleCancel },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { alt: "example", src: _vm.previewImage },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }