export const commonFormData = [
  {
    label: "项目名称",
    placeholder: "请输入项目名称",
    decorator: [
      "projectName",
      { rules: [{ required: true, message: "请输入项目名称" }] },
    ],
    type: "inputGroup",
    key: "projectName",
    dataSource: [],
    labelCol: 4,
    wrapperCol: 18,
    col: 12,
    display: true,
  },
  {
    label: "是否报建",
    placeholder: "请选择是否报建",
    decorator: [
      "isReport",
      { rules: [{ required: true, message: "请选择是否报建" }] },
    ],
    type: "select",
    key: "isReport",
    col: 6,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
    selectlist: [
      {
        value: 0,
        name: "不报建",
      },
      {
        value: 1,
        name: "报建",
      },
    ],
  },
  {
    label: "项目代码",
    placeholder: "请输入项目代码",
    decorator: [
      "projectNum",
      { rules: [{ required: false, message: "请输入项目代码" }] },
    ],
    type: "input",
    key: "projectNum",
    setRules: true,
    col: 6,
    labelCol: 8,
    wrapperCol: 12,
    display: false,
  },
  // {
  //     label: '项目简称',
  //     placeholder: '项目简称',
  //     decorator: ['shortName', { rules: [{required: true, message: '请输入项目简称' }] }],
  //     type: 'input',
  //     key: 'shortName',
  //     col:6,
  //     display:true,
  // },
  {
    label: "管理部门",
    placeholder: "请选择管理部门",
    decorator: [
      "gcglbId",
      { rules: [{ required: true, message: "请选择管理部门" }] },
    ],
    type: "select",
    key: "gcglbId",
    qb: true,
    selectlist: [],
    col: 6,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "管理部门",
    placeholder: "请选择管理部门",
    decorator: [
      "gcglbName",
      { rules: [{ required: true, message: "请选择管理部门" }] },
    ],
    type: "input",
    key: "gcglbName",
    col: 6,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "工期",
    placeholder: "请输入工期",
    decorator: [
      "gqDays",
      { rules: [{ required: false, message: "请输入工期" }] },
    ],
    type: "input",
    suffix: "天",
    key: "gqDays",
    col: 6,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "工程状态",
    placeholder: "请选择工程状态",
    decorator: [
      "status",
      { rules: [{ required: true, message: "请选择工程状态" }] },
    ],
    type: "select",
    key: "status",
    col: 6,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
    selectlist: [
      {
        value: 3,
        name: "未开工",
      },
      {
        value: 0,
        name: "在建",
      },
      {
        value: 2,
        name: "停缓建",
      },
      {
        value: 4,
        name: "完工",
      },
      {
        value: 5,
        name: "竣工",
      },
    ],
  },
  {
    label: "工程类别",
    placeholder: "请选择工程类别",
    decorator: [
      "ptype",
      { rules: [{ required: true, message: "请选择工程类别" }] },
    ],
    type: "select",
    key: "ptype",
    col: 6,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
    selectlist: [
      {
        value: 1,
        name: "房建",
      },
      {
        value: 2,
        name: "市政",
      },
      {
        value: 3,
        name: "装饰装修",
      },
      {
        value: 4,
        name: "水利",
      },
    ],
  },
  {
    label: "是否附属项目",
    placeholder: "请选择是否附属项目",
    decorator: [
      "isAttached",
      {
        initialValue: 0,
        rules: [{ required: false, message: "请选择附属项目" }],
      },
    ],
    type: "select",
    key: "isAttached",
    col: 6,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
    selectlist: [
      {
        value: 0,
        name: "否",
      },
      {
        value: 1,
        name: "是",
      },
    ],
  },
  {
    label: "建设单位",
    placeholder: "请选择建设单位",
    decorator: [
      "buildOfficeName",
      { rules: [{ required: false, message: "请选择建设单位" }] },
    ],
    key: "buildOfficeName",
    // showSearch:true,
    type: "inputGroup",
    dataSource: [],
    col: 12,
    labelCol: 4,
    wrapperCol: 18,
    display: true,
  },
  {
    label: "监理单位",
    placeholder: "请选择监理单位",
    decorator: [
      "effcOfficeName",
      { rules: [{ required: false, message: "请选择监理单位" }] },
    ],
    // showSearch:true,
    type: "inputGroup",
    dataSource: [],
    key: "effcOfficeName",
    col: 12,
    labelCol: 4,
    wrapperCol: 18,
    display: true,
  },
  {
    label: "项目经理",
    placeholder: "请输入项目经理",
    decorator: [
      "xmjl",
      { rules: [{ required: true, message: "请输入项目经理" }] },
    ],
    type: "inputGroup",
    key: "xmjl",
    dataSource: [],
    col: 6,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "项目经理电话",
    placeholder: "请输入项目经理电话",
    decorator: [
      "xmjlPhone",
      { rules: [{ required: true, message: "请输入项目经理电话" }] },
    ],
    type: "input",
    key: "xmjlPhone",
    col: 6,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "形象进度",
    placeholder: "请输入形象进度",
    decorator: [
      "xxjd",
      { rules: [{ required: false, message: "请输入形象进度" }] },
    ],
    type: "input",
    suffix: "%",
    col: 6,
    setRules: true,
    labelCol: 8,
    wrapperCol: 12,
    key: "xxjd",
    display: true,
  },
  {
    label: "安全文施创优要求",
    placeholder: "请輸入安全文施创优要求",
    decorator: [
      "cyyq",
      { rules: [{ required: false, message: "请輸入安全文施创优要求" }] },
    ],
    type: "select",
    selectlist: [
      {
        value: "1",
        name: "合同要求",
      },
      {
        value: "2",
        name: "滚动计划",
      },
    ],
    key: "cyyq",
    col: 6,
    labelCol: 8,
    wrapperCol: 12,
    display: false,
    disType: "table",
  },
  {
    label: "安全文施创优级别",
    placeholder: "请輸入安全文施创优级别",
    decorator: [
      "cylevel",
      { rules: [{ required: false, message: "请輸入安全文施创优级别" }] },
    ],
    type: "select",
    selectlist: [
      {
        value: "1",
        name: "市双优",
      },
      {
        value: "2",
        name: "省双优",
      },
      {
        value: "3",
        name: "国家级创优",
      },
      {
        value: "4",
        name: "其它",
      },
    ],
    key: "cylevel",
    col: 6,
    labelCol: 8,
    wrapperCol: 12,
    display: false,
    disType: "table",
  },
  {
    label: "安全评价书获取时间",
    placeholder: "请选择安全评价书获取时间",
    decorator: [
      "pjsDate",
      { rules: [{ required: false, message: "请选择安全评价书获取时间" }] },
    ],
    type: "datePicker",
    key: "pjsDate",
    col: 6,
    labelCol: 8,
    wrapperCol: 12,
    display: false,
    disType: "table",
    format: "yyyy-MM-DD",
  },
  {
    label: "上传安全评价书",
    placeholder: "上传安全评价书",
    decorator: [
      "pjsPath",
      { rules: [{ required: false, message: "上传安全评价书" }] },
    ],
    type: "Upload",
    uploadLength: 3,
    key: "pjsPath",
    col: 6,
    labelCol: 8,
    wrapperCol: 12,
    display: false,
    class: "Upload",
    disType: "table",
  },
  {
    label: "开工日期",
    placeholder: "请选择开工日期",
    decorator: [
      "startDate",
      { rules: [{ required: false, message: "请选择开工日期" }] },
    ],
    type: "datePicker",
    key: "startDate",
    col: 6,
    labelCol: 8,
    wrapperCol: 12,
    display: false,
    disType: "list",
    format: "yyyy-MM-DD",
  },
  {
    label: "完工日期",
    placeholder: "请选择完工日期",
    decorator: [
      "endDate",
      { rules: [{ required: false, message: "请选择完工日期" }] },
    ],
    type: "datePicker",
    key: "endDate",
    col: 6,
    labelCol: 8,
    wrapperCol: 12,
    display: false,
    disType: "list",
    format: "yyyy-MM-DD",
  },
  {
    label: "工程造价",
    placeholder: "请输入工程造价",
    decorator: [
      "projBudget",
      { rules: [{ required: false, message: "请输入工程造价" }] },
    ],
    type: "input",
    inputType: "number",
    suffix: "万元",
    key: "projBudget",
    col: 6,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
    style: { width: '100%' },
  },
  {
    label: "是否纳入月报",
    placeholder: "请选择是否纳入月报",
    decorator: [
      "monthlyReport",
      {
        initialValue: 0,
        rules: [{ required: false, message: "请选择是否纳入月报" }],
      },
    ],
    type: "select",
    key: "monthlyReport",
    col: 6,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
    selectlist: [
      {
        value: 0,
        name: "否",
      },
      {
        value: 1,
        name: "是",
      },
    ],
  },
  {
    label: "是否隐藏安全责任",
    placeholder: "请选择是否隐藏安全责任",
    decorator: [
      "safeDutyDisable",
      {
        initialValue: 0,
        rules: [{ required: false, message: "请选择是否隐藏安全责任" }],
      },
    ],
    type: "select",
    key: "safeDutyDisable",
    col: 6,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
    disType: "table",
    selectlist: [
      {
        value: 0,
        name: "否",
      },
      {
        value: 1,
        name: "是",
      },
    ],
  },
  {
    label: "重点监控项目",
    placeholder: "请选择是否重点监控项目",
    decorator: [
      "monitorDisable",
      {
        initialValue: 1,
        rules: [{ required: false, message: "请选择是否重点监控项目" }],
      },
    ],
    type: "select",
    key: "monitorDisable",
    col: 6,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
    disType: "table",
    selectlist: [
      {
        value: 0,
        name: "否",
      },
      {
        value: 1,
        name: "是",
      },
    ],
    disabled: true
  },
  {
    label: "重点监控备注",
    placeholder: "重点监控项目备注信息",
    decorator: [
      "monitorReason",
      { rules: [{ required: true, message: "重点项目监控备注信息" }] },
    ],
    type: "input",
    key: "monitorReason",
    col: 6,
    labelCol: 8,
    wrapperCol: 12,
    display: false,
  },
  {
    label: "工程概况",
    placeholder: "请输入工程概况",
    decorator: [
      "projDesc",
      { rules: [{ required: false, message: "请输入工程概况" }] },
    ],
    type: "textarea",
    key: "projDesc",
    styles: "width: 100%;",
    labelCol: 2,
    wrapperCol: 21,
    col: 24,
    display: true,
  },
  {
    label: "工程地址",
    placeholder: "请选择工程地址",
    decorator: [
      "projectAddress",
      { rules: [{ required: false, message: "请选择工程地址" }] },
    ],
    type: "cascader",
    key: "projectAddress",
    col: 12,
    labelCol: 4,
    wrapperCol: 18,
    display: true,
    disabled: true
  },
  {
    label: "工程经纬度",
    placeholder: "113.274,23.123",
    decorator: [
      "lanAndLat",
      { rules: [{ required: false, message: "请输入经纬度或从地图选择" }] },
    ],
    type: "input",
    key: "lanAndLat",
    col: 12,
    labelCol: 4,
    wrapperCol: 18,
    display: true,
  },
];