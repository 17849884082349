var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c("div", { staticClass: "plug-Overview" }, [
        _vm._v(
          _vm._s(
            (_vm.bookType == 1 ? "市" : _vm.bookType == 2 ? "省" : "国家") +
              "级安全创优获奖台账"
          )
        ),
      ]),
      _c("a-divider"),
      _c(
        "a-form-model",
        { staticClass: "clearfix" },
        [
          _vm.user.nowRoleKey.includes("OFFICE_AQMGLY")
            ? _c(
                "a-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "分公司",
                        labelCol: { span: 8 },
                        wrapperCol: { span: 14, offset: 1 },
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: { allowClear: "", placeholder: "" },
                          on: {
                            change: function ($event) {
                              return _vm.handlSelect($event)
                            },
                          },
                          model: {
                            value: _vm.params.gcglbId,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "gcglbId", $$v)
                            },
                            expression: "params.gcglbId",
                          },
                        },
                        _vm._l(_vm.gcglbList, function (item) {
                          return _c(
                            "a-select-option",
                            {
                              key: item.value,
                              attrs: { value: item.value, title: item.name },
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-col",
            { attrs: { span: 5 } },
            [
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "项目名称：",
                    labelCol: { span: 8 },
                    wrapperCol: { span: 14, offset: 1 },
                  },
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        allowClear: true,
                        "show-search": "",
                        "filter-option": _vm.filterOption,
                      },
                      on: { search: _vm.handleSearch },
                      model: {
                        value: _vm.params.projectName,
                        callback: function ($$v) {
                          _vm.$set(_vm.params, "projectName", $$v)
                        },
                        expression: "params.projectName",
                      },
                    },
                    _vm._l(_vm.projectList, function (items) {
                      return _c(
                        "a-select-option",
                        {
                          key: items.projectId + "",
                          attrs: {
                            value: items.projectName,
                            title: items.projectName,
                          },
                        },
                        [_vm._v(_vm._s(items.projectName))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 5 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "工程类别",
                    labelCol: { span: 8 },
                    wrapperCol: { span: 14, offset: 1 },
                  },
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { allowClear: "", placeholder: "" },
                      on: { change: _vm.queryDicCode },
                      model: {
                        value: _vm.params.ptype,
                        callback: function ($$v) {
                          _vm.$set(_vm.params, "ptype", $$v)
                        },
                        expression: "params.ptype",
                      },
                    },
                    _vm._l(_vm.pTypeOption, function (item) {
                      return _c(
                        "a-select-option",
                        { key: item.value, attrs: { value: item.value } },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 5 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "获奖年度",
                    labelCol: { span: 8 },
                    wrapperCol: { span: 14, offset: 1 },
                  },
                },
                [
                  _c("a-date-picker", {
                    attrs: { mode: "year", format: "YYYY" },
                    on: { panelChange: _vm.changeYear },
                    model: {
                      value: _vm.params.year,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "year", $$v)
                      },
                      expression: "params.year",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.bookType != 3
            ? _c(
                "a-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "奖项类型",
                        labelCol: { span: 8 },
                        wrapperCol: { span: 14, offset: 1 },
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: { allowClear: "", placeholder: "" },
                          model: {
                            value: _vm.params.certificateCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "certificateCode", $$v)
                            },
                            expression: "params.certificateCode",
                          },
                        },
                        _vm._l(_vm.codeOption, function (item) {
                          return _c(
                            "a-select-option",
                            {
                              key: item.certificateCode,
                              attrs: {
                                value: item.certificateCode,
                                title: item.certificateName,
                              },
                            },
                            [_vm._v(" " + _vm._s(item.certificateName) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-col",
            { attrs: { span: 4 } },
            [
              _c(
                "a-space",
                { staticClass: "btnBox" },
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleQuery },
                    },
                    [_vm._v(" 查询 ")]
                  ),
                  _c("a-button", { on: { click: _vm.resetQuery } }, [
                    _vm._v(" 重置 "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-space",
        { staticClass: "flex j-start" },
        [
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.doDowload } },
            [_vm._v(" 导出 ")]
          ),
        ],
        1
      ),
      _c("a-table", {
        key: _vm.bookType,
        staticClass: "mt-3",
        attrs: {
          columns: _vm.columns,
          "data-source": _vm.tableData,
          bordered: "",
          size: "default",
          pagination: {
            defaultCurrent: _vm.params.pageNumber,
            total: _vm.total,
            pageSize: _vm.params.pageSize,
            showTotal: function (total, range) {
              return "共 " + total + " 条"
            },
          },
        },
        on: { change: _vm.handleChangePage },
        scopedSlots: _vm._u([
          {
            key: "certificateName",
            fn: function (text) {
              return [
                _c(
                  "a-tooltip",
                  { attrs: { placement: "topLeft" } },
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v(" " + _vm._s(text) + " "),
                    ]),
                    _c("div", { staticClass: "overtext-1" }, [
                      _vm._v(_vm._s(text)),
                    ]),
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "action",
            fn: function (text, row) {
              return [
                _vm.user.loginType == 1 &&
                !_vm.user.nowRoleKey.includes("OFFICE_ZGB_USER")
                  ? _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.isOrg ? "查看" : "编辑") + " ")]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cert",
            fn: function (text, row) {
              return [
                _c(
                  "div",
                  { staticClass: "flex j-center" },
                  _vm._l(row.certificatePathsList, function (item, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        item.type == "img"
                          ? _c("img", {
                              staticStyle: { width: "32px" },
                              attrs: { alt: "", src: item.url },
                              on: {
                                click: function ($event) {
                                  return _vm.handlePreview(item)
                                },
                              },
                            })
                          : _vm._e(),
                        item.type == "pdf"
                          ? _c("a-icon", {
                              style: { fontSize: "32px" },
                              attrs: { type: "file-pdf" },
                              on: {
                                click: function ($event) {
                                  return _vm.handlePreview(item)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]
            },
          },
          {
            key: "projectFile",
            fn: function (text, row) {
              return [
                _c(
                  "div",
                  { staticClass: "flex j-center" },
                  [
                    row.projectFile
                      ? _c("a-icon", {
                          style: { fontSize: "32px" },
                          attrs: { type: "file-pdf" },
                          on: {
                            click: function ($event) {
                              return _vm.handleLXPreview(row.projectFile)
                            },
                          },
                        })
                      : row.projectStatus == "/"
                      ? _c("span", [_vm._v("/")])
                      : _c("span"),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "certProject",
            fn: function (text, row) {
              return [
                _c(
                  "div",
                  { staticClass: "flex j-center" },
                  _vm._l(row.certificatePathsList, function (item, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        item.type == "img"
                          ? _c("img", {
                              staticStyle: { width: "32px" },
                              attrs: { alt: "", src: item.url },
                              on: {
                                click: function ($event) {
                                  return _vm.handlePreview(item)
                                },
                              },
                            })
                          : _vm._e(),
                        item.type == "pdf"
                          ? _c("a-icon", {
                              style: { fontSize: "32px" },
                              attrs: { type: "file-pdf" },
                              on: {
                                click: function ($event) {
                                  return _vm.handlePreview(item)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]
            },
          },
          {
            key: "certPrivate",
            fn: function (text, row) {
              return [
                _c(
                  "div",
                  { staticClass: "flex j-center" },
                  _vm._l(
                    row.certificatePathsListPrivate,
                    function (item, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          item.type == "img"
                            ? _c("img", {
                                staticStyle: { width: "32px" },
                                attrs: { alt: "", src: item.url },
                                on: {
                                  click: function ($event) {
                                    return _vm.handlePreview(item)
                                  },
                                },
                              })
                            : _vm._e(),
                          item.type == "pdf"
                            ? _c("a-icon", {
                                style: { fontSize: "32px" },
                                attrs: { type: "file-pdf" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handlePreview(item)
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    }
                  ),
                  0
                ),
              ]
            },
          },
          {
            key: "projectDate",
            fn: function (text, row) {
              return [
                _c("div", { staticClass: "flex j-center" }, [
                  !row.cyyq || row.cyyq == 1
                    ? _c("span", [_vm._v("/")])
                    : _c("span", [_vm._v(_vm._s(_vm.renderDate(text)))]),
                ]),
              ]
            },
          },
        ]),
      }),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.previewVisible, footer: null },
          on: { cancel: _vm.handleCancel },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { alt: "", src: _vm.previewImage },
          }),
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.uploadShow, footer: null },
          on: { cancel: _vm.handleCancelUpload },
        },
        [
          _c(
            "a-form-model",
            {
              ref: "uploadForm",
              attrs: {
                layout: "horizontal",
                form: _vm.uploadForm,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol,
                model: _vm.uploadForm,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "奖项类别" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { allowClear: "", placeholder: "" },
                      model: {
                        value: _vm.uploadForm.certificateCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.uploadForm, "certificateCode", $$v)
                        },
                        expression: "uploadForm.certificateCode",
                      },
                    },
                    _vm._l(_vm.codeOptionTemp, function (item) {
                      return _c(
                        "a-select-option",
                        {
                          key: item.certificateCode,
                          attrs: { value: item.certificateCode },
                        },
                        [_vm._v(" " + _vm._s(item.certificateName) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "上传文件", name: "file" } },
                [
                  _c(
                    "a-upload",
                    {
                      attrs: {
                        "file-list": _vm.fileList,
                        "before-upload": _vm.beforeUpload,
                      },
                      on: { change: _vm.handleUploadChange },
                    },
                    [
                      _c(
                        "a-button",
                        [
                          _c("a-icon", { attrs: { type: "upload" } }),
                          _vm._v(" 选择文件 "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { "wrapper-col": { span: 14, offset: 4 } } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleUploadSubmit },
                    },
                    [_vm._v(" 提交 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("Export-loading", {
        attrs: {
          cPercent: _vm.cPercent,
          isExporting: _vm.isExporting,
          loadingText: _vm.loadingText,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }