<template>
  <div class="a_Approval">
    <form-index ref="formindex" :formData="formData" Overview="视频监控" flexSubmit
      :flexAction="true" :baiduMapBoolen="false" @handleSubmit="handleSubmit" @handlSelect="handlSelect">
      <template #flexAction>
        <a-space :style="{ marginTop: '4px' }">
          <a-button type="primary" html-type="submit" :loading="loading"> 查询 </a-button>
          <!-- <a-button @click="close"> 重置 </a-button> -->
        </a-space>
      </template>
    </form-index>
    <div v-if="videoList.length > 0" class="flex a-center j-center flex-column">
      <h2>{{gcglbName}}——{{projectName}}</h2>
      <div class="mt-2" style="width: 70%;">
        <a-row>
          <a-col v-for="(item, index) in videoList" :key="index" :span="8">
            <div class="flex a-center j-center p relative">
              <video 
                style="width: 100%;"
                id="video"
                :ref="`video${index}`"
                crossorigin="anonymous"
                autoplay
                ></video>
                <a-icon class="fullscreen-icon" type="fullscreen" @click="onClickVideo(item, index)"/>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <div v-else>
      <a-empty />
    </div>
    <a-modal v-model="modalVisible" :destroyOnClose="true" :footer="null" :width="1100" @cancel="closeModal"
      :maskClosable="false">
      <video 
        style="width: 100%;"
        id="video"
        ref="videoModal"
        crossorigin="anonymous"
        autoplay
        controls
        ></video>
    </a-modal>
  </div>
</template>

<script>
import Hls from 'hls.js';
import { getVideoView, getVideoProject } from '@/services/sys'
import { mapGetters } from "vuex";
import { QUERY_OFFICE_BY_USER } from '@/services/api'
import { request } from '@/utils/request'
import formIndex from '@/pages/components/form/index'

const formData = [
  {
    label: '管理部门',
    placeholder: '请选择管理部门',
    decorator: ['gcglbId', { rules: [{ required: false, message: '请选择管理部门' }] }],
    type: 'select',
    key: 'gcglbId',
    selectlist: [],
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
  }, 
  {
    label: '项目名称',
    placeholder: '请输入项目名称',
    decorator: ['projectName', { rules: [{ required: false, message: '请输入项目名称' }] }],
    type: 'select',
    key: 'projectName',
    selectlist: [],
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
  }, 
  {
    type: "action",
    col: 6,
    display: true,
  },
]

export default {
  components: {
    formIndex, //form表单封装
  },
  data() {
    return {
      formData,
      params: {},
      modalVisible: false,
      videoList: [],
      projectName: '',
      officeList: [],
      gcglbName: '',
      hlsModal: null,
      videoElement: null,
      loading: false
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  activated() {
    this.getgcglbList('/admin-api/system/dept/getOfficesByUser')
  },
  methods: {
    closeModal() {
      this.modalVisible = false
      if (this.hlsModal) {
        this.hlsModal.destroy()
        this.hlsModal = null
      }
      if (this.videoElement) {
        this.videoElement.play()
        this.videoElement = null
      }
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      this.loading = true
      request(path, 'get').then(res => {
        let data = res.data.data.filter(item => item.id != "3da0f5533f87425c9a9e8a11a7923300")
        this.formDataFn('gcglbId', data)
      })
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      this.officeList = data
      let arr = []
      data.forEach(item => {
        arr.push({ 'name': item.master, 'value': item.id })
      })
      this.formData.forEach(item => {
        if (item.key == key) {
          item.selectlist = arr
        }
      })
      this.$nextTick(() => {
        this.$refs.formindex.setFieldsValue('gcglbId', {'gcglbId': arr[0].value}, '')
        this.getVideoProjectData(arr[0].value, 'first')
      })
    },
    handlSelect({ val, keys }) {
      if (keys == 'gcglbId') {
        this.$refs.formindex.setFieldsValue('projectName', {'projectName': ''}, '')
        this.getVideoProjectData(val)
      }
    },
    getVideoProjectData(val, type) {
      getVideoProject({ officeId: val }).then(res => {
        if (res.status == 200) {
          const data = res.data.data
          let arr = []
          data.forEach(item => {
            if (item.children && item.children.length > 0) {
              item.children.forEach(el => {
                arr.push({ 'name': el.name, 'value': el.id })
              })
            }
          })
          this.formData.forEach(item => {
            if (item.key == 'projectName') {
              item.selectlist = arr
            }
          })
          if (type === 'first') {
            this.$nextTick(() => {
              this.$refs.formindex.setFieldsValue('projectName', {'projectName': arr[0].value}, '')
              this.params = {
                projectId: arr[0].value,
              }
              this.getData()
            })
          }
        }
      })
    },
    handleSubmit(e) {
      if (e.projectName && e.gcglbId) {
        this.loading = true
        this.params = {
          projectId: e.projectName,
        }
        this.getData()
      } else {
        this.$message.warning('请选择管理部门和项目名称')
      }
    },
    getData() {
      getVideoView(this.params).then((res) => {
        this.loading = false
        if (res.data.code === 0) {
          const gcglb = this.officeList.find(el => el.id == res.data.data.list[0].gcglbId)
          this.gcglbName = gcglb ? gcglb.name : ''
          this.projectName = res.data.data.list[0].projectName
          this.videoList = res.data.data.list[0].proejctVideoViewDOList
          this.videoList.forEach((el, index) => {
            this.$nextTick(() => {
              el.videoElement = this.$refs[`video${index}`][0];
              if (Hls.isSupported()) {
                el.hls = new Hls();
                el.hls.loadSource(el.url);
                el.hls.attachMedia(el.videoElement);
                el.hls.on(Hls.Events.MANIFEST_PARSED, () => {
                  el.videoElement.play();
                });
              } else if (el.videoElement.canPlayType('application/vnd.apple.mpegURL')) {
                el.videoElement.src = el.url;
                el.videoElement.addEventListener('loadedmetadata', () => {
                  el.videoElement.play();
                });
              }
            })
          })
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    onClickVideo(item, index) {
      this.modalVisible = true
      this.videoElement = item.videoElement
      this.videoElement.pause()
      this.$nextTick(() => {
        const video = this.$refs.videoModal
        if (Hls.isSupported()) {
          this.hlsModal = new Hls();
          this.hlsModal.loadSource(item.url);
          this.hlsModal.attachMedia(video);
          this.hlsModal.on(Hls.Events.MANIFEST_PARSED, () => {
            video.play();
          });
        } else if (video.canPlayType('application/vnd.apple.mpegURL')) {
          video.src = item.url;
          video.addEventListener('loadedmetadata', () => {
            video.play();
          });
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
.fullscreen-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  color: #fff;
  background: #9a9a9a;
}
</style>
